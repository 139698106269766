export interface YesNoTableFormatterOptions {}

export class YesNoTableFormatter {
  static default = new YesNoTableFormatter();

  format(value: boolean | undefined) {
    return YesNoTableFormatter.format(value);
  }

  static format(value: boolean | undefined) {
    return value ? "כן" : "לא";
  }
}
