import { FormApi } from "final-form";

export class FormCustomReset {
  static hardReset(form: FormApi<any>) {
    setTimeout(() => {
      form.batch(() => {
        Object.keys(form.getState().touched || {}).forEach(
          form.resetFieldState as any
        );
        form.setConfig("keepDirtyOnReinitialize", false);
        form.reset();
        form.setConfig("keepDirtyOnReinitialize", true);
      });
    }, 10);
  }
}
