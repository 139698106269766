import { UnitType } from "base/types";

export class AmountUnitTypeOptionsBuilder {
  static build(prepareUnitType: UnitType | undefined) {
    switch (prepareUnitType as UnitType) {
      case UnitType.KG:
        return [
          {
            label: "גרם",
            value: false,
          },
          {
            label: "ק״ג",
            value: true,
          },
        ];
      case UnitType.Liter:
        return [
          {
            label: "מ״ל",
            value: false,
          },
          {
            label: "ליטר",
            value: true,
          },
        ];
      case UnitType.Yeh:
        return [
          {
            label: "פריטים",
            value: false,
          },
        ];
      default:
        return [];
    }
  }
}
