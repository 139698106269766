import React, { useCallback, useState, useMemo } from "react";
import { DatePicker } from "base/components/DatePicker";
import { RouteContent } from "base/components/RouteContent";
import { Typography } from "base/components/Typography";
import { Form } from "form/components/Form";
import { FormTextInput } from "form/components/FormTextInput";
import { FormCustomReset } from "form/utils/FormCustomReset";
import { Row, Col, Button } from "react-bootstrap";
import { FormSpy, Field } from "react-final-form";
import { NumberFormatter, UnitTypeFormatter } from "base/utils/formatters";
import { FieldArray } from "react-final-form-arrays";
import { isEqual, sumBy } from "lodash";
import { AmountInput } from "base/components/input-controls/AmountInput";
import { FileViewer } from "base/components/FileViewer";
import { FileDir, BaseSelectListItem, UnitType } from "base/types";
import { FormNumberInput } from "form/components/FormNumberInput";
import {
  useCustomQuery,
  useCustomQueryWithParams,
  CustomQueryResponse,
} from "base/api/hooks/useCustomQuery";
import { FormSelect } from "form/components/FormSelect";
import { useSelectOptionsAdapter } from "base/components/Select/hooks/useSelectOptionsAdapter";
import { FieldsValidator } from "form/validators/FieldsValidator";
import { StockItemToAddFormValues } from "stock/types";
import { FormApi, getIn } from "final-form";
import { RoundCheckButton } from "base/components/RoundCheckButton";
import { SuppliersApi } from "supplier/SuppliersApi";
import { FormMetaError } from "form/components/FormMetaError";
import { useBooleanState } from "base/hooks/useBooleanState";
import { IngredSupplyMethodBarcodeModal } from "ingredient-supply-method/IngredSupplyMethodBarcodeModal";
import { format } from "date-fns";
import { SentOrdersRouteBuilder } from "sent-order/SentOrdersRouteBuilder";
import { useNavigateCallback } from "base/hooks/useNavigateCallback";
import { ProductSupplyMethodListItemModal } from "product/supply-method/ProductSupplyMethodListItemModal";
import { ProductsApi } from "product/ProductsApi";
import { ProductSupplyMethodsApi } from "product/supply-method/ProductSupplyMethodsApi";
import { SupplyMethodDescFormatter } from "order/SupplyMethodDescFormatter";
import { SimpleGlobalListApi } from "global-list/SimpleGlobalListApi";
import { SimpleGlobalListRoutePath } from "global-list/types";
import { ProductSelectListItemEditor } from "product/components/ProductSelectListItemEditor";
import { SentOrderFormValues, SentOrderItemPost } from "sent-order/types";
import { BaseSupplyMethod } from "base/types/BaseSupplyMethod";
import { Fields } from "form/components/Fields";
import { OrderHelper } from "order/OrderHelper";
import { SentOrdersApi } from "sent-order/SentOrdersApi";
import { OrderDocumentTypeOptions } from "sent-order/constants";

export const AddToStockScreen: React.FC = () => {
  const navigate = useNavigateCallback();
  const [selectedProductIdToAdd, setSelectedProductIdToAdd] = useState<
    number
  >();
  const {
    data: unitKinds,
  } = useCustomQueryWithParams(SimpleGlobalListApi.getList, () => [
    SimpleGlobalListRoutePath.UnitKinds,
  ]) as CustomQueryResponse<BaseSelectListItem[]>;
  const {
    data: packageKinds,
  } = useCustomQueryWithParams(SimpleGlobalListApi.getList, () => [
    SimpleGlobalListRoutePath.PackageKinds,
  ]) as CustomQueryResponse<BaseSelectListItem[]>;

  const { data: products } = useCustomQuery(ProductsApi.getList);
  const productsOptions = useSelectOptionsAdapter(products);

  const { data: suppliers } = useCustomQuery(SuppliersApi.getList);
  const suppliersOptions = useSelectOptionsAdapter(suppliers);

  const { data: selectedProductToAdd } = useCustomQueryWithParams(
    ProductsApi.getItem,
    () => [selectedProductIdToAdd!],
    {
      skip: selectedProductIdToAdd === undefined,
    }
  );
  const { data: selectedProductToAddSupplyMethods } = useCustomQueryWithParams(
    ProductSupplyMethodsApi.get,
    () => [selectedProductIdToAdd!],
    {
      skip: selectedProductIdToAdd === undefined,
    }
  );
  const selectedProductToAddSupplyMethodsOptions = useMemo(() => {
    if (!selectedProductToAddSupplyMethods) return [];
    return selectedProductToAddSupplyMethods.map((supplyMethod) => ({
      value: supplyMethod.id,
      label: SupplyMethodDescFormatter.format(
        supplyMethod,
        selectedProductToAdd?.prepareUnitType ?? 0,
        unitKinds,
        packageKinds
      ),
    }));
  }, [
    packageKinds,
    selectedProductToAdd,
    selectedProductToAddSupplyMethods,
    unitKinds,
  ]);

  const [
    displayBarcodeModal,
    onShowBarcodeModal,
    onHideBarcodeModal,
  ] = useBooleanState(false);

  const onSubmit = useCallback(
    async (values: SentOrderFormValues, form: FormApi<any, any>) => {
      await SentOrdersApi.saveOccasional(values);
      FormCustomReset.hardReset(form);
      alert("המלאי נקלט בהצלחה");
    },
    []
  );

  const fieldName = (property: keyof SentOrderFormValues) => property;
  const itemFieldName = (property: keyof SentOrderItemPost, prefix: string) =>
    `${prefix}.${property}`;
  const itemToAddFieldName = (property: keyof StockItemToAddFormValues) =>
    property;

  return (
    <RouteContent>
      <Typography variant="route-title">קליטה למלאי ללא הזמנה</Typography>
      <div>
        <Button
          onClick={() => {
            navigate(SentOrdersRouteBuilder.buildSentOrdersRoute());
          }}
        >
          קליטת הזמנה
        </Button>
      </div>
      <Form onSubmit={onSubmit} initialValues={{ isOccasional: true }}>
        {({ handleSubmit, form }) => (
          <div className="mt-4">
            <Row className="align-items-center mx-0">
              <Typography variant="description">ספק</Typography>
              <Col md={3}>
                <FormSelect
                  name={fieldName("supplierId")}
                  variant="react-select"
                  emptyOptionLabel="בחירת ספק"
                  options={suppliersOptions}
                  validate={FieldsValidator.required}
                />
              </Col>
              <Typography variant="description">אסמכתא</Typography>
              <Col md={2}>
                <FormTextInput name={fieldName("referenceNo")} required />
              </Col>
              <Typography variant="description">סוג מסמך</Typography>
              <Col md={2}>
                <FormSelect
                  name={fieldName("orderDocumentType")}
                  options={OrderDocumentTypeOptions}
                />
              </Col>
              <Typography variant="description">תאריך קליטה</Typography>
              <Col md={2}>
                <Field
                  name={fieldName("receiveDate")}
                  defaultValue={format(Date.now(), "yyyy-MM-dd")}
                  render={({ input, meta }) => (
                    <>
                      <DatePicker {...input} />
                      <FormMetaError meta={meta} />
                    </>
                  )}
                  validate={FieldsValidator.required}
                />
              </Col>
            </Row>
            <div className="my-5">
              <FieldArray<SentOrderItemPost>
                subscription={{ length: true }}
                isEqual={isEqual}
                name={fieldName("items")}
              >
                {({ fields: itemsFields }) => (
                  <>
                    <Typography variant="title">
                      מוצרים({itemsFields.length})
                    </Typography>
                    <table className="table table-sm ing-table">
                      <thead>
                        <tr>
                          <th />
                          <th />
                          <th />
                          <th />
                          <th />
                          <th />
                          <th />
                          <FormSpy<Partial<SentOrderFormValues>>
                            subscription={{ values: true }}
                            render={({ values }) => {
                              const totalOrder = sumBy(
                                values.items,
                                (item) =>
                                  item.receivedPrice * item.receivedAmount
                              );

                              return (
                                <th>
                                  סה״כ הזמנה:{" "}
                                  {NumberFormatter.default.formatPrice(
                                    totalOrder ?? 0
                                  )}
                                </th>
                              );
                            }}
                          />
                        </tr>
                        <tr>
                          <th />
                          <th />
                          <th style={{ width: "20%" }}>שם</th>
                          <th style={{ width: "15%" }}>כמות</th>
                          <th>צורת אספקה</th>
                          <th>מחיר</th>
                          <th>סה״כ כמות</th>
                          <th>סה״כ</th>
                        </tr>
                      </thead>
                      <tbody>
                        {itemsFields.map((prefix, index) => (
                          <tr>
                            <td>
                              <i
                                className="fas fa-trash text-danger ml-auto"
                                role="button"
                                onClick={() => {
                                  itemsFields.remove(index);
                                }}
                              />
                            </td>
                            <Field
                              name={itemFieldName("productId", prefix)}
                              render={({ input: { value: productId } }) => (
                                <td>
                                  <FileViewer
                                    type="image"
                                    imageProps={{
                                      style: { height: 24, width: 24 },
                                    }}
                                    fileName={
                                      products?.find(
                                        (ing) => ing.id === productId
                                      )!.imageName
                                    }
                                    fileDir={FileDir.Product}
                                  />
                                </td>
                              )}
                            />
                            <Field
                              name={itemFieldName("productId", prefix)}
                              render={({ input: { value: productId } }) => (
                                <td>
                                  {
                                    products?.find(
                                      (ing) => ing.id === productId
                                    )!.name
                                  }
                                </td>
                              )}
                            />
                            <td>
                              <div className="d-flex">
                                <Field
                                  name={itemFieldName("receivedAmount", prefix)}
                                  format={(val) =>
                                    val === "" ? undefined : val
                                  }
                                  render={({ input }) => (
                                    <AmountInput
                                      fixedDidigts={3}
                                      numpadTitle="בחר כמות"
                                      value={input.value}
                                      onChange={input.onChange}
                                      min={-Infinity}
                                    />
                                  )}
                                />
                                <div className="px-1">
                                  <Field<BaseSupplyMethod>
                                    name={itemFieldName(
                                      "receivedSupplyMethod",
                                      prefix
                                    )}
                                    render={({
                                      input: { value: supplyMethod },
                                    }) =>
                                      SupplyMethodDescFormatter.formatSupply(
                                        supplyMethod,
                                        unitKinds,
                                        packageKinds
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </td>
                            <td>
                              <Fields
                                names={[
                                  itemFieldName("receivedSupplyMethod", prefix),
                                  itemFieldName("productId", prefix),
                                ]}
                                render={([supplyMethod, productId]) => (
                                  <>
                                    {SupplyMethodDescFormatter.format(
                                      supplyMethod,
                                      products?.find((p) => p.id === productId)
                                        ?.prepareUnitType ?? 0,
                                      unitKinds,
                                      packageKinds
                                    )}
                                  </>
                                )}
                              />
                            </td>
                            <td>
                              <Field
                                name={itemFieldName("receivedPrice", prefix)}
                                format={(val) => (val === "" ? undefined : val)}
                                validate={FieldsValidator.required}
                                render={({ input, meta }) => (
                                  <div>
                                    <AmountInput
                                      fixedDidigts={2}
                                      numpadTitle="בחר כמות"
                                      value={input.value}
                                      onChange={input.onChange}
                                      min={-Infinity}
                                    />
                                    <FormMetaError meta={meta} />
                                  </div>
                                )}
                              />
                            </td>
                            {/* סה״כ כמות */}
                            <FormSpy<SentOrderFormValues>
                              subscription={{ values: true }}
                              render={({ values }) => {
                                const item: SentOrderItemPost = getIn(
                                  values,
                                  prefix
                                );
                                const { prepareUnitType = 0 } =
                                  products?.find(
                                    (p) => p.id === item.productId
                                  ) ?? {};
                                return (
                                  <th>
                                    {NumberFormatter.default.formatAmount(
                                      OrderHelper.calcTotalAmount(
                                        item.receivedAmount,
                                        item.receivedSupplyMethod,
                                        prepareUnitType
                                      )
                                    )}{" "}
                                    {UnitTypeFormatter.format(
                                      prepareUnitType,
                                      prepareUnitType === UnitType.Yeh
                                    )}
                                  </th>
                                );
                              }}
                            />
                            {/* סה״כ */}
                            <td>
                              <Fields
                                names={[
                                  itemFieldName("receivedPrice", prefix),
                                  itemFieldName("receivedAmount", prefix),
                                ]}
                                render={([receivedPrice, receivedAmount]) => (
                                  <AmountInput
                                    value={
                                      (receivedPrice ?? 0) *
                                      (receivedAmount ?? 0)
                                    }
                                    onChange={(nextValue) => {
                                      if (!receivedAmount) {
                                        return;
                                      }

                                      const netReceivedPrice =
                                        nextValue / receivedAmount;

                                      form.change(
                                        itemFieldName(
                                          "receivedPrice",
                                          prefix
                                        ) as any,
                                        netReceivedPrice
                                      );
                                    }}
                                    fixedDidigts={2}
                                  />
                                )}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="mt-5">
                      <Typography variant="title">הוספת מוצר</Typography>
                      <Field
                        name={fieldName("supplierId")}
                        render={({ input: { value: supplierId } }) => (
                          <Form
                            onSubmit={(
                              values: StockItemToAddFormValues,
                              addForm
                            ) => {
                              FormCustomReset.hardReset(addForm);
                              const productSupplyMethod = selectedProductToAddSupplyMethods!.find(
                                (sm) => sm.id === values.productSupplyMethodId
                              )!;
                              const psmSupplier = productSupplyMethod.suppliers.find(
                                (x) => x.supplierId === supplierId
                              );

                              itemsFields.push({
                                productId: values.productId,
                                receivedAmount: values.amount,
                                requestedAmount: values.amount,
                                documentAmount: values.amount,
                                receivedPrice: psmSupplier?.price ?? 0,
                                requestedPrice: psmSupplier?.price ?? 0,
                                receivedSupplyMethod: productSupplyMethod as BaseSupplyMethod,
                                requestedSupplyMethod: productSupplyMethod as BaseSupplyMethod,
                                isRemoveAmountGap: false,
                                isRemovePriceGap: false,
                              } as SentOrderItemPost);
                            }}
                            subscription={{ valid: true }}
                          >
                            {({ handleSubmit, valid, form: addForm }) => (
                              <>
                                <Row className="mx-0">
                                  <Col md={3}>
                                    <FormSelect
                                      variant="react-select"
                                      name={itemToAddFieldName("productId")}
                                      emptyOptionLabel="בחירת מוצר"
                                      options={productsOptions}
                                      validate={FieldsValidator.required}
                                      withAdd={{
                                        component: ProductSelectListItemEditor,
                                      }}
                                    />
                                  </Col>
                                  <Col md={3}>
                                    <FormSelect
                                      variant="react-select"
                                      name={itemToAddFieldName(
                                        "productSupplyMethodId"
                                      )}
                                      emptyOptionLabel="בחירת צורת אספקה"
                                      options={
                                        selectedProductToAddSupplyMethodsOptions
                                      }
                                      validate={FieldsValidator.required}
                                      disabled={
                                        selectedProductIdToAdd === undefined
                                      }
                                      withAdd={{
                                        customModalRender: (props) => (
                                          <ProductSupplyMethodListItemModal
                                            {...props}
                                            productName={
                                              products?.find(
                                                (p) =>
                                                  p.id ===
                                                  selectedProductIdToAdd
                                              )?.name ?? ""
                                            }
                                            initialValues={{
                                              productId: selectedProductIdToAdd!,
                                              supplierId: supplierId,
                                            }}
                                          />
                                        ),
                                      }}
                                    />
                                  </Col>
                                  <Col md={3}>
                                    <FormNumberInput
                                      name={itemToAddFieldName("amount")}
                                      placeholder="כמות"
                                      required
                                      maxDigitsAfterDot={3}
                                    />
                                  </Col>
                                  <Col md={3} className="d-flex">
                                    <Button
                                      disabled={!valid}
                                      className="mx-1"
                                      variant="primary"
                                      onClick={handleSubmit}
                                    >
                                      הוספה
                                    </Button>
                                    <Button
                                      className="mx-1"
                                      variant="success"
                                      onClick={onShowBarcodeModal}
                                    >
                                      קליטה לפי ברקוד
                                    </Button>
                                  </Col>
                                </Row>
                                <FormSpy<Partial<StockItemToAddFormValues>>
                                  subscription={{ values: true }}
                                  onChange={({ values }) => {
                                    const { productId } = values;
                                    setSelectedProductIdToAdd(productId);
                                  }}
                                />
                                <IngredSupplyMethodBarcodeModal
                                  show={displayBarcodeModal}
                                  onHide={onHideBarcodeModal}
                                  onMatch={(supplyMethod) => {
                                    FormCustomReset.hardReset(addForm);
                                    // addForm.change('productId', 9999999);
                                    // addForm.change('productSupplyMethodId', 999999)
                                  }}
                                />
                              </>
                            )}
                          </Form>
                        )}
                      />
                    </div>
                  </>
                )}
              </FieldArray>
              {/* Sync between received and requested values. */}
              <FormSpy<Partial<SentOrderFormValues>>
                subscription={{ values: true }}
                onChange={() => {
                  const { values } = form.getState();
                  if (!values.items) return;

                  form.batch(() => {
                    values.items.forEach((item, i) => {
                      const prefix = `items[${i}]`;
                      form.change(
                        itemFieldName("requestedAmount", prefix) as any,
                        item.receivedAmount
                      );
                      form.change(
                        itemFieldName("requestedPrice", prefix) as any,
                        item.receivedPrice
                      );
                      form.change(
                        itemFieldName("requestedSupplyMethod", prefix) as any,
                        item.receivedSupplyMethod
                      );
                    });
                  });
                }}
              />
            </div>
            <RoundCheckButton onClick={handleSubmit} />
          </div>
        )}
      </Form>
    </RouteContent>
  );
};
