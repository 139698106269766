import React, { useMemo, useRef } from "react";
import { Modal, Row, Col, Button } from "react-bootstrap";
import "modal/styles.scss";
import {
  ProductSupplyMethodSupplier,
  SupplyMethodSuppliersFormValues,
} from "./types";
import { Typography } from "base/components/Typography";
import { Form } from "form/components/Form";
import { noop } from "base/utils/noop";
import { FormSelect } from "form/components/FormSelect";
import { SuppliersApi } from "supplier/SuppliersApi";
import {
  useCustomQuery,
  useCustomQueryWithParams,
} from "base/api/hooks/useCustomQuery";
import { useSelectOptionsAdapter } from "base/components/Select/hooks/useSelectOptionsAdapter";
import { FieldsValidator } from "form/validators/FieldsValidator";
import { FieldArray } from "react-final-form-arrays";
import { ProductSupplyMethodsApi } from "./ProductSupplyMethodsApi";
import { FormNumberInput } from "form/components/FormNumberInput";
import { FormCheckbox } from "form/components/FormCheckbox";
import { Field, FormSpy } from "react-final-form";
import { Cancelable, isEqual, debounce } from "lodash";
import { getIn } from "final-form";
import { FormCustomReset } from "form/utils/FormCustomReset";
import { FormTextInput } from "form/components/FormTextInput";

export type ProductSupplyMethodSuppliersModalProps = {
  show: boolean;
  productId: number;
  productSupplyMethodId?: number;
  onHide: () => void;
};

export const ProductSupplyMethodSuppliersModal: React.FC<ProductSupplyMethodSuppliersModalProps> = ({
  show,
  productId,
  productSupplyMethodId,
  onHide,
}) => {
  const {
    data: supplyMethods,
  } = useCustomQueryWithParams(ProductSupplyMethodsApi.get, () => [productId]);

  const data = useMemo(() => {
    return supplyMethods?.find((s) => s.id === productSupplyMethodId)
      ?.suppliers;
  }, [productSupplyMethodId, supplyMethods]);

  const fieldName = (
    property: keyof ProductSupplyMethodSupplier,
    prefix?: string
  ) => `${prefix ? `${prefix}.` : ""}${property}`;

  const { data: suppliers } = useCustomQuery(SuppliersApi.getList);
  const suppliersOptions = useSelectOptionsAdapter(suppliers);

  const initialValues = useMemo<SupplyMethodSuppliersFormValues>(() => {
    return { rows: data ?? [] };
  }, [data]);

  const saveFn = async (data: ProductSupplyMethodSupplier) => {
    await ProductSupplyMethodsApi.saveSupplyMethodSupplier(productId, data);
  };

  const rowsAutoSaveMapRef = useRef<{
    [id: number]: typeof saveFn & Cancelable;
  }>({});

  return (
    <Modal show={show} onHide={onHide} centered size="xl">
      <div>
        <div className="modal-title-bar">
          <Typography variant="description" fontSize={24} bold>
            ספקים
          </Typography>
          <div className="close-btn" onClick={onHide} />
        </div>
      </div>
      <div className="p-2">
        <Form<ProductSupplyMethodSupplier>
          initialValues={{
            productSupplyMethodId,
            isDefault: data && data.length === 0,
          }}
          onSubmit={async (values, form) => {
            await saveFn(values);
            FormCustomReset.hardReset(form);
          }}
          subscription={{ submitting: true }}
        >
          {({ handleSubmit, submitting }) => (
            <Row noGutters>
              <Col md={3} className="px-1">
                <FormSelect
                  name={fieldName("supplierId")}
                  variant="react-select"
                  emptyOptionLabel="בחירת ספק"
                  options={suppliersOptions}
                  validate={FieldsValidator.required}
                />
              </Col>
              <Col md={1} className="px-1">
                <FormTextInput
                  name={fieldName("supplierCatalogId")}
                  placeholder={'מק"ט'}
                />
              </Col>
              <Col md={1} className="px-1">
                <FormNumberInput
                  name={fieldName("price")}
                  placeholder="מחיר"
                  selectOnFocus
                />
              </Col>
              <Col md={2} className="px-1">
                <FormNumberInput
                  name={fieldName("taraWeight")}
                  placeholder="משקל אריזה"
                />
              </Col>
              <Col md={1} className="px-1">
                <FormNumberInput
                  name={fieldName("discountPercentage")}
                  placeholder="אחוז הנחה"
                  selectOnFocus
                  validate={FieldsValidator.compose(
                    FieldsValidator.required,
                    FieldsValidator.minValue(0)
                  )}
                />
              </Col>
              <Col md="auto" className="px-1">
                <label>
                  <div className="d-inline-block align-middle mx-2">
                    <FormCheckbox
                      name={fieldName("isDefault")}
                      width={23}
                      disabled={data && data.length === 0}
                    />
                  </div>
                  ברירת מחדל
                </label>
              </Col>
              <Col md="auto" className="px-1">
                <Button
                  variant="primary"
                  onClick={handleSubmit}
                  disabled={submitting || !data}
                >
                  הוספה
                </Button>
              </Col>
            </Row>
          )}
        </Form>
        <div className="pt-2">
          <Typography variant="description" fontSize={20} bold>
            ספקים קיימים
          </Typography>
        </div>
        <Form onSubmit={noop} initialValues={initialValues}>
          {({ form }) => (
            <>
              <FieldArray name="rows">
                {({ fields }) =>
                  fields.map((prefix, index) => {
                    const rowId: number = getIn(
                      form.getState().values,
                      fieldName("id", prefix)
                    );
                    return (
                      <Row noGutters>
                        <Col md={3} className="px-1">
                          <FormSelect
                            name={fieldName("supplierId", prefix)}
                            variant="react-select"
                            emptyOptionLabel="בחירת ספק"
                            options={suppliersOptions}
                            validate={FieldsValidator.required}
                          />
                        </Col>
                        <Col md={1} className="px-1">
                          <FormTextInput
                            name={fieldName("supplierCatalogId", prefix)}
                            placeholder={'מק"ט'}
                          />
                        </Col>
                        <Col md={1} className="px-1">
                          <FormNumberInput
                            name={fieldName("price", prefix)}
                            placeholder="מחיר"
                            selectOnFocus
                          />
                        </Col>
                        <Col md={2} className="px-1">
                          <FormNumberInput
                            name={fieldName("taraWeight", prefix)}
                            placeholder="משקל אריזה"
                          />
                        </Col>
                        <Col md={2} className="px-1">
                          <FormNumberInput
                            name={fieldName("discountPercentage", prefix)}
                            placeholder="אחוז הנחה"
                            selectOnFocus
                            validate={FieldsValidator.compose(
                              FieldsValidator.required,
                              FieldsValidator.minValue(0)
                            )}
                          />
                        </Col>
                        <Col
                          className="p-1 d-flex align-items-center"
                          xs="auto"
                        >
                          <Field
                            name={fieldName("isDefault", prefix)}
                            render={({ input: { value: checked } }) => (
                              <label>
                                <div className="d-inline-block align-middle mx-2">
                                  <input
                                    type="radio"
                                    className="form-control"
                                    style={{ width: 30, height: 30 }}
                                    checked={checked}
                                    onClick={() => {
                                      form.change(
                                        fieldName("isDefault", prefix) as any,
                                        true
                                      );
                                    }}
                                  />
                                </div>
                                ברירת מחדל
                              </label>
                            )}
                          />
                        </Col>
                        <Field
                          name={`${prefix}.isDefault`}
                          render={({ input: { value: isDefault } }) => (
                            <Col className="p-1">
                              <Button
                                variant="danger"
                                disabled={isDefault}
                                title={
                                  isDefault
                                    ? "לא ניתן למחוק את שורת ברירת המחדל"
                                    : ""
                                }
                                onClick={() => {
                                  fields.remove(index);
                                  ProductSupplyMethodsApi.deleteSupplyMethodSupplier(
                                    productId,
                                    productSupplyMethodId!,
                                    rowId
                                  );
                                }}
                              >
                                מחיקה
                              </Button>
                            </Col>
                          )}
                        />
                        <FormSpy
                          subscription={{
                            validating: true,
                          }}
                          onChange={async ({ validating }) => {
                            if (!rowsAutoSaveMapRef.current[rowId]) {
                              rowsAutoSaveMapRef.current[rowId] = debounce(
                                saveFn,
                                250
                              );
                            }

                            const formState = form.getState();
                            if (validating || !formState.valid) {
                              rowsAutoSaveMapRef.current[rowId].cancel();
                              return;
                            }

                            const { values, initialValues } = formState;
                            const nextRowValue = values?.rows.find(
                              (row) => row.id === rowId
                            );
                            const prevRowValue = initialValues?.rows?.find(
                              (row) => row.id === rowId
                            );

                            if (
                              nextRowValue &&
                              prevRowValue &&
                              !isEqual(nextRowValue, prevRowValue)
                            ) {
                              rowsAutoSaveMapRef.current[rowId](nextRowValue);
                            }
                          }}
                        />
                      </Row>
                    );
                  })
                }
              </FieldArray>
              <FormSpy
                subscription={{
                  values: true,
                }}
                onChange={() => {
                  const { initialValues, values } = form.getState();

                  if (
                    (values.rows ?? []).filter((x) => x.isDefault).length > 1
                  ) {
                    const prevContactWithDefaultIdx = initialValues.rows!.findIndex(
                      (x) => x.isDefault
                    );
                    if (prevContactWithDefaultIdx !== -1) {
                      form.change(
                        fieldName(
                          "isDefault",
                          `rows[${prevContactWithDefaultIdx}]`
                        ) as any,
                        false
                      );
                    }
                  }
                }}
              />
            </>
          )}
        </Form>
      </div>
    </Modal>
  );
};
