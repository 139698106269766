import { Row, Col, Button } from "react-bootstrap";
import React, { useMemo, useState } from "react";
import { Form } from "form/components/Form";
import { FormSelect } from "form/components/FormSelect";
import { FormNumberInput } from "form/components/FormNumberInput";
import { FormCheckbox } from "form/components/FormCheckbox";
import { Typography } from "base/components/Typography";
import { ProductSupplyMethod, ProductSupplyMethodsFormValues } from "./types";
import { FieldArray } from "react-final-form-arrays";
import { isEqual } from "lodash";
import { SupplyUnitTypesSelectOptions } from "base/components/Select/constants/SupplyUnitTypesSelectOptions";
import {
  useCustomQueryWithParams,
  CustomQueryResponse,
} from "base/api/hooks/useCustomQuery";
import { ProductSupplyMethodsApi } from "./ProductSupplyMethodsApi";
import { FormCustomReset } from "form/utils/FormCustomReset";
import { FormApi, getIn } from "final-form";
import { FormSpy, Field } from "react-final-form";
import { useSelectOptionsAdapter } from "base/components/Select/hooks/useSelectOptionsAdapter";
import { FieldsValidator } from "form/validators/FieldsValidator";
import { UnitType, SupplyUnitType, BaseSelectListItem } from "base/types";
import { SelectProps } from "base/components/Select";
import { noop } from "base/utils/noop";
import { IngredientsApi } from "ingredient/IngredientsApi";
import { SimpleGlobalListApi } from "global-list/SimpleGlobalListApi";
import { SimpleGlobalListRoutePath } from "global-list/types";
import { SimpleGlobalListItemModal } from "global-list/components/SimpleGlobalListItemModal";
import { TextFormatter } from "base/utils/formatters/TextFormatter";
import { ProductSupplyMethodSuppliersModal } from "./ProductSupplyMethodSuppliersModal";
import { useBooleanState } from "base/hooks/useBooleanState";
import { AmountUnitTypeOptionsBuilder } from "./AmountUnitTypeOptionsBuilder";

export type ProductSupplyMethodsEditorProps = {
  productId: number;
  ingredientId: number;
};

export const ProductSupplyMethodsEditor: React.FC<ProductSupplyMethodsEditorProps> = ({
  productId,
  ingredientId,
}) => {
  const {
    data: supplyMethods,
  } = useCustomQueryWithParams(ProductSupplyMethodsApi.get, () => [productId]);

  const { data: ingredient } = useCustomQueryWithParams(
    IngredientsApi.getItem,
    () => [ingredientId]
  );

  const {
    data: unitKinds,
  } = useCustomQueryWithParams(SimpleGlobalListApi.getList, () => [
    SimpleGlobalListRoutePath.UnitKinds,
  ]) as CustomQueryResponse<BaseSelectListItem[]>;
  const unitKindsOptions = useSelectOptionsAdapter(unitKinds);

  const {
    data: packageKinds,
  } = useCustomQueryWithParams(SimpleGlobalListApi.getList, () => [
    SimpleGlobalListRoutePath.PackageKinds,
  ]) as CustomQueryResponse<BaseSelectListItem[]>;
  const packageKindsOptions = useSelectOptionsAdapter(packageKinds);

  const amountUnitTypeOptions = useMemo<SelectProps["options"]>(() => {
    return AmountUnitTypeOptionsBuilder.build(ingredient?.prepareUnitType);
  }, [ingredient]);

  const initialFormValues = useMemo<ProductSupplyMethodsFormValues>(
    () => ({
      methods: supplyMethods ?? [],
    }),
    [supplyMethods]
  );

  const [
    showSupplierModal,
    onShowSupplierModal,
    onHideSupplierModal,
  ] = useBooleanState(false);

  const [supplierModalInfo, setSupplierModalInfo] = useState<{
    supplyMethodId: number;
  }>();

  const saveFn = async (data: ProductSupplyMethod) => {
    try {
      await ProductSupplyMethodsApi.update(data);
    } catch (error) {
      alert(error.response.data?.error ?? "לא ניתן לשמור את הפריט");
    }
  };

  return (
    <>
      <Typography variant="description" fontSize={24} bold>
        אספקה
      </Typography>
      <Form<ProductSupplyMethodsFormValues>
        onSubmit={noop}
        initialValues={initialFormValues}
      >
        {({ form }) => (
          <div className="p-2 pt-3">
            <Typography variant="description" fontSize={16} bold>
              הוספה
            </Typography>
            <Form
              initialValues={{
                productId,
                isDefault: supplyMethods && supplyMethods.length === 0,
              }}
              onSubmit={async (
                newItem: ProductSupplyMethod,
                newSupplyMethodForm: FormApi<any>
              ) => {
                try {
                  await ProductSupplyMethodsApi.add(newItem);
                  FormCustomReset.hardReset(newSupplyMethodForm);
                } catch (error) {
                  alert(error.response.data?.error ?? "לא ניתן לשמור את הפריט");
                }
              }}
              subscription={{ submitting: true }}
            >
              {({ handleSubmit, submitting }) => (
                <Row noGutters className="pt-1">
                  <Col md={1} className="px-1">
                    <FormNumberInput name="barCode" placeholder="ברקוד" />
                  </Col>
                  <Col md="auto" className="px-1">
                    <FormSelect
                      name="supplyUnitType"
                      emptyOptionLabel="צורת אספקה"
                      options={SupplyUnitTypesSelectOptions}
                      validate={FieldsValidator.required}
                    />
                  </Col>
                  <Field
                    name="supplyUnitType"
                    render={({ input: supplyUnitTypeInput }) =>
                      supplyUnitTypeInput.value !== SupplyUnitType.Amount && (
                        <>
                          {supplyUnitTypeInput.value ===
                            SupplyUnitType.Package && (
                            <Col md={2} className="px-1">
                              <FormSelect
                                name="packageKindId"
                                emptyOptionLabel="סוג מארז"
                                options={packageKindsOptions}
                                withAdd={{
                                  customModalRender: (props) => (
                                    <SimpleGlobalListItemModal
                                      {...props}
                                      routeApiPath={
                                        SimpleGlobalListRoutePath.PackageKinds
                                      }
                                    />
                                  ),
                                }}
                              />
                            </Col>
                          )}
                          {(supplyUnitTypeInput.value ===
                            SupplyUnitType.SingleItem ||
                            supplyUnitTypeInput.value ===
                              SupplyUnitType.Package) && (
                            <Col md={2} className="px-1">
                              <FormSelect
                                name="unitKindId"
                                emptyOptionLabel="סוג פריט"
                                options={unitKindsOptions}
                                withAdd={{
                                  customModalRender: (props) => (
                                    <SimpleGlobalListItemModal
                                      {...props}
                                      routeApiPath={
                                        SimpleGlobalListRoutePath.UnitKinds
                                      }
                                    />
                                  ),
                                }}
                              />
                            </Col>
                          )}
                          {supplyUnitTypeInput.value &&
                            supplyUnitTypeInput.value !==
                              SupplyUnitType.SingleItem && (
                              <Col md={1} className="px-1">
                                <FormNumberInput
                                  name="unitsInPackage"
                                  placeholder="פריטים במארז"
                                />
                              </Col>
                            )}
                          {supplyUnitTypeInput.value &&
                            supplyUnitTypeInput.value !==
                              SupplyUnitType.Amount &&
                            ingredient?.prepareUnitType !== UnitType.Yeh && (
                              <Col md={1} className="px-1">
                                <FormNumberInput
                                  name="unitWeight"
                                  placeholder="כמות"
                                />
                              </Col>
                            )}
                          {supplyUnitTypeInput.value &&
                            supplyUnitTypeInput.value !==
                              SupplyUnitType.Amount &&
                            ingredient?.prepareUnitType !== UnitType.Yeh && (
                              <Col md={1} className="px-1">
                                <FormSelect
                                  name="isLargeUnit"
                                  options={amountUnitTypeOptions}
                                  displayEmptyOption={false}
                                />
                              </Col>
                            )}
                        </>
                      )
                    }
                  />
                  <Col md={1} className="px-1">
                    <FormNumberInput
                      name="freshnessDays"
                      placeholder="ימי טריות"
                    />
                  </Col>
                  <Col md="auto" className="px-1">
                    <label>
                      <div className="d-inline-block align-middle mx-2">
                        <FormCheckbox
                          name="isDefault"
                          width={23}
                          disabled={supplyMethods && supplyMethods.length === 0}
                        />
                      </div>
                      ברירת מחדל
                    </label>
                  </Col>
                  <Col md="auto" className="px-1">
                    <Button
                      variant="primary"
                      onClick={handleSubmit}
                      disabled={submitting || !supplyMethods}
                    >
                      הוספה
                    </Button>
                  </Col>
                </Row>
              )}
            </Form>
            <Typography variant="description" fontSize={16} bold>
              סוגי אספקה קיימים
            </Typography>
            <FieldArray
              name="methods"
              subscription={{ length: true }}
              isEqual={isEqual}
            >
              {({ fields }) =>
                fields.map((prefix, index) => {
                  const supplyMethodId: number = getIn(
                    form.getState().values,
                    `${prefix}.id`
                  );
                  return (
                    <React.Fragment key={prefix}>
                      <Row noGutters>
                        <Col md={1} className="p-1">
                          <FormNumberInput
                            name={`${prefix}.barCode`}
                            placeholder="ברקוד"
                          />
                        </Col>
                        <Col md="auto" className="p-1">
                          <FormSelect
                            name={`${prefix}.supplyUnitType`}
                            emptyOptionLabel="צורת אספקה"
                            options={SupplyUnitTypesSelectOptions}
                            displayEmptyOption={false}
                            validate={FieldsValidator.required}
                          />
                        </Col>
                        <Field
                          name={`${prefix}.supplyUnitType`}
                          render={({ input: supplyUnitTypeInput }) =>
                            supplyUnitTypeInput.value !== undefined &&
                            supplyUnitTypeInput.value !==
                              SupplyUnitType.Amount && (
                              <>
                                {supplyUnitTypeInput.value ===
                                  SupplyUnitType.Package && (
                                  <Col md={2} className="p-1">
                                    <FormSelect
                                      name={`${prefix}.packageKindId`}
                                      emptyOptionLabel="סוג מארז"
                                      options={packageKindsOptions}
                                      withAdd={{
                                        customModalRender: (props) => (
                                          <SimpleGlobalListItemModal
                                            {...props}
                                            routeApiPath={
                                              SimpleGlobalListRoutePath.PackageKinds
                                            }
                                          />
                                        ),
                                      }}
                                    />
                                  </Col>
                                )}
                                {(supplyUnitTypeInput.value ===
                                  SupplyUnitType.SingleItem ||
                                  supplyUnitTypeInput.value ===
                                    SupplyUnitType.Package) && (
                                  <Col md={2} className="p-1">
                                    <FormSelect
                                      name={`${prefix}.unitKindId`}
                                      emptyOptionLabel="סוג פריט"
                                      options={unitKindsOptions}
                                      withAdd={{
                                        customModalRender: (props) => (
                                          <SimpleGlobalListItemModal
                                            {...props}
                                            routeApiPath={
                                              SimpleGlobalListRoutePath.UnitKinds
                                            }
                                          />
                                        ),
                                      }}
                                    />
                                  </Col>
                                )}
                                {supplyUnitTypeInput.value !==
                                  SupplyUnitType.SingleItem && (
                                  <Col md={1} className="p-1">
                                    <FormNumberInput
                                      name={`${prefix}.unitsInPackage`}
                                      placeholder="פריטים במארז"
                                    />
                                  </Col>
                                )}
                                {supplyUnitTypeInput.value !==
                                  SupplyUnitType.Amount &&
                                  ingredient?.prepareUnitType !==
                                    UnitType.Yeh && (
                                    <Col md={1} className="p-1">
                                      <FormNumberInput
                                        name={`${prefix}.unitWeight`}
                                        placeholder="כמות"
                                      />
                                    </Col>
                                  )}
                                {supplyUnitTypeInput.value !==
                                  SupplyUnitType.Amount &&
                                  ingredient?.prepareUnitType !==
                                    UnitType.Yeh && (
                                    <Col md={1} className="p-1">
                                      <FormSelect
                                        name={`${prefix}.isLargeUnit`}
                                        options={amountUnitTypeOptions}
                                        displayEmptyOption={false}
                                      />
                                    </Col>
                                  )}
                              </>
                            )
                          }
                        />
                        <Col md={1} className="p-1">
                          <FormNumberInput
                            name={`${prefix}.freshnessDays`}
                            placeholder="ימי טריות"
                          />
                        </Col>
                        <Col
                          md="auto"
                          className="px-1 d-flex align-items-center"
                        >
                          <label>
                            <div className="d-inline-block align-middle mx-2">
                              <Field
                                name={`${prefix}.isDefault`}
                                render={({ input: { value: checked } }) => (
                                  <input
                                    type="radio"
                                    checked={checked}
                                    onClick={() => {
                                      form.change(
                                        `${prefix}.isDefault` as any,
                                        true
                                      );
                                    }}
                                  />
                                )}
                              />
                            </div>
                            ברירת מחדל
                          </label>
                        </Col>
                        <Col md="auto" className="px-1">
                          <Field
                            name={`${prefix}.suppliers`}
                            render={({ input: { value: suppliers } }) => (
                              <Button
                                variant="primary"
                                onClick={() => {
                                  setSupplierModalInfo({ supplyMethodId });
                                  onShowSupplierModal();
                                }}
                              >
                                {TextFormatter.format("$1 ($2)", [
                                  "ספקים",
                                  suppliers.length,
                                ])}
                              </Button>
                            )}
                          />
                        </Col>
                        <Field
                          name={`${prefix}.isDefault`}
                          render={({ input: { value: isDefault } }) => (
                            <Col md="auto" className="px-1">
                              <Button
                                variant="danger"
                                disabled={isDefault}
                                title={
                                  isDefault
                                    ? "לא ניתן למחוק את שורת ברירת המחדל"
                                    : ""
                                }
                                onClick={() => {
                                  const item: ProductSupplyMethod = getIn(
                                    form.getState().values,
                                    prefix
                                  );
                                  fields.remove(index);
                                  ProductSupplyMethodsApi.remove(
                                    productId!,
                                    item.id
                                  );
                                }}
                              >
                                מחיקה
                              </Button>
                            </Col>
                          )}
                        />
                      </Row>
                      <FormSpy
                        subscription={{
                          validating: true,
                        }}
                        onChange={async ({ validating }) => {
                          const formState = form.getState();
                          if (validating || !formState.valid) {
                            return;
                          }

                          const { values, initialValues } = formState;
                          const nextRowValue = values?.methods.find(
                            (x) => x.id === supplyMethodId
                          );
                          const prevRowValue = initialValues?.methods?.find(
                            (x) => x.id === supplyMethodId
                          );

                          if (
                            nextRowValue &&
                            prevRowValue &&
                            !isEqual(nextRowValue, prevRowValue)
                          ) {
                            saveFn(nextRowValue);
                          }
                        }}
                      />
                    </React.Fragment>
                  );
                })
              }
            </FieldArray>
            <FormSpy
              subscription={{
                values: true,
              }}
              onChange={() => {
                const { initialValues, values } = form.getState();

                if (
                  (values.methods ?? []).filter((x) => x.isDefault).length > 1
                ) {
                  const ingredientWithDefaultIdx = initialValues.methods!.findIndex(
                    (x) => x.isDefault
                  );
                  if (ingredientWithDefaultIdx !== -1) {
                    form.change(
                      `methods[${ingredientWithDefaultIdx}].isDefault` as any,
                      false
                    );
                  }
                }
              }}
            />
          </div>
        )}
      </Form>
      <ProductSupplyMethodSuppliersModal
        show={showSupplierModal}
        productId={productId}
        productSupplyMethodId={supplierModalInfo?.supplyMethodId}
        onHide={onHideSupplierModal}
      />
    </>
  );
};
