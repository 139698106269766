import { useRef, useEffect } from "react";
import { Cancelable, debounce } from "lodash";

export const useDebouncePool = <FN extends (...args: any) => any>(
  fn: FN,
  deps?: any[]
) => {
  const debouncesMapRef = useRef<{
    [id: string]: (FN & Cancelable) | undefined;
  }>({});

  useEffect(() => {
    debouncesMapRef.current = {};

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return {
    invoke: (debounceKey: string | number, ...args: Parameters<FN>) => {
      if (!debouncesMapRef.current[debounceKey]) {
        debouncesMapRef.current[debounceKey] = debounce(fn, 250);
      }
      debouncesMapRef.current[debounceKey]!(...args);
    },
    cancel: (debounceKey: string | number) => {
      debouncesMapRef.current[debounceKey]?.cancel();
    },
  };
};
