import React from "react";
import { FormContextValues } from "react-hook-form";
import { Col, FormGroup } from "react-bootstrap";
import { FormInput } from "base/components/form-controls/FormInput";
import { FormMaterialDepartmentsSelect } from "material/components/form-controls/FormMaterialDepartmentsSelect";
import { FormUnitTypeSelect } from "base/components/form-controls/FormUnitTypeSelect";
import { FormCheckbox } from "base/components/form-controls/FormCheckbox";
import { MaterialFormData } from "../types";

export type MaterialFormFieldsProps = {
  formInstance: FormContextValues<MaterialFormData>;
};

export const MaterialFormFields = ({
  formInstance,
}: MaterialFormFieldsProps) => {
  return (
    <>
      <FormGroup className="d-flex">
        <Col sm={5} md={4} xl={3}>
          שם
        </Col>
        <Col xs={9} sm={7} md={7} lg={6} xl={5}>
          <FormInput formInstance={formInstance} name="name" />
        </Col>
      </FormGroup>
      <FormGroup className="d-flex">
        <Col sm={5} md={4} xl={3}>
          מחלקה
        </Col>
        <Col xs={9} sm={7} md={7} lg={6} xl={5}>
          <FormMaterialDepartmentsSelect
            formInstance={formInstance}
            name="departmentId"
          />
        </Col>
      </FormGroup>
      <FormGroup className="d-flex">
        <Col sm={5} md={4} xl={3}>
          קוד ישות
        </Col>
        <Col xs={9} sm={7} md={7} lg={6} xl={5}>
          <FormInput formInstance={formInstance} name="id" />
        </Col>
      </FormGroup>
      <FormGroup className="d-flex">
        <Col sm={5} md={4} xl={3}>
          יח׳ מידה הכנה
        </Col>
        <Col xs={9} sm={7} md={7} lg={6} xl={5}>
          <FormUnitTypeSelect formInstance={formInstance} name="unitType" />
        </Col>
      </FormGroup>
      <FormGroup className="d-flex">
        <Col sm={5} md={4} xl={3}>
          ימי טריות
        </Col>
        <Col xs={9} sm={7} md={7} lg={6} xl={5}>
          <FormInput
            formInstance={formInstance}
            name="freshnessDays"
            type="number"
            step="1"
          />
        </Col>
      </FormGroup>
      <FormGroup className="d-flex">
        <Col sm={5} md={4} xl={3}>
          מלאי מינימום
        </Col>
        <Col xs={9} sm={7} md={7} lg={6} xl={5}>
          <FormInput
            formInstance={formInstance}
            name="minStock"
            type="number"
            step="0.001"
          />
        </Col>
      </FormGroup>
      <FormGroup className="d-flex">
        <Col sm={5} md={4} xl={3}>
          מלאי מקסימום
        </Col>
        <Col xs={9} sm={7} md={7} lg={6} xl={5}>
          <FormInput
            formInstance={formInstance}
            name="maxStock"
            type="number"
            step="0.001"
          />
        </Col>
      </FormGroup>
      <FormGroup className="d-flex">
        <Col sm={5} md={4} xl={3}>
          מלאי
        </Col>
        <Col xs={9} sm={7} md={7} lg={6} xl={5}>
          <FormInput
            formInstance={formInstance}
            name="currentStock"
            type="number"
            step="0.001"
          />
        </Col>
      </FormGroup>
      <FormGroup className="d-flex">
        <Col sm={5} md={4} xl={3}>
          פעיל
        </Col>
        <Col xs={9} sm={7} md={7} lg={6} xl={5}>
          <FormCheckbox formInstance={formInstance} name="isActive" />
        </Col>
      </FormGroup>
    </>
  );
};
