import React, { useMemo, useEffect } from "react";
import { Router, Switch, Route } from "react-router-dom";
import { SWRConfig, ConfigInterface } from "swr";
import "app/styles/index.scss"; // important for this to be first(before other scss)
import "3rd-parties/yup/yup-setup";
import { FullScreenLoaderProvider } from "full-screen-loader/FullScreenLoaderProvider";
import { HomeScreen } from "home/screens/HomeScreen";
import { IngredientsScreen } from "ingredient/screens/IngredientsScreen";
import { EditIngredientScreen } from "ingredient/screens/EditIngredientScreen";
import { AddIngredientScreen } from "ingredient/screens/AddIngredientScreen";
import { DishesScreen } from "dish/screens/DishesScreen";
import { AddDishScreen } from "dish/screens/AddDishScreen";
import { EditEmployeeScreen } from "employee/screens/EditEmployeeScreen";
import { AddEmployeeScreen } from "employee/screens/AddEmployeeScreen";
import { EmployeesScreen } from "employee/screens/EmployeesScreen";
import { EmployeesShiftsPresenceScreen } from "employee/screens/EmployeesShiftsPresenceScreen";
import { DevScreen } from "dev/screens/DevScreen";
import { OrdersScreen } from "order/screens/OrdersScreen";
import { MaterialsScreen } from "material/screens/MaterialsScreen";
import { AddMaterialScreen } from "material/screens/AddMaterialScreen";
import { StockTransactionsScreen } from "stockTransactions/screens/StockTransactionsScreen";
import { SuppliersScreen } from "supplier/screens/SuppliersScreen";
import { AddSupplierScreen } from "supplier/screens/AddSupplierScreen";
import { EditSupplierScreen } from "supplier/screens/EditSupplierScreen";
import { SentOrdersScreen } from "sent-order/screens/SentOrdersScreen";
import { EditSentOrderScreen } from "sent-order/screens/EditSentOrderScreen";
import { ScrollToTopHandler } from "./ScrollToTopHandler";
import { ContractsScreen } from "contract/screens/ContractsScreen";
import { AddContractScreen } from "contract/screens/AddContractScreen";
import { EditContractScreen } from "contract/screens/EditContractScreen";
import { EditOrderScreen } from "order/screens/EditOrderScreen";
import { TreeInfoScreen } from "tree-info/screens/TreeInfoScreen";
import { BasicTreeInfoScreen } from "basic-tree-info/screens/BasicTreeInfoScreen";
import { LoginScreen } from "auth/screens/LoginScreen";
import { GuardedRoute } from "base/components/GuardedRoute";
import { DepartmentsScreen } from "department/screens/DepartmentsScreen";
import { DepartmentsTreeScreen } from "department/screens/DepartmentsTreeScreen";
import { WorkScheduleScreen } from "work-schedule/screens/WorkScheduleScreen";
import { WeeklyWorkScheduleScreen } from "weekly-work-schedule/screens/WeeklyWorkScheduleScreen";
import { MyScheduleRequestScreen } from "schedule-requests/screens/MyScheduleRequestScreen";
import { RoleTypesListScreen } from "global-list/screens/RoleTypesListScreen";
import { RoleNamesListScreen } from "global-list/screens/RoleNamesListScreen";
import { ShiftDepartmentsListScreen } from "global-list/screens/ShiftDepartmentsListScreen";
import { SpecialDatesListScreen } from "global-list/screens/SpecialDatesListScreen";
import { SystemSettingsListScreen } from "global-list/screens/SystemSettingsListScreen";
import { SimpleGlobalListIndexScreen } from "global-list/screens/SimpleGlobalListIndexScreen";
import { POSItemMappingsListScreen } from "global-list/screens/POSItemMappingsListScreen";
import { AddToStockScreen } from "stock/screens/AddToStockScreen";
import { DefaultRouteRedirect } from "./DefaultRouteRedirect";
import { SideBar } from "./SideBar";
import { ChefsHomeScreen } from "chefs/ChefsHomeScreen";
import { PreparationTaskScreen } from "chefs/preparation-tasks/PreparationTaskScreen";
import { POSImportScreen } from "pos/POSImportScreen";
import { PackageKindsListScreen } from "global-list/screens/PackageKindsListScreen";
import { UnitKindsListScreen } from "global-list/screens/UnitKindsListScreen";
import { SimpleGlobalListRouteBuilder } from "global-list/SimpleGlobalListRouteBuilder";
import { ManufacturersListScreen } from "global-list/screens/ManufacturersListScreen";
import { AddProductScreen } from "product/AddProductScreen";
import { ProductsScreen } from "product/ProductsScreen";
import { EditProductScreen } from "product/EditProductScreen";
import { ContactTypesListScreen } from "global-list/screens/ContactTypesListScreen";
import { StandSettingsScreen } from "admin/StandSettingsScreen";
import { SentOrderViewerScreen } from "sent-order/screens/SentOrderViewerScreen";
import { ContactsListScreen } from "global-list/screens/ContactsListScreen";
import { StorageUnitsListScreen } from "global-list/screens/StorageUnitsListScreen";
import { SentOrdersRouteBuilder } from "sent-order/SentOrdersRouteBuilder";
import "app/axiosSetup";
import { history } from "app/history";
import { useStandType } from "auth/hooks/useStandType";
import { POSRouteBuilder } from "pos/POSRouteBuilder";
import { DishesRouteBuilder } from "dish/DishesRouteBuilder";
import { AdminRouteBuilder } from "admin/AdminRouteBuilder";
import { POSHistoryScreen } from "pos/POSHistoryScreen";
import { POSHistoryItemScreen } from "pos/POSHistoryItemScreen";
import { DishVisualEditorScreen } from "dish/screens/DishVisualEditorScreen";
import { OrderWizardRouterScreen } from "order/screens/OrderWizardRouterScreen";
import { TopBar } from "./TopBar";
import { ReceivedOrdersScreen } from "sent-order/screens/ReceivedOrdersScreen";
import { useAutoLogOffHandler } from "app/hooks/useAutoLogOffHandler";
import { defaultModules } from "@pnotify/core";
import * as PNotifyMobile from "@pnotify/mobile";
import "@pnotify/core/dist/PNotify.css";
import "@pnotify/core/dist/BrightTheme.css";
import "@pnotify/mobile/dist/PNotifyMobile.css";
import { ManagerRouteBuilder } from "manager/ManagerRouteBuilder";
import { DashboardScreen } from "manager/DashboardScreen";
import { SimpleGlobalListScreen } from "global-list/screens/SimpleGlobalListScreen";
import { OrdersRouteBuilder } from "order/OrdersRouteBuilder";
import { OrdersStatusScreen } from "order/screens/OrdersStatusScreen";

defaultModules.set(PNotifyMobile, {});

export function App() {
  // default behavior of requests.
  const swrConfig = useMemo<ConfigInterface>(() => {
    return {
      revalidateOnFocus: false, // Do Not refetch requests once tab gains focus.
      revalidateOnReconnect: false, // Do Not refetch requests once internet connection reastablishes.
      shouldRetryOnError: false, // Do Not retry once query errors.
    };
  }, []);

  const standType = useStandType();

  useEffect(() => {
    document.body.setAttribute("stand-type", standType);
  }, [standType]);

  useAutoLogOffHandler();

  return (
    <SWRConfig value={swrConfig}>
      <FullScreenLoaderProvider>
        <Router history={history}>
          <TopBar />
          <ScrollToTopHandler />
          <Switch>
            <Route path="/login">
              <LoginScreen />
            </Route>
            <GuardedRoute path="/ingredients/new">
              <AddIngredientScreen />
            </GuardedRoute>
            <GuardedRoute path="/ingredients/:ingredientId">
              <EditIngredientScreen />
            </GuardedRoute>
            <GuardedRoute path="/ingredients">
              <IngredientsScreen />
            </GuardedRoute>
            <GuardedRoute path="/materials/new">
              <AddMaterialScreen />
            </GuardedRoute>
            <GuardedRoute path="/materials">
              <MaterialsScreen />
            </GuardedRoute>
            <GuardedRoute path="/employees/new">
              <AddEmployeeScreen />
            </GuardedRoute>
            <GuardedRoute path="/employees/shifts-presence">
              <EmployeesShiftsPresenceScreen />
            </GuardedRoute>
            <GuardedRoute path="/employees/:employeeId">
              <EditEmployeeScreen />
            </GuardedRoute>
            <GuardedRoute path="/employees">
              <EmployeesScreen />
            </GuardedRoute>
            <GuardedRoute path="/templates">
              <WorkScheduleScreen />
            </GuardedRoute>
            <GuardedRoute path="/work-schedule/weekly">
              <WeeklyWorkScheduleScreen />
            </GuardedRoute>
            <GuardedRoute path="/schedule-requests/me">
              <MyScheduleRequestScreen />
            </GuardedRoute>
            <GuardedRoute path="/stockTransactions">
              <StockTransactionsScreen />
            </GuardedRoute>
            <GuardedRoute path={OrdersRouteBuilder.templates.OrderWizardRouter}>
              <OrderWizardRouterScreen />
            </GuardedRoute>
            <GuardedRoute path={OrdersRouteBuilder.templates.OrdersStatusRoute}>
              <OrdersStatusScreen />
            </GuardedRoute>
            <GuardedRoute path="/orders/:orderId">
              <EditOrderScreen />
            </GuardedRoute>
            <GuardedRoute path="/orders">
              <OrdersScreen />
            </GuardedRoute>
            <Route path={SentOrdersRouteBuilder.templates.Viewer}>
              <SentOrderViewerScreen />
            </Route>
            <Route path={SentOrdersRouteBuilder.templates.ReceivedOrders}>
              <ReceivedOrdersScreen />
            </Route>
            <GuardedRoute
              path={["/sent-orders/:orderId/admin", "/sent-orders/:orderId"]}
            >
              <EditSentOrderScreen />
            </GuardedRoute>
            <GuardedRoute path={SentOrdersRouteBuilder.templates.SentOrders}>
              <SentOrdersScreen />
            </GuardedRoute>
            <GuardedRoute path={DishesRouteBuilder.templates.AddDish}>
              <AddDishScreen />
            </GuardedRoute>
            <GuardedRoute path={DishesRouteBuilder.templates.Dishes}>
              <DishesScreen />
            </GuardedRoute>
            <GuardedRoute path={DishesRouteBuilder.templates.DishVisualEditor}>
              <DishVisualEditorScreen />
            </GuardedRoute>
            <GuardedRoute path="/suppliers/new">
              <AddSupplierScreen />
            </GuardedRoute>
            <GuardedRoute path="/suppliers/:supplierId">
              <EditSupplierScreen />
            </GuardedRoute>
            <GuardedRoute path="/suppliers">
              <SuppliersScreen />
            </GuardedRoute>
            <GuardedRoute path="/contracts/new">
              <AddContractScreen />
            </GuardedRoute>
            <GuardedRoute path="/contracts/:id">
              <EditContractScreen />
            </GuardedRoute>
            <GuardedRoute path="/contracts">
              <ContractsScreen />
            </GuardedRoute>
            <GuardedRoute path="/basic-tree-info/:type/:itemId">
              <BasicTreeInfoScreen />
            </GuardedRoute>
            <GuardedRoute path="/tree-info/:type/:itemId">
              <TreeInfoScreen />
            </GuardedRoute>
            <GuardedRoute path="/departments/:departmentId">
              <DepartmentsTreeScreen />
            </GuardedRoute>
            <GuardedRoute path="/departments">
              <DepartmentsScreen />
            </GuardedRoute>
            <GuardedRoute path="/work-schedule">
              <WorkScheduleScreen />
            </GuardedRoute>
            <GuardedRoute
              path={SimpleGlobalListRouteBuilder.buildRoleTypesRoute()}
            >
              <RoleTypesListScreen />
            </GuardedRoute>
            <GuardedRoute
              path={SimpleGlobalListRouteBuilder.buildRoleNamesRoute()}
            >
              <RoleNamesListScreen />
            </GuardedRoute>
            <GuardedRoute
              path={SimpleGlobalListRouteBuilder.buildShiftDepartmentsRoute()}
            >
              <ShiftDepartmentsListScreen />
            </GuardedRoute>
            <GuardedRoute
              path={SimpleGlobalListRouteBuilder.buildSpecialDatesRoute()}
            >
              <SpecialDatesListScreen />
            </GuardedRoute>
            <GuardedRoute
              path={SimpleGlobalListRouteBuilder.buildSystemSettingsRoute()}
            >
              <SystemSettingsListScreen />
            </GuardedRoute>
            <GuardedRoute
              path={SimpleGlobalListRouteBuilder.buildPackageKindsRoute()}
            >
              <PackageKindsListScreen />
            </GuardedRoute>
            <GuardedRoute
              path={SimpleGlobalListRouteBuilder.buildUnitKindsRoute()}
            >
              <UnitKindsListScreen />
            </GuardedRoute>
            <GuardedRoute
              path={SimpleGlobalListRouteBuilder.buildPOSMappingRoute()}
            >
              <POSItemMappingsListScreen />
            </GuardedRoute>
            <GuardedRoute
              path={SimpleGlobalListRouteBuilder.buildManufacturersRoute()}
            >
              <ManufacturersListScreen />
            </GuardedRoute>
            <GuardedRoute
              path={SimpleGlobalListRouteBuilder.buildContactTypesRoute()}
            >
              <ContactTypesListScreen />
            </GuardedRoute>
            <GuardedRoute
              path={SimpleGlobalListRouteBuilder.buildContactsRoute()}
            >
              <ContactsListScreen />
            </GuardedRoute>
            <GuardedRoute
              path={SimpleGlobalListRouteBuilder.buildStorageUnitsRoute()}
            >
              <StorageUnitsListScreen />
            </GuardedRoute>
            <GuardedRoute
              path={SimpleGlobalListRouteBuilder.templates.SimpleGlobalList}
            >
              <SimpleGlobalListScreen />
            </GuardedRoute>
            <GuardedRoute
              path={SimpleGlobalListRouteBuilder.buildListsIndexRoute()}
            >
              <SimpleGlobalListIndexScreen />
            </GuardedRoute>

            <GuardedRoute path="/stock/add">
              <AddToStockScreen />
            </GuardedRoute>
            <GuardedRoute path="/products/new">
              <AddProductScreen />
            </GuardedRoute>
            <GuardedRoute path="/products/:productId">
              <EditProductScreen />
            </GuardedRoute>
            <GuardedRoute path="/products">
              <ProductsScreen />
            </GuardedRoute>
            <GuardedRoute path="/chefs-home">
              <ChefsHomeScreen />
            </GuardedRoute>
            <GuardedRoute path="/preparation-task/:id">
              <PreparationTaskScreen />
            </GuardedRoute>
            <GuardedRoute path="/pos-import">
              <POSImportScreen />
            </GuardedRoute>
            <GuardedRoute path={POSRouteBuilder.templates.HistoryItem}>
              <POSHistoryItemScreen />
            </GuardedRoute>
            <GuardedRoute path={POSRouteBuilder.templates.History}>
              <POSHistoryScreen />
            </GuardedRoute>
            <GuardedRoute path={AdminRouteBuilder.templates.StandSettings}>
              <StandSettingsScreen />
            </GuardedRoute>
            <GuardedRoute path={ManagerRouteBuilder.templates.Dashboard}>
              <DashboardScreen />
            </GuardedRoute>
            <GuardedRoute path="/home">
              <HomeScreen />
            </GuardedRoute>
            {process.env.NODE_ENV === "development" && (
              <GuardedRoute path="/dev">
                <DevScreen />
              </GuardedRoute>
            )}
            <Route path="/">
              <DefaultRouteRedirect />
            </Route>
          </Switch>
          <SideBar />
        </Router>
      </FullScreenLoaderProvider>
    </SWRConfig>
  );
}
