import axios from "axios";
import { RequestCacheHelper } from "base/api/RequestCacheHelper";
import { ContractListItem, Contract } from "./types";

export class ContractsApi {
  static getList() {
    return axios.get<ContractListItem[]>("/api/contracts/items");
  }

  static getItem(id: number) {
    return axios.get<Contract>(`/api/contracts/${id}`);
  }

  static getItemDefaults() {
    return axios.get<Contract>(`/api/contracts/new`);
  }

  static async saveItem(data: WithPartialProperties<Contract, "id">) {
    const { data: savedItem } = await axios.post<Contract>(
      "/api/contracts",
      data
    );

    // updating the cache, so the ui will get notified of the changes.
    await RequestCacheHelper.instance.update(savedItem, ContractsApi.getItem, [
      savedItem.id,
    ]);
    RequestCacheHelper.instance.refetch(this.getList);

    return savedItem;
  }
}
