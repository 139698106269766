export enum BalancePeriodType {
  Daily = 0,
  Weekly = 1,
  Monthly = 2,
}

export interface DashboardData {
  balance: {
    periodType: BalancePeriodType;
    outcome: {
      total: number;
      // departments: [];
    };
    income: {
      total: number;
    };
  };
}
