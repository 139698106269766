import React, { useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { RouteContent } from "base/components/RouteContent";
import {
  EditIngredientRouteParams,
  IngredientFormData,
} from "ingredient/types";
import { Tabs, Tab } from "react-bootstrap";
import "base/styles/tabs.scss";
import { IngredientTreesTab } from "ingredient/components/IngredientTreesTab";
import { IngredientProductsTab } from "ingredient/components/IngredientProductsTab";
import { IngredientEditTab } from "ingredient/components/IngredientEditTab";
import { IngredientsApi } from "ingredient/IngredientsApi";
import { useCustomQueryWithParams } from "base/api/hooks/useCustomQuery";
import { useFullScreenLoader } from "full-screen-loader/hooks/useFullScreenLoader";
import { Form } from "form/components/Form";
import { debounce, isEqual } from "lodash";
import { noop } from "base/utils/noop";
import { FormSpy } from "react-final-form";
import { IngredientPlantsTab } from "ingredient/components/IngredientPlantsTab";
import { WeekStockRangesTable } from "week-stock-range/WeekStockRangesTable";

enum ETab {
  Edit = "Edit",
  Trees = "Trees",
  Products = "Products",
  Plants = "Plants",
  WeekStockRange = "WeekStockRange",
}

export const EditIngredientScreen: React.FC = () => {
  const { ingredientId: ingredientIdStr } = useParams<
    EditIngredientRouteParams
  >();
  const [selectedTab, setSelectedTab] = useState(ETab.Edit);

  const ingredientId = Number(ingredientIdStr);

  const {
    data,
    isValidating,
  } = useCustomQueryWithParams(IngredientsApi.getItem, () => [ingredientId]);

  const isLoading = !data || isValidating;

  useFullScreenLoader(isLoading);

  const onAutoSave = useCallback(
    debounce(async (values: IngredientFormData) => {
      try {
        await IngredientsApi.saveItem(values);
      } catch (error) {
        alert(error.response.data?.error ?? "לא ניתן לשמור את הפריט");
      }
    }, 250),
    []
  );

  return (
    <RouteContent>
      <Form initialValues={data} onSubmit={noop}>
        {({ form }) => (
          <>
            <Tabs
              activeKey={selectedTab}
              id="EditIngredintScreenTabs"
              onSelect={setSelectedTab as any}
              className="mt-2 main-tabs"
              style={{ fontSize: 34 }}
            >
              <Tab title="עריכה" eventKey={ETab.Edit} className="p-4">
                <IngredientEditTab id={ingredientId} />
              </Tab>
              <Tab title="עצי מוצר" eventKey={ETab.Trees} className="p-4">
                <IngredientTreesTab id={ingredientId} />
              </Tab>
              <Tab title="מוצרים" eventKey={ETab.Products} className="p-4">
                <IngredientProductsTab id={ingredientId} />
              </Tab>
              <Tab title="מועצת הצמחים" eventKey={ETab.Plants} className="p-4">
                <IngredientPlantsTab id={ingredientId} />
              </Tab>
              <Tab title="מלאי" eventKey={ETab.WeekStockRange} className="p-4">
                {data && (
                  <WeekStockRangesTable
                    weekStockRangeId={data.weekStockRangeId}
                    entityRelation={{ ingredientId }}
                  />
                )}
              </Tab>
            </Tabs>
            <FormSpy
              subscription={{
                validating: true,
              }}
              onChange={({ validating }) => {
                const formState = form.getState();
                if (validating || !formState.valid) {
                  onAutoSave.cancel();
                  return;
                }

                const {
                  values: nextValues,
                  initialValues: prevValues,
                } = formState;

                if (
                  nextValues &&
                  prevValues &&
                  !isEqual(nextValues, prevValues)
                ) {
                  onAutoSave(nextValues);
                }
              }}
            />
          </>
        )}
      </Form>
    </RouteContent>
  );
};
