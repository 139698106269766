export class BodyFormDataBuilder {
  static build(body: any) {
    const formData = Object.entries(body).reduce((formData, [key, value]) => {
      if (value !== undefined) {
        if (value instanceof File || value instanceof FileList) {
          formData.append(key, value as Blob);
        } else if (typeof value === "object") {
          formData.append(key, JSON.stringify(value));
        } else {
          formData.append(key, String(value));
        }
      }
      return formData;
    }, new FormData());

    return formData;
  }
  static build2(body: any) {
    function populateFormData(
      formData: FormData,
      data: any,
      parentKey: string | undefined
    ) {
      if (
        data &&
        typeof data === "object" &&
        !(data instanceof Date) &&
        !(data instanceof File) &&
        !(data instanceof FileList)
      ) {
        if (
          Array.isArray(data) &&
          data.some(
            (item) =>
              item instanceof File ||
              (typeof item !== "object" && !Array.isArray(item))
          )
        ) {
          data.forEach((item) => {
            populateFormData(formData, item, parentKey);
          });
        } else {
          Object.keys(data).forEach((key) => {
            populateFormData(
              formData,
              data[key],
              parentKey ? `${parentKey}[${key}]` : key
            );
          });
        }
      } else if (data !== undefined) {
        formData.append(parentKey!, data);
      }
    }

    const formData = new FormData();
    populateFormData(formData, body, undefined);
    return formData;
  }
}
