import Axios from "axios";
import { FoodDepartmentListItem } from "base/types";
import { ProductLitItem, ProductForm, ProductGet } from "./types";
import { BodyFormDataBuilder } from "base/api/BodyFormDataBuilder";
import { RequestCacheHelper } from "base/api/RequestCacheHelper";

export class ProductsApi {
  static getItem(id: number) {
    return Axios.get<ProductGet>(`/api/products/${id}`);
  }

  static getList() {
    return Axios.get<ProductLitItem[]>("/api/products/items");
  }

  static getDepartments() {
    return Axios.get<FoodDepartmentListItem[]>("/api/products/departments");
  }

  static async saveItem(item: WithPartialProperties<ProductForm, "id">) {
    const { data: savedItem } = await Axios.post<ProductGet>(
      "/api/products",
      BodyFormDataBuilder.build2(item),
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );

    // updating the getItem cache for the savedItem.id.
    await RequestCacheHelper.instance.update(savedItem, this.getItem, [
      savedItem.id,
    ]);

    // making sure to refetch the list of items to reflect change in list as well.
    RequestCacheHelper.instance.refetch(this.getList);

    return savedItem;
  }

  static async deleteItem(id: number) {
    await Axios.delete(`/api/products/${id}`);

    // making sure to refetch the list of items to reflect change in list as well.
    RequestCacheHelper.instance.refetch(this.getList);
  }
}
