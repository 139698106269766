import React, { useEffect, useState } from "react";
import { FormGroup, Button } from "react-bootstrap";
import { useForm } from "react-final-form";
import { useBooleanState } from "base/hooks/useBooleanState";
import { FormCheckbox } from "form/components/FormCheckbox";
import { FormTextInput } from "form/components/FormTextInput";
import { useFieldNameFormatFN } from "form/hooks/useFieldNameFormatFN";
import { BasicTreeInfoListSearchOptions } from "basic-tree-info/types";
import SpeechRecognition from "basic-tree-info/SpeechRecognition";

export const BasicTreeFoodsSearchFields: React.FC = () => {
  const form = useForm<BasicTreeInfoListSearchOptions>();
  const formatFieldName = useFieldNameFormatFN<
    BasicTreeInfoListSearchOptions
  >();

  const [capturingVoice, startCapturingVoice] = useBooleanState(false);

  // initializes once.
  const [speechService] = useState(
    () =>
      new SpeechRecognition((term) => {
        form.change("searchTerm", term);
      })
  );

  useEffect(() => {
    if (capturingVoice) {
      speechService.startCapture();
    } else {
      speechService.stopCapture();
    }

    return () => speechService.stopCapture();
  }, [capturingVoice, speechService]);

  return (
    <div className="search-container">
      <div className="d-flex justify-content-center">
        <div className="ml-5">
          <label>
            מרכיבים
            <div className="big-checkbox d-inline-block">
              <FormCheckbox name={formatFieldName("isIngred")} />
            </div>
          </label>
        </div>
        <div>
          <label>
            חומרי גלם
            <div className="big-checkbox d-inline-block">
              <FormCheckbox name={formatFieldName("isMaterial")} />
            </div>
          </label>
        </div>
      </div>
      <FormGroup className="d-flex">
        <FormTextInput
          name="searchTerm"
          placeholder="חיפוש לפי שם"
          className="dish-food-tree-input"
        />
        <Button onClick={startCapturingVoice}>הקלטה</Button>
      </FormGroup>
    </div>
  );
};
