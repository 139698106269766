export enum PreparationTaskStatus {
  Created = 1,
  InProcess = 5,
  Completed = 9,
}

export interface PreparationTaskListItem {
  id: number;
  amount: number;
  materialId: number;
  materialName: number;
  imageName: string;
  unitType: number;
  status: PreparationTaskStatus;
  employeeId: string;
  recipeId?: number;
  startDateTime?: string;
}

export interface AddPreparationTaskFormValues {
  materialId: number;
  amount: number;
  transactionDate?: string;
}
