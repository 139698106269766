import React, { useCallback } from "react";
import { Modal } from "react-bootstrap";
import { PickAmountFormValues } from "./types";
import { Form } from "form/components/Form";
import { PickAmountModalContent } from "./PickAmountModalContent";
import "./styles.scss";

export type PickAmountModalProps = {
  show: boolean;
  onPick: (amount: number) => void;
  onCancel: () => void;
};

export const PickAmountModal: React.FC<PickAmountModalProps> = ({
  show,
  onPick,
  onCancel,
}) => {
  const onSubmit = useCallback(
    ({ amount }: PickAmountFormValues) => {
      onPick(amount);
    },
    [onPick]
  );

  return (
    <Modal
      show={show}
      onHide={onCancel}
      dialogClassName="pick-amount-modal"
      centered
    >
      <Form onSubmit={onSubmit} component={PickAmountModalContent} />
    </Modal>
  );
};
