import React, { useCallback } from "react";
import {
  FormContextValues,
  Controller,
  ErrorMessage,
  EventFunction,
} from "react-hook-form";
import { FormErrorMessage } from "../FormErrorMessage";
import { FormControl } from "react-bootstrap";

export type FormCheckboxProps<FormValues> = {
  name: KeysByType<FormValues, boolean | undefined>;
  formInstance: FormContextValues<FormValues>;
};

export const FormCheckbox = <FormValues,>({
  name,
  formInstance,
}: FormCheckboxProps<FormValues>) => {
  const { errors, control } = formInstance;
  const onChange = useCallback<EventFunction>(([e]) => {
    return e.target.checked;
  }, []);

  return (
    <>
      <Controller
        as={<FormControl type="checkbox" />}
        name={name as string}
        control={control}
        onChange={onChange}
        defaultValue={false}
      />
      <div>
        {
          <ErrorMessage
            as={FormErrorMessage}
            name={name as string}
            errors={errors}
          />
        }
      </div>
    </>
  );
};
