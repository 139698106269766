import { BalancePeriodType, DashboardData } from "./types";
import Axios from "axios";
import { ApiRequestHelper } from "base/api/ApiRequestHelper";

export class ManagersApi {
  static getDashboardData(periodType: BalancePeriodType, date: string) {
    const formattedDate = ApiRequestHelper.formatDateQueryParam(date);

    return Axios.get<DashboardData>(
      `/api/managerDashboard?date=${formattedDate}&periodType=${periodType}`
    );
  }
}
