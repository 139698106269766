import React, { useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import { RouteContent } from "base/components/RouteContent";
import { useCustomQueryWithParams } from "base/api/hooks/useCustomQuery";
import { OrdersApi } from "order/OrdersApi";
import { PickIngredients } from "order/components/PickIngredients";
import { Button } from "react-bootstrap";
import { useNavigateCallback } from "base/hooks/useNavigateCallback";
import { OrdersRouteBuilder } from "order/OrdersRouteBuilder";
import { OrderWizardTopBar } from "order/components/OrderWizardTopBar";
import { useOrderWizardContext } from "order/context/hooks/useOrderWizardContext";
import { useObserver } from "mobx-react";
import { OrderSupplierInfoCard } from "order/components/OrderSupplierInfoCard";

export const SupplierScreen: React.FC = () => {
  const { supplierId: supplierIdStr } = useParams();
  const supplierId = Number(supplierIdStr);

  const navigate = useNavigateCallback();

  const { store } = useOrderWizardContext();
  const searchPhrase =
    useObserver(
      () =>
        store.routeSearchPhrases[
          OrdersRouteBuilder.buildSupplierRoute(supplierId)
        ]
    ) ?? "";

  const { data } = useCustomQueryWithParams(OrdersApi.getSupplier, () => [
    supplierId,
  ]);

  const [totalOrderPrice, setTotalOrderPrice] = useState(0);

  const filteredIngredients = useMemo(() => {
    if (!searchPhrase) return data?.ingredients;

    const formattedPhrase = searchPhrase.trim().toLowerCase();

    return data?.ingredients.filter(
      (item) => item.name?.toLowerCase().trim().indexOf(formattedPhrase) !== -1
    );
  }, [data, searchPhrase]);

  return (
    <RouteContent className="OrderSupplierScreen">
      <OrderWizardTopBar />
      <OrderSupplierInfoCard
        supplierId={supplierId}
        totalOrderPrice={totalOrderPrice}
      />
      {data?.orderId !== undefined && (
        <div className="text-center my-2">
          <Button
            onClick={() =>
              navigate(OrdersRouteBuilder.buildEditOrderRoute(data.orderId!))
            }
          >
            סיימתי ללקט
          </Button>
        </div>
      )}
      <PickIngredients
        ingredients={filteredIngredients}
        onTotalOrderPriceCalc={setTotalOrderPrice}
      />
    </RouteContent>
  );
};
