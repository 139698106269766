import React, { useMemo, useRef } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { SupplierContact } from "supplier/types";
import { FieldArray } from "react-final-form-arrays";
import { Form } from "form/components/Form";
import { FormTextInput } from "form/components/FormTextInput";
import { Field, FormSpy } from "react-final-form";
import { FormCheckbox } from "form/components/FormCheckbox";
import {
  useCustomQueryWithParams,
  CustomQueryResponse,
} from "base/api/hooks/useCustomQuery";
import { SuppliersApi } from "supplier/SuppliersApi";
import { SimpleGlobalListApi } from "global-list/SimpleGlobalListApi";
import { SimpleGlobalListRoutePath } from "global-list/types";
import { BaseSelectListItem } from "base/types";
import { useSelectOptionsAdapter } from "base/components/Select/hooks/useSelectOptionsAdapter";
import { FormSelect } from "form/components/FormSelect";
import { FormCustomReset } from "form/utils/FormCustomReset";
import { Cancelable, isEqual, debounce } from "lodash";
import { getIn } from "final-form";
import Axios from "axios";

export type SupplierContactsTabProps = {
  supplierId: number;
};

export const SupplierContactsTab: React.FC<SupplierContactsTabProps> = ({
  supplierId,
}) => {
  const fieldName = (property: keyof SupplierContact, prefix?: string) =>
    `${prefix ? `${prefix}.` : ""}${property}`;

  const { data: contacts } = useCustomQueryWithParams(
    SuppliersApi.getContacts,
    () => [supplierId]
  );

  const {
    data: contactTypes,
  } = useCustomQueryWithParams(SimpleGlobalListApi.getList, () => [
    SimpleGlobalListRoutePath.ContactTypes,
  ]) as CustomQueryResponse<BaseSelectListItem[]>;

  const contactTypesOptions = useSelectOptionsAdapter(contactTypes);

  const initialValues = useMemo(() => ({ contacts }), [contacts]);

  const saveFn = async (data: SupplierContact) => {
    await SuppliersApi.saveContact(supplierId, data);
  };
  const rowsAutoSaveMapRef = useRef<{
    [id: number]: typeof saveFn & Cancelable;
  }>({});

  return (
    <>
      <h4 className="mt-3">אנשי קשר</h4>
      <Form initialValues={initialValues} onSubmit={() => {}}>
        {({ form }) => (
          <>
            <FieldArray name="contacts">
              {({ fields }) =>
                fields.map((prefix, index) => {
                  const contactId: number = getIn(
                    form.getState().values,
                    fieldName("id", prefix)
                  );
                  return (
                    <Row noGutters key={prefix}>
                      <Col className="p-1" md={3}>
                        <FormTextInput
                          name={fieldName("name", prefix)}
                          placeholder="שם"
                        />
                      </Col>
                      <Col className="p-1" md={2}>
                        <FormSelect
                          name={fieldName("contactTypeId", prefix)}
                          options={contactTypesOptions}
                        />
                      </Col>
                      <Col className="p-1" md={1}>
                        <FormTextInput
                          name={fieldName("phone", prefix)}
                          placeholder="טלפון"
                        />
                      </Col>
                      <Col className="p-1" md={3}>
                        <FormTextInput
                          name={fieldName("email", prefix)}
                          placeholder="מייל"
                        />
                      </Col>
                      <Col className="p-1 d-flex align-items-center" xs="auto">
                        <Field
                          name={fieldName("isDefault", prefix)}
                          render={({ input: { value: checked } }) => (
                            <label>
                              <div className="d-inline-block align-middle mx-2">
                                <input
                                  type="radio"
                                  className="form-control"
                                  style={{ width: 30, height: 30 }}
                                  checked={checked}
                                  onClick={() => {
                                    form.change(
                                      fieldName("isDefault", prefix) as any,
                                      true
                                    );
                                  }}
                                />
                              </div>
                              ראשי
                            </label>
                          )}
                        />
                      </Col>
                      <Col className="p-1">
                        <button
                          className="btn btn-danger"
                          onClick={() => {
                            fields.remove(index);
                            SuppliersApi.deleteContact(contactId);
                          }}
                        >
                          מחיקה
                        </button>
                      </Col>
                      <FormSpy
                        subscription={{
                          validating: true,
                        }}
                        onChange={async ({ validating }) => {
                          if (!rowsAutoSaveMapRef.current[contactId]) {
                            rowsAutoSaveMapRef.current[contactId] = debounce(
                              saveFn,
                              500
                            );
                          }

                          const formState = form.getState();
                          if (validating || !formState.valid) {
                            rowsAutoSaveMapRef.current[contactId].cancel();
                            return;
                          }

                          const { values, initialValues } = formState;
                          const nextRowValue = values?.contacts.find(
                            (c) => c.id === contactId
                          );
                          const prevRowValue = initialValues?.contacts?.find(
                            (c) => c.id === contactId
                          );

                          if (
                            nextRowValue &&
                            prevRowValue &&
                            !isEqual(nextRowValue, prevRowValue)
                          ) {
                            rowsAutoSaveMapRef.current[contactId](nextRowValue);
                          }
                        }}
                      />
                    </Row>
                  );
                })
              }
            </FieldArray>
            <FormSpy
              subscription={{
                values: true,
              }}
              onChange={() => {
                const { initialValues, values } = form.getState();

                if (
                  (values.contacts ?? []).filter((x) => x.isDefault).length > 1
                ) {
                  const prevContactWithDefaultIdx = initialValues.contacts!.findIndex(
                    (x) => x.isDefault
                  );
                  if (prevContactWithDefaultIdx !== -1) {
                    form.change(
                      `contacts[${prevContactWithDefaultIdx}].isDefault` as any,
                      false
                    );
                  }
                }
              }}
            />
            <h4 className="mt-3">הוספת איש קשר חדש</h4>
            <Form<SupplierContact>
              onSubmit={async (newContact, form) => {
                await SuppliersApi.saveContact(supplierId, newContact);
                FormCustomReset.hardReset(form);
              }}
              subscription={{ dirty: true, submitting: true }}
            >
              {({ handleSubmit: handleAdd, dirty, submitting }) => (
                <Row noGutters>
                  <Col className="p-1" md={3}>
                    <FormTextInput name={fieldName("name")} placeholder="שם" />
                  </Col>
                  <Col className="p-1" md={2}>
                    <FormSelect
                      name={fieldName("contactTypeId")}
                      options={contactTypesOptions}
                    />
                  </Col>
                  <Col className="p-1" md={1}>
                    <FormTextInput
                      name={fieldName("phone")}
                      placeholder="טלפון"
                    />
                  </Col>
                  <Col className="p-1" md={3}>
                    <FormTextInput
                      name={fieldName("email")}
                      placeholder="מייל"
                    />
                  </Col>
                  <Col className="p-1" xs="auto">
                    <label>
                      <div className="d-inline-block align-middle mx-2">
                        <FormCheckbox
                          name={fieldName("isDefault")}
                          width={30}
                        />
                      </div>
                      ראשי
                    </label>
                  </Col>
                  <Col className="p-1">
                    <Button
                      onClick={handleAdd}
                      disabled={!dirty && !submitting}
                    >
                      הוספה
                    </Button>
                  </Col>
                </Row>
              )}
            </Form>
            <div>
              <FormSpy<Partial<typeof initialValues>>
                subscription={{ values: true }}
                render={({ values }) => {
                  const defaultContact = values.contacts?.find(
                    (x) => x.isDefault
                  );
                  return (
                    <>
                      <Button
                        variant="success"
                        className="mx-1"
                        disabled={!defaultContact || !defaultContact.phone}
                        onClick={async () => {
                          await Axios.get(
                            `/api/contacts/${defaultContact?.id}/testSMS`
                          );
                          alert("הפעולה בוצעה בהצלחה");
                        }}
                      >
                        שלח סמס בדיקה
                      </Button>
                      <Button
                        variant="success"
                        className="mx-1"
                        disabled={!defaultContact || !defaultContact.email}
                        onClick={async () => {
                          await Axios.get(
                            `/api/contacts/${defaultContact?.id}/testEmail`
                          );
                          alert("הפעולה בוצעה בהצלחה");
                        }}
                      >
                        שלח אימייל בדיקה
                      </Button>
                    </>
                  );
                }}
              />
            </div>
          </>
        )}
      </Form>
    </>
  );
};
