import React from "react";
import { RouteContent } from "base/components/RouteContent";
import { SimpleGlobalList } from "global-list/components/SimpleGlobalList";
import { SimpleGlobalListRoutePath } from "global-list/types";
import { useParams } from "react-router-dom";
import { GlobalListsDefinitions } from "global-list/constants";

export type SimpleGlobalListScreenParams = {
  routePath: string;
};

export const SimpleGlobalListScreen: React.FC = () => {
  const { routePath: routePathStr } = useParams<SimpleGlobalListScreenParams>();
  if (GlobalListsDefinitions[routePathStr] === undefined) {
    throw new Error("Route path not found");
  }

  const routePath = routePathStr as SimpleGlobalListRoutePath;

  return (
    <RouteContent>
      <SimpleGlobalList routeApiPath={routePath} />
    </RouteContent>
  );
};
