import React, {
  useEffect,
  useState,
  ImgHTMLAttributes,
  VideoHTMLAttributes,
} from "react";
import { getIn } from "final-form";
import { FormSpy } from "react-final-form";
import classNames from "classnames";
import { FileDir } from "base/types";
import { usePrevious } from "base/hooks/usePrevious";
import { useOnMountCallback } from "base/hooks/useOnMountCallback";
import { RoundTrashButton } from "base/components/RoundTrashButton";
import { FileViewer } from "base/components/FileViewer";

export type FormFileViewerProps = {
  pathName?: string;
  uploadName?: string;
  fileDir?: FileDir;
  type?: "image" | "video";
  isDeleted?: boolean;
  imageProps?: ImgHTMLAttributes<HTMLImageElement>;
  videoProps?: VideoHTMLAttributes<HTMLVideoElement>;
  className?: string;
  onDelete?: () => void;
};

export const FormFileViewer: React.FC<FormFileViewerProps> = ({
  pathName,
  uploadName,
  fileDir: fileDirProp,
  type = "image",
  isDeleted = false,
  imageProps,
  videoProps,
  className,
  onDelete,
}) => {
  const [pathValue, setPathValue] = useState<string>();
  const [uploadedFile, setUploadedFile] = useState<File>();

  const [uploadedFileBase64, setUploadedFileBase64] = useState<string>();

  const onMount = useOnMountCallback();

  const mode = (() => {
    if (uploadedFile) {
      return "display-upload";
    } else if (pathValue) {
      return "display-given-path";
    } else {
      return "empty";
    }
  })();

  const prevMode = usePrevious(mode);

  useEffect(() => {
    if (mode === "display-upload") {
      const reader = new FileReader();
      reader.onload = function (e) {
        setUploadedFileBase64((e.target?.result as string) ?? undefined);
      };

      reader.readAsDataURL(uploadedFile!);

      return () => {
        if (reader.readyState === 1) {
          reader.abort();
        }
      };
    } else {
      if (prevMode === "display-upload") {
        setUploadedFileBase64(undefined);
      }
    }
  }, [uploadedFile, mode, prevMode]);

  useEffect(() => {
    if (mode === "display-given-path") {
      setPathValue(pathValue);
    } else {
      if (prevMode === "display-given-path") {
        setUploadedFileBase64(undefined);
      }
    }
  }, [mode, pathValue, prevMode]);

  // TODO maybe one day change behavior, display a deleted image/file.
  if (isDeleted) {
    return null;
  }

  const fileName = mode === "display-upload" ? uploadedFileBase64 : pathValue;
  const fileDir = mode === "display-upload" ? FileDir.Empty : fileDirProp;

  return (
    <div className={classNames("position-relative", className)}>
      <FileViewer
        type={type}
        fileName={fileName}
        fileDir={fileDir}
        imageProps={{
          ...imageProps,
          className: classNames("imgFullWidth", imageProps?.className),
        }}
        videoProps={videoProps}
      />
      {onDelete && (uploadedFileBase64 || pathValue) && (
        <RoundTrashButton
          size={24}
          onClick={onDelete}
          className="position-absolute m-1"
          style={{ top: 0, right: 0 }}
        />
      )}
      <FormSpy
        subscription={{ values: true }}
        onChange={({ values }) => {
          onMount(() => {
            if (pathName) {
              setPathValue(getIn(values, pathName));
            }

            if (uploadName) {
              setUploadedFile(getIn(values, uploadName));
            }
          });
        }}
      />
    </div>
  );
};
