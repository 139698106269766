import React from "react";
import { Modal } from "react-bootstrap";
import { useBooleanState } from "base/hooks/useBooleanState";
import "../styles/CarouselFullScreenSupport.scss";

export interface CarouselFullScreenSupportProps {
  children: React.ReactNode;
}
export const CarouselFullScreenSupport: React.FC<CarouselFullScreenSupportProps> = ({
  children,
}) => {
  const [show, onShow, onHide] = useBooleanState(false);

  return (
    <>
      <div className="position-relative h-100">
        {children}
        <div
          style={{
            position: "absolute",
            pointerEvents: "none",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "rgba(62, 55, 55, 0.3)",
          }}
        />
        <i
          className="position-absolute fas fa-expand text-white"
          style={{ bottom: 20, right: 20, fontSize: 20, zIndex: 1 }}
          role="button"
          onClick={onShow}
        />
      </div>
      <Modal show={show} onHide={onHide} dialogClassName="carousel-full-screen">
        <div className="position-relative">
          {children}
          <div
            style={{
              position: "absolute",
              pointerEvents: "none",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              backgroundColor: "rgba(62, 55, 55, 0.3)",
            }}
          />
        </div>
      </Modal>
    </>
  );
};
