import React from "react";
import { useMediaQuery, Fade, Collapse } from "@material-ui/core";
import json2mq from "json2mq";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleDown } from "@fortawesome/free-solid-svg-icons";
import { useBooleanState } from "base/hooks/useBooleanState";
import { RoundButton } from "base/components/RoundButton";

export const MobileCollapseControl: React.FC = ({ children }) => {
  const [open, , , onToggleOpen] = useBooleanState(false);
  const isMobile = useMediaQuery(json2mq({ maxWidth: 600 }));

  if (isMobile) {
    return (
      <div className="mobile-collapse w-100 d-flex flex-column">
        <RoundButton
          size={32}
          onClick={onToggleOpen}
          className={classNames("toggle-button align-self-center", {
            expanding: open,
          })}
        >
          <FontAwesomeIcon icon={faAngleDoubleDown} size="lg" />
        </RoundButton>
        <Fade in={open} appear timeout={500}>
          <div>
            <Collapse in={open} appear timeout={700}>
              <div>{children}</div>
            </Collapse>
          </div>
        </Fade>
      </div>
    );
  } else {
    return <>{children}</>;
  }
};
