import { useState } from "react";
import { createForm, Config } from "final-form";
import arrayMutators from "final-form-arrays";
import { debounce } from "lodash";

const useDevFormLogger = () => {
  if (process.env.NODE_ENV === "development") {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useState(() => debounce(console.log, 200))[0];
  } else {
    return undefined;
  }
};

export const useCreateForm = <FormValues extends object>(
  config: Config<FormValues>
) => {
  const debugFN = useDevFormLogger();
  const validate = async () => {
    return;
  };

  const [formApi] = useState(() =>
    createForm({
      debug: debugFN,
      mutators: {
        ...arrayMutators,
      },
      // by default allow updating async the data from multiple places outside form.
      // and dont override the dirty fields
      keepDirtyOnReinitialize: true,
      ...config,
      validate,
    })
  );
  return formApi;
};
