import React, { VideoHTMLAttributes } from "react";
import { FileDir } from "base/types";

export type BaseVideoProps = {
  fileName?: string;
  fileDir?: FileDir;
} & VideoHTMLAttributes<HTMLVideoElement>;

export const BaseVideo: React.FC<BaseVideoProps> = ({
  fileName,
  fileDir = FileDir.Empty,
  ...videoProps
}) => {
  if (!fileName) {
    return null;
  }

  return (
    <video
      src={`${fileDir}${fileName}`}
      controls
      {...videoProps}
      className="w-100 mt-1"
    />
  );
};
