import "./styles.scss";

import React, { useState, useCallback, useEffect } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import classNames from "classnames";
import logoSrc from "app/assets/logo_valueup.png";
import { IngredientsRouteBuilder } from "ingredient/IngredientsRouteBuilder";
import { DishesRouteBuilder } from "dish/DishesRouteBuilder";
import { EmployeesRouteBuilder } from "employee/EmployeesRouteBuilder";
import { OrdersRouteBuilder } from "order/OrdersRouteBuilder";
import { MaterialsRouteBuilder } from "material/MaterialsRouteBuilder";
import { StockTransactionsRouteBuilder } from "stockTransactions/StockTransactionsRouteBuilder";
import { SuppliersRouteBuilder } from "supplier/SuppliersRouteBuilder";
import { SentOrdersRouteBuilder } from "sent-order/SentOrdersRouteBuilder";
import { useTokenPayload } from "auth/hooks/useTokenPayload";
import { useAppContext } from "app/context/hooks/useAppContext";
import { AuthRouteBuilder } from "auth/AuthRouteBuilder";
import { DepartmentsRouteBuilder } from "department/DepartmentsRouteBuilder";
import { SimpleGlobalListRouteBuilder } from "global-list/SimpleGlobalListRouteBuilder";
import { StockRouteBuilder } from "stock/StockRouteBuilder";
import { Button } from "react-bootstrap";
import { ChefsRouteBuilder } from "chefs/ChefsHomeRouteBuilder";
import { useStandType } from "auth/hooks/useStandType";
import { POSRouteBuilder } from "pos/POSRouteBuilder";
import { ProductsRouteBuilder } from "product/ProductsRouteBuilder";
import { AdminRouteBuilder } from "admin/AdminRouteBuilder";
import { ManagerRouteBuilder } from "manager/ManagerRouteBuilder";

export const SideBar: React.FC = () => {
  const isInRouteWithoutSidebar = useRouteMatch([
    AuthRouteBuilder.buildLoginRoute(),
    SentOrdersRouteBuilder.templates.Viewer,
  ]);

  const { setIsSidebarEnabled, onSignOut } = useAppContext();
  const tokenPayload = useTokenPayload();
  const standType = useStandType();

  const shouldSidebarBeEnabled =
    Boolean(tokenPayload) &&
    !isInRouteWithoutSidebar &&
    standType !== "receiveOrder" &&
    standType !== "chef";

  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const onToggleNavButtonClick = useCallback(() => {
    setIsMobileOpen((curr) => !curr);
  }, []);

  useEffect(() => {
    setIsSidebarEnabled(shouldSidebarBeEnabled);
  }, [setIsSidebarEnabled, shouldSidebarBeEnabled]);

  if (!shouldSidebarBeEnabled) {
    return null;
  }

  return (
    <div className={classNames("sidebar", { opened: isMobileOpen })}>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a className="toggleNavButton" onClick={onToggleNavButtonClick}>
        <span />
      </a>
      <a className="logo" href="/">
        <img src={logoSrc} alt="לוגו" />
      </a>
      <div className="sideBarButtons">
        <Link
          onClick={() => setIsMobileOpen(false)}
          to={OrdersRouteBuilder.buildAddOrderRoute()}
          className="nav-link orders"
        >
          יצירת הזמנה חדשה
        </Link>
        <Link
          onClick={() => setIsMobileOpen(false)}
          to={OrdersRouteBuilder.buildOrdersStatusRoute()}
          className="nav-link orders"
        >
          הזמנות ממתינות לשיגור
        </Link>
        <Link
          onClick={() => setIsMobileOpen(false)}
          to={IngredientsRouteBuilder.buildIngredientsRoute()}
          className="nav-link ingredients"
        >
          מרכיבים
        </Link>
        <Link
          onClick={() => setIsMobileOpen(false)}
          to={MaterialsRouteBuilder.buildMaterialsRoute()}
          className="nav-link materials"
        >
          חומרי גלם מעובדים
        </Link>
        <Link
          onClick={() => setIsMobileOpen(false)}
          to={DishesRouteBuilder.buildDishesRoute()}
          className="nav-link dishes"
        >
          מנות
        </Link>
        <Link
          onClick={() => setIsMobileOpen(false)}
          to={ProductsRouteBuilder.buildListRoute()}
          className="nav-link products"
        >
          מוצרים
        </Link>
        <Link
          onClick={() => setIsMobileOpen(false)}
          to={SuppliersRouteBuilder.buildSuppliersRoute()}
          className="nav-link suppliers"
        >
          ספקים
        </Link>
        <Link
          onClick={() => setIsMobileOpen(false)}
          to={EmployeesRouteBuilder.buildEmployeesRoute()}
          className="nav-link employees"
        >
          עובדים
        </Link>

        <Link
          onClick={() => setIsMobileOpen(false)}
          to={StockTransactionsRouteBuilder.buildStockTransactionsRoute()}
          className="nav-link stock-transactions"
        >
          תנועות מלאי
        </Link>
        <Link
          onClick={() => setIsMobileOpen(false)}
          to={StockRouteBuilder.buildAddToStockRoute()}
          className="nav-link add-to-stock"
        >
          קליטה למלאי ללא הזמנה
        </Link>
        <Link
          onClick={() => setIsMobileOpen(false)}
          to={DepartmentsRouteBuilder.buildDepartmentsRoute()}
          className="nav-link departments"
        >
          מחלקות
        </Link>
        <Link
          onClick={() => setIsMobileOpen(false)}
          to={SimpleGlobalListRouteBuilder.buildListsIndexRoute()}
          className="nav-link fast-lists"
        >
          רשימות מהירות
        </Link>
        <Link
          onClick={() => setIsMobileOpen(false)}
          to={ChefsRouteBuilder.buildChefsHomeRoute()}
          className="nav-link chefsHomeScreen"
        >
          עמדת שפים
        </Link>
        <Link
          onClick={() => setIsMobileOpen(false)}
          to={POSRouteBuilder.buildImportRoute()}
          className="nav-link pos-import"
        >
          קליטת קובץ מכר
        </Link>
        <Link
          onClick={() => setIsMobileOpen(false)}
          to={SentOrdersRouteBuilder.buildSentOrdersRoute()}
          className="nav-link sent-orders"
        >
          הזמנות ממתינות לקליטה
        </Link>
        <Link
          onClick={() => setIsMobileOpen(false)}
          to={SentOrdersRouteBuilder.buildReceivedOrdersRoute()}
          className="nav-link sent-orders"
        >
          הזמנות ממתינות לאישור
        </Link>
        <Link
          onClick={() => setIsMobileOpen(false)}
          to={ManagerRouteBuilder.buildDashboardRoute()}
          className="nav-link stand-settings"
        >
          דאשבורד מנהל
        </Link>
        <Link
          onClick={() => setIsMobileOpen(false)}
          to={AdminRouteBuilder.buildStandSettingsScreen()}
          className="nav-link stand-settings"
        >
          הגדרות עמדה
        </Link>
        {/* {process.env.NODE_ENV === "development" && (
        <a
          className="nav-link"
          href="http://vud.bestlink.co.il/FoodIngredients"
        >
          מנות ומרכיבים
        </a>
      )}  */}
        {/* <GuardedLink
        allowedRoles={[Role.Two]}
        allowedClaims={[Claim.Order_Create]}
        onClick={() => setIsMobileOpen(false)}
        to={OrdersRouteBuilder.buildOrdersRoute()}
        className="nav-link orders"
      >
        קישור מוגן
      </GuardedLink> */}

        {process.env.NODE_ENV === "development" && (
          <Link to="/dev/" className="nav-link dev">
            מגרש הנסיונות
          </Link>
        )}
        <div className="d-flex align-items-center justify-content-center p-3">
          <Button variant="danger" className="btn-block" onClick={onSignOut}>
            <i className="fas fa-sign-out-alt ml-2" aria-hidden="true" />
            התנתקות
          </Button>
        </div>
      </div>
    </div>
  );
};
