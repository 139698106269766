import React from "react";
import { BaseSelectListItem } from "base/types";
import { Typography } from "base/components/Typography";
import { Form } from "form/components/Form";
import { FormTextInput } from "form/components/FormTextInput";
import { SelectListItemEditorApi } from "select-list-item-editor/SelectListItemEditorApi";

export type WorkScheduleRoleNameEditorProps = {
  initialValues?: BaseSelectListItem;
  onItemSaved: (savedItem: BaseSelectListItem) => void;
};

export const WorkScheduleRoleNameEditor: React.FC<WorkScheduleRoleNameEditorProps> = ({
  initialValues,
  onItemSaved,
}) => {
  const onSubmit = async (item: BaseSelectListItem) => {
    const savedItem = await SelectListItemEditorApi.saveWorkScheduleRoleName(
      item
    );
    onItemSaved(savedItem);
  };

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues}>
      {({ handleSubmit }) => (
        <>
          <Typography variant="title" className="m-2 text-center">
            הוספת שם תפקיד
          </Typography>
          <div className="d-flex align-items-center justify-content-center w-100 p-4">
            <div className="mx-2 w-75">
              <FormTextInput name="name" required />
            </div>
            <button className="btn btn-primary" onClick={handleSubmit}>
              שמירה
            </button>
          </div>
        </>
      )}
    </Form>
  );
};
