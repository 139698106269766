import React from "react";
import { FormControlProps } from "react-bootstrap";
import { Field } from "react-final-form";
import { TextInputFieldInner } from "./TextInputFieldInner";

export type FormTextInputProps = {
  name: string;
  required?: boolean;
  className?: string;
  autoFocus?: boolean;
  selectOnFocus?: boolean;
  placeholder?: string;
  format?: (value: any) => any;
} & Partial<FormControlProps>;

export const FormTextInput: React.FC<FormTextInputProps> = ({
  name,
  required,
  format,
  ...textInputProps
}) => {
  return (
    <Field
      name={name}
      component={TextInputFieldInner}
      validate={(x, _, meta) => {
        if (required && !x) {
          return Promise.resolve("שדה חובה");
        }
      }}
      format={format}
      {...textInputProps}
    />
  );
};
