import React from "react";
import { RouteContent } from "base/components/RouteContent";
import { Typography } from "base/components/Typography";
import { useCustomQuery } from "base/api/hooks/useCustomQuery";
import { SuppliersApi } from "supplier/SuppliersApi";
import { useFullScreenLoader } from "full-screen-loader/hooks/useFullScreenLoader";
import { SupplierForm } from "supplier/components/SupplierForm";

export const AddSupplierScreen: React.FC = () => {
  const { data, isValidating } = useCustomQuery(SuppliersApi.getItemDefaults);

  const isLoading = !data || isValidating;

  useFullScreenLoader(isLoading);

  return (
    <RouteContent>
      <Typography variant="route-title">הוספה</Typography>
      <SupplierForm data={data} />
    </RouteContent>
  );
};
