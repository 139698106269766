import React, { useCallback, useMemo } from "react";
import { Grid } from "@material-ui/core";
import { Button } from "react-bootstrap";
import { FormApi } from "final-form";
import { useForm, FormSpy } from "react-final-form";
import { useFieldArray } from "react-final-form-arrays";
import { isEqual, debounce } from "lodash";
import { Typography } from "base/components/Typography";
import { Form } from "form/components/Form";
import { FormNumberInput } from "form/components/FormNumberInput";
import { FormTextInput } from "form/components/FormTextInput";
import { FormCheckbox } from "form/components/FormCheckbox";
import { Spacer } from "base/components/Spacer";
import { TreeFoodWork, TreeFoodWorksFormValues } from "../types";
import { TreeFoodWorksApi } from "../TreeFoodWorksApi";
import "food-works/styles.scss";
import { TreeType } from "base/types";

export const TreeFoodWorksForm: React.FC = () => {
  const form = useForm<TreeFoodWorksFormValues>();

  const { fields: existingFoodWorksFields } = useFieldArray<TreeFoodWork>(
    "foodWorks",
    {
      isEqual,
      subscription: {},
    }
  );

  const { treeType, treeId, foodWorks } = form.getState().values;

  const onAddFoodWork = useCallback(
    async (
      newFoodWork: Omit<TreeFoodWork, "id">,
      newFoodWorkform: FormApi<Omit<TreeFoodWork, "id">>
    ) => {
      await TreeFoodWorksApi.addFoodWork(treeType, treeId, newFoodWork);
      Object.keys(newFoodWorkform.getState().touched || {}).forEach(
        newFoodWorkform.resetFieldState as any
      );
      setTimeout(newFoodWorkform.reset);
    },
    [treeId, treeType]
  );

  const onRowChanged = useMemo(
    () =>
      debounce(async (foodWork: TreeFoodWork) => {
        await TreeFoodWorksApi.updateFoodWork(treeType, treeId, foodWork);
      }, 500),
    [treeId, treeType]
  );

  const onRowDeleted = useCallback(
    async (index: number) => {
      existingFoodWorksFields.remove(index);
      const foodWorkId = foodWorks[index].id;
      await TreeFoodWorksApi.deleteFoodWork(treeType, treeId, foodWorkId);
    },
    [existingFoodWorksFields, foodWorks, treeId, treeType]
  );

  return (
    <>
      <Typography variant="description" fontSize={20} bold>
        הוספת עיבוד חדש
      </Typography>
      <Spacer />
      <Form
        onSubmit={onAddFoodWork}
        subscription={{ pristine: true, submitting: true }}
        keepDirtyOnReinitialize={false}
      >
        {({ pristine, handleSubmit, submitting }) => (
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <FormTextInput placeholder="שם העיבוד" name="name" required />
            </Grid>
            <Grid item xs={2}>
              <FormNumberInput
                placeholder="פחת באחוזים"
                name="depreciation"
                required
              />
            </Grid>
            <Grid item xs={2}>
              <FormNumberInput
                placeholder="זמן (דק)"
                name="duration"
                required
              />
            </Grid>
            <Grid item xs={3}>
              <FormNumberInput placeholder="עלות חומרים" name="cost" required />
            </Grid>
            {(() => {
              switch (treeType) {
                case TreeType.Material:
                  return (
                    <Grid item xs={1}>
                      <FormCheckbox name="isRequired" />
                    </Grid>
                  );
                default:
                  return null;
              }
            })()}
            <Grid item xs={1}>
              <Button
                variant="primary"
                onClick={() => {
                  handleSubmit();
                }}
                disabled={pristine || submitting}
                size="sm"
                style={{ height: 38 }}
              >
                הוספה
              </Button>
            </Grid>
          </Grid>
        )}
      </Form>
      <Spacer units={4} />
      <Typography variant="description" fontSize={20} bold>
        עיבודים קיימים
      </Typography>
      <Spacer />
      {existingFoodWorksFields.length === 0 && <span>אין עיבודים קיימים</span>}
      {existingFoodWorksFields.map((prefix, index) => {
        const foodWorkId = foodWorks[index].id;
        return (
          <React.Fragment key={foodWorkId}>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <div className="d-flex align-items-center h-100">
                  <span>{index + 1}</span>
                  <div className="d-flex justify-content-center flex-grow-1">
                    {foodWorks[index].name}
                  </div>
                </div>
              </Grid>
              <Grid item xs={2}>
                <FormNumberInput
                  placeholder="פחת באחוזים"
                  name={`${prefix}.depreciation`}
                  required
                />
              </Grid>
              <Grid item xs={2}>
                <FormNumberInput
                  placeholder="זמן (דק)"
                  name={`${prefix}.duration`}
                  required
                />
              </Grid>
              <Grid item xs={3}>
                <FormNumberInput
                  placeholder="עלות חומרים"
                  name={`${prefix}.cost`}
                  required
                />
              </Grid>
              {(() => {
                switch (treeType) {
                  case TreeType.Material:
                    return (
                      <Grid item xs={1}>
                        <FormCheckbox name={`${prefix}.isRequired`} />
                      </Grid>
                    );
                  default:
                    return null;
                }
              })()}
              <Grid item xs={1}>
                <Button
                  variant="danger"
                  onClick={() => onRowDeleted(index)}
                  size="sm"
                  style={{ height: 38 }}
                >
                  מחיקה
                </Button>
              </Grid>
            </Grid>
            <FormSpy<Partial<TreeFoodWorksFormValues>>
              subscription={{
                values: true,
                initialValues: true,
              }}
              onChange={async ({ values, initialValues }) => {
                const nextRowValue = values.foodWorks?.find(
                  (x) => x.id === foodWorkId
                );
                const prevRowValue = initialValues.foodWorks?.find(
                  (x) => x.id === foodWorkId
                );

                if (
                  nextRowValue &&
                  prevRowValue &&
                  !isEqual(nextRowValue, prevRowValue)
                ) {
                  await onRowChanged(nextRowValue!);
                }
              }}
            />
          </React.Fragment>
        );
      })}
    </>
  );
};
