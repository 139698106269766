import React, { useCallback } from "react";
import { useBooleanState } from "base/hooks/useBooleanState";
import "./style.scss";
import { AmountInputModal } from "base/components/input-controls/AmountInput/components/AmountInputModal";
import { TextFormatter } from "base/utils/formatters/TextFormatter";
import { BaseSupplyMethod } from "base/types/BaseSupplyMethod";
import { SupplyUnitType, BaseSelectListItem, UnitType } from "base/types";
import { SupplyMethodDescFormatter } from "order/SupplyMethodDescFormatter";

export interface OrderInlineAmounterProps {
  value?: number;
  onChange: (next: number) => void;
  supplyMethod: BaseSupplyMethod;
  unitKinds: BaseSelectListItem[] | undefined;
  packageKinds: BaseSelectListItem[] | undefined;
  prepareUnitType: UnitType;
}

export const OrderInlineAmounter: React.FC<OrderInlineAmounterProps> = ({
  value = 0,
  supplyMethod,
  unitKinds,
  packageKinds,
  prepareUnitType,
  onChange,
}) => {
  const [showModal, onShowNumpad, onHideModal] = useBooleanState(false);

  const onSaveModal = useCallback(
    (nextAmount: number) => {
      if (nextAmount >= 0) {
        onChange(nextAmount);
        onHideModal();
      } else {
        alert("לא ניתן להכניס מספרים שליליים");
      }
    },
    [onChange, onHideModal]
  );

  return (
    <div className="inlineAmounter">
      <div
        className="minus"
        onClick={() => {
          if (value > 0) {
            onChange(value - 1);
          }
        }}
      >
        -
      </div>
      <div data-op="value">
        <input
          data-received-price=""
          type="number"
          step="0.01"
          value={value.toFixed(2)}
          onClick={onShowNumpad}
        />
      </div>
      <div
        className="plus"
        onClick={() => onChange(value + 1)}
        style={{ lineHeight: 0 }}
      >
        +
      </div>
      <AmountInputModal
        title={TextFormatter.format("יש להזין כמות ($1)", [
          supplyMethod.supplyUnitType === SupplyUnitType.Amount
            ? SupplyMethodDescFormatter.formatSupply(
                supplyMethod,
                unitKinds,
                packageKinds,
                prepareUnitType
              )
            : SupplyMethodDescFormatter.formatTopPackage(
                supplyMethod,
                prepareUnitType,
                unitKinds,
                packageKinds
              ),
        ])}
        show={showModal}
        initialValue={value}
        onCancel={onHideModal}
        onDone={onSaveModal}
      />
    </div>
  );
};
