import { useState, useEffect, useRef, useCallback } from "react";

// Returns a function that restarts the interval if it is on.
export const useRefreshInterval = (
  timeMillies: number,
  on = true,
  callback?: () => void,
  rerenderOn = callback === undefined // Should hook rerender the component that uses it.
) => {
  const [, setState] = useState(0);
  const refs = useRef({
    callback,
    rerenderOn,
    restartIntervalIfOn: () => {},
  });

  useEffect(() => {
    refs.current.callback = callback;
    refs.current.rerenderOn = rerenderOn;
  });

  useEffect(() => {
    refs.current.restartIntervalIfOn = () => {};
    if (!on) return;

    let timeoutId: number;

    const fn = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        refs.current.callback?.();
        if (refs.current.rerenderOn) {
          setState((t) => t + 1);
        }
        fn();
      }, timeMillies);
    };

    fn();

    refs.current.restartIntervalIfOn = fn;

    return () => {
      clearTimeout(timeoutId);
    };
  }, [timeMillies, on]);

  return useCallback(() => refs.current.restartIntervalIfOn(), []);
};
