import React from "react";
import { Typography } from "base/components/Typography";
import { RouteContent } from "base/components/RouteContent";
import { DishesTable } from "dish/components/DishesTable";

export const DishesScreen: React.FC = () => {
  return (
    <RouteContent>
      <Typography variant="route-title"> מנות</Typography>
      <DishesTable />
    </RouteContent>
  );
};
