import React, { useState, useMemo } from "react";
import { FormGroup, Col, FormLabel } from "react-bootstrap";
import { FormFileViewer } from "form/components/FormFileViewer";
import { FormFileUpload } from "form/components/FormFileUpload";
import { IngredientsApi } from "ingredient/IngredientsApi";
import { IngredientFormData } from "../types";
import { FormSpy, useForm } from "react-final-form";
import "../style.scss";
import { useCustomQuery } from "base/api/hooks/useCustomQuery";
import { UnitType } from "base/types";
import { FormTextInput } from "form/components/FormTextInput";
import { UnitTypeFormatter } from "base/utils/formatters";
import { FormNumberInput } from "form/components/FormNumberInput";
import { FieldsValidator } from "form/validators/FieldsValidator";
import { FormSelect } from "form/components/FormSelect";
import { MobileCollapseControl } from "base/components/MobileCollapseControl";
import { FormUnitTypeSelect } from "form/components/FormUnitTypeSelect";
import { FormCheckbox } from "form/components/FormCheckbox";
import { DishesApi } from "dish/DishesApi";
import { FormTextArea } from "form/components/FormTextArea";

export type IngredientEditTabFieldsProps = {
  isNew: boolean;
};

export const IngredientEditTabFields: React.FC<IngredientEditTabFieldsProps> = ({
  isNew,
}) => {
  const { data: departments } = useCustomQuery(IngredientsApi.getDepartments);
  const { data: storageUnits } = useCustomQuery(
    DishesApi.getStorageUnitsForSelectList
  );

  const [prepareUnitType, setPrepareUnitType] = useState<UnitType>();

  const fieldName = (property: keyof IngredientFormData) => property;

  const departmentOptions = useMemo(() => {
    return (
      departments?.map((item) => ({
        value: item.id,
        label: item.name,
      })) || []
    );
  }, [departments]);
  const storageUnitOptions = useMemo(() => {
    return (
      storageUnits?.map((item) => ({
        value: item.id,
        label: item.name,
      })) || []
    );
  }, [storageUnits]);

  const form = useForm();

  return (
    <FormGroup className="d-flex flex-wrap ingredient-form">
      <Col xs={12} md={9}>
        <FormGroup className="d-flex">
          <Col sm={5} md={4} xl={3}>
            שם
          </Col>
          <Col xs={9} sm={7} md={7} lg={6} xl={5}>
            <FormTextInput name={fieldName("name")} required />
          </Col>
        </FormGroup>
        <FormGroup className="d-flex">
          <Col sm={5} md={4} xl={3}>
            שם באנגלית
          </Col>
          <Col xs={9} sm={7} md={7} lg={6} xl={5}>
            <FormTextInput name={fieldName("englishName")} />
          </Col>
        </FormGroup>
        <FormGroup className="d-flex">
          <Col sm={5} md={4} xl={3}>
            מחיר ל{UnitTypeFormatter.format(prepareUnitType, false)}
          </Col>
          <Col xs={9} sm={7} md={7} lg={6} xl={5}>
            <FormNumberInput
              name={fieldName("defaultPrice")}
              step="0.01"
              validate={FieldsValidator.compose(
                FieldsValidator.required,
                FieldsValidator.minValue(0)
              )}
            />
          </Col>
        </FormGroup>
        <FormGroup className="d-flex">
          <Col sm={5} md={4} xl={3}>
            מחלקה
          </Col>
          <Col xs={9} sm={7} md={7} lg={6} xl={5}>
            <FormSelect
              name={fieldName("departmentId")}
              options={departmentOptions}
              emptyOptionLabel="בחר מחלקה"
            />
          </Col>
        </FormGroup>
        <FormGroup className="d-flex">
          <Col sm={5} md={4} xl={3}>
            אחסון
          </Col>
          <Col xs={9} sm={7} md={7} lg={6} xl={5}>
            <FormSelect
              name={fieldName("storageUnitId")}
              options={storageUnitOptions}
              emptyOptionLabel="בחר אחסון"
            />
          </Col>
        </FormGroup>
        <MobileCollapseControl>
          <FormGroup className="d-flex">
            <Col sm={5} md={4} xl={3}>
              קוד ישות
            </Col>
            <Col xs={9} sm={7} md={7} lg={6} xl={5}>
              <FormNumberInput name={fieldName("id")} step="1" />
            </Col>
          </FormGroup>
          <FormGroup className="d-flex">
            <Col sm={5} md={4} xl={3}>
              יח׳ מידה הכנה
            </Col>
            <Col xs={9} sm={7} md={7} lg={6} xl={5}>
              <FormUnitTypeSelect
                isSmallSize={true}
                name={fieldName("prepareUnitType")}
                validate={FieldsValidator.required}
              />
            </Col>
          </FormGroup>
          <FormGroup className="d-flex">
            <Col sm={5} md={4} xl={3}>
              אחוז מע"מ
            </Col>
            <Col xs={9} sm={7} md={7} lg={6} xl={5}>
              <FormNumberInput
                name={fieldName("vatPercentage")}
                step="0.1"
                validate={FieldsValidator.compose(
                  FieldsValidator.required,
                  FieldsValidator.minValue(0)
                )}
              />
            </Col>
          </FormGroup>
          <FormGroup className="d-flex">
            <Col sm={5} md={4} xl={3}>
              משקל סגולי
            </Col>
            <Col xs={9} sm={7} md={7} lg={6} xl={5}>
              <FormNumberInput
                name={fieldName("specificGravity")}
                step="0.001"
              />
            </Col>
          </FormGroup>
          <FormGroup className="d-flex">
            <Col sm={5} md={4} xl={3}>
              הערות ותזכורות בהזמנה
            </Col>
            <Col xs={9} sm={7} md={7} lg={6} xl={5}>
              <FormTextArea name={fieldName("notesForOrder")} rows={5} />
            </Col>
          </FormGroup>
          <FormGroup className="d-flex">
            <Col sm={5} md={4} xl={3}>
              סקירה מקצועית
            </Col>
            <Col xs={9} sm={7} md={7} lg={6} xl={5}>
              <FormTextArea name={fieldName("professionalReview")} rows={5} />
            </Col>
          </FormGroup>
          {!isNew && (
            <>
              <h3 className="my-0">מלאי</h3>
              <hr className="my-3" />
              <FormGroup className="d-flex">
                <Col sm={5} md={4} xl={3}>
                  מלאי מינימום
                </Col>
                <Col xs={9} sm={7} md={7} lg={6} xl={5}>
                  <div className="d-flex align-items-center">
                    <FormNumberInput
                      name={fieldName("minStock")}
                      step="0.001"
                      validate={FieldsValidator.compose(
                        FieldsValidator.required,
                        FieldsValidator.minValue(0)
                      )}
                    />
                    <div className="mr-2">
                      {UnitTypeFormatter.format(prepareUnitType, true)}
                    </div>
                  </div>
                </Col>
              </FormGroup>
              <FormGroup className="d-flex">
                <Col sm={5} md={4} xl={3}>
                  מלאי מקסימום
                </Col>
                <Col xs={9} sm={7} md={7} lg={6} xl={5}>
                  <div className="d-flex align-items-center">
                    <FormNumberInput
                      name={fieldName("maxStock")}
                      step="0.001"
                      validate={FieldsValidator.compose(
                        FieldsValidator.required,
                        FieldsValidator.minValue(0)
                      )}
                    />
                    <div className="mr-2">
                      {UnitTypeFormatter.format(prepareUnitType, true)}
                    </div>
                  </div>
                </Col>
              </FormGroup>
              <FormGroup className="d-flex">
                <Col sm={5} md={4} xl={3}>
                  מלאי
                </Col>
                <Col xs={9} sm={7} md={7} lg={6} xl={5}>
                  <div className="d-flex align-items-center">
                    <FormNumberInput
                      name={fieldName("currentStock")}
                      step="0.001"
                      validate={FieldsValidator.required}
                    />
                    <div className="mr-2">
                      {UnitTypeFormatter.format(prepareUnitType, true)}
                    </div>
                  </div>
                </Col>
              </FormGroup>
            </>
          )}
          <FormGroup className="d-flex">
            <Col sm={5} md={4} xl={3}>
              פעיל
            </Col>
            <Col xs={9} sm={7} md={7} lg={6} xl={5}>
              <FormCheckbox name={fieldName("isActive")} />
            </Col>
          </FormGroup>
          <FormGroup className="d-flex">
            <Col sm={5} md={4} xl={3}>
              מצריך שקילה
            </Col>
            <Col xs={9} sm={7} md={7} lg={6} xl={5}>
              <FormCheckbox name={fieldName("isWeightable")} />
            </Col>
          </FormGroup>
          <FormGroup className="d-flex">
            <Col sm={5} md={4} xl={3}>
              האם נדרש תאריך תפוגה
            </Col>
            <Col xs={9} sm={7} md={7} lg={6} xl={5}>
              <FormCheckbox name={fieldName("isNeedExpirationDate")} />
            </Col>
          </FormGroup>
        </MobileCollapseControl>
        <FormSpy<Partial<IngredientFormData>>
          subscription={{ values: true }}
          onChange={({ values }) => {
            setPrepareUnitType(values.prepareUnitType);
          }}
        />
      </Col>
      <Col xs={12} md={3} className="align-self-start">
        <FormLabel>תמונה</FormLabel>
        <FormFileUpload
          name="image"
          onUploaded={() => {
            form.change("isDeleteImage", false);
          }}
        />
        <div>
          <FormSpy
            subscription={{ values: true }}
            render={({ values, form }) => (
              <FormFileViewer
                pathName="imageName"
                uploadName="image"
                onDelete={() => {
                  if (form.getState().values.image) {
                    form.change("image", undefined);
                  } else {
                    form.change("isDeleteImage", true);
                  }
                }}
                isDeleted={values.isDeleteImage && !values.image}
              />
            )}
          />
        </div>
      </Col>
    </FormGroup>
  );
};
