import React, { TextareaHTMLAttributes } from "react";
import { FormControlProps } from "react-bootstrap";
import { Field } from "react-final-form";
import { FieldValidator } from "final-form";
import { FormTextAreaInner } from "./FormTextAreaInner";

export type FormTextAreaProps = {
  name: string;
  className?: string;
  placeholder?: string;
  format?: (value: any) => any;
  validate?: FieldValidator<any>;
} & Partial<FormControlProps> &
  TextareaHTMLAttributes<any>;

export const FormTextArea: React.FC<FormTextAreaProps> = ({
  name,
  format,
  validate,
  ...textInputProps
}) => {
  return (
    <Field
      name={name}
      component={FormTextAreaInner}
      format={format}
      validate={validate}
      {...textInputProps}
    />
  );
};
