import React, { useMemo } from "react";
import { useLocalStore } from "mobx-react";
import {
  OrderWizardContext,
  OrderWizardContextType,
} from "./OrderWizardContext";
import { OrderWizardStore } from "./OrderWizardStore";

export const OrderWizardContextProvider: React.FC = ({ children }) => {
  const store = useLocalStore(() => new OrderWizardStore());

  const context = useMemo<OrderWizardContextType>(
    () => ({
      store,
      setDisplayVariat: store.setDisplayVariat,
      setRouteSearchPhrase: store.setRouteSearchPhrase,
    }),
    [store]
  );

  return (
    <OrderWizardContext.Provider value={context}>
      {children}
    </OrderWizardContext.Provider>
  );
};
