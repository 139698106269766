import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  DishVEStepType,
  DishVEWizardFormValues,
  DishVEItem,
  StorageUnitItem,
  StorageUnit,
} from "dish/types";
import { useCustomQuery } from "base/api/hooks/useCustomQuery";
import { DishesApi } from "dish/DishesApi";
import { BaseImage } from "base/components/BaseFoodImage";
import { FileDir } from "base/types";
import { FormTextInput } from "form/components/FormTextInput";
import { FormNumberInput } from "form/components/FormNumberInput";
import { Row, Col, Carousel, Button } from "react-bootstrap";
import { Typography } from "base/components/Typography";
import { DishVEItemModal, DishVEItemModalProps } from "./DishVEItemModal";
import { useImmer } from "use-immer";
import "./styles.scss";
import { useForm, Field, FormSpy } from "react-final-form";
import produce from "immer";
import { useFieldArray } from "react-final-form-arrays";
import { FormFileViewer } from "form/components/FormFileViewer";
import { useBooleanState } from "base/hooks/useBooleanState";
import { DishVENutritionalsModal } from "nutritional/DishVENutritionalsModal";
import { Fields } from "form/components/Fields";
import { chunk, isEmpty } from "lodash";
import { DishHelper } from "dish/DishHelper";
import { Doughnut } from "react-chartjs-2";
import { DishVEDoneModal } from "./DishVEDoneModal";
import {
  DishVESpeedometerBorderColors,
  DishVESpeedometerPointerBGColors,
  DishVESpeedometerChartBGColors,
  DishVEFoodCostChartBGColors,
  DishVEFoodCostChartHoverBGColors,
} from "./constants";
import { useSpeechService } from "base/hooks/useSpeechService";
import { usePrevious } from "base/hooks/usePrevious";
import { NumberFormatter } from "base/utils/formatters";

export interface DishVEEditorStepProps {
  onStepChange: (nextStep: DishVEStepType) => void;
}

type ItemListName = "ingredients" | "materials";

type DishVEItemModalState = Pick<DishVEItemModalProps, "show" | "data"> & {
  listName: ItemListName;
};

type MixedItems = Array<{
  listName: ItemListName;
  storageUnitId: number;
  item: StorageUnitItem;
}>;

export const DishVEEditorStep: React.FC<DishVEEditorStepProps> = ({
  onStepChange,
}) => {
  const form = useForm<DishVEWizardFormValues>();
  const fieldName = (property: keyof DishVEWizardFormValues) => property;
  const itemFieldName = (property: keyof DishVEItem, prefix: string) =>
    `${prefix}.${property}`;
  const { fields: ingredintsFields } = useFieldArray(fieldName("ingredients"), {
    subscription: { length: true },
  });
  const { fields: materialsFields } = useFieldArray(fieldName("materials"), {
    subscription: { length: true },
  });
  const [dishVEItemModalState, updateDishVEItemModalState] = useImmer<
    DishVEItemModalState
  >({
    show: false,
    listName: "ingredients",
  });
  const [
    showNutritionals,
    onShowNutritionals,
    onHideNutritionals,
  ] = useBooleanState(false);
  const [
    isItemSpeechEnabled,
    onEnableItemSpeech,
    onDisableItemSpeech,
  ] = useBooleanState(false);
  const [searchPhrase, setSearchPhrase] = useState("");
  const [speech, setSpeech] = useState("");
  const prevSpeech = usePrevious(speech);

  useSpeechService((text: string) => {
    setSpeech(text.trim());
  }, isItemSpeechEnabled);

  const [showDoneModal, onShowDoneModal, onHideDoneModal] = useBooleanState(
    false
  );

  const { data: storageUnits } = useCustomQuery(DishesApi.getStorageUnits);
  const allItems = useMemo(() => {
    return (storageUnits ?? []).reduce<MixedItems>(
      (result, unit: StorageUnit) => [
        ...result,
        ...unit.ingredients.map((ing) => ({
          item: ing,
          listName: "ingredients" as ItemListName,
          storageUnitId: unit.id,
        })),
        ...unit.materials.map((mat) => ({
          item: mat,
          listName: "materials" as ItemListName,
          storageUnitId: unit.id,
        })),
      ],
      []
    );
  }, [storageUnits]);
  const [currStorageUnitId, setCurrStorageUnitId] = useState<number>();
  const currStorageUnit = storageUnits?.find(
    (su) => su.id === currStorageUnitId
  );
  const [matchingItems, setMatchingItems] = useState<MixedItems>([]);

  const showDishVEItemModal = (
    storageUnitItem: StorageUnitItem,
    listName: ItemListName
  ) => {
    updateDishVEItemModalState((state) => {
      const currItemsList = form.getState().values[listName] ?? [];
      const currItem = currItemsList.find((x) => x.id === storageUnitItem.id);

      state.show = true;
      state.data = {
        storageUnitItem,
        initialValues: currItem,
      };
      state.listName = listName;
    });
  };

  const onAddItem = useCallback(
    (values: DishVEItem, listName: ItemListName) => {
      const currItemsList = form.getState().values[listName] ?? [];

      const itemIndex = currItemsList.findIndex(
        (item) => item.id === values.id
      );

      form.change(
        listName,
        produce(currItemsList, (list) => {
          if (itemIndex === -1) {
            list.push(values);
          } else {
            list[itemIndex] = values;
          }
        })
      );
    },
    [form]
  );

  // Reset capturing voice state. (disable capturing)
  useEffect(() => {
    onDisableItemSpeech();
  }, [
    dishVEItemModalState.show,
    currStorageUnitId,
    onDisableItemSpeech,
    searchPhrase,
  ]);

  useEffect(() => {
    setCurrStorageUnitId(undefined);
  }, [searchPhrase]);

  useEffect(() => {
    if (searchPhrase) {
      setMatchingItems(
        allItems.filter((x) => x.item.name.includes(searchPhrase))
      );
    } else if (currStorageUnit) {
      setMatchingItems(
        allItems.filter((x) => x.storageUnitId === currStorageUnit.id)
      );
    }
  }, [allItems, currStorageUnit, searchPhrase, storageUnits]);

  useEffect(() => {
    if (!speech || prevSpeech === speech || !isItemSpeechEnabled) {
      return;
    }

    const speechWords = speech.split(" ");

    let speechSearchPhrase = "";
    let amount = 0;

    for (let speechWord of speechWords) {
      const possibleAmount = parseFloat(speechWord);
      if (!isNaN(possibleAmount)) {
        amount = possibleAmount;
      } else if (!["גרם", "מיליגרם", "יחידות"].includes(speechWord)) {
        const prefix = speechSearchPhrase === "" ? "" : " ";
        speechSearchPhrase += `${prefix}${speechWord}`;
      }
    }

    if (!amount) {
      setSearchPhrase(speechSearchPhrase);
    } else {
      const matches = allItems.filter((x) =>
        x.item.name.includes(speechSearchPhrase)
      );

      const hasExactlyOneMatch = matches.length === 1;

      if (hasExactlyOneMatch) {
        const match = matches[0];
        onAddItem({ ...match.item, amount }, match.listName);
      } else {
        setSearchPhrase(speechSearchPhrase);
      }
    }
  }, [
    allItems,
    isItemSpeechEnabled,
    onAddItem,
    onDisableItemSpeech,
    prevSpeech,
    speech,
  ]);

  useEffect(() => {
    if (speech) {
      onDisableItemSpeech();
    }
  }, [onDisableItemSpeech, speech]);

  return (
    <>
      <div className="flex-grow-1 ve-editor-step">
        <div className="right-wing">
          <div className="d-flex align-items-center my-2 ml-4">
            <input
              className="align-self-center form-control"
              value={searchPhrase}
              onChange={(e) => setSearchPhrase(e.target.value)}
              placeholder="חיפוש"
              style={{ paddingLeft: 30 }} // For the microphone
            />
            <i
              className="fas fa-microphone"
              role="button"
              style={{ fontSize: 26, position: "relative", left: 26 }}
              onClick={onEnableItemSpeech}
            />
          </div>
          {currStorageUnit && !searchPhrase && (
            <div className="d-flex align-items-center my-1">
              <i
                className="fas fa-arrow-circle-right fa-3x text-primary"
                role="button"
                onClick={() => setCurrStorageUnitId(undefined)}
              />
              <Typography variant="title" className="my-0 mx-1">
                {currStorageUnit.name}
              </Typography>
            </div>
          )}
          {searchPhrase && (
            <div className="d-flex align-items-center my-1">
              <i
                className="fas fa-arrow-circle-right fa-3x text-primary"
                role="button"
                onClick={() => setSearchPhrase("")}
              />
              <Typography variant="title" className="my-0 mx-1">
                {searchPhrase
                  ? `תוצאות חיפוש עבור "${searchPhrase}"`
                  : currStorageUnit!.name}
              </Typography>
            </div>
          )}
          <Carousel key={searchPhrase ?? currStorageUnit?.id} interval={null}>
            {currStorageUnit === undefined &&
              !searchPhrase &&
              chunk(storageUnits, 15).map((unitsChunk, index) => (
                <Carousel.Item key={index}>
                  <div className="right-wing-slide">
                    {unitsChunk.map((su) => (
                      <div
                        className="square-list-item"
                        key={`su_${su.id}`}
                        role="button"
                        onClick={() => {
                          setCurrStorageUnitId(su.id);
                        }}
                      >
                        <BaseImage
                          fileDir={FileDir.StorageUnit}
                          fileName={su.imageName}
                        />
                        <span>{su.name}</span>
                      </div>
                    ))}
                  </div>
                </Carousel.Item>
              ))}
            {chunk(matchingItems, 15).map((itemsChunk, index) => (
              <Carousel.Item key={`slide_item_${index}`}>
                <div className="right-wing-slide">
                  {itemsChunk.map(({ item, listName }) => (
                    <div
                      className="square-list-item"
                      key={`${listName}_${item.id}`}
                      role="button"
                      onClick={() => {
                        showDishVEItemModal(item, listName);
                      }}
                    >
                      <BaseImage
                        fileDir={FileDir.BaseFood}
                        fileName={item.imageName}
                      />
                      <span>{item.name}</span>
                    </div>
                  ))}
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
        <div className="middle-area">
          <Row noGutters className="m-1">
            <Col xs={3} md={3} className="d-flex align-items-center">
              שם המנה
            </Col>
            <Col>
              <FormTextInput name={fieldName("name")} />
            </Col>
          </Row>
          <Row noGutters className="m-1">
            <Col xs={3} md={3} className="d-flex align-items-center">
              מחיר היעד
            </Col>
            <Col>
              <FormNumberInput type="number" name={fieldName("menuPrice")} />
            </Col>
          </Row>
          <div className="items-stage">
            {ingredintsFields.map((prefix) => (
              <Field<DishVEItem>
                name={prefix}
                render={({ input: { value } }) => (
                  <div
                    role="button"
                    onClick={() => {
                      showDishVEItemModal(value, "ingredients");
                    }}
                  >
                    <FormFileViewer
                      pathName={itemFieldName("imageName", prefix)}
                    />
                  </div>
                )}
              />
            ))}
            {materialsFields.map((prefix) => (
              <Field<DishVEItem>
                name={prefix}
                render={({ input: { value } }) => (
                  <div
                    role="button"
                    onClick={() => {
                      showDishVEItemModal(value, "materials");
                    }}
                  >
                    <FormFileViewer
                      pathName={itemFieldName("imageName", prefix)}
                    />
                  </div>
                )}
              />
            ))}
          </div>
        </div>
        <div className="left-wing">
          <div
            className="square-list-item"
            role="button"
            onClick={onShowNutritionals}
          >
            <BaseImage />
            <span>ערכים תזונתיים</span>
          </div>
          <div className="square-list-item align-items-center">
            <div className="foodcost-chart-wrap">
              <FormSpy<Partial<DishVEWizardFormValues>>
                subscription={{ values: true }}
                render={({ values }) => {
                  let res = values?.ingredients?.reduce((result, item) => {
                    return result + (item.amount / 1000) * item.price;
                  }, 0);

                  const foodCost = DishHelper.calcFoodCost(
                    values.menuPrice,
                    res ?? 0
                  );
                  const chartOptions = {
                    responsive: true,
                    rotation: 1 * Math.PI,
                    circumference: 1 * Math.PI,
                    legend: {
                      display: false,
                    },
                    tooltips: {
                      enabled: false,
                    },
                    cutoutPercentage: 80,
                  };
                  return (
                    <>
                      <Doughnut
                        data={{
                          datasets: [
                            {
                              data: [25, 45, 30],
                              backgroundColor: DishVESpeedometerChartBGColors,
                              borderWidth: 5,
                            },
                          ],
                        }}
                        options={chartOptions}
                      />
                      <Doughnut
                        data={{
                          datasets: [
                            {
                              data: [
                                Math.min(foodCost, 100) - 1,
                                1,
                                100 - Math.min(foodCost, 100) - 1,
                              ],
                              backgroundColor: DishVESpeedometerPointerBGColors,
                              borderColor: DishVESpeedometerBorderColors,
                              borderWidth: 2,
                            },
                          ],
                        }}
                        options={chartOptions}
                      />
                      <div className="foodcost-text">
                        {NumberFormatter.default.formatPrice(foodCost)}%
                      </div>
                    </>
                  );
                }}
              />
            </div>
          </div>
          <div className="square-list-item">
            <FormSpy<Partial<DishVEWizardFormValues>>
              subscription={{ values: true }}
              render={({ values }) => {
                const all = [
                  ...(values.ingredients ?? []),
                  ...(values.materials ?? []),
                ];
                return (
                  <Doughnut
                    legend={{ display: false }}
                    data={{
                      labels: all?.map((x) => x.name),
                      datasets: [
                        {
                          data: all?.map((x) => x.amount),
                          backgroundColor: DishVEFoodCostChartBGColors,
                          hoverBackgroundColor: DishVEFoodCostChartHoverBGColors,
                        },
                      ],
                    }}
                    options={{
                      responsive: true,
                      maintainAspectRatio: true,
                      title: {
                        display: true,
                        text: "יחסי מרכיבים",
                        padding: 1,
                      },
                    }}
                  />
                );
              }}
            />
          </div>
        </div>
      </div>
      <div>
        <Button variant="success" onClick={onShowDoneModal}>
          סיימתי
        </Button>
      </div>
      <DishVEItemModal
        {...dishVEItemModalState}
        onHide={() => {
          updateDishVEItemModalState((state) => {
            state.show = false;
          });
        }}
        onDelete={() => {
          const { listName, data } = dishVEItemModalState;
          const {
            storageUnitItem: { id },
          } = data!;
          const currItemsList = form.getState().values[listName] ?? [];

          form.change(
            listName,
            currItemsList.filter((x) => x.id !== id)
          );
        }}
        onSubmit={(values) => {
          const { listName } = dishVEItemModalState;
          onAddItem(values, listName);
        }}
      />
      <Fields
        names={[fieldName("name"), fieldName("nutritionals")]}
        render={([name, nutritionals]) => (
          <DishVENutritionalsModal
            show={showNutritionals}
            onHide={onHideNutritionals}
            onSave={(values) => {
              form.change("nutritionals", values);
            }}
            dishName={name}
            existingNutritionals={nutritionals}
          />
        )}
      />
      <FormSpy<DishVEWizardFormValues | {}>
        subscription={{ values: true }}
        render={({ values }) => (
          <DishVEDoneModal
            show={showDoneModal}
            onHide={onHideDoneModal}
            data={
              isEmpty(values) ? undefined : (values as DishVEWizardFormValues)
            }
          />
        )}
      />
    </>
  );
};
