import React, { useMemo, useCallback } from "react";
import { Modal, Row, Col, Button } from "react-bootstrap";
import {
  useCustomQueryWithParams,
  useCustomQuery,
} from "base/api/hooks/useCustomQuery";
import { Form } from "form/components/Form";
import { Typography } from "base/components/Typography";
import { FormSelect } from "form/components/FormSelect";
import { FormCustomReset } from "form/utils/FormCustomReset";
import { FieldsValidator } from "form/validators/FieldsValidator";
import "modal/styles.scss";
import { NutritionalsApi } from "./NutritionalsApi";
import { useSelectOptionsAdapter } from "base/components/Select/hooks/useSelectOptionsAdapter";
import { isEqual, debounce } from "lodash";
import { FieldArray } from "react-final-form-arrays";
import { getIn } from "final-form";
import { FormSpy, Field } from "react-final-form";
import { noop } from "base/utils/noop";
import { NutritionalItem, NutritionalRelationType } from "./types";
import { FormNumberInput } from "form/components/FormNumberInput";
import { NutritionalUnitTypeFormatter } from "./NutritionalUnitTypeFormatter";

export type NutritionalsModalProps = {
  show: boolean;
  onCancel: () => void;
  relationType: NutritionalRelationType;
  relationId?: number;
  relationName?: string;
};

export const NutritionalsModal: React.FC<NutritionalsModalProps> = ({
  show,
  onCancel,
  relationType,
  relationId,
  relationName,
}) => {
  const p_show = show && relationId;

  const { data: nutritionalTypes } = useCustomQuery(NutritionalsApi.getTypes, {
    skip: !p_show,
  });
  const nutritionalOptions = useSelectOptionsAdapter(nutritionalTypes);

  const { data: existingNutritionals } = useCustomQueryWithParams(
    NutritionalsApi.getNutritionals,
    () => [relationType, relationId!],
    {
      skip: !p_show,
    }
  );

  const formInitialValues = useMemo(
    () => ({
      nutritionals: existingNutritionals,
    }),
    [existingNutritionals]
  );

  const onRowChanged = useCallback(
    debounce(async (nutritional: NutritionalItem) => {
      await NutritionalsApi.save(relationType, relationId!, nutritional);
    }, 500),
    [relationId]
  );

  return (
    <Modal show={show} onHide={onCancel} centered size="lg">
      <div>
        <div className="modal-title-bar">
          <Typography variant="description" fontSize={24} bold>
            ניהול ערכים תזונתיים - {relationName}
          </Typography>
          <div className="close-btn" onClick={onCancel} />
        </div>
        <div className="p-2">
          <Typography variant="description" fontSize={20} bold>
            הוספת ערך תזונתי חדש
          </Typography>
          <Form
            onSubmit={async (values: NutritionalItem, form) => {
              await NutritionalsApi.save(relationType, relationId!, values);
              FormCustomReset.hardReset(form);
            }}
            subscription={{ submitting: true }}
          >
            {({ handleSubmit, submitting }) => (
              <Row noGutters className="pt-2">
                <Col xs={4} className="px-1">
                  <FormSelect
                    name="nutritional"
                    emptyOptionLabel="בחירת ערך תזונתי"
                    options={nutritionalOptions}
                    validate={FieldsValidator.required}
                  />
                </Col>
                <Col xs={2} className="px-1">
                  <FormNumberInput name="value" placeholder="כמות במנה" />
                </Col>
                <Col xs="auto" className="px-1 d-flex align-items-center">
                  <Field
                    name="nutritional"
                    render={({
                      input: { value: selectedNutritionalTypeId },
                    }) => {
                      const nutUnitType = nutritionalTypes?.find(
                        (t) => t.id === selectedNutritionalTypeId
                      );
                      return NutritionalUnitTypeFormatter.format(
                        nutUnitType?.type
                      );
                    }}
                  />
                </Col>
                <Col xs="auto">
                  <Button
                    variant="primary"
                    onClick={() => {
                      handleSubmit();
                    }}
                    disabled={submitting}
                    size="sm"
                    style={{ height: 38 }}
                  >
                    הוספה
                  </Button>
                </Col>
              </Row>
            )}
          </Form>
          <div className="pt-2">
            <Typography variant="description" fontSize={20} bold>
              ערכים תזונתיים קיימים
            </Typography>
          </div>
          <Form initialValues={formInitialValues} onSubmit={noop}>
            {({ form }) => (
              <FieldArray
                name="nutritionals"
                isEqual={isEqual}
                subscription={{ length: true }}
              >
                {({ fields }) => (
                  <div>
                    {fields.map((fieldPrefix) => {
                      const nutritionalId: number = getIn(
                        form.getState().values,
                        `${fieldPrefix}.id`
                      );
                      return (
                        <Row noGutters key={`${fieldPrefix}.id`}>
                          <Col xs={4} className="p-1">
                            <FormSelect
                              name={`${fieldPrefix}.nutritional`}
                              emptyOptionLabel="בחירת ערך תזונתי"
                              options={nutritionalOptions}
                              validate={FieldsValidator.required}
                            />
                          </Col>
                          <Col xs={2} className="p-1">
                            <FormNumberInput
                              name={`${fieldPrefix}.value`}
                              placeholder="כמות במנה"
                            />
                          </Col>
                          <Col
                            xs="auto"
                            className="px-1 d-flex align-items-center"
                          >
                            <Field
                              name={`${fieldPrefix}.nutritional`}
                              render={({ input: { value: nutritional } }) => {
                                const nutUnitType = nutritionalTypes?.find(
                                  (t) => t.id === nutritional
                                );
                                return NutritionalUnitTypeFormatter.format(
                                  nutUnitType?.type
                                );
                              }}
                            />
                          </Col>
                          <Col
                            xs="auto"
                            className="p-1 d-flex align-items-center"
                          >
                            <i
                              className="fas fa-trash text-danger ml-auto"
                              aria-hidden="true"
                              role="button"
                              onClick={async () => {
                                if (window.confirm("האם אתה בטוח?")) {
                                  await NutritionalsApi.delete(
                                    relationType,
                                    relationId!,
                                    nutritionalId
                                  );
                                }
                              }}
                            />
                          </Col>
                          <FormSpy
                            subscription={{
                              validating: true,
                            }}
                            onChange={({ validating }) => {
                              const formState = form.getState();
                              if (validating || !formState.valid) {
                                onRowChanged.cancel();
                                return;
                              }

                              const nextRowValue = formState.values.nutritionals?.find(
                                (x) => x.id === nutritionalId
                              );
                              const prevRowValue = formState.initialValues.nutritionals?.find(
                                (x) => x.id === nutritionalId
                              );

                              if (
                                nextRowValue &&
                                prevRowValue &&
                                !isEqual(nextRowValue, prevRowValue)
                              ) {
                                onRowChanged(nextRowValue!);
                              }
                            }}
                          />
                        </Row>
                      );
                    })}
                  </div>
                )}
              </FieldArray>
            )}
          </Form>
        </div>
      </div>
    </Modal>
  );
};
