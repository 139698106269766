import { NutritionalUnitType } from "./types";

export class NutritionalUnitTypeFormatter {
  static format(value: number | undefined) {
    switch (value) {
      case NutritionalUnitType.KCAL:
        return "ק״ק";
      case NutritionalUnitType.GR:
        return "גרם";
      case NutritionalUnitType.MG:
        return "מ״ג";
      case NutritionalUnitType.MKG:
        return "מק״ג";
      case NutritionalUnitType.SPOON:
        return "כפיות";
      case NutritionalUnitType.PERCENTAGE:
        return "אחוזים";
      default:
        return "";
    }
  }
}
