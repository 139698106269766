import React, { useEffect, useMemo, useCallback } from "react";
import { FormControl } from "react-bootstrap";
import ReactSelect from "react-select";
import classNames from "classnames";

export type SelectProps = {
  options: Array<{
    value: any;
    label: string;
  }>;
  displayEmptyOption?: boolean;
  emptyOptionLabel?: string;
  applyCustomSelectClass?: boolean;
  className?: string;
  value: any;
  onChange: (nextValue: any) => void;
  onFocus?: () => void;
  sortAsc?: boolean;
  variant?: "default" | "react-select";
  menuPortalTarget?: HTMLElement;
  defaultSelectedValue?: any;
  isMulti?: boolean;
  disabled?: boolean;
  autoClearValueIfNotFound?: boolean;
};

export const Select: React.FC<SelectProps> = ({
  options,
  className,
  displayEmptyOption = true,
  emptyOptionLabel = "בחר",
  applyCustomSelectClass = true,
  value,
  onChange: onChangeProp,
  onFocus,
  sortAsc = true,
  variant = "default",
  menuPortalTarget,
  defaultSelectedValue,
  isMulti = false,
  disabled = false,
  autoClearValueIfNotFound = false,
}) => {
  const resolvedOptions = useMemo(() => {
    const sortedOptions = sortAsc
      ? [...options].sort((a, b) => (a.label > b.label ? 1 : -1))
      : options;

    if (displayEmptyOption) {
      return [{ value: undefined, label: emptyOptionLabel }, ...sortedOptions];
    } else {
      return sortedOptions;
    }
  }, [displayEmptyOption, emptyOptionLabel, options, sortAsc]);

  const reactSelectResolvedOptions = useMemo(() => {
    if (displayEmptyOption) {
      return resolvedOptions.filter((option) => option.value !== undefined);
    } else {
      return resolvedOptions;
    }
  }, [resolvedOptions, displayEmptyOption]);

  // value of select element is always stringish.. even if values are numbers.
  // therefore this method is assential.
  const onChange = useCallback(
    (e) => {
      const { selectedIndex } = e.target.options;
      onChangeProp(resolvedOptions[selectedIndex].value);
    },
    [onChangeProp, resolvedOptions]
  );

  const onReactSelectChange = useCallback(
    (selectedOption?: any) => {
      if (isMulti) {
        onChangeProp(selectedOption?.map((opt) => opt.value));
      } else {
        onChangeProp(selectedOption?.value);
      }
    },
    [isMulti, onChangeProp]
  );

  // fallback to first value when value is undefined.
  useEffect(() => {
    if (
      resolvedOptions.length > 0 && // has options.
      !displayEmptyOption && // undefined(empty) value is not enabled.
      (value === undefined ||
        value === "" ||
        (autoClearValueIfNotFound &&
          !isMulti &&
          !resolvedOptions.find((x) => x.value === value)) ||
        (isMulti && value.length === 0)) // value not set(undefined).
    ) {
      // pick first value.
      const timeoutId = setTimeout(() => {
        if (isMulti) {
          onChangeProp(
            defaultSelectedValue !== undefined
              ? [defaultSelectedValue]
              : [resolvedOptions[0].value]
          );
        } else {
          onChangeProp(defaultSelectedValue ?? resolvedOptions[0].value);
        }
      }, 60);

      return () => clearTimeout(timeoutId);
    }

    if (
      process.env.NODE_ENV === "development" &&
      !autoClearValueIfNotFound &&
      !isMulti &&
      value !== undefined &&
      resolvedOptions.length > 0 &&
      !resolvedOptions.find((x) => x.value === value) &&
      !displayEmptyOption
    ) {
      console.error("SELECT:: Selected Option not found");
    }
  }, [
    defaultSelectedValue,
    displayEmptyOption,
    onChangeProp,
    resolvedOptions,
    value,
    isMulti,
    autoClearValueIfNotFound,
  ]);

  if (variant === "default") {
    return (
      <FormControl
        as="select"
        custom={applyCustomSelectClass}
        className={className}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        type="select"
        disabled={disabled}
      >
        {resolvedOptions.map((item) => (
          <option key={item.value ?? "empty"} value={item.value ?? ""}>
            {item.label}
          </option>
        ))}
      </FormControl>
    );
  } else {
    const rsValue = (() => {
      if (isMulti) {
        if (!value || !reactSelectResolvedOptions) return null;
        return value.map((x) =>
          reactSelectResolvedOptions.find((option) => option.value === x)
        );
      } else {
        return (
          reactSelectResolvedOptions.find((option) => option.value === value) ??
          null
        );
      }
    })();
    return (
      <ReactSelect
        options={reactSelectResolvedOptions}
        value={rsValue}
        isClearable={displayEmptyOption}
        onChange={onReactSelectChange}
        className={classNames("w-100", className)}
        isRtl
        placeholder={emptyOptionLabel}
        noOptionsMessage={() => "לא נמצאו פריטים תואמים"}
        menuPortalTarget={menuPortalTarget}
        isMulti={isMulti}
        isDisabled={disabled}
      />
    );
  }
};
