import { useCallback } from "react";
import { useHistory } from "react-router-dom";

export type UseNavigateCallbackOptions = {
  replace?: boolean;
};

export const useNavigateCallback = () => {
  const history = useHistory();

  return useCallback(
    (path: string, options?: UseNavigateCallbackOptions) => {
      if (options?.replace) {
        history.replace(path);
      } else {
        history.push(path);
      }
    },
    [history]
  );
};
