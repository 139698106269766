import { Route, Switch, Redirect } from "react-router-dom";
import React from "react";
import { OrdersRouteBuilder } from "order/OrdersRouteBuilder";
import { SupplierListScreen } from "./supplier/SupplierListScreen";
import { SupplierScreen } from "./supplier/SupplierScreen";
import { DepartmentListScreen } from "./department/DepartmentListScreen";
import { DepartmentScreen } from "./department/DepartmentScreen";
import { AddOrderScreen } from "./AddOrderScreen";
import { OrderWizardContextProvider } from "order/context/OrderWizardContextProvider";
import { OrderSearchProductsScreen } from "./OrderSearchProductsScreen";

export const OrderWizardRouterScreen: React.FC = () => {
  return (
    <OrderWizardContextProvider>
      <Switch>
        <Route path={OrdersRouteBuilder.templates.OrderSearchProductsRoute}>
          <OrderSearchProductsScreen />
        </Route>
        <Route path={OrdersRouteBuilder.templates.SupplierRoute}>
          <SupplierScreen />
        </Route>
        <Route path={OrdersRouteBuilder.templates.SupplierListRoute}>
          <SupplierListScreen />
        </Route>
        <Route path={OrdersRouteBuilder.templates.DepartmentRoute}>
          <DepartmentScreen />
        </Route>
        <Route path={OrdersRouteBuilder.templates.DepartmentListRoute}>
          <DepartmentListScreen />
        </Route>
        <Route path={OrdersRouteBuilder.templates.AddOrderRoute}>
          <AddOrderScreen />
        </Route>
        <Route path="/">
          <Redirect to={OrdersRouteBuilder.buildAddOrderRoute()} />
        </Route>
      </Switch>
    </OrderWizardContextProvider>
  );
};
