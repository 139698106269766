import React from "react";
import { Auth } from "auth/types";
import { AppStore } from "./AppStore";

export type AppContextType = {
  store: AppStore;
  setIsSidebarEnabled: (isSidebarEnabled: boolean) => void;
  onSignIn: (auth: Auth) => void;
  onSignOut: () => void;
};

export const AppContext = React.createContext<AppContextType | undefined>(
  undefined
);
