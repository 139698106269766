import Axios from "axios";
import { RequestCacheHelper } from "base/api/RequestCacheHelper";
import { ScheduleRequest } from "./types";

export class ScheduleRequestsApi {
  static getMySchedule(formattedDate: string) {
    return Axios.get<ScheduleRequest>(
      `/api/scheduleRequests?date=${formattedDate}`
    );
  }

  static async saveMySchedule(
    formattedDate: string,
    schedule: ScheduleRequest
  ) {
    await Axios.post<ScheduleRequest>(
      `/api/scheduleRequests?date=${formattedDate}`,
      schedule.shifts
    );

    await RequestCacheHelper.instance.update(
      schedule,
      ScheduleRequestsApi.getMySchedule,
      [formattedDate]
    );
  }
}
