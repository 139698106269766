import axios from "axios";
import {
  FoodDepartmentListItem,
  FoodTreeListItem,
  BaseSelectListItem,
} from "base/types";
import { BodyFormDataBuilder } from "base/api/BodyFormDataBuilder";
import { RequestCacheHelper } from "base/api/RequestCacheHelper";
import {
  DishListItem,
  Dish,
  FoodTreeListSearchOptions,
  StorageUnit,
} from "./types";
import { Recipe } from "recipe/types";
import { RecipeApi } from "recipe/RecipeApi";

export class DishesApi {
  static getTempMaterialList() {
    return axios.get<any>("/api/materials/items");
  }

  static getList() {
    return axios.get<DishListItem[]>("/api/foods/items");
  }

  static getItem(id: number) {
    return axios.get<Dish>(`/api/foods/${id}`);
  }

  static getItemDefaults() {
    return axios.get<Partial<Dish>>(`/api/foods/new`);
  }

  static getFoodTreeList({
    isIngred,
    isMaterial,
    searchTerm = "",
  }: FoodTreeListSearchOptions) {
    return axios.get<FoodTreeListItem[]>(`/api/foods/search`, {
      params: {
        isIngred,
        isMat: isMaterial,
        phrase: searchTerm ?? "",
      },
    });
  }

  static getDepartments() {
    return axios.get<FoodDepartmentListItem[]>(`/api/foods/departments`);
  }

  // TODO maybe extract?
  static getStorageUnits() {
    return axios.get<StorageUnit[]>(`/api/storageUnits/getWithItems`);
  }

  // TODO maybe extract?
  static getStorageUnitsForSelectList() {
    return axios.get<BaseSelectListItem[]>(`/api/storageUnits/selectList`);
  }

  static async saveItem(
    item: WithPartialProperties<Dish, "id">,
    attachment?: File
  ) {
    const { data: savedItem } = await axios.post<Dish>(
      "/api/foods",
      BodyFormDataBuilder.build({ ...item, image: attachment }),
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );

    // updating the getItem cache for the savedItem.id.
    await RequestCacheHelper.instance.update(savedItem, this.getItem, [
      savedItem.id,
    ]);

    // making sure to refetch the list of items to reflect change in list as well.
    RequestCacheHelper.instance.refetch(this.getList);

    return savedItem;
  }

  static async addMaterial(dishId: number, materialId: number, amount: number) {
    await axios.post(`/api/foods/${dishId}/addItem`, {
      itemId: materialId,
      isIngred: false,
      amount,
    });

    // refetching the getItem request.
    RequestCacheHelper.instance.refetch(this.getItem, [dishId]);
  }

  static async addIngredient(
    dishId: number,
    ingredientId: number,
    amount: number
  ) {
    await axios.post(`/api/foods/${dishId}/addItem`, {
      itemId: ingredientId,
      isIngred: true,
      amount,
    });

    // refetching the getItem request.
    RequestCacheHelper.instance.refetch(this.getItem, [dishId]);
  }

  static async addRecipe(id: number) {
    const { data: createdRecipe } = await axios.post<Recipe>(
      `/api/foods/${id}/recipe/add`
    );

    // Updating cache of created recipe and material
    await Promise.all([
      RequestCacheHelper.instance.update(createdRecipe, RecipeApi.get, [
        createdRecipe.id,
      ]),
      RequestCacheHelper.instance.update<Dish>(
        (item) => {
          if (!item) {
            return undefined;
          }

          return { ...item, recipeId: createdRecipe.id };
        },
        DishesApi.getItem,
        [id]
      ),
    ]);
  }

  static async detachMaterial(dishId: number, materialId: number) {
    await axios.post(`/api/foods/${dishId}/deleteItem`, {
      itemId: materialId,
      isIngred: false,
    });

    // updating the getItem cache for the dishId, excluding the material.
    await RequestCacheHelper.instance.update(
      (currDish: Dish | undefined) => {
        return {
          ...currDish!,
          materials: currDish?.materials?.filter(
            (item) => item.materialId !== materialId
          ),
        };
      },
      this.getItem,
      [dishId]
    );
  }

  static async detachIngredient(dishId: number, ingredientId: number) {
    await axios.post(`/api/foods/${dishId}/deleteItem`, {
      itemId: ingredientId,
      isIngred: true,
    });

    // updating the getItem cache for the dishId, excluding the ingredient.
    await RequestCacheHelper.instance.update(
      (currDish: Dish | undefined) => {
        return {
          ...currDish!,
          ingredients: currDish?.ingredients?.filter(
            (item) => item.ingredientId !== ingredientId
          ),
        };
      },
      this.getItem,
      [dishId]
    );
  }

  static async deleteItem(id: number) {
    await axios.delete<void>(`/api/foods/${id}`);
  }
}
