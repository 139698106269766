import React, { useCallback } from "react";
import { useParams, useRouteMatch } from "react-router-dom";
import { useNavigateCallback } from "base/hooks/useNavigateCallback";
import { RouteContent } from "base/components/RouteContent";
import { Typography } from "base/components/Typography";
import {
  EditSentOrderRouteParams,
  SentOrderEditorMode,
} from "sent-order/types";
import { SentOrdersRouteBuilder } from "sent-order/SentOrdersRouteBuilder";
import { SentOrderInfo } from "sent-order/components/SentOrderEditor/components/SentOrderInfo";
import { SentOrderEditor } from "sent-order/components/SentOrderEditor";
import "./../style.scss";
import { Button } from "react-bootstrap";
import { AuthRenderer } from "auth/components/AuthRenderer";
import { Role } from "auth/types";

export const EditSentOrderScreen: React.FC = () => {
  const navigate = useNavigateCallback();
  const { orderId: orderIdStr } = useParams<EditSentOrderRouteParams>();
  const orderId = Number(orderIdStr);

  const adminRouteMatch = useRouteMatch(
    SentOrdersRouteBuilder.buildEditSentOrderRoute(orderId, "advanced")
  );
  const isAdmin = Boolean(adminRouteMatch);
  const editorMode: SentOrderEditorMode = isAdmin ? "advanced" : "default";

  const onBack = useCallback(() => {
    navigate(SentOrdersRouteBuilder.buildSentOrdersRoute());
  }, [navigate]);

  const onToggleMode = useCallback(() => {
    navigate(
      SentOrdersRouteBuilder.buildEditSentOrderRoute(
        orderId,
        editorMode === "default" ? "advanced" : "default"
      )
    );
  }, [editorMode, navigate, orderId]);

  return (
    <RouteContent>
      <div className="back-button">
        <i onClick={onBack} className="fas fa-arrow-circle-right" />
      </div>
      <Typography variant="route-title" className="text-center">
        קליטת הזמנה {orderId}
      </Typography>
      <SentOrderInfo id={orderId} />
      <AuthRenderer includeRoles={[Role.Manager]}>
        <div className="text-center mt-4">
          <Button onClick={onToggleMode}>
            {editorMode === "default" ? "ניהול מתקדם" : "מסך שקילה"}
          </Button>
        </div>
      </AuthRenderer>
      <div className="mt-4">
        <SentOrderEditor id={orderId} mode={editorMode} />
      </div>
    </RouteContent>
  );
};
