import React, { useCallback, useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FormInput } from "../../../form-controls/FormInput";
import { Typography } from "../../../Typography";
import * as yup from "yup";
import { usePrevious } from "base/hooks/usePrevious";
import { Spacer } from "base/components/Spacer";

type AmountInputModalFormValues = {
  value: number;
};

const AmountInputModalFormSchema = yup
  .object<AmountInputModalFormValues>()
  .shape<AmountInputModalFormValues>({
    value: yup.number().required(),
  })
  .asForm();

export type AmountInputModalProps = {
  show: boolean;
  title?: string;
  initialValue?: number;
  onDone: (value: number) => void;
  onCancel: () => void;
};

export const AmountInputModal: React.FC<AmountInputModalProps> = ({
  show,
  title = "בחירת משקל",
  initialValue = 0,
  onDone,
  onCancel,
}) => {
  const [displayForm, setDisplayForm] = useState(false);
  const [previousShow, previousInitialValue] = usePrevious([
    show,
    initialValue,
  ]) || [false, initialValue];

  const form = useForm<AmountInputModalFormValues>({
    validationSchema: AmountInputModalFormSchema,
    defaultValues: {
      value: initialValue,
    },
    mode: "onSubmit",
  });
  const { reset } = form;

  const onSave = useCallback(
    ({ value }: AmountInputModalFormValues) => {
      onDone(value);
    },
    [onDone]
  );

  useEffect(() => {
    if ((show && !previousShow) || initialValue !== previousInitialValue) {
      reset({
        value: initialValue,
      });
    }

    const timeoutId = setTimeout(() => setDisplayForm(show), 100);
    return () => clearTimeout(timeoutId);
  }, [show, reset, initialValue, previousShow, previousInitialValue]);

  return (
    <Modal
      show={show}
      onHide={onCancel}
      dialogClassName="numpad-modal"
      centered
    >
      <div className="numpad-moda-content">
        <div className="text-center">
          <Typography variant="description" fontSize={18}>
            {title}
          </Typography>
        </div>
        <Spacer units={4} />
        <div className="px-4">
          {displayForm && (
            <FormInput
              type="number"
              formInstance={form}
              name="value"
              inputMode="decimal"
              autoFocus
              selectOnFocus
            />
          )}
        </div>
        <Spacer units={4} />
        <div className="text-center">
          <Button onClick={form.handleSubmit(onSave)}>שמור</Button>
        </div>
      </div>
    </Modal>
  );
};
