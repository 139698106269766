import React from "react";
import { RouteContent } from "base/components/RouteContent";
import { useParams } from "react-router-dom";
import { TreeInfoRouteParams } from "tree-info/types";
import { TreeInfo } from "tree-info/components/TreeInfo";
import { TreeTypeFormatter } from "base/utils/formatters/TreeTypeFormatter";

export const TreeInfoScreen = () => {
  const { itemId: itemIdStr, type: typeStr } = useParams<TreeInfoRouteParams>();

  const itemId = Number(itemIdStr);
  const type = TreeTypeFormatter.formatRoutePathReverse(typeStr);

  return (
    <RouteContent>
      {/* Key usage is important, if rendering different type we get errors. this is an ez fix. */}
      <TreeInfo itemId={itemId} type={type} key={type} />
    </RouteContent>
  );
};
