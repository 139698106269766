import axios from "axios";
import {
  AuthCredentials,
  PinAuthCredentials,
  Auth,
  AccountUserListItem,
} from "./types";

export class AuthApi {
  static async login(credentials: AuthCredentials) {
    const { data } = await axios.post<Auth>(`/api/account/auth`, credentials);

    return data;
  }

  static async pinCodeAuth(credentials: PinAuthCredentials) {
    const { data } = await axios.post<Auth>(
      `/api/account/pinAuth`,
      credentials
    );

    return data;
  }

  static async getAccountUsers() {
    return axios.get<AccountUserListItem[]>(`/api/account/users`);
  }
}
