import axios from "axios";
import { StockTransactionListItem } from "./StockTransactionListItem";
import { RequestCacheHelper } from "base/api/RequestCacheHelper";

export class StockTransactionsApi {
  static getList() {
    return axios.get<StockTransactionListItem[]>("/api/stockTransactions");
  }

  static async delete(id: number) {
    await axios.delete(`/api/stockTransactions/${id}`);
    RequestCacheHelper.instance.update<StockTransactionListItem[]>(
      (transactions) => {
        if (!transactions) return undefined;
        return transactions.filter((t) => t.id !== id);
      },
      StockTransactionsApi.getList
    );
  }
}
