import React, { useEffect, useState, ImgHTMLAttributes } from "react";
import { FormContextValues } from "react-hook-form";
import { usePrevious } from "base/hooks/usePrevious";
import { BaseImage } from "base/components/BaseFoodImage";
import styled from "styled-components";

export type FormImageViewerProps<FormValues> = {
  pathName?: KeysByType<FormValues, string | undefined>;
  uploadName?: KeysByType<FormValues, FileList | undefined>;
  formInstance: FormContextValues<FormValues>;
} & ImgHTMLAttributes<HTMLImageElement>;

const UploadedImage = styled.img({
  width: "100%",
  height: "100%",
});

// TODO - simplify.
export const FormImageViewer = <FormValues,>({
  pathName,
  uploadName,
  formInstance,
  ...imageProps
}: FormImageViewerProps<FormValues>) => {
  const { watch } = formInstance;
  const [imageValue, setImageValue] = useState<string>();

  const watchDeps = [pathName, uploadName].filter(
    (dep) => dep !== undefined
  ) as string[];

  const watchResults = watch(watchDeps);

  const pathValue: string | undefined = (watchResults as any)[pathName];
  const uploadedFileList: FileList | undefined = (watchResults as any)[
    uploadName
  ];

  const mode = (() => {
    if (uploadedFileList && uploadedFileList[0]) {
      return "uploaded-image";
    } else if (pathValue) {
      return "image-path";
    } else {
      return "empty";
    }
  })();

  const prevMode = usePrevious(mode);

  useEffect(() => {
    if (mode !== "uploaded-image") {
      if (prevMode === "uploaded-image") {
        setImageValue(undefined);
      }
      return;
    }

    const reader = new FileReader();
    reader.onload = function (e) {
      setImageValue((e.target?.result as string) ?? undefined);
    };

    reader.readAsDataURL(uploadedFileList![0]);

    return () => {
      if (reader.readyState === 1) {
        reader.abort();
      }
    };
  }, [uploadedFileList, mode, prevMode]);

  useEffect(() => {
    if (mode !== "image-path") {
      if (prevMode === "image-path") {
        setImageValue(undefined);
      }

      return;
    }

    setImageValue(pathValue);
  }, [mode, pathValue, prevMode]);

  if (mode === "image-path") {
    return (
      <BaseImage
        className="imgFullWidth"
        fileName={imageValue}
        {...imageProps}
      />
    );
  } else {
    return <UploadedImage alt="" src={imageValue} {...imageProps} />;
  }
};
