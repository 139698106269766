import { WorkScheduleDuplicator } from "work-schedule/util/WorkScheduleDuplicator";
import { WeeklyWorkScheduleType } from "weekly-work-schedule/types";
import { WorkScheduleType } from "work-schedule/types";

export class WeeklyScheduleBuilder {
  static build(
    templateSchedules: WorkScheduleType[]
  ): WeeklyWorkScheduleType[] {
    return templateSchedules.map(WorkScheduleDuplicator.duplicate);
  }
}
