import React from "react";
import classNames from "classnames";
import { useIsSidebarEnabled } from "app/hooks/useIsSidebarEnabled";

export interface RouteContentProps {
  className?: string;
}

export const RouteContent: React.FC<RouteContentProps> = ({
  children,
  className,
}) => {
  const isSidebarEnabled = useIsSidebarEnabled();
  return (
    <div
      className={classNames("route-content", className, { isSidebarEnabled })}
    >
      {children}
    </div>
  );
};
