import React, { useState, useCallback } from "react";
import { useForm, FormSpy } from "react-final-form";
import { debounce } from "lodash";
import Swiper, { ReactIdSwiperProps } from "react-id-swiper";
import { BaseImage } from "base/components/BaseFoodImage";
import { useCustomQueryWithParams } from "base/api/hooks/useCustomQuery";
import { FoodTreeListItem } from "base/types";
import { BasicTreeInfoListSearchOptions } from "basic-tree-info/types";
import { BasicTreeInfoApi } from "basic-tree-info/BasicTreeInfoApi";
import { TreeType } from "base/types/TreeType";
import { TreeInfoApi } from "tree-info/TreeInfoApi";
import { PickAmountModal } from "./PickAmountModal";

export type BasicTreeFoodsSearchResultsProps = {
  type: TreeType;
  id: number;
};

type AmountModalState =
  | {
      open: false;
      foodTreeItem: undefined;
    }
  | { open: true; foodTreeItem: FoodTreeListItem };

export const BasicTreeFoodsSearchResults: React.FC<BasicTreeFoodsSearchResultsProps> = ({
  type,
  id,
}) => {
  const form = useForm<BasicTreeInfoListSearchOptions>();
  const [amountModalState, setAmountModalState] = useState<AmountModalState>({
    foodTreeItem: undefined,
    open: false,
  });

  const [searchParams, setSearchParams] = useState<
    BasicTreeInfoListSearchOptions
  >(form.getState().values);

  const { data } = useCustomQueryWithParams(
    BasicTreeInfoApi.getFoodTreeList,
    () => [type, searchParams]
  );

  const onSearchFieldsChange = useCallback(
    debounce((nextSearchFields: BasicTreeInfoListSearchOptions) => {
      setSearchParams(nextSearchFields);
    }, 500),
    []
  );

  const onHideAmountModal = useCallback(() => {
    setAmountModalState({
      open: false,
      foodTreeItem: undefined,
    });
  }, []);

  const onAddItem = useCallback((foodTreeItem: FoodTreeListItem) => {
    setAmountModalState({
      open: true,
      foodTreeItem,
    });
  }, []);

  const onItemAmountPicked = useCallback(
    async (amount: number) => {
      if (!amountModalState.open) {
        return;
      }

      if (amountModalState.foodTreeItem.isIngred) {
        await TreeInfoApi.addIngredient(
          type,
          id,
          amountModalState.foodTreeItem.id,
          amount
        );
      } else {
        await TreeInfoApi.addMaterial(
          type,
          id,
          amountModalState.foodTreeItem.id,
          amount
        );
      }
      onHideAmountModal();
    },
    [amountModalState, id, onHideAmountModal, type]
  );

  const swiperOptions: ReactIdSwiperProps = {
    rtl: ".swiper-container-rtl",
    slidesPerView: "auto",
    spaceBetween: 30,
    slidesPerColumn: 1,
    grabCursor: true,
    centeredSlides: true,
    effect: "coverflow",
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    coverflowEffect: {
      rotate: 30,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: false,
    },
    containerClass: "swiper-container swiper-ingredients-chooser",
  };

  return (
    <>
      <div className="foods-tree-search-results">
        {data && (
          <Swiper {...swiperOptions} key={JSON.stringify(searchParams)}>
            {data.slice(0, 20).map((item) => (
              <div
                className="swiper-item"
                key={item.id}
                onClick={() => onAddItem(item)}
              >
                <div>
                  <BaseImage
                    fileName={item.imageName}
                    className="swiper-item-image"
                  />
                  <div className="add">
                    <div>הוספה למנה</div>
                  </div>
                </div>
                <div>{item.name}</div>
                <div className="used">
                  <div>
                    <i className="fas fa-check" aria-hidden="true" />
                  </div>
                </div>
              </div>
            ))}
          </Swiper>
        )}
        <PickAmountModal
          show={amountModalState.open}
          onCancel={onHideAmountModal}
          onPick={onItemAmountPicked}
        />
      </div>
      <FormSpy<BasicTreeInfoListSearchOptions>
        subscription={{ values: true }}
        onChange={({ values }) => {
          onSearchFieldsChange(values);
        }}
      />
    </>
  );
};
