import React, { useMemo } from "react";
import { FilterProps } from "react-table";
import { uniq } from "lodash";
import { Select } from "base/components/Select";

export const SelectColumnFilter = <D extends object>({
  column: { filterValue, setFilter, preFilteredRows, id },
}: FilterProps<D>) => {
  const options = useMemo(() => {
    const uniqueValues = uniq(preFilteredRows.map((row) => row.values[id]));
    const options = uniqueValues.map((value) => ({
      value,
      label: value,
    }));
    return options;
  }, [id, preFilteredRows]);

  return (
    <Select
      options={options}
      value={filterValue}
      onChange={setFilter}
      variant="react-select"
      menuPortalTarget={document.body}
    />
  );
};
