import * as yup from "yup";

yup.setLocale({
  number: {
    integer: "ערך השדה צריך להיות מספר שלם",
    min: ({ min }) => `המספר המינימלי האפשרי הוא  ${min}`,
    max: ({ max }) => `המספר המקסימלי האפשרי הוא  ${max}`,
  },
  string: {
    min: ({ min }) => `ערך שדה מינימלי:  ${min} תווים`,
    max: ({ max }) => `ערך שדה מקסימלי:  ${max} תווים`,
  },
  mixed: {
    required: "שדה חובה",
    notType: () => {
      return "ערך השדה לא חוקי";
    },
  },
});

/* Currently only converting empty string to undefined when field is defined as number.
   Doing it because yup sees it as an error,
   we want empty strings to be treated as valid number,
   unless the field is required or something like that. */
yup.addMethod(yup.object, "asForm", function () {
  return this.transform((transformedValue, originalValue) => {
    // important that this describe() happens once for performance.
    const fields = this.describe().fields;

    return Object.entries(transformedValue).reduce((obj, [key, value]) => {
      const isNumberAndGiveEmptyString =
        fields[key]?.type === "number" && value === "";

      return {
        ...obj,
        [key]: isNumberAndGiveEmptyString ? undefined : value,
      };
    }, {});
  });
});
