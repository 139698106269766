import React from "react";
import { Row, Col } from "react-bootstrap";
import { FormSelect } from "form/components/FormSelect";
import { SupplierFormData, PaymentTermsOptions } from "supplier/types";
import { FormNumberInput } from "form/components/FormNumberInput";
import { OrderAnnouncementTypeOptions } from "order/constants";
import { FormTextArea } from "form/components/FormTextArea";
import { SupplierOrdersTable } from "./SupplierOrdersTable";

export type SupplierOrdersTabProps = {
  supplierId: number;
};

export const SupplierOrdersTab: React.FC<SupplierOrdersTabProps> = ({
  supplierId,
}) => {
  const fieldName = (property: keyof SupplierFormData) => property;

  return (
    <>
      <Row noGutters>
        <Col xs={12} sm={4} className="p-1 d-flex align-items-center">
          מינימום להזמנה
        </Col>
        <Col xs={12} sm={8} className="p-1">
          <FormNumberInput name={fieldName("minimunOrderPrice")} />
        </Col>
        <Col xs={12} sm={4} className="p-1 d-flex align-items-center">
          תנאי תשלום
        </Col>
        <Col xs={12} sm={8} className="p-1">
          <FormSelect
            name={fieldName("paymentTerms")}
            options={PaymentTermsOptions}
            displayEmptyOption={false}
          />
        </Col>
        <Col xs={12} sm={4} className="p-1 d-flex align-items-center">
          צורת שליחת הזמנה
        </Col>
        <Col xs={12} sm={8} className="p-1">
          <FormSelect
            name={fieldName("announcementTypes")}
            isMulti
            options={OrderAnnouncementTypeOptions}
            variant="react-select"
          />
        </Col>
        <Col xs={12} sm={4} className="p-1 d-flex align-items-center">
          הודעה בשיגור הזמנה
        </Col>
        <Col xs={12} sm={8} className="p-1">
          <FormTextArea name={fieldName("submitOrderMessage")} />
        </Col>
      </Row>
      <h3 className="my-4">
        <u>הזמנות ותעודות קודמות</u>
      </h3>
      <SupplierOrdersTable supplierId={supplierId} />
    </>
  );
};
