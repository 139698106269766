import Axios from "axios";
import {
  NutritionalSelectListItem,
  NutritionalRelationType,
  NutritionalItem,
} from "./types";
import { RequestCacheHelper } from "base/api/RequestCacheHelper";

export class NutritionalsApi {
  static getTypes() {
    return Axios.get<NutritionalSelectListItem[]>(
      `/api/itemNutritionals/types`
    );
  }

  static getNutritionals(
    relationType: NutritionalRelationType,
    relationId: number
  ) {
    switch (relationType) {
      case NutritionalRelationType.Food:
        return Axios.get<NutritionalItem[]>(
          `/api/foods/${relationId}/nutritionals`
        );
      case NutritionalRelationType.Product:
        return Axios.get<NutritionalItem[]>(
          `/api/products/${relationId}/nutritionals`
        );
      case NutritionalRelationType.Material:
        return Axios.get<NutritionalItem[]>(
          `/api/materials/${relationId}/nutritionals`
        );
    }
  }

  static async save(
    relationType: NutritionalRelationType,
    relationId: number,
    nutritional: NutritionalItem
  ) {
    const { data: savedNutritional } = await Axios.post(
      `/api/itemNutritionals/`,
      {
        foodId:
          relationType === NutritionalRelationType.Food
            ? relationId
            : undefined,
        productId:
          relationType === NutritionalRelationType.Product
            ? relationId
            : undefined,
        materialId:
          relationType === NutritionalRelationType.Material
            ? relationId
            : undefined,
        ...nutritional,
      }
    );

    RequestCacheHelper.instance.update<NutritionalItem[]>(
      (nutritionals) => {
        if (!nutritionals) return undefined;
        else if (!nutritional.id) return [...nutritionals, savedNutritional];
        else
          return nutritionals.map((item) =>
            item.id === savedNutritional.id ? savedNutritional : item
          );
      },
      NutritionalsApi.getNutritionals,
      [relationType, relationId]
    );
  }

  static async delete(
    relationType: NutritionalRelationType,
    relationId: number,
    idToDelete: number
  ) {
    await Axios.delete(`/api/itemNutritionals/${idToDelete}`);

    RequestCacheHelper.instance.update<NutritionalItem[]>(
      (nutritionals) => {
        if (!nutritionals) return undefined;

        return nutritionals.filter((a) => a.id !== idToDelete);
      },
      NutritionalsApi.getNutritionals,
      [relationType, relationId]
    );
  }
}
