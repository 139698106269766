import React from "react";
import ReactDOM from "react-dom";
import { AppContextProvider } from "app/context/AppContextProvider";
import { App } from "./app/components/App";
import * as serviceWorker from "./serviceWorker";
import { isTestEnv } from "app/constants";

ReactDOM.render(
  <React.StrictMode>
    <AppContextProvider>
      <App />
    </AppContextProvider>
    {isTestEnv && (
      <div
        className="badge badge-success"
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
          margin: "10px",
          padding: "10px",
          fontSize: "16px",
          zIndex: 10,
        }}
      >
        TEST env.
      </div>
    )}
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
