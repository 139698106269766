import React from "react";
import { RouteContent } from "base/components/RouteContent";
import { Typography } from "base/components/Typography";
import { SentOrdersTable } from "sent-order/components/SentOrdersTable";

export const SentOrdersScreen: React.FC = () => {
  return (
    <RouteContent>
      <Typography variant="route-title">הזמנות ממתינות לקליטה</Typography>
      <SentOrdersTable />
    </RouteContent>
  );
};
