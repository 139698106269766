import React from "react";
import { RouteContent } from "base/components/RouteContent";
import { Typography } from "base/components/Typography";
import { EmployeeForm } from "employee/components/EmployeeForm";

export const AddEmployeeScreen: React.FC = () => {
  return (
    <RouteContent>
      <Typography variant="route-title">הוספה</Typography>
      <EmployeeForm mode="new" data={undefined} />
    </RouteContent>
  );
};
