import axios from "axios";
import {
  Department,
  DepartmentListItem,
  DepartmentInfoFormValues,
  DepartmentAddons,
  AddDepartmentAddonFormValues,
  DepartmentAddon,
} from "./types";
import { RequestCacheHelper } from "base/api/RequestCacheHelper";
import { BodyFormDataBuilder } from "base/api/BodyFormDataBuilder";
import { omit } from "lodash";

export class DepartmentsApi {
  static getList() {
    return axios.get<DepartmentListItem[]>("/api/departments/items");
  }

  static async getItem(id: number) {
    return axios.get<Department>(`/api/departments/${id}`);
  }

  static async getAddons(id: number) {
    return axios.get<DepartmentAddons>(`/api/departments/${id}/addons`);
  }

  static async saveChildren(parentId: number, children: Department[]) {
    const { data: savedParentDepartment } = await axios.post<Department>(
      `/api/departments/${parentId}`,
      children
    );

    await RequestCacheHelper.instance.update(
      savedParentDepartment,
      DepartmentsApi.getItem,
      [parentId]
    );
  }

  static async saveItemInfo(item: DepartmentInfoFormValues) {
    await axios.post(
      `/api/departments/single/${item.id}`,
      BodyFormDataBuilder.build2(item)
    );

    const department: Department = omit<DepartmentInfoFormValues, "photoFile">(
      item,
      "photoFile"
    );

    await Promise.all([
      RequestCacheHelper.instance.update(department, DepartmentsApi.getItem, [
        item.id,
      ]),
      RequestCacheHelper.instance.refetch(DepartmentsApi.getItem, [
        department.parentId,
      ]),
    ]);
  }

  static async addAddon(
    departmentId: number,
    item: AddDepartmentAddonFormValues,
    list: "materials" | "ingredients"
  ) {
    const routeByList = list === "materials" ? "material" : "ingredient";
    const { data: addedItem } = await axios.post<DepartmentAddon>(
      `/api/departments/${departmentId}/addons/${routeByList}`,
      item
    );
    await RequestCacheHelper.instance.update(
      (curr: DepartmentAddons | undefined) => {
        if (!curr) return undefined;
        return {
          ...curr,
          [list]: [...curr[list], addedItem],
        };
      },
      DepartmentsApi.getAddons,
      [departmentId]
    );
  }

  static async deleteAddon(
    departmentId: number,
    idToDelete: number,
    list: "materials" | "ingredients"
  ) {
    await axios.delete(`/api/departmentAddons/${idToDelete}`);
    await RequestCacheHelper.instance.update(
      (curr: DepartmentAddons | undefined) => {
        if (!curr) return undefined;
        return {
          ...curr,
          [list]: curr[list].filter((item) => {
            return item.id !== idToDelete;
          }),
        };
      },
      DepartmentsApi.getAddons,
      [departmentId]
    );
  }

  static async updateAddon(
    departmentId: number,
    addon: DepartmentAddon,
    list: "materials" | "ingredients"
  ) {
    await axios.post(`/api/departmentAddons`, addon);
    await RequestCacheHelper.instance.update(
      (curr: DepartmentAddons | undefined) => {
        if (!curr) return undefined;

        return {
          ...curr,
          [list]: curr[list].map((item) => {
            return item.id === addon.id ? addon : item;
          }),
        };
      },
      DepartmentsApi.getAddons,
      [departmentId]
    );
  }
}
