import React, { useCallback } from "react";
import { Field } from "react-final-form";
import { CheckboxFieldInner } from "./CheckboxFieldInner";

export type FormCheckboxProps = {
  name: string;
  className?: string;
  width?: string | number;
  disabled?: boolean;
};

export const FormCheckbox: React.FC<FormCheckboxProps> = ({
  name,
  className,
  width,
  disabled,
}) => {
  // incase field was undefined, but was reset to false.
  const isEqual = useCallback((a, b) => Boolean(a) === Boolean(b), []);

  return (
    <Field
      name={name}
      component={CheckboxFieldInner}
      isEqual={isEqual}
      className={className}
      width={width}
      disabled={disabled}
    />
  );
};
