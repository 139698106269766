import { SelectProps } from "base/components/Select";

export enum SalaryType {
  Houry,
  Tips,
  Globaly,
  Trusted,
}

export const SalaryTypeSelectOptions: SelectProps["options"] = [
  { label: "שעתי", value: SalaryType.Houry },
  { label: "טיפים", value: SalaryType.Tips },
  { label: "גלובאלי", value: SalaryType.Globaly },
  { label: "משרת אמון", value: SalaryType.Trusted },
];

export interface Employee {
  id: string;
  username: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  hourlyRate: number;
  pinCode: number;
  salaryType: SalaryType;
  bankAccount: string;
  isPartOfTips: boolean;
  workingStartDate: string | undefined;
  email: string | undefined;
  birthday: string | undefined;
  addressLine: string | undefined;
  addressStreet: string | undefined;
  addressCity: string | undefined;
  addressHome: string | undefined;
  addressZipCode: string | undefined;
  imageName: string | undefined;
  employeeContractId: number;
  isActive: boolean;
  employeeKey: number;
  personalId: number;
}

export interface EmployeeListItem {
  id: string;
  imageName: string | undefined;
  isActive: boolean;
  hourlyRate: number;
  firstName: string;
  lastName: string;
  salaryType: number;
  isGlobalSalary: boolean;
  isPartOfTips: boolean;
  workingStartDate: string;
  employeeRoleName: string;
}

export interface EmployeeOngoingShiftItem {
  employeeId: string;
  entranceTime?: string;
}

export interface EmployeeFormValues
  extends WithPartialProperties<Employee, "id"> {
  image?: File;
  newPassword?: string;
}
