import React, { useCallback, useRef, useMemo } from "react";
import {
  FullScreenLoader,
  FullScreenLoaderApi,
} from "./components/FullScreenLoader/FullScreenLoader";
import { FullScreenLoaderContext } from "./FullScreenLoaderContext";

const { Provider } = FullScreenLoaderContext;

export const FullScreenLoaderProvider: React.FC = ({ children }) => {
  const fullScreenLoaderApiRef = useRef<FullScreenLoaderApi | null>(null);
  const counterRef = useRef(0);
  const onDisplayStatuseChange = useCallback((nextStatus: "show" | "hide") => {
    if (nextStatus === "show") {
      counterRef.current += 1;
      fullScreenLoaderApiRef.current!.onShow();
    } else {
      counterRef.current -= 1;
      if (counterRef.current <= 0) {
        counterRef.current = 0;
        fullScreenLoaderApiRef.current!.onHide();
      }
    }
  }, []);

  const context = useMemo(() => ({ onDisplayStatuseChange }), [
    onDisplayStatuseChange,
  ]);

  return (
    <Provider value={context}>
      {children}
      <FullScreenLoader apiRef={fullScreenLoaderApiRef} />
    </Provider>
  );
};
