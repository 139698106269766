import React, { useMemo, useCallback } from "react";
import { Form } from "form/components/Form";
import { WorkScheduleType, WorkScheduleFormValues } from "work-schedule/types";
import { WorkScheduleForm } from "./WorkScheduleForm";
import { WorkScheduleApi } from "work-schedule/WorkScheduleApi";

export type WorkScheduleProps = {
  schedules: WorkScheduleType[];
};

export const WorkSchedule: React.FC<WorkScheduleProps> = ({ schedules }) => {
  const formValues = useMemo<WorkScheduleFormValues>(
    () => ({
      schedules,
      isDataready: schedules.length > 0,
    }),
    [schedules]
  );

  const onSubmit = useCallback(
    async ({ schedules: nextSchedules }: WorkScheduleFormValues) => {
      await WorkScheduleApi.saveTemplateSchedules(nextSchedules);
      alert("הפעולה הסתיימה בהצלחה");
    },
    []
  );

  return (
    <Form
      initialValues={formValues}
      onSubmit={onSubmit}
      component={WorkScheduleForm}
    />
  );
};
