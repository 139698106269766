import React from "react";
import { RouteContent } from "base/components/RouteContent";
import { Typography } from "base/components/Typography";
import { EmployeesTable } from "employee/components/EmployeesTable";

export const EmployeesScreen: React.FC = () => {
  return (
    <RouteContent>
      <Typography variant="route-title"> עובדים</Typography>
      <EmployeesTable />
    </RouteContent>
  );
};
