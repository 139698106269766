export class DishesRouteBuilder {
  static templates = {
    Dishes: "/dishes",
    AddDish: "/dishes/new",
    DishVisualEditor: "/dish-visual-editor",
  };

  static buildDishesRoute() {
    return DishesRouteBuilder.templates.Dishes;
  }

  static buildAddDishRoute() {
    return DishesRouteBuilder.templates.AddDish;
  }

  static buildDishVisualEditorRoute() {
    return DishesRouteBuilder.templates.DishVisualEditor;
  }
}
