import useSWR, { responseInterface } from "swr";
import { RequestCacheHelper } from "../RequestCacheHelper";
import { IUseCustomQuery, IUseCustomQueryWithParams } from "../types";

export type CustomQueryResponse<T> = responseInterface<T, any>;

export interface IUseCustomQueryOptions {
  skip?: boolean;
  pollingInterval?: number;
}

// 1. determined if skip
// 2. while skipping no server requests, hooks result always {data: undefined};
// 3. while not skipping, hook result is {data: Res} when request finishes, or has cache data.
export const useCustomQueryWithParams: IUseCustomQueryWithParams = (
  method,
  getParams,
  options: IUseCustomQueryOptions = {}
) => {
  const { skip = false } = options;

  // params shouldn't matter when we skip.
  const params = skip ? undefined : getParams();

  /* cacheKey should be null when skipping. 
     This way, a request will not be sent to server.
  */
  const cacheKey = skip
    ? null
    : RequestCacheHelper.instance.getKey(method, params);

  return useSWR(
    cacheKey,
    async () => {
      const { data } = await method(...((params as any) || []));
      return data;
    },
    {
      refreshInterval: options.pollingInterval,
    }
  );
};

export const useCustomQuery: IUseCustomQuery = (
  method,
  options?: IUseCustomQueryOptions
) => {
  return useCustomQueryWithParams(method, () => [], options);
};
