import Axios from "axios";
import { Recipe, RecipeEditorFormValues } from "./types";
import { RequestCacheHelper } from "base/api/RequestCacheHelper";
import { BodyFormDataBuilder } from "base/api/BodyFormDataBuilder";

export class RecipeApi {
  static get(id: number) {
    return Axios.get<Recipe>(`/api/recipes/${id}`);
  }

  static async save(recipe: RecipeEditorFormValues) {
    const { data: savedPartialRecipe } = await Axios.post<Partial<Recipe>>(
      `/api/recipes/${recipe.id}`,
      BodyFormDataBuilder.build2(recipe)
    );

    await RequestCacheHelper.instance.update(
      savedPartialRecipe,
      RecipeApi.get,
      [recipe.id]
    );
  }
}
