import React, { useMemo } from "react";
import { startOfWeek } from "date-fns";
import { RouteContent } from "base/components/RouteContent";
import {
  useCustomQuery,
  useCustomQueryWithParams,
} from "base/api/hooks/useCustomQuery";
import { WeeklyWorkSchedule } from "weekly-work-schedule/components/WeeklyWorkSchedule";
import { WeeklyWorkScheduleApi } from "weekly-work-schedule/WeeklyWorkScheduleApi";
import { useQueryParams } from "base/api/hooks/useQueryParams";
import { WorkScheduleApi } from "work-schedule/WorkScheduleApi";
import { useFullScreenLoader } from "full-screen-loader/hooks/useFullScreenLoader";
import { WeeklyWorkScheduleType } from "weekly-work-schedule/types";
import { WeeklyScheduleBuilder } from "weekly-work-schedule/utils/WeeklySchedulesBuilder";
import { WeeklyWSStartOfWeekAdapter } from "weekly-work-schedule/utils/WeeklyWSStartOfWeekAdapter";

export const WeeklyWorkScheduleScreen = () => {
  const queryParams = useQueryParams();
  const resolvedStartOfWeekDay = startOfWeek(
    WeeklyWSStartOfWeekAdapter.parse(queryParams.get("startOfWeekDay")!)
  );

  const {
    data: templateSchedules,
    isValidating: isValidatingTemplates,
  } = useCustomQuery(WorkScheduleApi.getTemplateSchedules);
  const {
    data: weeklyWorkSchedules,
    isValidating,
  } = useCustomQueryWithParams(WeeklyWorkScheduleApi.get, () => [
    WeeklyWSStartOfWeekAdapter.format(resolvedStartOfWeekDay),
  ]);

  const resolvedWeeklyWorkSchedules = useMemo<WeeklyWorkScheduleType[]>(() => {
    if (weeklyWorkSchedules && weeklyWorkSchedules.length > 0) {
      return weeklyWorkSchedules;
    } else {
      return WeeklyScheduleBuilder.build(templateSchedules ?? []);
    }
  }, [weeklyWorkSchedules, templateSchedules]);

  const isLoading =
    !templateSchedules ||
    !weeklyWorkSchedules ||
    isValidatingTemplates ||
    isValidating;
  useFullScreenLoader(isLoading);

  return (
    <RouteContent>
      <WeeklyWorkSchedule
        schedules={resolvedWeeklyWorkSchedules}
        startOfWeekDay={resolvedStartOfWeekDay}
      />
    </RouteContent>
  );
};
