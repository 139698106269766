import React from "react";
import { SentOrderEditorMode } from "sent-order/types";
import { SentOrderDefaultEditorEditor } from "./SentOrderDefaultEditor";
import { SentOrderAdvancedEditor } from "./SentOrderAdvancedEditor";

export type SentOrderEditorProps = {
  id: number;
  mode?: SentOrderEditorMode;
};

export const SentOrderEditor: React.FC<SentOrderEditorProps> = ({
  id,
  mode = "default",
}) => {
  if (mode === "default") {
    return <SentOrderDefaultEditorEditor id={id} />;
  } else {
    return <SentOrderAdvancedEditor id={id} />;
  }
};
