import React, { useCallback, useMemo, useState } from "react";
import classNames from "classnames";
import { debounce, isEqual } from "lodash";
import { NumberFormatter, UnitTypeFormatter } from "base/utils/formatters";
import {
  useCustomQueryWithParams,
  CustomQueryResponse,
} from "base/api/hooks/useCustomQuery";
import { useNavigateCallback } from "base/hooks/useNavigateCallback";
import { AmountInput } from "base/components/input-controls/AmountInput";
import { BaseImage } from "base/components/BaseFoodImage";
import { Typography } from "base/components/Typography";
import { Spacer } from "base/components/Spacer";
import { SentOrdersApi } from "sent-order/SentOrdersApi";
import {
  SentOrderItemGet,
  SentOrderFormValues,
  SentOrderItemGapReason,
} from "sent-order/types";
import { SentOrderFoodItemModal } from "./SentOrderFoodItemModal";
import { SentOrdersRouteBuilder } from "sent-order/SentOrdersRouteBuilder";
import { Form } from "form/components/Form";
import { FieldArray } from "react-final-form-arrays";
import { Field, FormSpy } from "react-final-form";
import { Fields } from "form/components/Fields";
import { noop } from "base/utils/noop";
import { FormSelect } from "form/components/FormSelect";
import { SentOrderGapReasonOptions } from "sent-order/constants";
import { SupplyMethodDescFormatter } from "order/SupplyMethodDescFormatter";
import { SimpleGlobalListApi } from "global-list/SimpleGlobalListApi";
import { SimpleGlobalListRoutePath } from "global-list/types";
import { BaseSelectListItem, SupplyUnitType } from "base/types";
import { OrderHelper } from "order/OrderHelper";
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import { useBooleanState } from "base/hooks/useBooleanState";
import { SentOrderSimulationTable } from "./SentOrderSimulationTable";
import { SentOrderQuickAddProduct } from "./SentOrderQuickAddProduct";
import produce from "immer";
import { success } from "@pnotify/core";

export type SentOrderAdvancedEditorProps = {
  id: number;
};

type FoodItemModalState =
  | {
      show: false;
      item: undefined;
    }
  | { show: true; item: SentOrderItemGet };

type Gap = {
  sentOrderItemId: number;
  type: "price" | "amount";
};

export const SentOrderAdvancedEditor: React.FC<SentOrderAdvancedEditorProps> = ({
  id,
}) => {
  const navigate = useNavigateCallback();
  const [
    isDisplaySimulation,
    onDisplaySimulation,
    onHideSimulation,
  ] = useBooleanState(false);
  const { data } = useCustomQueryWithParams(SentOrdersApi.getItem, () => [id]);
  const isOrderClosed = data?.status === 5;

  const fieldName = (property: keyof SentOrderFormValues) => property;
  const itemFieldName = (property: keyof SentOrderItemGet, index: number) =>
    `items[${index}]${property}`;

  const {
    data: unitKinds,
  } = useCustomQueryWithParams(SimpleGlobalListApi.getList, () => [
    SimpleGlobalListRoutePath.UnitKinds,
  ]) as CustomQueryResponse<BaseSelectListItem[]>;
  const {
    data: packageKinds,
  } = useCustomQueryWithParams(SimpleGlobalListApi.getList, () => [
    SimpleGlobalListRoutePath.PackageKinds,
  ]) as CustomQueryResponse<BaseSelectListItem[]>;

  const [foodItemModalState, setFoodItemModalState] = useState<
    FoodItemModalState
  >({
    show: false,
    item: undefined,
  });

  const gapList = useMemo<Gap[]>(() => {
    if (!data) {
      return [];
    }

    return data.items.reduce<Gap[]>((result, item, index) => {
      const next = [...result];

      if (OrderHelper.hasAmountGap(item)) {
        next.push({
          sentOrderItemId: item.id,
          type: "amount",
        });
      }

      if (OrderHelper.hasPriceGap(item)) {
        next.push({
          sentOrderItemId: item.id,
          type: "price",
        });
      }

      return next;
    }, []);
  }, [data]);

  // producing a debounce fn of the save api.
  const onAutoSave = useCallback(
    debounce(
      (nextSentOrder: SentOrderFormValues) => {
        onHideSimulation();
        SentOrdersApi.saveSentOrder(nextSentOrder);
      },
      200,
      { maxWait: 400, trailing: true, leading: true }
    ),
    []
  );

  const onHideFoodItemModal = useCallback(() => {
    setFoodItemModalState({ show: false, item: undefined });
  }, []);

  const onCloseOrder = useCallback(async () => {
    try {
      await SentOrdersApi.closeSentOrder(id);
      navigate(SentOrdersRouteBuilder.buildSentOrdersRoute());
    } catch (error) {}
  }, [id, navigate]);

  const onRequestMissingDocument = useCallback(async () => {
    await SentOrdersApi.requestMissingDocument(id);
    success("הודעה נשלחה בהצלחה");
  }, [id]);

  const onCancelOrder = useCallback(async () => {
    if (window.confirm("האם לבצע זיכוי?")) {
      await SentOrdersApi.cancel(id);
      alert("ההזמנה זוכתה");
    }
  }, [id]);

  return (
    <Form initialValues={data} onSubmit={noop} keepDirtyOnReinitialize={false}>
      {({ form }) => (
        <>
          <table className="table table-sm" id="sentOrderTable">
            <thead>
              <tr>
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
                <td>
                  סה"כ הזמנה:{" "}
                  <span className="font-weight-bold">
                    {NumberFormatter.default.formatPrice(
                      data?.items.reduce(
                        (total, item) =>
                          (total += item.receivedPrice * item.documentAmount),
                        0
                      )
                    )}
                  </span>
                </td>
              </tr>
              <tr>
                <td />
                <td />
                <td>מוצר</td>
                <td>צורת קבלה</td>
                <td>כמות בהזמנה</td>
                <td>כמות במסמך</td>
                <td>כמות בפועל</td>
                <td>משקל בפועל</td>
                <td>מחיר בהזמנה/מערכת</td>
                <td>מחיר ליח' במסמך</td>
                <td>מחיר ליח' בפועל</td>
                <td>סה"כ שורה</td>
              </tr>
            </thead>
            <tbody>
              <FieldArray
                name={fieldName("items")}
                isEqual={isEqual}
                subscription={{ length: true }}
              >
                {({ fields }) =>
                  fields.map((prefix, index) => (
                    <Field
                      name={itemFieldName("isDelete", index)}
                      render={({ input: { value: isDelete } }) =>
                        !isDelete &&
                        data?.items[index] && (
                          <tr className="ingredient" key={prefix}>
                            <td>
                              <Field
                                name={itemFieldName(
                                  "isAddedAfterSubmit",
                                  index
                                )}
                                render={({
                                  input: { value: isAddedAfterSubmit },
                                }) =>
                                  isAddedAfterSubmit && (
                                    <i
                                      className="fas fa-trash text-danger"
                                      aria-hidden="true"
                                      role="button"
                                      onClick={async () => {
                                        const {
                                          items,
                                        } = form.getState().values;
                                        form.change(
                                          "items",
                                          produce(items, (s) => {
                                            s[index].isDelete = true;
                                          })
                                        );
                                      }}
                                    />
                                  )
                                }
                              />
                            </td>
                            <td>
                              <Field
                                name={itemFieldName("imageName", index)}
                                render={({ input: { value: imageName } }) => (
                                  <BaseImage data-image fileName={imageName} />
                                )}
                              />
                            </td>
                            <td data-name>
                              <Field
                                name={itemFieldName("name", index)}
                                render={({ input: { value: name } }) =>
                                  name ?? ""
                                }
                              />
                            </td>
                            <td>
                              <Fields
                                names={[
                                  itemFieldName("prepareUnitType", index),
                                  itemFieldName("receivedSupplyMethod", index),
                                ]}
                                render={([
                                  prepareUnitType,
                                  receivedSupplyMethod,
                                ]) => (
                                  <OverlayTrigger
                                    placement="left"
                                    overlay={
                                      <Tooltip id={`rsm-${index}`}>
                                        {SupplyMethodDescFormatter.format(
                                          receivedSupplyMethod,
                                          prepareUnitType,
                                          unitKinds,
                                          packageKinds
                                        )}
                                      </Tooltip>
                                    }
                                  >
                                    <div className="alert-warning">
                                      {SupplyMethodDescFormatter.formatTopPackage(
                                        receivedSupplyMethod,
                                        prepareUnitType,
                                        unitKinds,
                                        packageKinds
                                      )}
                                    </div>
                                  </OverlayTrigger>
                                )}
                              />
                            </td>
                            <td>
                              <Field
                                name={itemFieldName("requestedAmount", index)}
                                render={({
                                  input: { value: requestedAmount },
                                }) =>
                                  NumberFormatter.default.formatAmount(
                                    requestedAmount
                                  )
                                }
                              />
                            </td>
                            <td>
                              <Fields
                                names={[
                                  itemFieldName("name", index),
                                  itemFieldName("documentAmount", index),
                                  itemFieldName("receivedSupplyMethod", index),
                                  itemFieldName("prepareUnitType", index),
                                ]}
                                render={([
                                  name,
                                  documentAmount,
                                  receivedSupplyMethod,
                                  prepareUnitType,
                                ]) => (
                                  <div className="d-flex align-items-center">
                                    <AmountInput
                                      numpadTitle={`בחירת משקל: ${name ?? ""}`}
                                      value={documentAmount}
                                      onChange={(nextValue) => {
                                        form.change(
                                          itemFieldName(
                                            "documentAmount",
                                            index
                                          ) as any,
                                          nextValue
                                        );
                                      }}
                                      fixedDidigts={3}
                                      disabled={isOrderClosed}
                                    />
                                    {
                                      <span className="pr-2 font-weight-bold">
                                        {NumberFormatter.default.formatAmount(
                                          OrderHelper.calcTotalAmount(
                                            documentAmount,
                                            receivedSupplyMethod,
                                            prepareUnitType
                                          )
                                        )}{" "}
                                        {UnitTypeFormatter.format(
                                          prepareUnitType
                                        )}
                                      </span>
                                    }
                                  </div>
                                )}
                              />
                            </td>
                            <td>
                              <div
                                className={classNames({
                                  mismatch: OrderHelper.hasAmountGap(
                                    data!.items[index]
                                  ),
                                })}
                              >
                                <Fields
                                  names={[
                                    itemFieldName("name", index),
                                    itemFieldName("receivedAmount", index),
                                    itemFieldName(
                                      "receivedSupplyMethod",
                                      index
                                    ),
                                    itemFieldName("prepareUnitType", index),
                                  ]}
                                  render={([
                                    name,
                                    receivedAmount,
                                    receivedSupplyMethod,
                                    prepareUnitType,
                                  ]) => (
                                    <div className="d-flex align-items-center">
                                      <AmountInput
                                        numpadTitle={`בחירת משקל: ${
                                          name ?? ""
                                        }`}
                                        value={receivedAmount}
                                        onChange={(nextValue) => {
                                          form.change(
                                            itemFieldName(
                                              "receivedAmount",
                                              index
                                            ) as any,
                                            nextValue
                                          );

                                          if (
                                            receivedSupplyMethod.supplyUnitType ===
                                            SupplyUnitType.Amount
                                          ) {
                                            form.change(
                                              itemFieldName(
                                                "receivedWeight",
                                                index
                                              ) as any,
                                              nextValue
                                            );
                                          }
                                        }}
                                        fixedDidigts={3}
                                        disabled={isOrderClosed}
                                      />
                                      {
                                        <span className="pr-2 font-weight-bold">
                                          {NumberFormatter.default.formatAmount(
                                            OrderHelper.calcTotalAmount(
                                              receivedAmount,
                                              receivedSupplyMethod,
                                              prepareUnitType
                                            )
                                          )}{" "}
                                          {UnitTypeFormatter.format(
                                            prepareUnitType
                                          )}
                                        </span>
                                      }
                                    </div>
                                  )}
                                />
                              </div>
                            </td>
                            <td>
                              <div
                                className={classNames("with-amount-and-scale", {
                                  mismatch: OrderHelper.hasAmountGap(
                                    data!.items[index]
                                  ),
                                })}
                              >
                                <Fields
                                  names={[
                                    itemFieldName("name", index),
                                    itemFieldName("receivedWeight", index),
                                    itemFieldName(
                                      "receivedSupplyMethod",
                                      index
                                    ),
                                  ]}
                                  render={([
                                    name,
                                    receivedWeight,
                                    receivedSupplyMethod,
                                  ]) => {
                                    if (
                                      OrderHelper.isWeightable(
                                        data!.items[index]
                                      )
                                    ) {
                                      return (
                                        <AmountInput
                                          numpadTitle={`בחירת משקל: ${
                                            name ?? ""
                                          }`}
                                          value={receivedWeight}
                                          onChange={(nextValue) => {
                                            form.change(
                                              itemFieldName(
                                                "receivedWeight",
                                                index
                                              ) as any,
                                              nextValue
                                            );
                                            if (
                                              receivedSupplyMethod.supplyUnitType ===
                                              SupplyUnitType.Amount
                                            ) {
                                              form.change(
                                                itemFieldName(
                                                  "receivedAmount",
                                                  index
                                                ) as any,
                                                nextValue
                                              );
                                            }
                                          }}
                                          fixedDidigts={3}
                                          disabled={isOrderClosed}
                                        />
                                      );
                                    } else {
                                      return <></>;
                                    }
                                  }}
                                />
                                <Field
                                  name={prefix}
                                  render={({
                                    input: { value: sentOrderItem },
                                  }) =>
                                    OrderHelper.isWeightable(sentOrderItem) && (
                                      <div
                                        className={classNames("weight", {
                                          disabled: isOrderClosed,
                                        })}
                                        onClick={() => {
                                          setFoodItemModalState({
                                            show: true,
                                            item: sentOrderItem,
                                          });
                                        }}
                                      >
                                        <img
                                          src="/content/images/scale.png"
                                          alt="פתיחת מסך שקילה"
                                        />
                                      </div>
                                    )
                                  }
                                />
                              </div>
                            </td>
                            <td>
                              <div className="d-flex">
                                <div>
                                  <Field
                                    name={itemFieldName(
                                      "requestedPrice",
                                      index
                                    )}
                                    render={({
                                      input: { value: requestedPrice },
                                    }) =>
                                      NumberFormatter.default.formatPrice(
                                        requestedPrice
                                      )
                                    }
                                  />
                                </div>
                                {
                                  <div className="mr-2">
                                    (ל
                                    {UnitTypeFormatter.format(
                                      data!.items[index].prepareUnitType
                                    )}{" "}
                                    {NumberFormatter.format(
                                      OrderHelper.calcNormalizedKGPrice(
                                        data!.items[index]
                                          .requestedSupplyMethod,
                                        data!.items[index].requestedPrice,
                                        data!.items[index].prepareUnitType
                                      )
                                    )}
                                    ){" "}
                                  </div>
                                }
                              </div>
                            </td>
                            <td>
                              <div className="d-flex">
                                <div
                                  className={classNames(
                                    "with-amount-and-scale",
                                    {
                                      mismatch: OrderHelper.hasPriceGap(
                                        data!.items[index]
                                      ),
                                    }
                                  )}
                                >
                                  <Fields
                                    names={[
                                      itemFieldName("name", index),
                                      itemFieldName("receivedPrice", index),
                                    ]}
                                    render={([name, receivedPrice]) => (
                                      <AmountInput
                                        numpadTitle={`בחירת משקל: ${
                                          name ?? ""
                                        }`}
                                        value={receivedPrice}
                                        onChange={(nextValue) => {
                                          form.change(
                                            itemFieldName(
                                              "receivedPrice",
                                              index
                                            ) as any,
                                            nextValue
                                          );
                                        }}
                                        fixedDidigts={2}
                                        disabled={isOrderClosed}
                                      />
                                    )}
                                  />
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex">
                                <div>
                                  <Field
                                    name={prefix}
                                    render={({
                                      input: { value: sentOrderItem },
                                    }) => {
                                      let amount = 0;
                                      if (
                                        OrderHelper.isWeightable(sentOrderItem)
                                      ) {
                                        amount = sentOrderItem.receivedWeight;
                                      } else {
                                        amount = sentOrderItem.receivedAmount;
                                      }
                                      let totalRow =
                                        sentOrderItem.documentAmount *
                                        sentOrderItem.receivedPrice;

                                      let isFinite = true;
                                      if (amount === 0) {
                                        isFinite = false;
                                      }
                                      return (
                                        <div>
                                          ל
                                          {UnitTypeFormatter.format(
                                            sentOrderItem.prepareUnitType
                                          )}{" "}
                                          {OrderHelper.isWeightable(
                                            sentOrderItem
                                          ) &&
                                            NumberFormatter.default.formatPrice(
                                              isFinite ? totalRow / amount : 0
                                            )}{" "}
                                          {!OrderHelper.isWeightable(
                                            sentOrderItem
                                          ) &&
                                            NumberFormatter.default.formatPrice(
                                              isFinite
                                                ? totalRow /
                                                    OrderHelper.calcTotalAmount(
                                                      amount,
                                                      sentOrderItem.receivedSupplyMethod,
                                                      sentOrderItem.prepareUnitType
                                                    )
                                                : 0
                                            )}
                                        </div>
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </td>
                            <td>
                              <Fields
                                names={[
                                  itemFieldName("receivedPrice", index),
                                  itemFieldName("documentAmount", index),
                                ]}
                                render={([receivedPrice, documentAmount]) => (
                                  <AmountInput
                                    value={
                                      (receivedPrice ?? 0) *
                                      (documentAmount ?? 0)
                                    }
                                    onChange={(nextValue) => {
                                      if (!documentAmount) {
                                        return;
                                      }

                                      const netReceivedPrice =
                                        nextValue / documentAmount;

                                      form.change(
                                        itemFieldName(
                                          "receivedPrice",
                                          index
                                        ) as any,
                                        netReceivedPrice
                                      );
                                    }}
                                    fixedDidigts={2}
                                    disabled={isOrderClosed}
                                  />
                                )}
                              />
                            </td>
                          </tr>
                        )
                      }
                    />
                  ))
                }
              </FieldArray>
            </tbody>
          </table>
          <div className="my-5">
            <Typography variant="title">הוספת מוצר</Typography>
            <Field
              name={fieldName("supplierId")}
              render={({ input: { value: supplierId } }) => (
                <SentOrderQuickAddProduct
                  orderId={data?.id}
                  supplierId={supplierId}
                />
              )}
            />
          </div>
          <div className="text-center d-flex justify-content-center">
            <div className="px-1">
              <button
                className="btn btn-primary"
                onClick={onCloseOrder}
                disabled={isOrderClosed}
              >
                דיווח למלאי והנפקת חשבונית זיכוי
              </button>
            </div>
            {isOrderClosed && (
              <div className="px-1">
                <button className="btn btn-primary" onClick={onCancelOrder}>
                  זיכוי חשבונית
                </button>
              </div>
            )}
            <div className="px-1">
              <button
                className="btn btn-primary"
                onClick={onRequestMissingDocument}
              >
                בקשת אסמכתא מהספק
              </button>
            </div>
          </div>
          <Spacer units={12} />
          <Typography variant="title" className="text-center">
            שורות חשבונית זיכוי
          </Typography>
          <table className="table table-sm" id="creditRowsTable">
            <thead>
              <tr>
                <td>ביטול</td>
                <td>סוג פער</td>
                <td>מוצר</td>
                <td>כמות במסמך</td>
                <td>כמות/משקל בפועל</td>
                <td>הפרש כמות</td>
                <td>סיבת פער </td>
                <td>מחיר ליח' בהזמנה</td>
                <td>מחיר ליח' במסמך</td>
                <td>הפרש מחיר יח'</td>
                {/* <td>סה"כ הפרש מחיר</td> */}
                <td>סה"כ </td>
              </tr>
            </thead>
            <tbody>
              {gapList.map((gapItem) => {
                const { sentOrderItemId, type } = gapItem;

                const sentOrderItemIndex = data!.items.findIndex(
                  (item) => item.id === sentOrderItemId
                );

                return (
                  <Field<SentOrderItemGet>
                    key={`${sentOrderItemId}_${type}`}
                    name={`items[${sentOrderItemIndex}]`}
                    render={({ input: { value: sentOrderItem } }) => (
                      <tr
                        className={classNames({
                          "gap-type-price": type === "price",
                          "gap-type-amount": type === "amount",
                          "strike-amount": sentOrderItem.isRemoveAmountGap,
                          "strike-price": sentOrderItem.isRemovePriceGap,
                          more:
                            sentOrderItem.receivedAmount /
                              sentOrderItem.documentAmount >
                            1.1,
                        })}
                      >
                        <td className="removeFromCredit">
                          <i
                            className={classNames("fa fa-minus-circle", {
                              disabled: isOrderClosed,
                            })}
                            onClick={() => {
                              switch (type) {
                                case "amount":
                                  form.change(
                                    itemFieldName(
                                      "isRemoveAmountGap",
                                      sentOrderItemIndex
                                    ) as any,
                                    !sentOrderItem.isRemoveAmountGap
                                  );
                                  break;
                                case "price":
                                  form.change(
                                    itemFieldName(
                                      "isRemovePriceGap",
                                      sentOrderItemIndex
                                    ) as any,
                                    !sentOrderItem.isRemovePriceGap
                                  );
                                  break;
                              }
                            }}
                          />
                        </td>
                        <td>
                          {(() => {
                            switch (type) {
                              case "price":
                                return "פער במחיר";
                              case "amount":
                                return "פער בכמות";
                            }
                          })()}
                        </td>
                        <td data-name>{sentOrderItem.name}</td>
                        <td data-requested-amount>
                          {
                            <>
                              {NumberFormatter.default.formatAmount(
                                OrderHelper.calcTotalAmount(
                                  sentOrderItem.documentAmount,
                                  sentOrderItem.receivedSupplyMethod,
                                  sentOrderItem.prepareUnitType
                                )
                              )}
                            </>
                          }
                        </td>
                        <td data-received-amount>
                          {type === "amount" &&
                            OrderHelper.isWeightable(sentOrderItem) && (
                              <>
                                {NumberFormatter.default.formatAmount(
                                  sentOrderItem.receivedWeight
                                )}
                              </>
                            )}
                          {type === "amount" &&
                            !OrderHelper.isWeightable(sentOrderItem) &&
                            NumberFormatter.default.formatAmount(
                              OrderHelper.calcTotalAmount(
                                sentOrderItem.receivedAmount,
                                sentOrderItem.receivedSupplyMethod,
                                sentOrderItem.prepareUnitType
                              )
                            )}
                        </td>
                        <td data-amount-gap className="gap">
                          {type === "amount" &&
                            NumberFormatter.default.formatAmount(
                              OrderHelper.calcDocumentedAmountGap(sentOrderItem)
                            )}
                        </td>
                        <td data-gap-reason>
                          {type === "amount" && (
                            <FormSelect
                              name={itemFieldName(
                                "amountGapReason",
                                sentOrderItemIndex
                              )}
                              displayEmptyOption={false}
                              options={SentOrderGapReasonOptions}
                              disabled={isOrderClosed}
                              defaultSelectedValue={
                                SentOrderItemGapReason.Shortage
                              }
                              autoClearValueIfNotFound // To avoid the possible server 0 value.
                            />
                          )}
                          {type === "price" && (
                            <FormSelect
                              name={itemFieldName(
                                "priceGapReason",
                                sentOrderItemIndex
                              )}
                              displayEmptyOption={false}
                              options={SentOrderGapReasonOptions}
                              disabled={isOrderClosed}
                              defaultSelectedValue={
                                SentOrderItemGapReason.PriceGap
                              }
                              autoClearValueIfNotFound // To avoid the possible server 0 value.
                            />
                          )}
                        </td>
                        <td data-requested-price>
                          {NumberFormatter.format(
                            OrderHelper.calcNormalizedKGPrice(
                              sentOrderItem.requestedSupplyMethod,
                              sentOrderItem.requestedPrice,
                              sentOrderItem.prepareUnitType
                            )
                          )}
                        </td>
                        <td data-received-price>
                          {type === "price" &&
                            NumberFormatter.format(
                              OrderHelper.calcNormalizedKGPrice(
                                sentOrderItem.receivedSupplyMethod,
                                sentOrderItem.receivedPrice,
                                sentOrderItem.prepareUnitType
                              )
                            )}
                        </td>
                        <td data-price-gap className="gap">
                          {type === "price" &&
                            NumberFormatter.format(
                              OrderHelper.calcNormalizedKGPrice(
                                sentOrderItem.requestedSupplyMethod,
                                sentOrderItem.requestedPrice,
                                sentOrderItem.prepareUnitType
                              ) -
                                OrderHelper.calcNormalizedKGPrice(
                                  sentOrderItem.receivedSupplyMethod,
                                  sentOrderItem.receivedPrice,
                                  sentOrderItem.prepareUnitType
                                )
                            )}
                        </td>
                        <td data-received-total-price dir="ltr" className="gap">
                          {type === "price" &&
                            NumberFormatter.default.formatPrice(
                              (OrderHelper.calcNormalizedKGPrice(
                                sentOrderItem.requestedSupplyMethod,
                                sentOrderItem.requestedPrice,
                                sentOrderItem.prepareUnitType
                              ) -
                                OrderHelper.calcNormalizedKGPrice(
                                  sentOrderItem.receivedSupplyMethod,
                                  sentOrderItem.receivedPrice,
                                  sentOrderItem.prepareUnitType
                                )) *
                                OrderHelper.calcTotalAmount(
                                  sentOrderItem.documentAmount,
                                  sentOrderItem.receivedSupplyMethod,
                                  sentOrderItem.prepareUnitType
                                )
                            )}

                          {type === "amount" &&
                            OrderHelper.isWeightable(sentOrderItem) &&
                            NumberFormatter.default.formatPrice(
                              OrderHelper.calcDocumentedAmountGap(
                                sentOrderItem
                              ) *
                                OrderHelper.calcNormalizedKGPrice(
                                  sentOrderItem.requestedSupplyMethod,
                                  sentOrderItem.requestedPrice,
                                  sentOrderItem.prepareUnitType
                                )
                            )}
                          {type === "amount" &&
                            !OrderHelper.isWeightable(sentOrderItem) &&
                            NumberFormatter.default.formatPrice(
                              (sentOrderItem.receivedAmount -
                                sentOrderItem.documentAmount) *
                                sentOrderItem.receivedPrice
                            )}
                        </td>
                      </tr>
                    )}
                  />
                );
              })}
            </tbody>
          </table>
          <div className="text-center">
            <Button variant="primary" onClick={onDisplaySimulation}>
              סימולציית תנועות
            </Button>
          </div>
          {isDisplaySimulation && (
            <>
              <Typography variant="title">תנועות מלאי שיווצרו</Typography>
              <SentOrderSimulationTable id={id} />
            </>
          )}
          <SentOrderFoodItemModal
            show={foodItemModalState.show}
            item={foodItemModalState.item}
            onCancel={onHideFoodItemModal}
            onDone={(modifiedItem) => {
              const { items } = form.getState().values;
              const index = items.findIndex((x) => x.id === modifiedItem.id);
              form.change(`items[${index}]` as any, modifiedItem);
              onHideFoodItemModal();
            }}
          />
          <FormSpy<Partial<SentOrderFormValues>>
            subscription={{ values: true }}
            onChange={({ values }) => {
              if (form.getState().dirty) {
                onAutoSave(values as SentOrderFormValues);
              }
            }}
          />
        </>
      )}
    </Form>
  );
};
