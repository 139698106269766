export enum AllergenRelationType {
  Food = "Food",
  Product = "Product",
  Material = "Material",
}

export interface AllergenItem {
  id: number;
  allergen: number; // allergen id
  status: AllergenStatus;
}

export enum AllergenStatus {
  NotContains = 0,
  MightContain = 1,
  Contains = 2,
}
