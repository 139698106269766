import React from "react";
import { RouteContent } from "base/components/RouteContent";
import { Typography } from "base/components/Typography";
import { useCustomQuery } from "base/api/hooks/useCustomQuery";
import { MaterialsApi } from "material/MaterialsApi";
import { useFullScreenLoader } from "full-screen-loader/hooks/useFullScreenLoader";
import { MaterialForm } from "material/components/MaterialForm";

export const AddMaterialScreen: React.FC = () => {
  const { data, isValidating } = useCustomQuery(MaterialsApi.getItemDefaults);

  const isLoading = !data || isValidating;

  useFullScreenLoader(isLoading);

  return (
    <RouteContent>
      <Typography variant="route-title">הוספה</Typography>
      <MaterialForm data={data} />
    </RouteContent>
  );
};
