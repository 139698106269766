import React from "react";
import { RouteContent } from "base/components/RouteContent";
import { Typography } from "base/components/Typography";
import { ContractsTable } from "contract/components/ContractsTable";

export const ContractsScreen: React.FC = () => {
  return (
    <RouteContent>
      <Typography variant="route-title"> הסכמי שכר</Typography>
      <ContractsTable />
    </RouteContent>
  );
};
