import React, { useCallback } from "react";
import { RouteContent } from "base/components/RouteContent";
import { Typography } from "base/components/Typography";
import { Form } from "form/components/Form";
import { Row, Col, Button } from "react-bootstrap";
import { FormSelect } from "form/components/FormSelect";
import { AuthStandType } from "auth/types";
import { useStandType } from "auth/hooks/useStandType";
import { useAppContext } from "app/context/hooks/useAppContext";

const StandTypeOptions: Array<{ label: string; value: AuthStandType }> = [
  { label: "עמדה רגילה", value: "default" },
  { label: "עמדת טבחים", value: "chef" },
  { label: "עמדת שקילה", value: "receiveOrder" },
  { label: "עורך מנה", value: "dish-editor" },
];

export const StandSettingsScreen: React.FC = () => {
  const { store } = useAppContext();
  const standType = useStandType();
  type SetStandTypeValues = { standType: AuthStandType };

  const onSetStandType = useCallback(
    ({ standType }: SetStandTypeValues) => {
      store.setStandType(standType);
    },
    [store]
  );

  return (
    <RouteContent>
      <Typography variant="route-title">הגדרות עמדה</Typography>
      <Form onSubmit={onSetStandType} initialValues={{ standType }}>
        {({ handleSubmit }) => (
          <Row noGutters>
            <Col xs="auto" className="p-1 d-flex align-items-center">
              <div>בחר סוג עמדה</div>
            </Col>
            <Col xs="auto" className="p-1 d-flex align-items-center">
              <FormSelect
                name="standType"
                displayEmptyOption={false}
                options={StandTypeOptions}
              />
            </Col>
            <Col xs="auto" className="p-1 d-flex align-items-center">
              <Button onClick={handleSubmit}>שמירה</Button>
            </Col>
          </Row>
        )}
      </Form>
    </RouteContent>
  );
};
