import Axios from "axios";
import { FoodDepartmentListItem } from "base/types";
import { BodyFormDataBuilder } from "base/api/BodyFormDataBuilder";
import { RequestCacheHelper } from "base/api/RequestCacheHelper";
import {
  SupplierListItem,
  Supplier,
  SupplierFormData,
  SupplierOrderListItem,
  SupplierContact,
  DistributionDay,
  IngredientPriceAgreement,
  SupplierProductSupplyListItem,
} from "./types";

export class SuppliersApi {
  static getList() {
    return Axios.get<SupplierListItem[]>("/api/suppliers/items");
  }

  static getOrdersList(id: number) {
    return Axios.get<SupplierOrderListItem[]>(`/api/suppliers/${id}/orders`);
  }

  static getContacts(id: number) {
    return Axios.get<SupplierContact[]>(`/api/suppliers/${id}/contacts`);
  }

  static getIngredientPriceAgreements(id: number) {
    return Axios.get<IngredientPriceAgreement[]>(
      `/api/suppliers/${id}/ingredientPriceAgreements`
    );
  }

  static getItem(id: number) {
    return Axios.get<Supplier>(`/api/suppliers/${id}`);
  }

  static getItemDefaults() {
    return Axios.get<Partial<Supplier>>(`/api/suppliers/new`);
  }

  static getDepartments() {
    return Axios.get<FoodDepartmentListItem[]>("/api/suppliers/departments");
  }

  static getDistributionDays(id: number) {
    return Axios.get<DistributionDay[]>(
      `/api/suppliers/${id}/distributionDays`
    );
  }

  static getSupplierProductsSupply(id: number) {
    return Axios.get<SupplierProductSupplyListItem[]>(
      `/api/suppliers/${id}/getProductsSupply`
    );
  }

  static async saveItem(data: SupplierFormData) {
    const { data: savedItem } = await Axios.post<Supplier>(
      "/api/suppliers",
      BodyFormDataBuilder.build2(data),
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );

    // updating the cache, so the ui will get notified of the changes.
    await RequestCacheHelper.instance.update(savedItem, this.getItem, [
      savedItem.id,
    ]);
    RequestCacheHelper.instance.refetch(this.getList);
  }

  static async deleteItem(id: number) {
    await Axios.delete(`/api/suppliers/${id}`);

    RequestCacheHelper.instance.refetch(SuppliersApi.getList);
  }

  static async deleteContact(id: number) {
    await Axios.delete(`/api/contacts/${id}`);

    RequestCacheHelper.instance.update<SupplierContact[]>((contacts) => {
      if (!contacts) return undefined;
      return contacts.filter((a) => a.id !== id);
    }, SuppliersApi.getContacts);
  }

  static async saveContact(
    supplierId: number,
    contact: WithPartialProperties<SupplierContact, "id">
  ) {
    const isNew = contact.id === undefined;
    const { data: savedContact } = await Axios.post<SupplierContact>(
      `/api/contacts`,
      { ...contact, supplierId }
    );

    RequestCacheHelper.instance.update<SupplierContact[]>(
      (contacts) => {
        if (!contacts) return undefined;
        if (isNew) return [...contacts, savedContact];
        return contacts.map((c) =>
          c.id === savedContact.id ? savedContact : c
        );
      },
      SuppliersApi.getContacts,
      [supplierId]
    );
  }

  static async saveDistributionDay(
    distributionDay: WithPartialProperties<DistributionDay, "id">
  ) {
    const isNew = distributionDay.id === undefined;
    const { data: savedDay } = await Axios.post<DistributionDay>(
      `/api/distributionDays`,
      distributionDay
    );

    RequestCacheHelper.instance.update<DistributionDay[]>(
      (days) => {
        if (!days) return undefined;
        if (isNew) return [...days, savedDay];
        return days.map((c) => (c.id === savedDay.id ? savedDay : c));
      },
      SuppliersApi.getDistributionDays,
      [distributionDay.supplierId]
    );
  }

  static async saveIngredientPriceAgreement(
    item: WithPartialProperties<IngredientPriceAgreement, "id">
  ) {
    const isNew = item.id === undefined;
    const { data: savedItem } = await Axios.post<IngredientPriceAgreement>(
      `/api/IngredientPriceAgreements`,
      item
    );

    RequestCacheHelper.instance.update<IngredientPriceAgreement[]>(
      (items) => {
        if (!items) return undefined;
        if (isNew) return [...items, savedItem];
        return items.map((c) => (c.id === savedItem.id ? savedItem : c));
      },
      SuppliersApi.getIngredientPriceAgreements,
      [item.supplierId]
    );
  }

  static async deleteIngredientPriceAgreement(supplierId: number, id: number) {
    await Axios.delete(`/api/IngredientPriceAgreements/${id}`);

    RequestCacheHelper.instance.update<IngredientPriceAgreement[]>(
      (items) => {
        if (!items) return undefined;
        return items.filter((a) => a.id !== id);
      },
      SuppliersApi.getIngredientPriceAgreements,
      [supplierId]
    );
  }
}
