import React from "react";
import { RouteContent } from "base/components/RouteContent";
import { DepartmentList } from "order/components/DepartmentList";
import { OrderWizardTopBar } from "order/components/OrderWizardTopBar";

export const DepartmentListScreen: React.FC = () => {
  return (
    <RouteContent className="OrderDepartmentListScreen">
      <OrderWizardTopBar />
      <DepartmentList />
    </RouteContent>
  );
};
