import { FoodWork } from "./types";

export class TreeInfoHelper {
  static calcAmountAfterFoodWorks(amount: number, foodWorks: FoodWork[]) {
    let amountAfterFoodWorks = amount;
    foodWorks.forEach((item) => {
      amountAfterFoodWorks =
        amountAfterFoodWorks - (amountAfterFoodWorks * item.depreciation) / 100;
    });

    return amountAfterFoodWorks;
  }
  static calcPriceAfterFoodWorks(basicPrice: number, foodWorks: FoodWork[]) {
    let priceAfterFoodWorks = basicPrice;

    foodWorks.forEach((item) => {
      priceAfterFoodWorks =
        priceAfterFoodWorks * (100 / (100 - item.depreciation)) + item.cost;
    });

    return priceAfterFoodWorks;
  }
}
