import Axios from "axios";
import { BodyFormDataBuilder } from "base/api/BodyFormDataBuilder";
import {
  POSUploadFormValues,
  POSUploadResult,
  POSHistoryListItem,
  POSHistoryItem,
} from "./types";

export class POSApi {
  static async getHistoryList() {
    return Axios.get<POSHistoryListItem[]>("/api/poshistory/items");
  }

  static async getHistoryItem(fileName: string) {
    return Axios.get<POSHistoryItem>(`/api/poshistory/${fileName}`);
  }

  static async upload(values: POSUploadFormValues) {
    const { data: uploadResult } = await Axios.post<POSUploadResult>(
      "/api/bi/pos",
      BodyFormDataBuilder.build2(values),
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );

    return uploadResult;
  }

  static async mapItem(foodId: number, mappingName: string) {
    await Axios.post<POSUploadResult>("/api/POSItemMappings", {
      foodId,
      name: mappingName,
    });
  }
}
