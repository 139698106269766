export class SuppliersRouteBuilder {
  static buildSuppliersRoute() {
    return `/suppliers`;
  }

  static buildAddSupplierRoute() {
    return `/suppliers/new`;
  }

  static buildEditSupplierRoute(supplierId: number) {
    return `/suppliers/${supplierId}`;
  }
}
