import Axios from "axios";
import { ProductSupplyMethod, ProductSupplyMethodSupplier } from "./types";
import { RequestCacheHelper } from "base/api/RequestCacheHelper";

export class ProductSupplyMethodsApi {
  static async get(productId: number) {
    return Axios.get<ProductSupplyMethod[]>(
      `/api/products/${productId}/supplyMethods`
    );
  }

  static async add(item: ProductSupplyMethod) {
    const { data: savedItem } = await Axios.post<ProductSupplyMethod>(
      `/api/productSupplyMethods`,
      item
    );

    await RequestCacheHelper.instance.update<ProductSupplyMethod[]>(
      (supplyMethods) => {
        if (!supplyMethods) return undefined;
        return [...supplyMethods, savedItem];
      },
      ProductSupplyMethodsApi.get,
      [item.productId]
    );

    return savedItem;
  }

  static async update(item: ProductSupplyMethod) {
    const { data: savedItem } = await Axios.post<ProductSupplyMethod>(
      `/api/productSupplyMethods`,
      item
    );

    await RequestCacheHelper.instance.update<ProductSupplyMethod[]>(
      (supplyMethods) => {
        if (!supplyMethods) return undefined;
        return supplyMethods.map((item) => {
          if (item.id !== savedItem.id) {
            return item;
          } else {
            return savedItem;
          }
        });
      },
      ProductSupplyMethodsApi.get,
      [item.productId]
    );
  }

  static async remove(productId: number, supplyMethodId: number) {
    await Axios.delete<ProductSupplyMethod>(
      `/api/productSupplyMethods/${supplyMethodId}`
    );

    await RequestCacheHelper.instance.update<ProductSupplyMethod[]>(
      (supplyMethods) => {
        if (!supplyMethods) return undefined;
        return supplyMethods.filter((item) => item.id !== supplyMethodId);
      },
      ProductSupplyMethodsApi.get,
      [productId]
    );
  }

  static async saveSupplyMethodSupplier(
    productId: number,
    supplyMethodSupplier: WithPartialProperties<
      ProductSupplyMethodSupplier,
      "id"
    >
  ) {
    const isNew = supplyMethodSupplier.id === undefined;

    const { data: savedItem } = await Axios.post<ProductSupplyMethodSupplier>(
      `/api/ProductSupplyMethodSuppliers`,
      supplyMethodSupplier
    );

    await RequestCacheHelper.instance.update<ProductSupplyMethod[]>(
      (supplyMethods) => {
        if (!supplyMethods) return undefined;
        return supplyMethods.map((supplyMethod) => {
          if (supplyMethod.id !== supplyMethodSupplier.productSupplyMethodId) {
            return supplyMethod;
          } else if (isNew) {
            return {
              ...supplyMethod,
              suppliers: [...supplyMethod.suppliers, savedItem],
            };
          } else {
            return {
              ...supplyMethod,
              suppliers: supplyMethod.suppliers.map((s) => {
                if (s.id !== savedItem.id) return s;
                else return savedItem;
              }),
            };
          }
        });
      },
      ProductSupplyMethodsApi.get,
      [productId]
    );
  }

  static async deleteSupplyMethodSupplier(
    productId: number,
    supplyMethodId: number,
    itemId: number
  ) {
    await Axios.delete<ProductSupplyMethodSupplier>(
      `/api/ProductSupplyMethodSuppliers/${itemId}`
    );

    await RequestCacheHelper.instance.update<ProductSupplyMethod[]>(
      (supplyMethods) => {
        if (!supplyMethods) return undefined;
        return supplyMethods.map((supplyMethod) => {
          if (supplyMethod.id !== supplyMethodId) {
            return supplyMethod;
          } else {
            return {
              ...supplyMethod,
              suppliers: supplyMethod.suppliers.filter((s) => s.id !== itemId),
            };
          }
        });
      },
      ProductSupplyMethodsApi.get,
      [productId]
    );
  }
}
