import React, { useState, useCallback } from "react";
import { Field, useForm, FormSpy } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import {
  format,
  addDays,
  subDays,
  differenceInMinutes,
  addMinutes,
  addHours,
  startOfDay,
} from "date-fns";
import { isEqual } from "lodash";
import {
  useCustomQueryWithParams,
  CustomQueryResponse,
} from "base/api/hooks/useCustomQuery";
import { Typography } from "base/components/Typography";
import {
  SimpleGlobalListRoutePath,
  SpecialDatelistItem,
  SPECIAL_DATES_FORMAT,
} from "global-list/types";
import { SimpleGlobalListApi } from "global-list/SimpleGlobalListApi";
import "schedule-requests/styles.scss";
import {
  ScheduleRequestShiftDayTimePicker,
  ScheduleRequestShiftDayTimePickerProps,
} from "./ScheduleRequestShiftDayTimePicker";
import { useBooleanState } from "base/hooks/useBooleanState";
import { ScheduleRequest } from "schedule-requests/types";
import classNames from "classnames";
import { useNavigateCallback } from "base/hooks/useNavigateCallback";
import { ScheduleRequestsRouteBuilder } from "schedule-requests/ScheduleRequestsRouteBuilder";
import { differenceInHours } from "date-fns/esm";
import { useRefreshInterval } from "base/hooks/useRefreshInterval";

const daysNamesConfig = [
  { dayKey: "sunday", displayName: "יום א׳" },
  { dayKey: "monday", displayName: "יום ב׳" },
  { dayKey: "tuesday", displayName: "יום ג׳" },
  { dayKey: "wednesday", displayName: "יום ד׳" },
  { dayKey: "thursday", displayName: "יום ה׳" },
  { dayKey: "friday", displayName: "יום ו׳" },
  { dayKey: "saturday", displayName: "יום ש" },
] as const;

const statusToNameMap = {
  0: "רוצה",
  1: "יכול",
  2: "לא יכול",
};

type DayKey = typeof daysNamesConfig[number]["dayKey"];

export type MyScheduleRequestFormProps = {
  startOfWeekDay: Date;
  error?: string;
};

export const MyScheduleRequestForm: React.FC<MyScheduleRequestFormProps> = ({
  startOfWeekDay,
  error,
}) => {
  useRefreshInterval(1000 * 60);
  const navigate = useNavigateCallback();
  const form = useForm<ScheduleRequest>();
  const {
    data: specialDates,
  } = useCustomQueryWithParams(SimpleGlobalListApi.getList, () => [
    SimpleGlobalListRoutePath.SpecialDates,
  ]) as CustomQueryResponse<SpecialDatelistItem[]>;

  const specialDateToName =
    specialDates?.reduce((obj, item) => {
      return { ...obj, [item.date]: item.caption };
    }, {} as { [date: string]: string }) ?? {};

  const [
    showShiftTimePicker,
    onShowShiftTimePicker,
    onHideShiftTimePicker,
  ] = useBooleanState(false);

  const [shiftTimePickerData, setShiftTimePickerData] = useState<
    ScheduleRequestShiftDayTimePickerProps["data"] & {
      id: number;
      dayKey: DayKey;
    }
  >();

  const onPickShiftTime = useCallback(
    (shiftIndex: number, dayKey: DayKey) => {
      const shift = form.getState().values.shifts[shiftIndex];
      const shiftDay = shift[dayKey];
      const dayNameItemConfig = daysNamesConfig.find(
        (conf) => conf.dayKey === dayKey
      )!;
      setShiftTimePickerData({
        id: shift.id,
        dayKey,
        title: `${dayNameItemConfig.displayName} - ${shift.name}`,
        initialFromTime: shiftDay.fromTime ?? shiftDay.originalFromTime,
        initialToTime: shiftDay.toTime ?? shiftDay.originalToTime,
        notes: shiftDay.notes,
      });
      onShowShiftTimePicker();
    },
    [form, onShowShiftTimePicker]
  );

  const onSaveShiftTimePicker = useCallback(
    (fromTime: string, toTime: string, notes?: string) => {
      const shifts = form.getState().values.shifts;
      const { id: shiftId, dayKey } = shiftTimePickerData!;
      form.change(
        "shifts",
        shifts.map((shift) => {
          if (shift.id !== shiftId) {
            return shift; // return shift unchanged.
          } else {
            return {
              ...shift,
              [dayKey]: {
                ...shift[dayKey],
                fromTime,
                toTime,
                notes,
              },
            };
          }
        })
      );
      onHideShiftTimePicker();
    },
    [form, shiftTimePickerData, onHideShiftTimePicker]
  );

  const onNavigateToPrevWeek = useCallback(() => {
    navigate(
      ScheduleRequestsRouteBuilder.buildMyScheduleRequestsRoute(
        subDays(startOfWeekDay, 7)
      )
    );
  }, [navigate, startOfWeekDay]);

  const onNavigateToNextWeek = useCallback(() => {
    navigate(
      ScheduleRequestsRouteBuilder.buildMyScheduleRequestsRoute(
        addDays(startOfWeekDay, 7)
      )
    );
  }, [navigate, startOfWeekDay]);

  return (
    <div className="d-flex flex-column align-items-center">
      <Typography variant="title" className="text-center">
        הגשת משמרות לשבוע
      </Typography>
      <Field
        name="startDate"
        render={({ input }) => {
          const startDate = new Date(input.value);
          const endDate = addDays(startDate, 6);
          return (
            <div className="d-flex align-items-center">
              <i
                role="button"
                className="fa fa-arrow-right mx-2"
                onClick={onNavigateToPrevWeek}
              />
              <span dir="ltr">
                {format(startDate, "dd.MM.yy")} - {format(endDate, "dd.MM.yy")}
              </span>
              <i
                role="button"
                className="fa fa-arrow-left mx-2"
                onClick={onNavigateToNextWeek}
              />
            </div>
          );
        }}
      />
      <div className="mb-1" />

      {error && <div className="mb-1">{error}</div>}
      <Field
        name="lastEmployeeRegistrationDate"
        render={({ input }) => {
          if (!input.value) {
            return null;
          }

          const date = new Date(input.value);
          const hoursDiff = differenceInHours(date, Date.now());
          const minDiff = differenceInMinutes(date, Date.now());
          const remainingTime = format(
            addHours(
              addMinutes(startOfDay(Date.now()), minDiff - 60 * hoursDiff),
              hoursDiff
            ),
            "hh:mm"
          );
          return (
            <Typography variant="description" className="text-center">
              <i className="fa fa-clock-o mx-1" />
              נותרו {remainingTime} שעות להגשת המשמרות
            </Typography>
          );
        }}
      />

      <FieldArray
        name="shifts"
        subscription={{ length: true }}
        isEqual={isEqual}
      >
        {({ fields: shiftFields }) => {
          if (shiftFields.length === 0) {
            return null;
          }
          return (
            <table className="my-schedule-request">
              <thead>
                <tr>
                  <th>יום</th>
                  {shiftFields.map((prefix) => (
                    <Field
                      key={prefix}
                      name={`${prefix}.name`}
                      render={({ input }) => <th>{input.value}</th>}
                    />
                  ))}
                </tr>
              </thead>
              <tbody>
                <Field
                  name="startDate"
                  render={({ input }) => {
                    const startDate = new Date(input.value);
                    return daysNamesConfig.map(
                      ({ displayName, dayKey }, dayIndex) => (
                        <tr key={dayKey}>
                          <td>
                            <div>
                              <div>{displayName}</div>
                              <div>
                                {format(
                                  addDays(startDate, dayIndex),
                                  "dd.MM.yy"
                                )}
                              </div>
                              <div>
                                {specialDateToName[
                                  format(
                                    addDays(startDate, dayIndex),
                                    SPECIAL_DATES_FORMAT
                                  )
                                ] ?? ""}
                              </div>
                            </div>
                          </td>
                          {shiftFields.map((prefix, shiftIndex) => (
                            <Field
                              key={prefix}
                              name={`${prefix}.${dayKey}.status`}
                              render={({ input: statusInput }) => (
                                <td
                                  className={`shift-day status-${statusInput.value} text-center`}
                                  role="button"
                                  onClick={() => {
                                    statusInput.onChange(
                                      (statusInput.value + 1) % 3
                                    );
                                  }}
                                >
                                  <div
                                    dir="ltr"
                                    className="original-times-section d-flex justify-content-center"
                                  >
                                    <Field
                                      name={`${prefix}.${dayKey}.originalFromTime`}
                                      render={({ input }) => (
                                        <div>{input.value}</div>
                                      )}
                                    />
                                    <span>-</span>
                                    <Field
                                      name={`${prefix}.${dayKey}.originalToTime`}
                                      render={({ input }) => (
                                        <div>{input.value}</div>
                                      )}
                                    />
                                  </div>
                                  <div>
                                    {statusToNameMap[statusInput.value]}
                                  </div>
                                  <div
                                    className={classNames("bottom-section", {
                                      invisible: statusInput.value === 2,
                                    })}
                                  >
                                    <div
                                      dir="ltr"
                                      className={classNames(
                                        "d-flex",
                                        "justify-content-center",
                                        "filled-times-section"
                                      )}
                                    >
                                      <Field
                                        name={`${prefix}.${dayKey}.fromTime`}
                                        render={({ input }) => (
                                          <div>{input.value}</div>
                                        )}
                                      />
                                      <span>-</span>
                                      <Field
                                        name={`${prefix}.${dayKey}.toTime`}
                                        render={({ input }) => (
                                          <div>{input.value}</div>
                                        )}
                                      />
                                    </div>
                                    <i
                                      role="button"
                                      className="fa fa-gear align-self-end"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        onPickShiftTime(shiftIndex, dayKey);
                                      }}
                                    />
                                  </div>
                                </td>
                              )}
                            />
                          ))}
                        </tr>
                      )
                    );
                  }}
                />
              </tbody>
            </table>
          );
        }}
      </FieldArray>
      <ScheduleRequestShiftDayTimePicker
        show={showShiftTimePicker}
        data={shiftTimePickerData}
        onHide={onHideShiftTimePicker}
        onSave={onSaveShiftTimePicker}
      />
      <FormSpy<ScheduleRequest>
        subscription={{
          validating: true,
          initialValues: true,
        }}
        onChange={({ validating }) => {
          const formState = form.getState();
          const { submitting, valid } = formState;
          if (validating || submitting || !valid) {
            return;
          }

          const { initialValues, values } = formState;
          if (!isEqual(initialValues, values)) {
            form.submit();
          }
        }}
      />
    </div>
  );
};
