import React, { useCallback, useRef, useEffect } from "react";
import { useForm } from "react-final-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { RoundButton } from "base/components/RoundButton";
import { FormNumberInput } from "form/components/FormNumberInput";
import SpeechRecognition from "basic-tree-info/SpeechRecognition";
import { PickAmountFormValues } from "./types";
import "./styles.scss";
import { Row, Col } from "react-bootstrap";

export const PickAmountModalContent: React.FC = () => {
  const { change, submit } = useForm<PickAmountFormValues>();
  const speechServiceRef = useRef<SpeechRecognition>();

  const onResult = useCallback(
    (speechResult: string) => {
      const parsedResult = parseInt(speechResult, 10);

      change("amount", parsedResult);
      setTimeout(() => {
        submit();
      }, 150);
    },
    [change, submit]
  );

  useEffect(() => {
    const speechService = new SpeechRecognition(onResult);
    speechServiceRef.current = speechService;
    speechService.startCapture();

    return () => speechServiceRef.current?.stopCapture();
  }, [onResult]);

  return (
    <div className="pick-amount-modal-content">
      <FormNumberInput placeholder="כמות" name="amount" required />
      <Row noGutters dir="ltr" className="justify-content-center">
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map((val) => (
          <Col key={val} xs={4} className="p-3 d-flex justify-content-center">
            <div
              className="num-slot"
              role="button"
              // onClick={() => {
              //   if (pin.length < 4) {
              //     setPin(`${pin}${val}`);
              //   }
              // }}
            >
              {val}
            </div>
          </Col>
        ))}
      </Row>
      <div className="submit-container">
        <RoundButton onClick={submit}>
          <FontAwesomeIcon icon={faCheck} size="lg" />
        </RoundButton>
      </div>
    </div>
  );
};
