import { BalancePeriodType } from "./types";

export const BalancePeriodTypeOptions = [
  { label: "יומי", value: BalancePeriodType.Daily },
  { label: "שבועי", value: BalancePeriodType.Weekly },
  { label: "חודשי", value: BalancePeriodType.Monthly },
];

// export const DashboardCostsChartBGColors = [
//   "#FF0000" /* RED */,
//   "#00FF00" /* GREEN */,
// ];
