import React, { useState, useCallback, useMemo } from "react";
import { FormControl } from "react-bootstrap";
import { TreeInfoApi } from "tree-info/TreeInfoApi";
import { TreeType } from "base/types/TreeType";
import { useCustomQuery } from "base/api/hooks/useCustomQuery";
import { IngredientsApi } from "ingredient/IngredientsApi";
import { MaterialsApi } from "material/MaterialsApi";
import { Select } from "base/components/Select";
import { useSelectOptionsAdapter } from "base/components/Select/hooks/useSelectOptionsAdapter";

export type TreeInfoAddSectionProps = {
  type: TreeType;
  id: number;
  listName: "materials" | "ingredients";
};

export const TreeInfoAddSection: React.FC<TreeInfoAddSectionProps> = ({
  type,
  id,
  listName,
}) => {
  const { data: ingredientsList } = useCustomQuery(
    IngredientsApi.getSelectList,
    {
      skip: listName !== "ingredients",
    }
  );
  const ingredientsListOptions = useSelectOptionsAdapter(ingredientsList);

  const { data: materialsList } = useCustomQuery(MaterialsApi.getSelectList, {
    skip: listName !== "materials",
  });
  const materialsListOptions = useSelectOptionsAdapter(materialsList);

  const preFilterOptions =
    listName === "materials" ? materialsListOptions : ingredientsListOptions;

  const options = useMemo(() => {
    return preFilterOptions.filter((item) => item.value !== id);
  }, [id, preFilterOptions]);

  const [state, setState] = useState<{
    listItemId?: number;
    amount?: number;
  }>({});

  const onAdd = useCallback(async () => {
    setState({});
    if (listName === "materials") {
      await TreeInfoApi.addMaterial(type, id, state.listItemId!, state.amount!);
    } else {
      await TreeInfoApi.addIngredient(
        type,
        id,
        state.listItemId!,
        state.amount!
      );
    }
  }, [listName, type, id, state]);

  const selectPlaceholder =
    listName === "materials" ? "בחירת חומר גלם" : "בחירת מרכיב";

  return (
    <div className="d-flex">
      <Select
        options={options}
        variant="react-select"
        value={state.listItemId}
        onChange={(value: any) => {
          setState((s) => ({
            ...s,
            listItemId: value,
          }));
        }}
        className="w-25 mx-1"
        emptyOptionLabel={selectPlaceholder}
      />
      <FormControl
        value={state.amount ?? ""}
        type="number"
        onChange={(e) => {
          const { value } = e.target;
          setState((s) => ({
            ...s,
            amount: value ? Number(value) : undefined,
          }));
        }}
        className="w-25 mx-1"
      />
      <button
        className="btn btn-sm btn-primary mx-1"
        onClick={onAdd}
        disabled={!state.amount || state.listItemId === undefined}
      >
        הוספה
      </button>
    </div>
  );
};
