import { useCallback } from "react";
import { AuthStandType } from "auth/types";
import {
  UseNavigateCallbackOptions,
  useNavigateCallback,
} from "base/hooks/useNavigateCallback";
import { ChefsRouteBuilder } from "chefs/ChefsHomeRouteBuilder";
import { SentOrdersRouteBuilder } from "sent-order/SentOrdersRouteBuilder";
import { DishesRouteBuilder } from "dish/DishesRouteBuilder";
import { HomeRouteBuilder } from "home/HomeRouteBuilder";

export const useStandTypeRedirectCallback = () => {
  const navigate = useNavigateCallback();
  return useCallback(
    (standType: AuthStandType, navOpts?: UseNavigateCallbackOptions) => {
      switch (standType) {
        case "chef":
          navigate(ChefsRouteBuilder.buildChefsHomeRoute());
          break;
        case "receiveOrder":
          navigate(SentOrdersRouteBuilder.buildSentOrdersRoute(), navOpts);
          break;
        case "dish-editor":
          navigate(DishesRouteBuilder.buildDishVisualEditorRoute(), navOpts);
          break;
        case "default":
        default:
          navigate(HomeRouteBuilder.buildHomeRoute(), navOpts);
          break;
      }
    },
    [navigate]
  );
};
