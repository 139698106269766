import React, { useState, useEffect } from "react";
import { Modal, Row, Col, Button, Alert } from "react-bootstrap";
import { Form } from "react-final-form";
import { FormNumberInput } from "form/components/FormNumberInput";
import { FieldsValidator } from "form/validators/FieldsValidator";
import { Typography } from "base/components/Typography";
import { PreparationTasksApi } from "./PreparationTasksApi";
import { useNavigateCallback } from "base/hooks/useNavigateCallback";
import { ChefsRouteBuilder } from "chefs/ChefsHomeRouteBuilder";

type FormValues = {
  amount: number;
};

export type PreparationTaskDoneModalProps = {
  show: boolean;
  onHide: () => void;
  id?: number;
};

export const PreparationTaskDoneModal: React.FC<PreparationTaskDoneModalProps> = ({
  show,
  onHide,
  id,
}) => {
  const p_show = id !== undefined && show;
  const [isPrintSuccess, setIsPrintSuccess] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigateCallback();

  // Handle error state reset.
  useEffect(() => {
    if (!p_show) {
      setIsPrintSuccess(false);
      setError("");
    }
  }, [p_show]);

  // Handle success state reset if error received.
  useEffect(() => {
    if (error) {
      setIsPrintSuccess(false);
    }
  }, [error]);

  return (
    <Modal
      dialogClassName="preparation-task-screen-modal"
      show={p_show}
      onHide={onHide}
      centered
      size="sm"
    >
      <div className="modal-title-bar">
        <Typography variant="description" fontSize={24} bold>
          סיום משימה
        </Typography>
        <div className="close-btn" onClick={onHide} />
      </div>
      {isPrintSuccess && (
        <Alert variant="success" className="text-center">
          <div style={{ fontSize: 22 }}>פקודה נשלחה בהצלחה</div>
          <div style={{ fontSize: 16 }}>
            *ניתן להדפיס מדבקות נוספות, בסיום נא להקיש על "סיום משימה"
          </div>
        </Alert>
      )}
      {error && (
        <Alert style={{ fontSize: 22 }} variant="danger">
          {error}
        </Alert>
      )}
      <Row noGutters>
        <Form
          onSubmit={async ({ amount }: FormValues) => {
            if (amount < 10 || window.confirm("האם אתה בטוח?")) {
              try {
                await PreparationTasksApi.printLabels(id!, amount);
                setIsPrintSuccess(true);
              } catch (e) {
                setError(e?.response?.data?.error ?? "הפעולה נכשלה");
              }
            }
          }}
        >
          {({ handleSubmit }) => (
            <>
              <Col xs={12} className="p-1">
                <FormNumberInput
                  name="amount"
                  inputMode="numeric"
                  placeholder="כמות מדבקות"
                  validate={FieldsValidator.compose(
                    FieldsValidator.required,
                    FieldsValidator.integer,
                    FieldsValidator.minValue(1),
                    FieldsValidator.maxValue(99)
                  )}
                />
              </Col>
              <Col xs={12} className="p-1 mt-2">
                <Button
                  size="lg"
                  color="primary"
                  onClick={handleSubmit}
                  className="w-100"
                >
                  הדפסה
                </Button>
              </Col>
            </>
          )}
        </Form>
        <Col xs={12} className="p-1 mt-3">
          <Button
            size="lg"
            color="primary"
            onClick={async () => {
              try {
                await PreparationTasksApi.setDone(id!);
                navigate(ChefsRouteBuilder.buildChefsHomeRoute(), {
                  replace: true,
                });
              } catch (e) {
                setError(e?.response?.data?.error ?? "הפעולה נכשלה");
              }
            }}
            className="w-100"
          >
            סיום משימה
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};
