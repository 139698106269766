import React, { useMemo } from "react";
import {
  FormSelect,
  FormSelectProps,
} from "base/components/form-controls/FormSelect";
import { useCustomQuery } from "base/api/hooks/useCustomQuery";
import { MaterialsApi } from "material/MaterialsApi";

export type FormMaterialDepartmentsSelectProps<FormValues> = Omit<
  FormSelectProps<FormValues, number | undefined>,
  "data"
>;

export const FormMaterialDepartmentsSelect = <FormValues,>(
  props: FormMaterialDepartmentsSelectProps<FormValues>
) => {
  const { data: departments } = useCustomQuery(MaterialsApi.getDepartments);

  const data = useMemo(() => {
    return (
      departments?.map((item) => ({
        value: item.id,
        label: item.name,
      })) || []
    );
  }, [departments]);

  return <FormSelect emptyOptionLabel="בחר מחלקה" {...props} data={data} />;
};
