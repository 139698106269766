import { WorkScheduleType } from "work-schedule/types";
import { ShiftDuplicator } from "./ShiftDuplicator";

export class WorkScheduleDuplicator {
  static duplicate(workSchedule: WorkScheduleType): WorkScheduleType {
    return {
      ...workSchedule,
      id: (undefined as any) as number,
      shifts: workSchedule.shifts.map(ShiftDuplicator.duplicate),
    };
  }
}
