import React, { useMemo } from "react";
import { SentOrderQuickAddProductFormValues } from "sent-order/types";
import { FormCustomReset } from "form/utils/FormCustomReset";
import { Form } from "form/components/Form";
import { Row, Col, Button } from "react-bootstrap";
import { FormSelect } from "form/components/FormSelect";
import { ProductSupplyMethodListItemModal } from "product/supply-method/ProductSupplyMethodListItemModal";
import { FieldsValidator } from "form/validators/FieldsValidator";
import { ProductSelectListItemEditor } from "product/components/ProductSelectListItemEditor";
import { FormNumberInput } from "form/components/FormNumberInput";
import { FormSpy } from "react-final-form";
import { useImmer } from "use-immer";
import { ProductsApi } from "product/ProductsApi";
import {
  useCustomQueryWithParams,
  useCustomQuery,
  CustomQueryResponse,
} from "base/api/hooks/useCustomQuery";
import { useSelectOptionsAdapter } from "base/components/Select/hooks/useSelectOptionsAdapter";
import { ProductSupplyMethodsApi } from "product/supply-method/ProductSupplyMethodsApi";
import { SimpleGlobalListApi } from "global-list/SimpleGlobalListApi";
import { SimpleGlobalListRoutePath } from "global-list/types";
import { BaseSelectListItem } from "base/types";
import { SupplyMethodDescFormatter } from "order/SupplyMethodDescFormatter";
import { SentOrdersApi } from "sent-order/SentOrdersApi";

export interface SentOrderQuickAddProductProps {
  orderId: number | undefined;
  supplierId?: number;
}

type FormState = {
  productId: number | undefined;
};

export const SentOrderQuickAddProduct: React.FC<SentOrderQuickAddProductProps> = ({
  orderId,
  supplierId,
}) => {
  const fieldName = (property: keyof SentOrderQuickAddProductFormValues) =>
    property;

  const [formState, updateFormState] = useImmer<FormState>({
    productId: undefined,
  });

  const { data: products } = useCustomQuery(ProductsApi.getList);
  const productsOptions = useSelectOptionsAdapter(products);

  const { data: selectedProductToAdd } = useCustomQueryWithParams(
    ProductsApi.getItem,
    () => [formState.productId!],
    {
      skip: formState.productId === undefined,
    }
  );

  const { data: selectedProductToAddSupplyMethods } = useCustomQueryWithParams(
    ProductSupplyMethodsApi.get,
    () => [formState.productId!],
    {
      skip: formState.productId === undefined,
    }
  );

  const {
    data: unitKinds,
  } = useCustomQueryWithParams(SimpleGlobalListApi.getList, () => [
    SimpleGlobalListRoutePath.UnitKinds,
  ]) as CustomQueryResponse<BaseSelectListItem[]>;
  const {
    data: packageKinds,
  } = useCustomQueryWithParams(SimpleGlobalListApi.getList, () => [
    SimpleGlobalListRoutePath.PackageKinds,
  ]) as CustomQueryResponse<BaseSelectListItem[]>;

  const selectedProductToAddSupplyMethodsOptions = useMemo(() => {
    if (!selectedProductToAddSupplyMethods) return [];
    return selectedProductToAddSupplyMethods.map((supplyMethod) => ({
      value: supplyMethod.id,
      label: SupplyMethodDescFormatter.format(
        supplyMethod,
        selectedProductToAdd?.prepareUnitType ?? 0,
        unitKinds,
        packageKinds
      ),
    }));
  }, [
    packageKinds,
    selectedProductToAdd,
    selectedProductToAddSupplyMethods,
    unitKinds,
  ]);

  return (
    <Form
      onSubmit={async (values: SentOrderQuickAddProductFormValues, form) => {
        await SentOrdersApi.quickAddProduct(values);
        FormCustomReset.hardReset(form);
      }}
      initialValues={{
        orderId,
      }}
      subscription={{ valid: true, submitting: true }}
    >
      {({ handleSubmit, valid, submitting }) => (
        <>
          <Row className="mx-0">
            <Col md={3}>
              <FormSelect
                variant="react-select"
                name={fieldName("productId")}
                emptyOptionLabel="בחירת מוצר"
                options={productsOptions}
                validate={FieldsValidator.required}
                withAdd={{
                  component: ProductSelectListItemEditor,
                }}
              />
            </Col>
            <Col md={3}>
              <FormSelect
                variant="react-select"
                name={fieldName("supplyMethodId")}
                emptyOptionLabel="בחירת צורת אספקה"
                options={selectedProductToAddSupplyMethodsOptions}
                validate={FieldsValidator.required}
                disabled={formState.productId === undefined}
                withAdd={{
                  customModalRender: (props) => (
                    <ProductSupplyMethodListItemModal
                      {...props}
                      productName={
                        products?.find((p) => p.id === formState.productId)
                          ?.name ?? ""
                      }
                      initialValues={{
                        productId: formState.productId!,
                        supplierId: supplierId,
                      }}
                    />
                  ),
                }}
              />
            </Col>
            <Col md={3}>
              <FormNumberInput
                name={fieldName("amount")}
                placeholder="כמות"
                required
                maxDigitsAfterDot={3}
              />
            </Col>
            <Col md={3} className="d-flex">
              <Button
                disabled={orderId !== undefined && !valid && !submitting}
                className="mx-1"
                variant="primary"
                onClick={handleSubmit}
              >
                הוספה
              </Button>
            </Col>
          </Row>
          <FormSpy<Partial<SentOrderQuickAddProductFormValues>>
            subscription={{ values: true }}
            onChange={({ values }) => {
              const { productId } = values;
              updateFormState((s) => {
                s.productId = productId;
              });
            }}
          />
        </>
      )}
    </Form>
  );
};
