import React, { useState, useEffect, useCallback } from "react";
import { FieldArray } from "react-final-form-arrays";
import { useForm, Field, FormSpy } from "react-final-form";
import InputMask from "react-input-mask";
import { isEqual } from "lodash";
import { format, addDays, subDays } from "date-fns";
import { useNavigateCallback } from "base/hooks/useNavigateCallback";
import {
  useCustomQuery,
  useCustomQueryWithParams,
  CustomQueryResponse,
} from "base/api/hooks/useCustomQuery";
import { Spacer } from "base/components/Spacer";
import { Typography } from "base/components/Typography";
import { Select } from "base/components/Select";
import { RoundCheckButton } from "base/components/RoundCheckButton";
import { FormSelect } from "form/components/FormSelect";
import {
  SimpleGlobalListRoutePath,
  SPECIAL_DATES_FORMAT,
  SpecialDatelistItem,
} from "global-list/types";
import { SimpleGlobalListApi } from "global-list/SimpleGlobalListApi";
import { WorkScheduleApi } from "work-schedule/WorkScheduleApi";
import { ShiftItemDuplicator } from "work-schedule/util/ShiftItemDuplicator";
import { ShiftDuplicator } from "work-schedule/util/ShiftDuplicator";
import {
  WeeklyWorkScheduleFormValues,
  WeeklyShiftItem,
  WeeklyShift,
} from "weekly-work-schedule/types";
import "./style.scss";
import { WeeklyWorkSchedulesRouteBuilder } from "weekly-work-schedule/WeeklyWorkSchedulesRouteBuilder";
import { useSelectOptionsAdapter } from "base/components/Select/hooks/useSelectOptionsAdapter";
import { EmployeesApi } from "employee/EmployeesApi";
import { WorkScheduleRoleTypeEditor } from "select-list-item-editor/components/WorkScheduleRoleTypeEditor";
import { WorkScheduleRoleNameEditor } from "select-list-item-editor/components/WorkScheduleRoleNameEditor";

export type WeeklyWorkScheduleFormProps = {
  startOfWeekDay: Date;
};

export const WeeklyWorkScheduleForm: React.FC<WeeklyWorkScheduleFormProps> = ({
  startOfWeekDay,
}) => {
  const navigate = useNavigateCallback();
  const { getState, submit, subscribe } = useForm<
    WeeklyWorkScheduleFormValues
  >();
  const [departmentIndex, setDepartmentIndex] = useState<number | undefined>(
    undefined
  );
  const [departmentId, setDepartmentId] = useState<number | undefined>(
    undefined
  );
  const { data: roleTypes } = useCustomQuery(WorkScheduleApi.getRoleTypes);
  const roleTypesOptions = useSelectOptionsAdapter(roleTypes);

  const { data: roleNames } = useCustomQuery(WorkScheduleApi.getRoleNames);
  const roleNamesOptions = useSelectOptionsAdapter(roleNames);

  const { data: shiftDepartments } = useCustomQuery(
    WorkScheduleApi.getShiftDepartments
  );
  const shiftDepartmentsOptions = useSelectOptionsAdapter(shiftDepartments);

  const { data: employees } = useCustomQuery(EmployeesApi.getList);
  const employeesOptions = useSelectOptionsAdapter(
    employees?.map((e) => ({
      id: e.id,
      name: e.firstName,
    }))
  );

  const {
    data: specialDates,
  } = useCustomQueryWithParams(SimpleGlobalListApi.getList, () => [
    SimpleGlobalListRoutePath.SpecialDates,
  ]) as CustomQueryResponse<SpecialDatelistItem[]>;

  const specialDateToName =
    specialDates?.reduce((obj, item) => {
      return { ...obj, [item.date]: item.caption };
    }, {} as { [date: string]: string }) ?? {};

  const onNavigateToPrevWeek = useCallback(() => {
    navigate(
      WeeklyWorkSchedulesRouteBuilder.buildWeeklyWorkScheduleRoute(
        subDays(startOfWeekDay, 7)
      )
    );
  }, [navigate, startOfWeekDay]);

  const onNavigateToNextWeek = useCallback(() => {
    navigate(
      WeeklyWorkSchedulesRouteBuilder.buildWeeklyWorkScheduleRoute(
        addDays(startOfWeekDay, 7)
      )
    );
  }, [navigate, startOfWeekDay]);

  useEffect(() => {
    if (departmentId === undefined) {
      setDepartmentIndex(undefined);
      return;
    }

    const unsubscribe = subscribe(
      ({ values }) => {
        if (values.isDataReady) {
          setDepartmentIndex(
            values.schedules.findIndex((s) => s.departmentId === departmentId)
          );
          setTimeout(() => unsubscribe());
        }
      },
      { values: true }
    );
    return unsubscribe;
  }, [departmentId, subscribe]);

  return (
    <div className="weeklyWorkScheduleForm">
      <div className="d-flex justify-content-start mb-5 formHeader">
        <Select
          options={shiftDepartmentsOptions}
          value={departmentId}
          onChange={setDepartmentId as any}
          className="w-25 mx-2 mb-1"
          displayEmptyOption={false}
        />
      </div>
      <div className="d-flex flex-column align-items-center">
        <Typography variant="title">סידור לשבוע</Typography>
        <div className="d-flex align-items-center">
          <i
            role="button"
            className="fa fa-arrow-right mx-2"
            onClick={onNavigateToPrevWeek}
          />
          <span>{format(startOfWeekDay, "dd.MM.yy")}</span>
          <i
            role="button"
            className="fa fa-arrow-left mx-2"
            onClick={onNavigateToNextWeek}
          />
        </div>
      </div>
      <FieldArray<WeeklyShift>
        name={`schedules[${departmentIndex}].shifts`}
        key={departmentIndex}
        isEqual={isEqual}
        subscription={{ length: true }}
      >
        {({ fields }) => (
          <>
            {fields.map((shiftFieldName, shiftIndex) => (
              <div key={shiftIndex}>
                <FieldArray<WeeklyShiftItem>
                  name={`${shiftFieldName}.items`}
                  isEqual={isEqual}
                  subscription={{ length: true }}
                >
                  {({ fields: shiftFields }) => (
                    <>
                      <div className="d-flex">
                        <Field
                          className="form-control w-auto"
                          name={`${shiftFieldName}.name`}
                          component="input"
                        />
                        <div
                          onClick={() =>
                            fields.push(
                              ShiftDuplicator.duplicate(
                                getState().values.schedules[departmentIndex!]
                                  .shifts[shiftIndex]
                              )
                            )
                          }
                          role="button"
                          className="d-flex align-items-center mx-1"
                        >
                          <i className="fa fa-copy text-white fa-lg" />
                        </div>
                        <div
                          onClick={() => fields.remove(shiftIndex)}
                          role="button"
                          className="d-flex align-items-center mx-1"
                        >
                          <i className="fa fa-trash text-danger fa-lg" />
                        </div>
                      </div>
                      <div className="table-container">
                        <table className="table table-striped">
                          <thead className="thead-dark">
                            <tr>
                              <th style={{ minWidth: 120, width: "15%" }}>
                                סוג תפקיד
                              </th>
                              <th style={{ minWidth: 120, width: "15%" }}>
                                שם תפקיד
                              </th>
                              <th className="dayHeader">שעות</th>
                              <th className="dayHeader">
                                א' ({format(startOfWeekDay, "dd.MM")})
                                <br />
                                <span>
                                  {specialDateToName[
                                    format(startOfWeekDay, SPECIAL_DATES_FORMAT)
                                  ] ?? ""}
                                </span>
                              </th>
                              <th className="dayHeader">
                                ב' (
                                {format(addDays(startOfWeekDay, 1), "dd.MM")})
                                <br />
                                <span>
                                  {specialDateToName[
                                    format(
                                      addDays(startOfWeekDay, 1),
                                      SPECIAL_DATES_FORMAT
                                    )
                                  ] ?? ""}
                                </span>
                              </th>
                              <th className="dayHeader">
                                ג' (
                                {format(addDays(startOfWeekDay, 2), "dd.MM")})
                                <br />
                                <span>
                                  {specialDateToName[
                                    format(
                                      addDays(startOfWeekDay, 2),
                                      SPECIAL_DATES_FORMAT
                                    )
                                  ] ?? ""}
                                </span>
                              </th>
                              <th className="dayHeader">
                                ד' (
                                {format(addDays(startOfWeekDay, 3), "dd.MM")})
                                <br />
                                <span>
                                  {specialDateToName[
                                    format(
                                      addDays(startOfWeekDay, 3),
                                      SPECIAL_DATES_FORMAT
                                    )
                                  ] ?? ""}
                                </span>
                              </th>
                              <th className="dayHeader">
                                ה' (
                                {format(addDays(startOfWeekDay, 4), "dd.MM")})
                                <br />
                                <span>
                                  {specialDateToName[
                                    format(
                                      addDays(startOfWeekDay, 4),
                                      SPECIAL_DATES_FORMAT
                                    )
                                  ] ?? ""}
                                </span>
                              </th>
                              <th className="dayHeader">
                                ו' (
                                {format(addDays(startOfWeekDay, 5), "dd.MM")})
                                <br />
                                <span>
                                  {specialDateToName[
                                    format(
                                      addDays(startOfWeekDay, 5),
                                      SPECIAL_DATES_FORMAT
                                    )
                                  ] ?? ""}
                                </span>
                              </th>
                              <th className="dayHeader">
                                ש' (
                                {format(addDays(startOfWeekDay, 6), "dd.MM")})
                                <br />
                                <span>
                                  {specialDateToName[
                                    format(
                                      addDays(startOfWeekDay, 6),
                                      SPECIAL_DATES_FORMAT
                                    )
                                  ] ?? ""}
                                </span>
                              </th>
                              <th style={{ width: 60 }} />
                            </tr>
                          </thead>
                          <tbody>
                            {shiftFields.map(
                              (shiftItemFieldName, shiftItemIndex) => (
                                <tr key={shiftItemIndex}>
                                  <td>
                                    <FormSelect
                                      name={`${shiftItemFieldName}.roleTypeId`}
                                      options={roleTypesOptions}
                                      emptyOptionLabel="בחר סוג תפקיד"
                                      withAdd={{
                                        component: WorkScheduleRoleTypeEditor,
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <FormSelect
                                      name={`${shiftItemFieldName}.roleNameId`}
                                      options={roleNamesOptions}
                                      emptyOptionLabel="בחר שם תפקיד"
                                      withAdd={{
                                        component: WorkScheduleRoleNameEditor,
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <div className="text-center">
                                      <div>התחלה</div>
                                      <div className="mt-2">סיום</div>
                                      <div className="mt-2">עובד</div>
                                    </div>
                                  </td>
                                  <td className="dayCell">
                                    <div>
                                      <Field
                                        render={({ input, meta }) => (
                                          <InputMask
                                            className="form-control"
                                            mask={"99:99"}
                                            alwaysShowMask
                                            dir="ltr"
                                            {...input}
                                          />
                                        )}
                                        name={`${shiftItemFieldName}.sunday.fromTime`}
                                      />
                                    </div>
                                    <div>
                                      <Field
                                        render={({ input, meta }) => (
                                          <InputMask
                                            className="form-control"
                                            mask={"99:99"}
                                            alwaysShowMask
                                            dir="ltr"
                                            {...input}
                                          />
                                        )}
                                        name={`${shiftItemFieldName}.sunday.toTime`}
                                      />
                                    </div>
                                    <div>
                                      <FormSelect
                                        name={`${shiftItemFieldName}.sunday.employeeId`}
                                        options={employeesOptions}
                                      />
                                    </div>
                                  </td>
                                  <td className="dayCell">
                                    <div>
                                      <Field
                                        render={({ input }) => (
                                          <InputMask
                                            className="form-control"
                                            mask="99:99"
                                            alwaysShowMask
                                            dir="ltr"
                                            {...input}
                                          />
                                        )}
                                        name={`${shiftItemFieldName}.monday.fromTime`}
                                      />
                                    </div>
                                    <div>
                                      <Field
                                        render={({ input, meta }) => (
                                          <InputMask
                                            className="form-control"
                                            mask={"99:99"}
                                            alwaysShowMask
                                            dir="ltr"
                                            {...input}
                                          />
                                        )}
                                        name={`${shiftItemFieldName}.monday.toTime`}
                                      />
                                    </div>
                                    <div>
                                      <FormSelect
                                        name={`${shiftItemFieldName}.monday.employeeId`}
                                        options={employeesOptions}
                                      />
                                    </div>
                                  </td>
                                  <td className="dayCell">
                                    <div>
                                      <Field
                                        render={({ input }) => (
                                          <InputMask
                                            className="form-control"
                                            mask="99:99"
                                            alwaysShowMask
                                            dir="ltr"
                                            {...input}
                                          />
                                        )}
                                        name={`${shiftItemFieldName}.tuesday.fromTime`}
                                      />
                                    </div>
                                    <div>
                                      <Field
                                        render={({ input, meta }) => (
                                          <InputMask
                                            className="form-control"
                                            mask={"99:99"}
                                            alwaysShowMask
                                            dir="ltr"
                                            {...input}
                                          />
                                        )}
                                        name={`${shiftItemFieldName}.tuesday.toTime`}
                                      />
                                    </div>
                                    <div>
                                      <FormSelect
                                        name={`${shiftItemFieldName}.tuesday.employeeId`}
                                        options={employeesOptions}
                                      />
                                    </div>
                                  </td>
                                  <td className="dayCell">
                                    <div>
                                      <Field
                                        render={({ input }) => (
                                          <InputMask
                                            className="form-control"
                                            mask="99:99"
                                            alwaysShowMask
                                            dir="ltr"
                                            {...input}
                                          />
                                        )}
                                        name={`${shiftItemFieldName}.wednesday.fromTime`}
                                      />
                                    </div>
                                    <div>
                                      <Field
                                        render={({ input, meta }) => (
                                          <InputMask
                                            className="form-control"
                                            mask={"99:99"}
                                            alwaysShowMask
                                            dir="ltr"
                                            {...input}
                                          />
                                        )}
                                        name={`${shiftItemFieldName}.wednesday.toTime`}
                                      />
                                    </div>
                                    <div>
                                      <FormSelect
                                        name={`${shiftItemFieldName}.wednesday.employeeId`}
                                        options={employeesOptions}
                                      />
                                    </div>
                                  </td>
                                  <td className="dayCell">
                                    <div>
                                      <Field
                                        render={({ input }) => (
                                          <InputMask
                                            className="form-control"
                                            mask="99:99"
                                            alwaysShowMask
                                            dir="ltr"
                                            {...input}
                                          />
                                        )}
                                        name={`${shiftItemFieldName}.thursday.fromTime`}
                                      />
                                    </div>
                                    <div>
                                      <Field
                                        render={({ input, meta }) => (
                                          <InputMask
                                            className="form-control"
                                            mask={"99:99"}
                                            alwaysShowMask
                                            dir="ltr"
                                            {...input}
                                          />
                                        )}
                                        name={`${shiftItemFieldName}.thursday.toTime`}
                                      />
                                    </div>
                                    <div>
                                      <FormSelect
                                        name={`${shiftItemFieldName}.thursday.employeeId`}
                                        options={employeesOptions}
                                      />
                                    </div>
                                  </td>
                                  <td className="dayCell">
                                    <div>
                                      <Field
                                        render={({ input }) => (
                                          <InputMask
                                            className="form-control"
                                            mask="99:99"
                                            alwaysShowMask
                                            dir="ltr"
                                            {...input}
                                          />
                                        )}
                                        name={`${shiftItemFieldName}.friday.fromTime`}
                                      />
                                    </div>
                                    <div>
                                      <Field
                                        render={({ input, meta }) => (
                                          <InputMask
                                            className="form-control"
                                            mask={"99:99"}
                                            alwaysShowMask
                                            dir="ltr"
                                            {...input}
                                          />
                                        )}
                                        name={`${shiftItemFieldName}.friday.toTime`}
                                      />
                                    </div>
                                    <div>
                                      <FormSelect
                                        name={`${shiftItemFieldName}.friday.employeeId`}
                                        options={employeesOptions}
                                      />
                                    </div>
                                  </td>
                                  <td className="dayCell">
                                    <div>
                                      <Field
                                        render={({ input }) => (
                                          <InputMask
                                            className="form-control"
                                            mask="99:99"
                                            alwaysShowMask
                                            dir="ltr"
                                            {...input}
                                          />
                                        )}
                                        name={`${shiftItemFieldName}.saturday.fromTime`}
                                      />
                                    </div>
                                    <div>
                                      <Field
                                        render={({ input, meta }) => (
                                          <InputMask
                                            className="form-control"
                                            mask={"99:99"}
                                            alwaysShowMask
                                            dir="ltr"
                                            {...input}
                                          />
                                        )}
                                        name={`${shiftItemFieldName}.saturday.toTime`}
                                      />
                                    </div>
                                    <div>
                                      <FormSelect
                                        name={`${shiftItemFieldName}.sundaysaturday`}
                                        options={employeesOptions}
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="d-flex justify-content-around align-items-center">
                                      <div
                                        role="button"
                                        onClick={() =>
                                          shiftFields.push(
                                            ShiftItemDuplicator.duplicate(
                                              getState().values.schedules[
                                                departmentIndex!
                                              ].shifts[shiftIndex].items[
                                                shiftItemIndex
                                              ]
                                            )
                                          )
                                        }
                                      >
                                        <i className="fa fa-copy fa-lg text-white" />
                                      </div>
                                      <div
                                        role="button"
                                        onClick={() =>
                                          shiftFields.remove(shiftItemIndex)
                                        }
                                      >
                                        <i className="fa fa-trash fa-lg text-danger" />
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div
                        className="mr-auto btn btn-primary"
                        onClick={() => shiftFields.push({} as WeeklyShiftItem)}
                      >
                        הוספת תפקיד
                      </div>
                    </>
                  )}
                </FieldArray>
                <Spacer units={7} />
              </div>
            ))}
            <Spacer units={4} />
            <div
              className="btn btn-primary"
              onClick={() =>
                fields.push({ name: "משמרת חדשה", items: [{}] } as WeeklyShift)
              }
            >
              הוספת משמרת
            </div>
          </>
        )}
      </FieldArray>
      <Spacer units={6} />
      <FormSpy
        subscription={{ submitting: true }}
        render={({ submitting }) => (
          <RoundCheckButton onClick={submit} disabled={submitting} />
        )}
      />
    </div>
  );
};
