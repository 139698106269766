import axios from "axios";
import { FoodDepartmentListItem, BaseSelectListItem } from "base/types";
import { BodyFormDataBuilder } from "base/api/BodyFormDataBuilder";
import { RequestCacheHelper } from "base/api/RequestCacheHelper";
import { Recipe } from "recipe/types";
import { MaterialListItem, Material, MaterialRelatedTree } from "./types";
import { RecipeApi } from "recipe/RecipeApi";

export class MaterialsApi {
  static getList() {
    return axios.get<MaterialListItem[]>("/api/materials/items");
  }

  static getItem(id: number) {
    return axios.get<Material>(`/api/materials/${id}`);
  }

  static getItemDefaults() {
    return axios.get<Partial<Material>>(`/api/materials/new`);
  }

  static getDepartments() {
    return axios.get<FoodDepartmentListItem[]>("/api/materials/departments");
  }

  static getSelectList() {
    return axios.get<BaseSelectListItem[]>("/api/materials/selectlist");
  }

  static getRecipe(id: number) {
    return axios.get<Partial<Recipe>>(`/api/materials/${id}/recipe`);
  }

  static getTrees(id: number) {
    return axios.get<MaterialRelatedTree[]>(`/api/materials/${id}/trees`);
  }

  static async addRecipe(id: number) {
    const { data: createdRecipe } = await axios.post<Recipe>(
      `/api/materials/${id}/recipe/add`
    );

    // Updating cache of created recipe and material
    await Promise.all([
      RequestCacheHelper.instance.update(createdRecipe, RecipeApi.get, [
        createdRecipe.id,
      ]),
      RequestCacheHelper.instance.update<Material>(
        (item) => {
          if (!item) {
            return undefined;
          }

          return { ...item, recipeId: createdRecipe.id };
        },
        MaterialsApi.getItem,
        [id]
      ),
    ]);
  }

  static async saveItem(
    data: WithPartialProperties<Material, "id">,
    attachment?: File
  ) {
    const { data: savedItem } = await axios.post<Material>(
      "/api/materials",
      BodyFormDataBuilder.build({ ...data, image: attachment }),
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );

    // updating the cache, so the ui will get notified of the changes.
    await RequestCacheHelper.instance.update(savedItem, this.getItem, [
      savedItem.id,
    ]);
    RequestCacheHelper.instance.refetch(this.getList);

    return savedItem;
  }

  static async deleteItem(id: number) {
    await axios.delete<void>(`/api/materials/${id}`);
  }
}
