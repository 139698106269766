import React from "react";
import { RouteContent } from "base/components/RouteContent";
import { Typography } from "base/components/Typography";
import { ProductEditor } from "./components/ProductEditor";
import { useParams } from "react-router-dom";
import { useFullScreenLoader } from "full-screen-loader/hooks/useFullScreenLoader";
import { useCustomQueryWithParams } from "base/api/hooks/useCustomQuery";
import { ProductsApi } from "./ProductsApi";

export type EditProductScreenParams = {
  productId: string;
};

export const EditProductScreen: React.FC = () => {
  const { productId: productIdStr } = useParams<EditProductScreenParams>();
  const productId = Number(productIdStr);

  const { data, isValidating } = useCustomQueryWithParams(
    ProductsApi.getItem,
    () => [productId]
  );

  const isLoading = !data || isValidating;

  useFullScreenLoader(isLoading);

  return (
    <RouteContent>
      <Typography variant="route-title">עריכה</Typography>
      <ProductEditor mode="edit" data={data} />
    </RouteContent>
  );
};
