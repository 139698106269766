import React, { useCallback } from "react";
import { FormContextValues, Controller, ErrorMessage } from "react-hook-form";
import { FormErrorMessage } from "../FormErrorMessage";
import { FormControl } from "react-bootstrap";

export type FormInputProps<FormValues> = {
  name: KeysByType<FormValues, number | string | undefined>;
  formInstance: FormContextValues<FormValues>;
  type?: "text" | "number";
  step?: string;
  placeholder?: string;
  className?: string;
  autoFocus?: boolean;
  selectOnFocus?: boolean;
  inputMode?: "none" | "decimal";
};

export const FormInput = <FormValues,>({
  name,
  formInstance,
  className,
  type = "text",
  step,
  placeholder,
  autoFocus = false,
  selectOnFocus = false,
  inputMode = "none",
}: FormInputProps<FormValues>) => {
  const { errors, control } = formInstance;
  const onSelectOnFocus = useCallback<
    React.FocusEventHandler<HTMLInputElement>
  >((event) => {
    const input = event.target;
    setTimeout(() => input.select());
  }, []);

  return (
    <>
      <Controller
        as={
          <FormControl
            autoComplete="off"
            type={type}
            step={step}
            placeholder={placeholder}
            className={className}
            autoFocus={autoFocus}
            onFocus={selectOnFocus ? onSelectOnFocus : undefined}
            inputMode={inputMode}
          />
        }
        name={name as string}
        control={control}
        defaultValue={type === "text" ? "" : undefined}
      />
      <div>
        {
          <ErrorMessage
            as={FormErrorMessage}
            name={name as string}
            errors={errors}
          />
        }
      </div>
    </>
  );
};
