// import React, { useState, useEffect } from "react";
// import { Modal } from "react-bootstrap";
// import { IngredientSupplyMethod } from "./types";
// import { useCustomQueryWithParams } from "base/api/hooks/useCustomQuery";
// import { IngredientSupplyMethodsApi } from "./IngredientSupplyMethodsApi";
// import { usePrevious } from "base/hooks/usePrevious";

// export type IngredSupplyMethodBarcodeModalProps = {
//   show: boolean;
//   onHide: () => void;
//   onMatch: (supplyMethod: IngredientSupplyMethod) => void;
// };

// export const IngredSupplyMethodBarcodeModal: React.FC<IngredSupplyMethodBarcodeModalProps> = ({
//   show,
//   onHide,
//   onMatch,
// }) => {
//   const [barcode, setBarcode] = useState<number>();
//   const isBarcodeFilled = barcode !== undefined;

//   const [barcodeChanges, setBarcodeChanges] = useState<string[]>([]);

//   const { data: matchingSupplyMethod } = useCustomQueryWithParams(
//     IngredientSupplyMethodsApi.getByBarcode,
//     () => [barcode!],
//     {
//       skip: !barcode,
//     }
//   );
//   const prevMatchingSupplyMethod = usePrevious(matchingSupplyMethod);

//   // Handle input updates.
//   useEffect(() => {
//     if (isBarcodeFilled || barcodeChanges.length === 0) return;

//     const timeoutId = setTimeout(() => {
//       setBarcode(parseInt(barcodeChanges[barcodeChanges.length - 1]));
//     }, 2000);
//     return () => clearTimeout(timeoutId);
//   }, [barcodeChanges, isBarcodeFilled]);

//   // Handle notify match found.
//   useEffect(() => {
//     if (
//       matchingSupplyMethod &&
//       prevMatchingSupplyMethod !== matchingSupplyMethod
//     ) {
//       onMatch(matchingSupplyMethod);
//       onHide();
//     }
//   }, [matchingSupplyMethod, onHide, onMatch, prevMatchingSupplyMethod]);

//   // Handle clear state.
//   useEffect(() => {
//     if (!show) {
//       setBarcodeChanges([]);
//       setBarcode(undefined);
//     }
//   }, [show]);

//   return (
//     <Modal show={show} onHide={onHide} centered size="sm">
//       <input
//         autoFocus
//         onChange={(e) => {
//           const val = e.target.value;
//           setBarcodeChanges((x) => [...x, val]);
//         }}
//       />
//       <div>
//         Changes order {"{"}Test{"}"}
//         <br />
//         <code>{barcodeChanges.join(", ")}</code>
//       </div>
//     </Modal>
//   );
// };

export const IngredSupplyMethodBarcodeModal: React.FC<any> = () => null;
