import { useRef, useEffect, useCallback } from "react";
import { useIsMountedRef } from "./useIsMountedRef";

export const useOnMountCallback = () => {
  const isMountedRef = useIsMountedRef();
  const queueRef = useRef<Array<() => void>>([]);

  useEffect(() => {
    if (queueRef.current.length > 0) {
      const queue = [...queueRef.current];
      queueRef.current = [];

      queue.forEach((fn) => fn());
    }
  }, []);

  return useCallback(
    (fn: () => void) => {
      if (isMountedRef.current) {
        fn();
      } else {
        queueRef.current.push(fn);
      }
    },
    [isMountedRef]
  );
};
