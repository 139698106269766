import React, { useMemo, useState, useCallback } from "react";
import { useCustomQueryWithParams } from "base/api/hooks/useCustomQuery";
import { useBooleanState } from "base/hooks/useBooleanState";
import {
  CardsTableColumn,
  CardsTable,
} from "base/components/tables/components/CardsTable";
import { TableDateCell } from "base/components/tables/components/TableDateCell";
import { Typography } from "base/components/Typography";
import { SimpleGlobalListApi } from "global-list/SimpleGlobalListApi";
import { SimpleGlobalListItemModal } from "./SimpleGlobalListItemModal";
import "./../style.scss";
import { GlobalListsDefinitions } from "global-list/constants";
import { SimpleGlobalListRoutePath } from "global-list/types";
import { BaseImage } from "base/components/BaseFoodImage";

export type SimpleGlobalListProps = {
  routeApiPath: SimpleGlobalListRoutePath;
};

export const SimpleGlobalList: React.FC<SimpleGlobalListProps> = ({
  routeApiPath,
}) => {
  const { title, fields: fieldsDef } = GlobalListsDefinitions[routeApiPath];
  const [showEditModal, onShowEditModal, onHideEditModal] = useBooleanState(
    false
  );
  const [editIndex, setEditIndex] = useState<number>();

  const { data } = useCustomQueryWithParams(SimpleGlobalListApi.getList, () => [
    routeApiPath,
  ]);

  const columns = useMemo<CardsTableColumn<any>[]>(
    () =>
      fieldsDef
        .filter((def) => !def.isTableColumnHidden)
        .map<CardsTableColumn<any>>((def) => ({
          id: def.accessor,
          accessor: def.accessor,
          Header: def.tableTitle ?? def.title,
          ...(def.type === "date"
            ? {
                sortType: "datetime",
                Cell: TableDateCell,
              }
            : {}),
          ...(def.type === "boolean"
            ? {
                sortType: "boolean",
              }
            : {}),
          ...(def.type === "image"
            ? {
                Cell: ({ value }) => (
                  <BaseImage
                    alt="תמונה"
                    fileName={value}
                    fileDir={def.imageDir}
                  />
                ),
              }
            : {}),
        })),
    [fieldsDef]
  );

  const onEditRowClick = useCallback(
    (_, index) => {
      setEditIndex(index);
      onShowEditModal();
    },
    [onShowEditModal]
  );

  const onAddRowClick = useCallback(() => {
    setEditIndex(undefined);
    onShowEditModal();
  }, [onShowEditModal]);

  return (
    <div className="simpleGlobalListContainer">
      <Typography variant="route-title" className="pb-2">
        {title}
      </Typography>
      <CardsTable
        columns={columns}
        data={data}
        onEditRowClick={onEditRowClick}
        onAddRowClick={onAddRowClick}
      />
      <SimpleGlobalListItemModal
        show={showEditModal}
        routeApiPath={routeApiPath}
        onHide={onHideEditModal}
        initialValues={editIndex !== undefined ? data?.[editIndex] : undefined}
      />
    </div>
  );
};
