import { useContext, useEffect } from "react";
import { FullScreenLoaderContext } from "full-screen-loader/FullScreenLoaderContext";

export const useFullScreenLoader = (show: boolean) => {
  const { onDisplayStatuseChange } = useContext(FullScreenLoaderContext)!;

  useEffect(() => {
    onDisplayStatuseChange(show ? "show" : "hide");
  }, [show, onDisplayStatuseChange]);
};
