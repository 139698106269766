import React, { useState } from "react";
import { debounce, isEqual } from "lodash";
import { Form as RFFForm, FormProps as RFFFormProps } from "react-final-form";
import arrayMutators from "final-form-arrays";

export type FormProps<FormValues extends object> = RFFFormProps<FormValues>;

const useDevFormLogger = () => {
  if (process.env.NODE_ENV === "development") {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useState(() => debounce(console.log, 200))[0];
  } else {
    return undefined;
  }
};

export const Form = <FormValues extends object>({
  ...formProps
}: FormProps<FormValues>) => {
  const debugFN = useDevFormLogger();

  return (
    <RFFForm
      debug={debugFN}
      validate={async (values) => {
        return;
      }}
      subscription={{}}
      mutators={{
        ...arrayMutators,
      }}
      // by default allow updating async the data from multiple places outside form.
      // and dont override the dirty fields
      keepDirtyOnReinitialize
      initialValuesEqual={isEqual}
      {...formProps}
      initialValues={formProps.initialValues ?? {}}
    />
  );
};
