import React, { useCallback, useEffect, useMemo } from "react";
import "./styles.scss";
import { OrderInlineAmounter } from "order/components/PickIngredientItem/OrderInlineAmounter/OrderInlineAmounter";
import { NumberFormatter, UnitTypeFormatter } from "base/utils/formatters";
import { BaseImage } from "base/components/BaseFoodImage";
import { debounce } from "lodash";
import Axios from "axios";
import {
  useCustomQuery,
  useCustomQueryWithParams,
  CustomQueryResponse,
} from "base/api/hooks/useCustomQuery";
import { SuppliersApi } from "supplier/SuppliersApi";
import { useSelectOptionsAdapter } from "base/components/Select/hooks/useSelectOptionsAdapter";
import { Select } from "base/components/Select";
import { FileDir, BaseSelectListItem, UnitType } from "base/types";
import { OrderIngredientItem } from "order/types";
import { useBooleanState } from "base/hooks/useBooleanState";
import { BaseSupplyMethod } from "base/types/BaseSupplyMethod";
import { SupplyMethodDescFormatter } from "order/SupplyMethodDescFormatter";
import classNames from "classnames";
import { SupplyMethodPicker } from "supply-methods/SupplyMethodPicker";
import { OrderHelper } from "order/OrderHelper";
import { SimpleGlobalListApi } from "global-list/SimpleGlobalListApi";
import { SimpleGlobalListRoutePath } from "global-list/types";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { format } from "date-fns";
import { TextFormatter } from "base/utils/formatters/TextFormatter";
import { useImmer } from "use-immer";
import { current } from "immer";
import { Collapse } from "react-bootstrap";

export type PickIngredientItemProps = {
  item: OrderIngredientItem;
  onChanged?: (arg: {
    amount: number;
    supplierId: number;
    supplyMethod: BaseSupplyMethod | undefined;
  }) => void;
};

export const PickIngredientItem: React.FC<PickIngredientItemProps> = ({
  item,
  onChanged,
}) => {
  const {
    productId,
    requestedAmount: amount,
    supplyMethod: initialSupplyMethod,
    requestedPrice: initialRequestedPrice,
  } = item;
  const supplierId = item.supplierModel.id;
  const [isExpanded, , , onToggle] = useBooleanState(false);

  const { data: suppliers } = useCustomQuery(SuppliersApi.getList);
  const suppliersOptions = useSelectOptionsAdapter(suppliers);
  const {
    data: unitKinds,
  } = useCustomQueryWithParams(SimpleGlobalListApi.getList, () => [
    SimpleGlobalListRoutePath.UnitKinds,
  ]) as CustomQueryResponse<BaseSelectListItem[]>;
  const {
    data: packageKinds,
  } = useCustomQueryWithParams(SimpleGlobalListApi.getList, () => [
    SimpleGlobalListRoutePath.PackageKinds,
  ]) as CustomQueryResponse<BaseSelectListItem[]>;

  const orderHistoryAverage = useMemo(() => {
    if (item.orderHistory.length === 0) return 0;

    const sumAmount = item.orderHistory.reduce(
      (sum, his) => sum + his.amount,
      0
    );

    return sumAmount / item.orderHistory.length;
  }, [item.orderHistory]);

  const [state, updateState] = useImmer({
    amount,
    supplierId,
    supplyMethod: initialSupplyMethod,
    requestedPrice: initialRequestedPrice as number | undefined,
  });

  const [
    showSupplyMethodPicker,
    onDisplaySupplyMethodPicker,
    onHideSupplyMethodPicker,
  ] = useBooleanState(false);

  const { supplyMethod } = state;

  const updateServer = useCallback(
    debounce(
      async (nextState: typeof state) => {
        onChanged?.(nextState);

        await Axios.post("/api/orders/addToOrder", {
          productId,
          ...nextState,
        });
      },
      250,
      { trailing: true, maxWait: 700 }
    ),
    [productId]
  );

  const onAmountChange = useCallback(
    (nextAmount: number) => {
      if (nextAmount >= 0) {
        updateState((s) => {
          s.amount = nextAmount;

          // no await. update in background.
          updateServer(current(s));
        });
      }
    },
    [updateServer, updateState]
  );

  const onSupplierChange = useCallback(
    (nextSupplierId) => {
      updateState((s) => {
        s.supplierId = nextSupplierId;

        // no await. update in background.
        updateServer(current(s));
      });
    },
    [updateServer, updateState]
  );

  const onSupplyMethodPick = useCallback(
    (
      nextSelectedSupplyMethod: BaseSupplyMethod,
      supplierId?: number,
      requestedPrice?: number
    ) => {
      onHideSupplyMethodPicker();
      updateState((s) => {
        s.supplierId = supplierId ?? s.supplierId;
        s.supplyMethod = nextSelectedSupplyMethod;
        s.requestedPrice = requestedPrice;

        // no await. update in background.
        updateServer(current(s));
      });
    },
    [onHideSupplyMethodPicker, updateServer, updateState]
  );

  useEffect(() => {
    updateState((s) => {
      s.amount = amount;
      s.supplierId = supplierId;
    });
  }, [amount, supplierId, updateState]);

  return (
    <>
      <div
        className={classNames("pickIngredientItem", {
          inactive: false,
        })}
      >
        <div>
          <div>
            <div className="item-header">{item.name}</div>
            <div className="item-image">
              <BaseImage
                fileName={item.imageName}
                fileDir={FileDir.BaseFood}
                height="50"
              />
            </div>
          </div>
          <div>
            <div onClick={onToggle}>
              <i
                className={classNames("fas fa-chevron-left ml-1", {
                  "fa-rotate-270": isExpanded,
                })}
              />
              <span className="font-weight-bold">פרטים נוספים</span>
            </div>
            <Collapse in={isExpanded}>
              <div className="mr-2">
                <div className="expanded-section-content">
                  <div className="info-row">
                    <div>מלאי עדכני:</div>
                    <div>
                      {NumberFormatter.formatAmount(item.currentStock, false, {
                        applyUSLocaleStr: true,
                      })}
                    </div>
                  </div>
                </div>
                {/* order recommendation */}

                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip
                      id={`order-history-tooltip-${item.productId}`}
                      className="order-history-tooltip"
                      hidden={item.orderHistory.length === 0}
                    >
                      {item.orderHistory.map(({ amount, date }, index) => (
                        <div key={index} className="py-1">
                          {TextFormatter.format("תאריך: $1, כמות בהזמנה: $2", [
                            format(new Date(date), "dd/MM/yyyy"),
                            NumberFormatter.default.formatAmount(amount),
                          ])}
                        </div>
                      ))}
                    </Tooltip>
                  }
                >
                  <div>
                    <div className="info-row">
                      <div>מומלץ להזמין:</div>
                      <div>
                        {NumberFormatter.default.formatAmount(
                          orderHistoryAverage
                        )}
                      </div>
                    </div>
                  </div>
                </OverlayTrigger>
              </div>
            </Collapse>
          </div>
        </div>
        <div>
          <div className="supplyInfo">
            <div className="text-center my-1 font-weight-bold d-flex w-100  align-items-center flex-wrap">
              <div
                className={`flex-grow-1  ${state.amount > 0 ? "disabled" : ""}`}
              >
                <Select
                  onChange={onSupplierChange}
                  options={suppliersOptions}
                  value={state.supplierId}
                />
              </div>
            </div>
          </div>
          <OrderInlineAmounter
            value={state.amount}
            supplyMethod={supplyMethod}
            unitKinds={unitKinds}
            packageKinds={packageKinds}
            prepareUnitType={item.prepareUnitType}
            onChange={onAmountChange}
          />
          <div className="info-row mt-2">
            <div>מומלץ להזמין:</div>
            <div>
              {NumberFormatter.default.formatAmount(orderHistoryAverage)}
            </div>
          </div>
          <div className="info-row">
            <div>סה"כ עלות:</div>
            <div>
              {NumberFormatter.default.formatAmount(orderHistoryAverage)}
            </div>
          </div>
        </div>
      </div>
      <SupplyMethodPicker
        show={showSupplyMethodPicker}
        supplyMethods={item.supplyMethods}
        prepareUnitType={item.prepareUnitType}
        supplierIdFilter={state.amount > 0 ? state.supplierId : undefined}
        onPick={onSupplyMethodPick}
        onHide={onHideSupplyMethodPicker}
      />
    </>
  );
};
