import React, { useState, useCallback, useEffect } from "react";
import { debounce } from "lodash";
import { RouteContent } from "base/components/RouteContent";
import { PickIngredients } from "order/components/PickIngredients";
import { useCustomQueryWithParams } from "base/api/hooks/useCustomQuery";
import { OrdersApi } from "order/OrdersApi";
import { OrderWizardTopBar } from "order/components/OrderWizardTopBar";
import { useOrderWizardContext } from "order/context/hooks/useOrderWizardContext";
import { useObserver } from "mobx-react";
import { OrdersRouteBuilder } from "order/OrdersRouteBuilder";

export const OrderSearchProductsScreen: React.FC = () => {
  const { store } = useOrderWizardContext();
  const searchPhrase =
    useObserver(
      () =>
        store.routeSearchPhrases[
          OrdersRouteBuilder.buildOrderProductsSearchRoute()
        ]
    ) ?? "";
  const [debouncedSearch, setDebouncedSearch] = useState("");

  // Fetch ingredients, skip if less than 2 letters are being searched.
  const { data: ingredients = [] } = useCustomQueryWithParams(
    OrdersApi.searchIngredients,
    () => [debouncedSearch],
    {
      skip: debouncedSearch.length < 2,
    }
  );

  // Make sure we set debounceSearch once per 500 mils.
  const onSetDebouncedSearch = useCallback(
    debounce(setDebouncedSearch, 500),
    []
  );

  // Sync debouncedSearch.
  useEffect(() => {
    onSetDebouncedSearch(searchPhrase);
  }, [onSetDebouncedSearch, searchPhrase]);

  return (
    <RouteContent className="OrderSearchProductsScreen">
      <OrderWizardTopBar />
      <PickIngredients ingredients={ingredients} />
    </RouteContent>
  );
};
