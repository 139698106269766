import React, { useMemo, useCallback } from "react";
import {
  CardsTable,
  CardsTableProps,
} from "base/components/tables/components/CardsTable";
import { SupplierOrderListItem } from "supplier/types";
import { TableDateCell } from "base/components/tables/components/TableDateCell";
import { OrderStatusFormatter } from "base/utils/formatters";
import { OrdersRouteBuilder } from "order/OrdersRouteBuilder";
import { useNavigateCallback } from "base/hooks/useNavigateCallback";
import { SuppliersApi } from "supplier/SuppliersApi";
import { useCustomQueryWithParams } from "base/api/hooks/useCustomQuery";
import { SentOrderFormatters } from "sent-order/utils/OrderFormatters";
import { SentOrdersRouteBuilder } from "sent-order/SentOrdersRouteBuilder";
import { TableAmountCell } from "base/components/tables/components/TableAmountCell";

export type SupplierOrdersTableProps = {
  supplierId: number;
};

export const SupplierOrdersTable: React.FC<SupplierOrdersTableProps> = ({
  supplierId,
}) => {
  const navigate = useNavigateCallback();

  const { data } = useCustomQueryWithParams(SuppliersApi.getOrdersList, () => [
    supplierId,
  ]);

  const onEditRowClick = useCallback(
    (data: SupplierOrderListItem) => {
      if (data.status === 0) {
        navigate(OrdersRouteBuilder.buildEditOrderRoute(data.id));
      } else {
        navigate(
          SentOrdersRouteBuilder.buildEditSentOrderRoute(data.id, "advanced")
        );
      }
    },
    [navigate]
  );

  const columns = useMemo<CardsTableProps<SupplierOrderListItem>["columns"]>(
    () => [
      {
        id: "id",
        accessor: "id",
        Header: "מזהה",
      },
      {
        id: "totalItems",
        accessor: "totalItems",
        Header: "כמות פריטים",
      },
      {
        id: "status",
        accessor: "status",
        Header: "סטטוס",
        Cell: ({ value }) => OrderStatusFormatter.format(value),
      },
      {
        id: "receiveDate",
        accessor: "receiveDate",
        Header: "תאריך מסמך",
        Cell: TableDateCell,
      },
      {
        id: "submitDate",
        accessor: "submitDate",
        Header: "תאריך שיגור",
        Cell: TableDateCell,
      },
      {
        id: "referenceNo",
        accessor: "referenceNo",
        Header: "מס' אסמכתא",
      },
      {
        id: "orderDocumentType",
        accessor: ({ orderDocumentType }) =>
          SentOrderFormatters.formatDocumentType(orderDocumentType),
        Header: "סוג מסמך",
      },
      {
        id: "totalPrice",
        accessor: "totalPrice",
        Header: 'סה"כ מחיר',
        Cell: TableAmountCell,
      },
      {
        id: "table-closer",
        Header: "",
        cellProps: {
          className: "table-closer",
        },
        disableSortBy: true,
        disableGlobalFilter: true,
      },
    ],
    []
  );

  return (
    <CardsTable
      columns={columns}
      data={data}
      globalFilterAllowedCols={["status"]}
      onEditRowClick={onEditRowClick}
      searchable={false}
    />
  );
};
