import React, { HTMLAttributes } from "react";
import { Field } from "react-final-form";
import { NumberInputFieldInner } from "./NumberInputFieldInner";
import { FieldValidator } from "final-form";
import { FormControlProps } from "react-bootstrap";

export type FormNumberInputProps = {
  name: string;
  placeholder?: string;
  className?: string;
  autoFocus?: boolean;
  selectOnFocus?: boolean;
  step?: string;
  required?: boolean;
  maxDigitsAfterDot?: number;
  validate?: FieldValidator<any>;
  displayNumpadOnFocus?: boolean;
} & Partial<FormControlProps> &
  HTMLAttributes<HTMLInputElement>;

export const FormNumberInput: React.FC<FormNumberInputProps> = ({
  name,
  required,
  validate,
  ...numberInputProps
}) => {
  return (
    <Field
      name={name}
      component={NumberInputFieldInner}
      validate={
        validate ??
        ((x, _, meta) => {
          if (required && x === undefined) {
            return Promise.resolve("שדה חובה");
          }
        })
      }
      {...numberInputProps}
    />
  );
};
