import React, { ImgHTMLAttributes, VideoHTMLAttributes } from "react";
import { FileDir } from "base/types";
import { BaseImage } from "../BaseFoodImage";
import { BaseVideo } from "../BaseVideo";

export type FileViewerProps = {
  type: "image" | "video";
  fileName?: string;
  fileDir?: FileDir;
  imageProps?: ImgHTMLAttributes<HTMLImageElement>;
  videoProps?: VideoHTMLAttributes<HTMLVideoElement>;
};

export const FileViewer: React.FC<FileViewerProps> = ({
  type,
  fileName,
  fileDir,
  imageProps,
  videoProps,
}) => {
  if (type === "image") {
    return <BaseImage fileName={fileName} fileDir={fileDir} {...imageProps} />;
  } else {
    return <BaseVideo fileName={fileName} fileDir={fileDir} {...videoProps} />;
  }
};
