import React from "react";
import {
  FormSelect,
  FormSelectProps,
} from "base/components/form-controls/FormSelect";
import { UnitType } from "base/types";

export type FormUnitTypeSelectProps<FormValues> = Omit<
  FormSelectProps<FormValues, number | undefined>,
  "data"
> & {
  isBigSize?: boolean;
};

export const FormUnitTypeSelect = <FormValues,>(
  props: FormUnitTypeSelectProps<FormValues>,
  isBigSize
) => {
  const data = [
    { value: UnitType.KG, label: isBigSize ? "KG" : "GR" },
    { value: UnitType.Liter, label: isBigSize ? "LT" : "ML" },
    { value: UnitType.Yeh, label: isBigSize ? "Package" : "Unit" },
  ];

  return <FormSelect emptyOptionLabel="בחר יחידה" {...props} data={data} />;
};
