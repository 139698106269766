import React from "react";
import { ContractExistingItemEditor } from "./ContractExistingItemEditor";
import { ContractNewItemEditor } from "./ContractNewItemEditor";

export type ContractEditorProps = {
  id?: number;
};

export const ContractEditor: React.FC<ContractEditorProps> = ({ id }) => {
  if (id) {
    return <ContractExistingItemEditor id={id} />;
  } else {
    return <ContractNewItemEditor />;
  }
};
