import { RouteContent } from "base/components/RouteContent";
import React from "react";
import { DetailedOrderCard } from "order/components/DetailedOrderCard";
import { useCustomQuery } from "base/api/hooks/useCustomQuery";
import { OrdersApi } from "order/OrdersApi";

export const OrdersStatusScreen: React.FC = () => {
  const { data: detailedOrders } = useCustomQuery(OrdersApi.getDetailedList);
  return (
    <RouteContent className="OrdersStatusScreen">
      <div className="top-bar">
        <div className="title">
          <span>סטטוס הזמנות</span>
          <i className="fas fa-shopping-cart" />
        </div>
        <div className="nav">
          <div className="item active">הזמנות לאישור</div>
          <div className="item">הזמנות בדרך</div>
        </div>
      </div>
      <div>
        {detailedOrders?.map((detailedOrder) => (
          <DetailedOrderCard
            detailedOrder={detailedOrder}
            key={detailedOrder.orderId}
          />
        ))}
      </div>
    </RouteContent>
  );
};
