import React from "react";
import { FormContextValues, Controller, ErrorMessage } from "react-hook-form";
import { FormControl } from "react-bootstrap";
import { FormErrorMessage } from "../FormErrorMessage";
import "./style.scss";

type ValidValueType = number | string | undefined;

export type FormSelectProps<
  FormValues,
  ValueType extends ValidValueType = ValidValueType
> = {
  name: KeysByType<FormValues, ValueType>;
  formInstance: FormContextValues<FormValues>;
  data: Array<{
    value: undefined extends ValueType
      ? Exclude<ValueType, undefined>
      : ValueType;
    label: string;
  }>;
  displayEmptyOption?: boolean;
  emptyOptionLabel?: string;
  applyCustomSelectClass?: boolean;
  className?: string;
};

export const FormSelect = <
  FormValues,
  ValueType extends ValidValueType = ValidValueType
>({
  name,
  formInstance,
  data,
  className,
  displayEmptyOption = true,
  emptyOptionLabel = "בחר",
  applyCustomSelectClass = true,
}: FormSelectProps<FormValues, ValueType>) => {
  const { errors, control } = formInstance;

  return (
    <>
      <Controller
        as={
          <FormControl
            as="select"
            custom={applyCustomSelectClass}
            className={className}
          >
            {displayEmptyOption && <option value="">{emptyOptionLabel}</option>}
            {data.map((item) => (
              <option key={item.value as any} value={item.value}>
                {item.label}
              </option>
            ))}
          </FormControl>
        }
        name={name as string}
        control={control}
        defaultValue={displayEmptyOption ? undefined : data[0]?.value}
      />
      <div>
        {
          <ErrorMessage
            as={FormErrorMessage}
            name={name as string}
            errors={errors}
          />
        }
      </div>
    </>
  );
};
