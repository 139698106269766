import React, { useCallback, useState } from "react";
import { FormSpy, Field } from "react-final-form";
import { Col, Row } from "react-bootstrap";
import { debounce, isEqual } from "lodash";
import {
  useCustomQueryWithParams,
  useCustomQuery,
} from "base/api/hooks/useCustomQuery";
import { TreeType, UnitType } from "base/types";
import { useNavigateCallback } from "base/hooks/useNavigateCallback";
import { useBooleanState } from "base/hooks/useBooleanState";
import { useSelectOptionsAdapter } from "base/components/Select/hooks/useSelectOptionsAdapter";
import { Form } from "form/components/Form";
import { FormTextInput } from "form/components/FormTextInput";
import { FormSelect } from "form/components/FormSelect";
import { FormNumberInput } from "form/components/FormNumberInput";
import { FormCheckbox } from "form/components/FormCheckbox";
import { FormFileUpload } from "form/components/FormFileUpload";
import { FormFileViewer } from "form/components/FormFileViewer";
import { FormValuesWithUpload } from "form/types";
import { RecipeModal } from "recipe/components/RecipeModal";
import { Dish } from "dish/types";
import { DishesApi } from "dish/DishesApi";
import { DishesRouteBuilder } from "dish/DishesRouteBuilder";
import { TreeFoodWorksModal } from "food-works/tree-food-works/components/TreeFoodWorksModal";
import "./../styles.scss";
import { TreeInfo } from "tree-info/types";
import { NumberFormatter } from "base/utils/formatters";
import { AllergensModal } from "allergen/AllergensModal";
import { AllergenRelationType } from "allergen/types";
import { NutritionalsModal } from "nutritional/NutritionalsModal";
import { NutritionalRelationType } from "nutritional/types";
import { TreeInfoHelper } from "tree-info/TreeInfoHelper";

export type TreeInfoDishHeaderProps = {
  itemId: number;
};

export const TreeInfoDishHeader: React.FC<TreeInfoDishHeaderProps> = ({
  itemId,
}) => {
  const navigate = useNavigateCallback();
  const { data } = useCustomQueryWithParams(DishesApi.getItem, () => [itemId]);
  const { data: departments } = useCustomQuery(DishesApi.getDepartments);
  const departmentOptions = useSelectOptionsAdapter(departments);
  const [totalAmount, setTotalAmount] = useState(0);

  const [showFoodWorks, onShowFoodWorks, onHideFoodWorks] = useBooleanState(
    false
  );

  const [showAllergens, onShowAllergens, onHideAllergens] = useBooleanState(
    false
  );

  const [
    showNutritonals,
    onShowNutritonals,
    onHideNutritonals,
  ] = useBooleanState(false);

  const [showRecipe, onShowRecipe, onHideRecipe] = useBooleanState(false);

  const onAddRecipe = useCallback(async () => {
    await DishesApi.addRecipe(itemId);
    onShowRecipe();
  }, [itemId, onShowRecipe]);

  const onSubmit = useCallback(
    debounce(
      async (nextFormValues: FormValuesWithUpload<Dish>) => {
        const { image, ...nextDish } = nextFormValues;
        await DishesApi.saveItem(nextDish, image);
      },
      200,
      { maxWait: 350 }
    ),
    []
  );

  const onDelete = useCallback(async () => {
    if (window.confirm("האם אתה בטוח?")) {
      onSubmit.cancel();
      try {
        await DishesApi.deleteItem(itemId);
        navigate(DishesRouteBuilder.buildDishesRoute());
      } catch (e) {
        alert(`לא ניתן למחוק פריט זה: \n${e?.response?.data}`);
      }
    }
  }, [itemId, navigate, onSubmit]);

  return (
    <>
      {/* Calculate the totalAmount, and save to state. */}
      <FormSpy<Partial<TreeInfo>>
        subscription={{ values: true }}
        onChange={({ values }) => {
          const totalAmount = [
            ...(values.ingredients ?? []),
            ...(values.materials ?? []),
          ].reduce((total, item) => {
            const { basicPrice = 0, amount = 0, foodWorks, unitType } = item;

            const amountAfterFoodWorks = TreeInfoHelper.calcAmountAfterFoodWorks(
              amount,
              foodWorks
            );
            const priceAfterFoodWorks = TreeInfoHelper.calcPriceAfterFoodWorks(
              basicPrice,
              foodWorks
            );

            const value = amountAfterFoodWorks * priceAfterFoodWorks;

            const normalizedValue =
              unitType !== UnitType.Yeh ? value / 1000 : value;

            return total + normalizedValue;
          }, 0);

          setTotalAmount(totalAmount);
        }}
      />
      <Form onSubmit={onSubmit} initialValues={data}>
        {({ handleSubmit, form }) => (
          <>
            <Row noGutters className="tree-info-header-container">
              <Col sm={8}>
                <Row noGutters>
                  <Col sm={4} className="p-1">
                    <FormTextInput name="name" placeholder="שם המרכיב" />
                  </Col>
                  <Col sm={5} className="p-1">
                    <Row>
                      <label className="col-sm-7 col-form-label">
                        מחיר תפריט
                      </label>
                      <div className="col-sm-5">
                        <FormNumberInput
                          name="menuPrice"
                          placeholder="מחיר תפריט"
                        />
                      </div>
                    </Row>
                  </Col>
                  <Col sm={4} className="p-1">
                    <FormSelect
                      name="departmentId"
                      emptyOptionLabel="בחר מחלקה"
                      options={departmentOptions}
                    />
                  </Col>
                  <Col sm={5} className="p-1">
                    <Row>
                      <label className="col-sm-7 col-form-label">
                        זמן הרכבה
                      </label>
                      <div className="col-sm-5">
                        <FormNumberInput
                          name="assemblingTime"
                          placeholder="דקות"
                        />
                      </div>
                    </Row>
                  </Col>
                  <Col sm={0} md={2} /> {/* Placeholder */}
                  <Col sm={4} md={2} className="p-1">
                    <div className="d-flex align-items-center">
                      <span>פעיל:&nbsp;&nbsp;</span>
                      <div style={{ width: 40 }}>
                        <FormCheckbox name="isActive" />
                      </div>
                    </div>
                  </Col>
                  <Col sm={4} className="p-1 d-flex align-items-center">
                    <Field
                      name="menuPrice"
                      render={({ input: { value: menuPrice } }) => (
                        <span>
                          עלות מרכיבים:{" "}
                          {NumberFormatter.default.formatPrice(totalAmount)} (
                          {NumberFormatter.format(
                            (totalAmount / menuPrice) * 100
                          )}
                          %)
                        </span>
                      )}
                    />
                  </Col>
                </Row>
              </Col>
              <Col sm={2}>
                <FormFileUpload name="image" />
                <div className="w-75 mt-2">
                  <FormFileViewer uploadName="image" pathName="imageName" />
                </div>
              </Col>
              <Col sm={2}>
                <Row noGutters>
                  {data?.recipeId === undefined && (
                    <div
                      className="btn w-100 m-1 btn-success"
                      onClick={onAddRecipe}
                    >
                      הוספת מתכון
                    </div>
                  )}
                  {data?.recipeId !== undefined && (
                    <div
                      className="btn w-100 m-1 btn-primary"
                      onClick={onShowRecipe}
                    >
                      ניהול מתכון
                    </div>
                  )}
                  <div
                    className="btn btn-primary w-100 m-1"
                    onClick={onShowFoodWorks}
                  >
                    ניהול עיבודים
                  </div>
                  <div
                    className="btn btn-primary w-100 m-1"
                    onClick={onShowAllergens}
                  >
                    ניהול אלרגנים
                  </div>
                  <div
                    className="btn btn-primary w-100 m-1"
                    onClick={onShowNutritonals}
                  >
                    ניהול ערכים תזונתיים
                  </div>
                  <div className="btn btn-danger w-100 m-1" onClick={onDelete}>
                    מחיקה
                  </div>
                </Row>
              </Col>
            </Row>
            <FormSpy
              subscription={{
                validating: true,
                initialValues: true,
              }}
              onChange={({ validating }) => {
                const formState = form.getState();
                const { submitting, valid } = formState;
                if (validating || submitting || !valid) {
                  return;
                }

                const { initialValues, values, dirty } = formState;
                if (dirty && !isEqual(initialValues, values)) {
                  handleSubmit();
                }
              }}
            />
            <TreeFoodWorksModal
              show={showFoodWorks}
              treeId={itemId}
              treeType={TreeType.Dish}
              onCancel={onHideFoodWorks}
            />
            <RecipeModal
              treeType={TreeType.Dish}
              show={showRecipe}
              onHide={onHideRecipe}
              id={data?.recipeId}
            />
            <AllergensModal
              show={showAllergens}
              relationType={AllergenRelationType.Food}
              relationId={itemId}
              relationName={data?.name}
              onCancel={onHideAllergens}
            />
            <NutritionalsModal
              show={showNutritonals}
              relationType={NutritionalRelationType.Food}
              relationId={itemId}
              relationName={data?.name}
              onCancel={onHideNutritonals}
            />
          </>
        )}
      </Form>
    </>
  );
};
