import React from "react";
import { useParams } from "react-router-dom";
import { RouteContent } from "base/components/RouteContent";
import { Typography } from "base/components/Typography";
import { useCustomQueryWithParams } from "base/api/hooks/useCustomQuery";
import { OrdersApi } from "order/OrdersApi";
import { PickIngredients } from "order/components/PickIngredients";

export const DepartmentScreen: React.FC = () => {
  const { departmentId } = useParams();
  const { data } = useCustomQueryWithParams(OrdersApi.getDepartment, () => [
    departmentId,
  ]);

  return (
    <RouteContent>
      <Typography variant="route-title">
        מחלקה: {data?.department?.name}
      </Typography>
      <PickIngredients ingredients={data?.ingredients} />
    </RouteContent>
  );
};
