import React, { useCallback, useMemo } from "react";
import { Grid } from "@material-ui/core";
import { Button } from "react-bootstrap";
import { FormApi } from "final-form";
import { useForm, FormSpy } from "react-final-form";
import { useFieldArray } from "react-final-form-arrays";
import { isEqual, debounce } from "lodash";
import { Typography } from "base/components/Typography";
import { Form } from "form/components/Form";
import { FormNumberInput } from "form/components/FormNumberInput";
import { FormTextInput } from "form/components/FormTextInput";
import { Spacer } from "base/components/Spacer";
import { TreeItemFoodWorksFormValues } from "../types/TreeItemFoodWorksFormValues";
import { TreeItemFoodWork } from "../types/TreeItemFoodWork";
import { TreeItemFoodWorksApi } from "../TreeItemFoodWorksApi";
import "food-works/styles.scss";
import { TextFormatter } from "base/utils/formatters/TextFormatter";
import { UnitTypeFormatter } from "base/utils/formatters";
import { Ingredient } from "ingredient/types";
import { Material } from "material/types";

export const TreeItemFoodWorksForm: React.FC = () => {
  const form = useForm<TreeItemFoodWorksFormValues>();

  const { fields: existingFoodWorksFields } = useFieldArray<TreeItemFoodWork>(
    "foodWorks",
    {
      isEqual,
      subscription: {},
    }
  );

  const {
    treeType,
    treeItemType,
    treeItemId,
    foodWorks,
    item,
  } = form.getState().values;

  const onAddFoodWork = useCallback(
    async (
      newFoodWork: Omit<TreeItemFoodWork, "id" | "isRequired">,
      newFoodWorkform: FormApi<Omit<TreeItemFoodWork, "id" | "isRequired">>
    ) => {
      await TreeItemFoodWorksApi.addFoodWork(
        treeType,
        treeItemType,
        treeItemId,
        newFoodWork
      );
      Object.keys(newFoodWorkform.getState().touched || {}).forEach(
        newFoodWorkform.resetFieldState as any
      );
      setTimeout(newFoodWorkform.reset);
    },
    [treeItemId, treeItemType, treeType]
  );

  const onRowChanged = useMemo(
    () =>
      debounce(async (foodWork: TreeItemFoodWork) => {
        await TreeItemFoodWorksApi.updateFoodWork(
          treeType,
          treeItemType,
          treeItemId,
          foodWork
        );
      }, 500),
    [treeItemId, treeItemType, treeType]
  );

  const onRowDeleted = useCallback(
    async (index: number) => {
      const foodWorkId = foodWorks[index].id;
      existingFoodWorksFields.remove(index);
      await TreeItemFoodWorksApi.deleteFoodWork(
        treeType,
        treeItemType,
        treeItemId,
        foodWorkId
      );
    },
    [existingFoodWorksFields, foodWorks, treeItemId, treeItemType, treeType]
  );

  return (
    <>
      <Typography variant="description" fontSize={20} bold>
        הוספת עיבוד חדש
      </Typography>
      <Spacer />
      <Form
        onSubmit={onAddFoodWork}
        subscription={{ pristine: true, submitting: true }}
        keepDirtyOnReinitialize={false}
      >
        {({ pristine, handleSubmit, submitting }) => (
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <FormTextInput placeholder="שם העיבוד" name="name" required />
            </Grid>
            <Grid item xs={2}>
              <FormNumberInput
                placeholder="פחת באחוזים"
                name="depreciation"
                required
              />
            </Grid>
            <Grid item xs={2}>
              <FormNumberInput
                placeholder="(זמן (דק"
                name="duration"
                required
              />
            </Grid>
            <Grid item xs={3}>
              <FormNumberInput
                name="cost"
                placeholder={TextFormatter.format("(עלות חומרים ($1", [
                  UnitTypeFormatter.format(
                    treeItemType === "ingredient"
                      ? (item as Ingredient)?.prepareUnitType
                      : (item as Material)?.unitType
                  ),
                ])}
                required
              />
            </Grid>
            <Grid item xs={1}>
              <Button
                variant="primary"
                onClick={() => {
                  handleSubmit();
                }}
                disabled={pristine || submitting}
                size="sm"
                style={{ height: 38 }}
              >
                הוספה
              </Button>
            </Grid>
          </Grid>
        )}
      </Form>
      <Spacer units={4} />
      <Typography variant="description" fontSize={20} bold>
        עיבודים קיימים
      </Typography>
      <Spacer />
      {existingFoodWorksFields.length === 0 && <span>אין עיבודים קיימים</span>}
      {existingFoodWorksFields.map((prefix, index) => {
        const foodWorkId = foodWorks[index].id;
        return (
          <React.Fragment key={foodWorkId}>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <div className="d-flex align-items-center h-100">
                  <span>{index + 1}</span>
                  <div className="d-flex justify-content-center flex-grow-1">
                    {foodWorks[index].name}
                  </div>
                </div>
              </Grid>
              <Grid item xs={2}>
                <FormNumberInput
                  placeholder="פחת באחוזים"
                  name={`${prefix}.depreciation`}
                  required
                />
              </Grid>
              <Grid item xs={2}>
                <FormNumberInput
                  placeholder="(זמן (דק"
                  name={`${prefix}.duration`}
                  required
                />
              </Grid>
              <Grid item xs={3}>
                <FormNumberInput
                  name={`${prefix}.cost`}
                  placeholder={TextFormatter.format("(עלות חומרים ($1", [
                    UnitTypeFormatter.format(
                      treeItemType === "ingredient"
                        ? (item as Ingredient)?.prepareUnitType
                        : (item as Material)?.unitType
                    ),
                  ])}
                  required
                />
              </Grid>
              <Grid item xs={2}>
                <FormSpy<Partial<TreeItemFoodWorksFormValues>>
                  subscription={{
                    values: true,
                  }}
                  render={({ values, initialValues, dirtyFields }) => {
                    const row = values.foodWorks?.find(
                      (x) => x.id === foodWorkId
                    )!;

                    if (row.isRequired) {
                      return (
                        <div
                          className="d-flex align-items-center h-100"
                          title="עיבוד חובה"
                        >
                          <span className="badge badge-warning"> חובה</span>
                        </div>
                      );
                    }

                    return (
                      <Button
                        variant="danger"
                        onClick={() => onRowDeleted(index)}
                        size="sm"
                        style={{ height: 38 }}
                      >
                        מחיקה
                      </Button>
                    );
                  }}
                />
              </Grid>
            </Grid>
            <FormSpy<Partial<TreeItemFoodWorksFormValues>>
              subscription={{
                values: true,
                initialValues: true,
              }}
              onChange={async ({ values, initialValues }) => {
                const nextRowValue = values.foodWorks?.find(
                  (x) => x.id === foodWorkId
                );
                const prevRowValue = initialValues.foodWorks?.find(
                  (x) => x.id === foodWorkId
                );

                if (
                  nextRowValue &&
                  prevRowValue &&
                  !isEqual(nextRowValue, prevRowValue)
                ) {
                  await onRowChanged(nextRowValue!);
                }
              }}
            />
          </React.Fragment>
        );
      })}
    </>
  );
};
