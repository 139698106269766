import axios from "axios";
import { BaseSelectListItem, FoodTreeListItem } from "base/types";
import { TreeType } from "base/types/TreeType";
import { TreeApiPathFormatter } from "base/utils/formatters/TreeApiPathFormatter";
import { BasicTreeInfoListSearchOptions } from "./types";

export class BasicTreeInfoApi {
  static getDepartments(type: TreeType) {
    return axios.get<BaseSelectListItem[]>(
      `/api/${TreeApiPathFormatter.format(type)}/departments`
    );
  }

  static getFoodTreeList(
    type: TreeType,
    { isIngred, isMaterial, searchTerm = "" }: BasicTreeInfoListSearchOptions
  ) {
    return axios.get<FoodTreeListItem[]>(
      `/api/${TreeApiPathFormatter.format(type)}/search`,
      {
        params: {
          isIngred,
          isMat: isMaterial,
          phrase: searchTerm ?? "",
        },
      }
    );
  }
}
