import React, { useCallback } from "react";
import { IngredientsApi } from "ingredient/IngredientsApi";
import { IngredientEditTabFields } from "ingredient/components/IngredientEditTabFields";
import { useBooleanState } from "base/hooks/useBooleanState";
import { SingleItemFoodWorksModal } from "food-works/single-item-food-works/components/SingleItemFoodWorksModal";
import { Spacer } from "base/components/Spacer";
import { Button } from "react-bootstrap";
import { useNavigateCallback } from "base/hooks/useNavigateCallback";
import { IngredientsRouteBuilder } from "ingredient/IngredientsRouteBuilder";

export type IngredientEditTabProps = {
  id: number;
};

export const IngredientEditTab: React.FC<IngredientEditTabProps> = ({ id }) => {
  const navigate = useNavigateCallback();

  const [showFoodWorks, onShowFoodWorks, onHideFoodWorks] = useBooleanState(
    false
  );

  const onDelete = useCallback(async () => {
    if (window.confirm("האם אתה בטוח?")) {
      try {
        await IngredientsApi.deleteItem(id);
        navigate(IngredientsRouteBuilder.buildIngredientsRoute());
      } catch (e) {
        alert(e?.response?.data ?? "לא ניתן למחוק את הפריט");
      }
    }
  }, [id, navigate]);

  return (
    <>
      <div>
        <Button variant="primary" className="mx-1" onClick={onShowFoodWorks}>
          עיבודים
        </Button>
        <Button variant="danger" className="mx-1" onClick={onDelete}>
          מחק
        </Button>
      </div>
      <Spacer />
      <IngredientEditTabFields isNew={false} />
      <SingleItemFoodWorksModal
        show={showFoodWorks}
        treeItemId={id}
        treeItemType="ingredient"
        onCancel={onHideFoodWorks}
      />
    </>
  );
};
