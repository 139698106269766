import Axios from "axios";
import { RequestCacheHelper } from "base/api/RequestCacheHelper";
import { WeeklyWorkScheduleType } from "./types";

export class WeeklyWorkScheduleApi {
  static async get(formattedDateStr: string) {
    return Axios.get<WeeklyWorkScheduleType[]>(
      `/api/workSchedules?startOfWeekDay=${formattedDateStr}`
    );
  }

  static async save(
    formattedDateStr: string,
    schedules: WeeklyWorkScheduleType[]
  ) {
    const { data: savedWeeklySchedules } = await Axios.post<
      WeeklyWorkScheduleType[]
    >(`/api/workSchedules?startOfWeekDay=${formattedDateStr}`, schedules);

    await RequestCacheHelper.instance.update(
      savedWeeklySchedules,
      WeeklyWorkScheduleApi.get,
      [formattedDateStr]
    );
  }
}
