import React from "react";
import { Row, Col } from "react-bootstrap";
import { Typography } from "base/components/Typography";
import { DishVEStepType } from "dish/types";

export interface DishVEWelcomeStepProps {
  onStepChange: (nextStep: DishVEStepType) => void;
}

export const DishVEWelcomeStep: React.FC<DishVEWelcomeStepProps> = ({
  onStepChange,
}) => {
  return (
    <Row
      noGutters
      className="flex-grow-1 align-items-center justify-content-center"
    >
      <Col xs="auto">
        <div
          className="bg-primary text-center"
          style={{ padding: 140 }}
          role="button"
          onClick={() => onStepChange(DishVEStepType.DishType)}
        >
          <Typography
            variant="description"
            className="text-white"
            fontSize={36}
          >
            צור מנה חדשה
          </Typography>
        </div>
      </Col>
    </Row>
  );
};
