import React from "react";
import { Typography } from "base/components/Typography";
import "modal/styles.scss";

export interface ModalTitleBarProps {
  title: string;
  onClose: () => void;
}

export const ModalTitleBar: React.FC<ModalTitleBarProps> = ({
  title,
  onClose,
}) => {
  return (
    <div className="modal-title-bar">
      <Typography variant="description" fontSize={24} bold>
        {title}
      </Typography>
      <div className="close-btn" onClick={onClose} />
    </div>
  );
};
