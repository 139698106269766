import { Role, Claim, AuthTokenPayload } from "./types";

export type AuthHelperMatchOptions = {
  includeRoles?: Role[];
  includeClaims?: Claim[];
};

export class AuthHelper {
  static match(
    tokenPayload: AuthTokenPayload | undefined,
    { includeClaims, includeRoles }: AuthHelperMatchOptions
  ) {
    if (!tokenPayload) return false;

    const isRolesMatching =
      !includeRoles || includeRoles.includes(tokenPayload.role);
    const isClaimsMatching =
      !includeClaims ||
      includeClaims.some((x) => tokenPayload.permissions.includes(x));

    return isRolesMatching && isClaimsMatching;
  }
}
