import React, { useCallback } from "react";
import { RoundButton } from "base/components/RoundButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

export type CardsTableTopBarProps = {
  searchPhrase: string | undefined;
  searchPlaceholder: string | undefined;
  onSearchPhraseChange: (nextSearchPhrase: string) => void;
  onAddRowClick?: () => void;
  customActions?: JSX.Element;
  searchable?: boolean;
};

export const CardsTableTopBar: React.FC<CardsTableTopBarProps> = ({
  searchable = true,
  searchPhrase = "",
  searchPlaceholder = "חיפוש",
  onSearchPhraseChange,
  onAddRowClick,
  customActions,
}) => {
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onSearchPhraseChange(e.target.value);
    },
    [onSearchPhraseChange]
  );

  return (
    <div className="d-flex justify-content-between align-items-center cardsTableTopBar">
      {searchable && (
        <input
          className="align-self-center form-control"
          value={searchPhrase}
          onChange={onChange}
          placeholder={searchPlaceholder}
        />
      )}
      {customActions ?? null}
      {onAddRowClick && (
        <RoundButton onClick={onAddRowClick}>
          <FontAwesomeIcon icon={faPlus} size="lg" />
        </RoundButton>
      )}
    </div>
  );
};
