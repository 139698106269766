import React from "react";
import { useParams } from "react-router-dom";
import { useCustomQueryWithParams } from "base/api/hooks/useCustomQuery";
import { RouteContent } from "base/components/RouteContent";
import { Typography } from "base/components/Typography";
import { EmployeeForm } from "employee/components/EmployeeForm";
import { EmployeesApi } from "employee/EmployeesApi";
import { useFullScreenLoader } from "full-screen-loader/hooks/useFullScreenLoader";
import { RouteParams } from "base/types";

export const EditEmployeeScreen: React.FC = () => {
  const { employeeId } = useParams<RouteParams<"employeeId">>();

  const { data, isValidating } = useCustomQueryWithParams(
    EmployeesApi.getItem,
    () => [employeeId]
  );

  const isLoading = !data || isValidating;

  useFullScreenLoader(isLoading);

  return (
    <RouteContent>
      <Typography variant="route-title">עריכה</Typography>
      <EmployeeForm mode="existing" data={data} />
    </RouteContent>
  );
};
