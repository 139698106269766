import React, { useMemo } from "react";
import { useParams, useHistory } from "react-router-dom";
import { RouteParams, TreeType } from "base/types";
import { RouteContent } from "base/components/RouteContent";
import { PreparationTasksApi } from "./PreparationTasksApi";
import { useCustomQuery } from "base/api/hooks/useCustomQuery";
import { PreparationTaskStatus } from "./types";
import { useRefreshInterval } from "base/hooks/useRefreshInterval";
import { differenceInMilliseconds, format, setMilliseconds } from "date-fns";
import { RecipeModal } from "recipe/components/RecipeModal";
import { useBooleanState } from "base/hooks/useBooleanState";
import { startOfDay } from "date-fns";
import classNames from "classnames";
import { PreparationTaskDoneModal } from "./PreparationTaskDoneModal";
import { TextFormatter } from "base/utils/formatters/TextFormatter";
import { UnitTypeFormatter } from "base/utils/formatters";

export const PreparationTaskScreen: React.FC = () => {
  const history = useHistory();

  const { id: idStr } = useParams<RouteParams<"id">>();
  const id = Number(idStr);
  const { data: list } = useCustomQuery(PreparationTasksApi.getList);

  const item = useMemo(() => list?.find((x) => x.id === id), [id, list]);
  const [showRecipe, onShowRecipe, onHideRecipe] = useBooleanState(false);

  const [showDone, onShowDone, onHideDone] = useBooleanState(false);
  // refresh every second if status is completed.
  useRefreshInterval(1000, item?.status === PreparationTaskStatus.InProcess);

  return (
    <RouteContent>
      {history.length > 2 && (
        <i
          className="fas fa-arrow-circle-right fa-3x text-primary"
          role="button"
          onClick={history.goBack}
        />
      )}
      <p className="h3 text-center">{item?.materialName}</p>
      <p className="h5 text-center">
        {TextFormatter.format("$1 $2", [
          item?.amount ?? "",
          UnitTypeFormatter.format(item?.unitType),
        ])}
      </p>
      <div
        className={classNames(
          "flex-grow-1",
          "d-flex",
          "flex-column",
          "justify-content-around",
          "align-items-center"
        )}
      >
        {item?.status === PreparationTaskStatus.Created && (
          <div
            role="button"
            className="p-5 rounded bg-primary text-white text-center"
            style={{ width: 350 }}
            onClick={() => PreparationTasksApi.startWork(id)}
          >
            <h2>יאללה, התחלתי</h2>
          </div>
        )}
        {item?.status === PreparationTaskStatus.InProcess && (
          <div
            role="button"
            className="p-4 rounded bg-primary text-white text-center"
            style={{ width: 400 }}
            onClick={onShowDone}
          >
            <h3>הכנתי, מעבר להדפסת מדבקות</h3>
            <h2>
              {format(
                setMilliseconds(
                  startOfDay(Date.now()),
                  differenceInMilliseconds(
                    Date.now(),
                    new Date(item.startDateTime!)
                  )
                ),
                "HH:mm:ss"
              )}
            </h2>
          </div>
        )}
        {item?.recipeId !== undefined && (
          <>
            <h2
              role="button"
              className="p-5 rounded bg-primary text-white text-center"
              style={{ width: 350 }}
              onClick={onShowRecipe}
            >
              לצפיה במתכון
            </h2>
            <RecipeModal
              treeType={TreeType.Material}
              show={showRecipe}
              onHide={onHideRecipe}
              id={item.recipeId}
              isCanEdit={false}
              materialData={{
                id: item.materialId,
                initialTotalAmount: item.amount * 1000,
              }}
            />
          </>
        )}
      </div>
      <PreparationTaskDoneModal
        id={item?.id}
        show={showDone}
        onHide={onHideDone}
      />
    </RouteContent>
  );
};
