import axios from "axios";
import { FoodDepartmentListItem } from "base/types";
import { BodyFormDataBuilder } from "base/api/BodyFormDataBuilder";
import { RequestCacheHelper } from "base/api/RequestCacheHelper";
import {
  IngredientListItem,
  IngredientSupplierListItem,
  Ingredient,
} from "./types";
import { BaseSelectListItem } from "base/types/BaseSelectListItem";
import {
  IngredientRelatedTree,
  IngredientRelatedProduct,
  IngredientFormData,
} from "./types";
import { TaklitSelectListItem } from "base/types/TaklitSelectListItem";

export class IngredientsApi {
  static getList() {
    return axios.get<IngredientListItem[]>("/api/ingredients/items");
  }

  static getItem(id: number) {
    return axios.get<Ingredient>(`/api/ingredients/${id}`);
  }

  static getItemDefaults() {
    return axios.get<Partial<Ingredient>>(`/api/ingredients/new`);
  }

  static getDepartments() {
    return axios.get<FoodDepartmentListItem[]>("/api/ingredients/departments");
  }

  static getTaklitSelectList() {
    return axios.get<TaklitSelectListItem[]>(
      "/api/ingredients/taklitSelectList"
    );
  }

  static getSuppliers() {
    return axios.get<IngredientSupplierListItem[]>(
      "/api/ingredients/suppliers"
    );
  }

  static getSelectList() {
    return axios.get<BaseSelectListItem[]>("/api/ingredients/selectlist");
  }

  static getTrees(id: number) {
    return axios.get<IngredientRelatedTree[]>(`/api/ingredients/${id}/trees`);
  }

  static getProducts(id: number) {
    return axios.get<IngredientRelatedProduct[]>(
      `/api/ingredients/${id}/products`
    );
  }

  static async saveItem(item: WithPartialProperties<IngredientFormData, "id">) {
    const { data: savedItem } = await axios.post<Ingredient>(
      "/api/ingredients",
      BodyFormDataBuilder.build2(item),
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );

    // updating the getItem cache for the savedItem.id.
    await RequestCacheHelper.instance.update(savedItem, this.getItem, [
      savedItem.id,
    ]);

    // making sure to refetch the list of items to reflect change in list as well.
    RequestCacheHelper.instance.refetch(this.getList);

    return savedItem;
  }

  static async saveFromSelect(
    item: any,
    attachment?: File // keep this for future use
  ) {
    const { data: savedItem } = await axios.post<Ingredient>(
      "/api/ingredients/addFromSelect",
      BodyFormDataBuilder.build({ ...item, image: attachment }),
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );

    // updating the getItem cache for the savedItem.id.
    await RequestCacheHelper.instance.update(savedItem, this.getItem, [
      savedItem.id,
    ]);

    // making sure to refetch the list of items to reflect change in list as well.
    RequestCacheHelper.instance.refetch(this.getList);

    return savedItem;
  }

  static async deleteItem(id: number) {
    await axios.delete(`/api/ingredients/${id}`);

    await RequestCacheHelper.instance.update(
      undefined,
      IngredientsApi.getItem,
      [id]
    );
  }
}
