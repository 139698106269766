import Axios, { AxiosError } from "axios";
import { AuthRouteBuilder } from "../auth/AuthRouteBuilder";
import { matchPath } from "react-router-dom";
import { history } from "./history";
import { error } from "@pnotify/core";

// Add token + timestamp to requests.
Axios.interceptors.request.use(async (req) => {
  req.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
  req.headers.timestamp = Date.now();
  return Promise.resolve(req);
});

// Initializing the Refresh Token Interceptor.
Axios.interceptors.response.use(undefined, async (err: AxiosError) => {
  const isUnAuthorized = err.response?.status === 401;
  const isError500 = err.response?.status === 500;

  const handleUnauthorized = () => {
    localStorage.removeItem("token");

    const loginRoute = AuthRouteBuilder.buildLoginRoute();

    if (!matchPath(history.location.pathname, loginRoute)) {
      history.replace(loginRoute);
    }
  };

  if (isUnAuthorized) {
    handleUnauthorized();
  } else if (isError500) {
    error({
      text: err.response!.data?.error ?? "אירעה שגיאה כללית",
    });
  }

  return Promise.reject(err); // rethrow error.
});
