import React, { useState, useEffect } from "react";
import { PickIngredientItem } from "./PickIngredientItem/PickIngredientItem";
import "./style.scss";
import { OrderIngredientItem } from "order/types";
import { BaseSupplyMethod } from "base/types/BaseSupplyMethod";
import { OrderHelper } from "order/OrderHelper";

type ItemToTotalPrices = {
  [ingId: number]: number;
};

export type PickIngredientsProps = {
  ingredients: OrderIngredientItem[] | undefined;
  onTotalOrderPriceCalc?: (price: number) => void;
};

export const PickIngredients: React.FC<PickIngredientsProps> = ({
  ingredients,
  onTotalOrderPriceCalc,
}) => {
  const [itemToTotalPrice, setItemToTotalPrice] = useState<ItemToTotalPrices>(
    {}
  );

  const onIngredientChanged = (
    ingId: any,
    details: {
      amount: number;
      supplyMethod?: BaseSupplyMethod;
    }
  ) => {
    const { amount } = details;
    const ing = ingredients!.find((x) => x.productId === ingId)!;
    setItemToTotalPrice((currItemToTotalPrice) => {
      return {
        ...currItemToTotalPrice,
        [ingId]: OrderHelper.calcTotalPrice(amount, ing.requestedPrice),
      };
    });
  };

  useEffect(() => {
    if (onTotalOrderPriceCalc && ingredients) {
      const totalPrice = ingredients.reduce((result, item) => {
        return (
          result +
          (itemToTotalPrice[item.productId] ??
            OrderHelper.calcTotalPrice(
              item.requestedAmount,
              item.requestedPrice
            ))
        );
      }, 0);

      onTotalOrderPriceCalc(totalPrice);
    } else {
      onTotalOrderPriceCalc?.(0);
    }
  }, [ingredients, itemToTotalPrice, onTotalOrderPriceCalc]);

  return (
    <div className="pickIngredientsFlex">
      {(ingredients === undefined || ingredients.length === 0) &&
        "לא נמצאו מוצרים תואמים"}
      {ingredients?.map((item: any) => (
        <PickIngredientItem
          item={item}
          key={item.productId}
          onChanged={({ amount, supplyMethod }) => {
            onIngredientChanged(item.productId, { amount, supplyMethod });
          }}
        />
      ))}
    </div>
  );
};
