export class OrderStatusFormatter {
  static format(value: number | string | undefined) {
    switch (value) {
      case 0:
        return "חדשה";
      case 1:
        return "";
      case 2:
        return "נשלחה לספק";
      case 3:
        return "נקלטה למלאי";
      case 4:
        return "נשלחה לזיכוי";
      case 5:
        return "סגור";
      default:
        return "";
    }
  }
}
