import { FormSpy } from "react-final-form";
import React from "react";
import { getIn } from "final-form";

export type FieldsProps = {
  names: string[];
  render: (values: any[]) => React.ReactNode;
};

export const Fields: React.FC<FieldsProps> = ({ names, render }) => {
  return (
    <FormSpy
      subscription={{ values: true }}
      render={({ form }) => {
        const state = form.getState();
        const values = names.map((name) => getIn(state.values, name));
        return render(values) ?? null;
      }}
    />
  );
};
