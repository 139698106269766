import React, { useCallback, useMemo } from "react";
import { RouteContent } from "base/components/RouteContent";
import { Typography } from "base/components/Typography";
import {
  CardsTable,
  CardsTableProps,
} from "base/components/tables/components/CardsTable";
import { useCustomQuery } from "base/api/hooks/useCustomQuery";
import { ProductsApi } from "./ProductsApi";
import { useNavigateCallback } from "base/hooks/useNavigateCallback";
import { ProductsRouteBuilder } from "./ProductsRouteBuilder";
import { ProductLitItem } from "./types";
import { BaseImage } from "base/components/BaseFoodImage";
import { FileDir } from "base/types";
import { YesNoTableFormatter } from "base/components/tables/utils/formatters/YesNoTableFormatter";

export const ProductsScreen: React.FC = () => {
  const { data } = useCustomQuery(ProductsApi.getList);
  const navigate = useNavigateCallback();

  const onAddRowClick = useCallback(() => {
    navigate(ProductsRouteBuilder.buildAddRoute());
  }, [navigate]);

  const onEditRowClick = useCallback(
    (data: ProductLitItem) => {
      navigate(ProductsRouteBuilder.buildEditRoute(data.id));
    },
    [navigate]
  );

  const columns = useMemo<CardsTableProps<ProductLitItem>["columns"]>(
    () => [
      {
        id: "image",
        accessor: "imageName",
        Header: "",
        Cell: ({ value }) => (
          <BaseImage alt="מוצר" fileName={value} fileDir={FileDir.Product} />
        ),
        disableSortBy: true,
        disableGlobalFilter: true,
      },
      {
        id: "IngredientName",
        accessor: "ingredientName",
        Header: "מוצר",
        cellProps: {},
      },
      {
        id: "ManufacturerName",
        accessor: "manufacturerName",
        Header: "יצרן",
        cellProps: {},
      },
      {
        id: "DepartmentName",
        accessor: "departmentName",
        Header: "מחלקה",
        cellProps: {},
      },
      {
        id: "IsActive",
        accessor: "isActive",
        Header: "פעיל?",
        cellProps: {},
        Cell: ({ value }) => YesNoTableFormatter.default.format(value),
        sortType: "boolean",
      },
      {
        id: "table-closer",
        Header: "",
        cellProps: {
          className: "table-closer",
        },
        disableSortBy: true,
        disableGlobalFilter: true,
      },
    ],
    []
  );

  return (
    <RouteContent>
      <Typography variant="route-title">מוצרים</Typography>
      <CardsTable
        columns={columns}
        data={data}
        onEditRowClick={onEditRowClick}
        onAddRowClick={onAddRowClick}
        localStorageKey="ingredientsDT"
        globalFilterAllowedCols={["IngredientName"]}
      />
    </RouteContent>
  );
};
