import { BaseSelectListItem } from "base/types";

export enum NutritionalRelationType {
  Food = "Food",
  Product = "Product",
  Material = "Material",
}

export interface NutritionalItem {
  id: number;
  nutritional: number;
  unitType: NutritionalUnitType;
}

export enum NutritionalUnitType {
  KCAL = 0,
  GR,
  MG,
  MKG,
  SPOON,
  PERCENTAGE,
}

export interface NutritionalSelectListItem extends BaseSelectListItem {
  type: NutritionalUnitType;
}
