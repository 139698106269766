import React from "react";
import { RouteContent } from "base/components/RouteContent";
import { Typography } from "base/components/Typography";
import { ContractEditor } from "contract/components/ContractsEditor";

export const AddContractScreen: React.FC = () => {
  return (
    <RouteContent>
      <Typography variant="route-title">הוספת הסכם חדש</Typography>
      <ContractEditor />
    </RouteContent>
  );
};
