import * as yup from "yup";
import { MaterialFormData } from "./types";

export const MaterialFormSchema = yup
  .object<MaterialFormData>()
  .shape<MaterialFormData>({
    id: yup.number(),
    name: yup.string().required(),
    imageName: yup.string(),
    departmentId: yup.number(),
    unitType: yup.number().required(),
    isActive: yup.boolean().required(),
    dateCreated: yup.string(),
    totalAmount: yup.number(),
    minStock: yup.number(),
    maxStock: yup.number(),
    currentStock: yup.number(),
    freshnessDays: yup.number(),
    image: yup.mixed(), // TODO add generic yup.test method to ensure FileList
  })
  .asForm<MaterialFormData>();
