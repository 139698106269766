import React, { useCallback, useState, useEffect } from "react";
import { Typography } from "base/components/Typography";
import { Spacer } from "base/components/Spacer";
import { AccountUserListItem } from "auth/types";
import { AuthApi } from "auth/AuthApi";
import { useAppContext } from "app/context/hooks/useAppContext";
import "./style.scss";
import { useCustomQuery } from "base/api/hooks/useCustomQuery";
import { Row, Col, Modal } from "react-bootstrap";
import { BaseImage } from "base/components/BaseFoodImage";
import { FileDir } from "base/types";
import { useBooleanState } from "base/hooks/useBooleanState";
import { usePrevious } from "base/hooks/usePrevious";
import { useStandType } from "auth/hooks/useStandType";
import { useStandTypeRedirectCallback } from "auth/hooks/useStandTypeRedirectCallback";

export const PinCodeLoginScreen: React.FC = () => {
  const { onSignIn } = useAppContext();
  const standType = useStandType();
  const standTypeRedirect = useStandTypeRedirectCallback();
  const { data } = useCustomQuery(AuthApi.getAccountUsers);
  const [
    displayPinCodeModal,
    onShowPinCodeModal,
    onHidePinCodeModal,
  ] = useBooleanState(false);
  const [selectedItem, setSelectedItem] = useState<AccountUserListItem>();
  const [authError, setAuthError] = useState<string>();
  const [pin, setPin] = useState("");
  const prevPin = usePrevious(pin);

  const onSubmit = useCallback(async () => {
    try {
      const auth = await AuthApi.pinCodeAuth({
        username: selectedItem!.userName,
        pinCode: pin,
      });
      onSignIn(auth);
      standTypeRedirect(standType);
    } catch (e) {
      setAuthError(e?.response?.data?.error ?? "סיסמא שגויה, נסו שנית");
    }
  }, [selectedItem, pin, onSignIn, standTypeRedirect, standType]);

  // Call Login when pin changed to 4.
  useEffect(() => {
    if (prevPin !== pin && pin.length === 4) {
      onSubmit();
    }
  }, [onSubmit, pin, prevPin]);

  // Reset pin when getting an error.
  useEffect(() => {
    if (authError) {
      setPin("");
    }
  }, [authError]);

  // Reset error when start typing the pin.
  useEffect(() => {
    if (pin) {
      setAuthError("");
    }
  }, [pin]);

  return (
    <>
      <div className="px-5 pb-5 pt-2 text-center rounded pincode-login">
        <Spacer units={10} />
        <Typography variant="route-title">התחברות</Typography>
        <Spacer units={3} />
        <div className="d-flex justify-content-center flex-sm-wrap">
          {data?.map((item) => (
            <div key={item.userName} className="p-5">
              <div
                role="button"
                onClick={() => {
                  onShowPinCodeModal();
                  setSelectedItem(item);
                }}
              >
                <BaseImage
                  className="mb-3"
                  fileName={item.imageName}
                  fileDir={FileDir.User}
                  width={100}
                  height={100}
                  rounded
                />
                <Typography variant="description" fontSize={24} bold>
                  {item.firstName
                    ? `${item.firstName} ${item.lastName ?? ""}`
                    : item.userName}
                </Typography>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Modal
        show={displayPinCodeModal && selectedItem}
        onHide={onHidePinCodeModal}
        dialogClassName="pinCodeModal"
        centered
      >
        <div className="mt-3 d-flex flex-column align-items-center rounded">
          <div className="text-center">
            <BaseImage
              fileName={selectedItem?.imageName}
              fileDir={FileDir.User}
              width={120}
              rounded
            />
            <Typography variant="description" fontSize={30} bold>
              שלום,{" "}
              {selectedItem?.firstName
                ? `${selectedItem?.firstName} ${selectedItem?.lastName ?? ""}`
                : selectedItem?.userName}
            </Typography>
            <div style={{ height: 100 }}>
              <div style={{ fontSize: 22 }}>נא להקיש קוד</div>
              {pin.split("").map(() => (
                <span style={{ fontSize: 44 }}>*</span>
              ))}
              {authError && (
                <div style={{ fontSize: 22 }} className="alert alert-danger">
                  {authError}
                </div>
              )}
            </div>
          </div>
          <div className="mt-2 px-2">
            <Row noGutters dir="ltr" className="justify-content-center">
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map((val) => (
                <Col
                  key={val}
                  xs={4}
                  className="p-3 d-flex justify-content-center"
                >
                  <div
                    className="pinSlot"
                    role="button"
                    onClick={() => {
                      if (pin.length < 4) {
                        setPin(`${pin}${val}`);
                      }
                    }}
                  >
                    {val}
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </Modal>
    </>
  );
};
