import { observable, action } from "mobx";
import { Auth, AuthStandType } from "auth/types";

export class AppStore {
  @observable.ref
  auth: Auth | undefined;

  @observable
  isSidebarEnabled: boolean;

  @observable.ref
  standType: AuthStandType;

  constructor() {
    const token: string | undefined =
      localStorage.getItem("token") ?? undefined;
    const standType: AuthStandType =
      (localStorage.getItem("standType") as AuthStandType) ?? "default";

    this.auth = token ? { token } : undefined;
    this.standType = standType;
    this.isSidebarEnabled = false;
  }

  @action
  setAuth = (auth: Auth | undefined) => {
    this.auth = auth;
    if (auth) {
      localStorage.setItem("token", auth.token);
    } else {
      localStorage.removeItem("token");
    }
  };

  @action
  setStandType = (standType: AuthStandType) => {
    this.standType = standType;
    localStorage.setItem("standType", standType);
  };

  @action
  setIsSidebarEnabled = (isSidebarEnabled: boolean) => {
    this.isSidebarEnabled = isSidebarEnabled;
  };
}
