import React from "react";
import { Route, RouteProps } from "react-router-dom";
import { GuardedRouteScreen } from "./GuardedRouteScreen";

export type GuardedRouteProps = RouteProps;

export const GuardedRoute: React.FC<GuardedRouteProps> = ({
  children,
  ...routeProps
}) => {
  return (
    <Route {...routeProps}>
      <GuardedRouteScreen>{children}</GuardedRouteScreen>
    </Route>
  );
};
