import React from "react";
import { useCustomQueryWithParams } from "base/api/hooks/useCustomQuery";
import { Typography } from "base/components/Typography";
import { Spacer } from "base/components/Spacer";
import { useFullScreenLoader } from "full-screen-loader/hooks/useFullScreenLoader";
import { Form } from "form/components/Form";
import { TreeInfoApi } from "tree-info/TreeInfoApi";
import { TreeType } from "base/types/TreeType";
import { BasicTreeFields } from "./BasicTreeFields";
import { BasicTreeFoodsSearch } from "./BasicTreeFoodsSearch";
import "./styles.scss";
import { BasicTreeFoodsTable } from "./BasicTreeFoodsTable";

export type BasicTreeInfoProps = {
  type: TreeType;
  itemId: number;
};

export const BasicTreeInfo: React.FC<BasicTreeInfoProps> = ({
  itemId,
  type,
}) => {
  const {
    data,
    isValidating,
  } = useCustomQueryWithParams(TreeInfoApi.getTreeInfo, () => [type, itemId]);

  const isLoading = !data || isValidating;

  useFullScreenLoader(isLoading);

  return (
    <Form onSubmit={() => {}} initialValues={data}>
      {() => (
        <div className="basic-tree-info">
          <Typography
            variant="route-title"
            className="text-center"
            fontSize={32}
          >
            ניהול עץ מוצר
          </Typography>
          <Spacer units={2} />
          <BasicTreeFields type={type} />
          <Spacer units={8} />
          <Typography
            variant="route-title"
            className="text-center"
            fontSize={32}
          >
            הוספת מרכיבים\חומרי גלם
          </Typography>
          <Spacer units={2} />
          <BasicTreeFoodsSearch type={type} id={itemId} />
          <Spacer units={3} />
          <BasicTreeFoodsTable type={type} id={itemId} />
        </div>
      )}
    </Form>
  );
};
