import Axios from "axios";
import { WeekStockRange } from "./types";
import { RequestCacheHelper } from "base/api/RequestCacheHelper";
import { Material } from "material/types";
import { MaterialsApi } from "material/MaterialsApi";
import { Ingredient } from "ingredient/types";
import { IngredientsApi } from "ingredient/IngredientsApi";

export class WeekStockRangesApi {
  static getRanges(id: number) {
    return Axios.get<WeekStockRange>(`/api/WeekStockRanges/${id}`);
  }
  static async saveRanges(weekStockRange: WeekStockRange) {
    const { data: savedWeekStockRange } = await Axios.post<WeekStockRange>(
      `/api/WeekStockRanges`,
      weekStockRange
    );

    RequestCacheHelper.instance.update(
      savedWeekStockRange,
      WeekStockRangesApi.getRanges,
      [savedWeekStockRange.id]
    );
    const {
      id: weekStockRangeId,
      materialId,
      ingredientId,
    } = savedWeekStockRange;

    if (materialId !== undefined) {
      RequestCacheHelper.instance.update<Material>(
        (mat) => {
          if (!mat) return undefined;
          return { ...mat, weekStockRangeId };
        },
        MaterialsApi.getItem,
        [materialId]
      );
    } else {
      RequestCacheHelper.instance.update<Ingredient>(
        (ing) => {
          if (!ing) return undefined;
          return { ...ing, weekStockRangeId };
        },
        IngredientsApi.getItem,
        [ingredientId]
      );
    }
  }
}
