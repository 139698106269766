import React from "react";
import { Col, Row } from "react-bootstrap";
import { FormTextInput } from "form/components/FormTextInput";
import { IngredientFormData } from "ingredient/types";
import "../style.scss";
import { FormNumberInput } from "form/components/FormNumberInput";
import { Field } from "react-final-form";
import { format } from "date-fns";

export type IngredientPlantsTabProps = {
  id: number;
};

export const IngredientPlantsTab: React.FC<IngredientPlantsTabProps> = ({
  id,
}) => {
  const fieldName = (property: keyof IngredientFormData) => property;

  return (
    <Row noGutters>
      <Col xs={3} sm={6} md={2} className="d-flex align-items-center py-1">
        שם במועצת הצמחים
      </Col>
      <Col xs={9} sm={6} md={5} className="py-1">
        <FormTextInput name={fieldName("plantsName")} />
      </Col>
      <Col xs="auto" md={5} /> {/* Col Placeholder */}
      <Col xs={3} sm={6} md={2} className="d-flex align-items-center py-1">
        מחיר מועצת הצמחים
      </Col>
      <Col xs={9} sm={6} md={5} className="py-1">
        <FormNumberInput name={fieldName("plantsPrice")} disabled />
      </Col>
      <Col xs={12} md={5} className="d-flex align-items-center p-1">
        <Field
          name={fieldName("plantsLastUpdate")}
          render={({ input: { value: plantsLastUpdate } }) =>
            `עודכן לאחרונה: ${
              plantsLastUpdate
                ? format(new Date(plantsLastUpdate), "dd/MM/yyyy")
                : "-"
            }`
          }
        />
      </Col>
    </Row>
  );
};
