import React from "react";
import { RecipeIngredient } from "recipe/types";
import { UnitTypeFormatter, NumberFormatter } from "base/utils/formatters";
import "../styles.scss";

export type RecipeViewerIngredientsProps = {
  ingredients: RecipeIngredient[];
  materials: RecipeIngredient[];
  quantityMultiplier: number;
  fontSize: number;
};

export const RecipeViewerIngredients: React.FC<RecipeViewerIngredientsProps> = ({
  ingredients,
  materials,
  quantityMultiplier,
  fontSize,
}) => {
  return (
    <table className="ingredients-table">
      <tbody>
        {[...ingredients, ...materials].map((ing) => (
          <tr key={ing.id} style={{ fontSize }}>
            <td dir="ltr">
              {NumberFormatter.format(ing.amount * quantityMultiplier)}
            </td>
            <td>{UnitTypeFormatter.default.format(ing.unitType, true)}</td>
            <td>{ing.name}</td>
            <td>
              {/* TODO - ing.foodworks will always have value(can be empty).. waiting for server. */}
              {(ing.foodWorks ?? []).length > 1
                ? `(${ing.foodWorks!.join(", ")})`
                : ""}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
