export interface Contract {
  id: number;
  name: string;
  contractItems: ContractItem[];
}

export interface ContractListItem {
  id: number;
  name: string;
}

export type ContractItem = {
  id: number;
  itemType: number;
  fromTime: string;
  toTime: string;
  percentage: number;
};

export enum ContractGroupTypes {
  Yom_Hol = 0,
  Erev_Shabat_And_Hag = 1,
  Shabat_And_Hag = 2,
}

export type EditContractRouteParams = {
  id: string;
};
