import React from "react";
import { Spacer } from "base/components/Spacer";
import { Form } from "form/components/Form";
import { TreeType } from "base/types/TreeType";
import { BasicTreeInfoListSearchOptions } from "basic-tree-info/types";
import { BasicTreeFoodsSearchFields } from "./BasicTreeFoodsSearchFields";
import { BasicTreeFoodsSearchResults } from "./BasicTreeFoodsSearchResults";

export type BasicTreeFoodsSearchProps = {
  type: TreeType;
  id: number;
};

export const BasicTreeFoodsSearch: React.FC<BasicTreeFoodsSearchProps> = ({
  type,
  id,
}) => {
  return (
    <Form<BasicTreeInfoListSearchOptions>
      onSubmit={() => {}}
      initialValues={{
        isIngred: true,
        isMaterial: true,
      }}
    >
      {() => (
        <>
          <BasicTreeFoodsSearchFields />
          <Spacer units={3} />
          <BasicTreeFoodsSearchResults type={type} id={id} />
        </>
      )}
    </Form>
  );
};
