import { SentOrderEditorMode } from "./types";

export class SentOrdersRouteBuilder {
  static templates = {
    SentOrders: `/sent-orders`,
    Viewer: "/sent-orders/:orderKey/viewer",
    ReceivedOrders: "/sent-orders/received",
  };

  static buildSentOrdersRoute() {
    return SentOrdersRouteBuilder.templates.SentOrders;
  }

  static buildReceivedOrdersRoute() {
    return SentOrdersRouteBuilder.templates.ReceivedOrders;
  }

  static buildEditSentOrderRoute(
    orderId: number,
    mode: SentOrderEditorMode = "default"
  ) {
    return `/sent-orders/${orderId}${mode === "advanced" ? "/admin" : ""}`;
  }

  static buildViewerRoute(orderKey: string) {
    return SentOrdersRouteBuilder.templates.Viewer.replace(
      ":orderKey",
      orderKey
    );
  }
}
