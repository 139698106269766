import React from "react";
import { useCustomQuery } from "base/api/hooks/useCustomQuery";
import { useFullScreenLoader } from "full-screen-loader/hooks/useFullScreenLoader";
import { Form } from "form/components/Form";
import { Contract } from "contract/types";
import { ContractsApi } from "contract/ContractsApi";
import { useSaveContractCallback } from "contract/hooks/useSaveContractCallback";
import { ContractForm } from "./ContractForm";

export const ContractNewItemEditor: React.FC = () => {
  const { data, isValidating } = useCustomQuery(ContractsApi.getItemDefaults);

  const isLoading = !data || isValidating;

  useFullScreenLoader(isLoading);

  const onSave = useSaveContractCallback();

  return (
    <Form<Contract>
      onSubmit={onSave}
      initialValues={data}
      component={ContractForm}
    />
  );
};
