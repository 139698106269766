import React, { useCallback, useState, useEffect } from "react";
import { FormControl } from "react-bootstrap";
import { FieldRenderProps } from "react-final-form";
import { FormErrorMessage } from "../../FormErrorMessage";
import { usePrevious } from "base/hooks/usePrevious";
import { NumpadModal } from "base/components/NumpadModal/NumpadModal";
import { useBooleanState } from "base/hooks/useBooleanState";

export type NumberInputFieldInnerProps = FieldRenderProps<any> & {
  placeholder?: string;
  className?: string;
  autoFocus?: boolean;
  selectOnFocus?: boolean;
  step?: string;
  maxDigitsAfterDot?: number;
  displayNumpadOnFocus?: boolean;
};

export const NumberInputFieldInner: React.FC<NumberInputFieldInnerProps> = ({
  input,
  meta,
  placeholder,
  className,
  autoFocus,
  selectOnFocus,
  step,
  maxDigitsAfterDot = 2,
  ...numberInputProps
}) => {
  const [value, setValue] = useState<string>(String(input.value ?? ""));
  const prevValue = usePrevious(value);
  const { value: valueProp, onFocus, onChange: onChangeProp } = input;
  const [showNumpad, onOpenNumpad, onHideNumpad] = useBooleanState(false);

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const nextValue = e.target.value;
    const isInProgress =
      nextValue === "-" ||
      (nextValue.endsWith(".") && nextValue.split(".").length === 2);
    const isValueValid = e.target.validity.valid;

    if (!isValueValid && !isInProgress) {
      return;
    }

    setValue(nextValue);
  }, []);

  const onSelectOnFocus = useCallback<
    React.FocusEventHandler<HTMLInputElement>
  >(
    (event) => {
      const inputElement = event.target;
      onFocus(event);
      setTimeout(() => inputElement.select());
    },
    [onFocus]
  );

  const onNumpadDone = useCallback(
    (nextValue: number) => {
      setValue(String(nextValue));
      onHideNumpad();
    },
    [onHideNumpad]
  );

  // Handle updates that originates from within the component
  useEffect(() => {
    if (value === prevValue) {
      return;
    }

    if (value.trim() === "") {
      onChangeProp(undefined);
    } else if (!isNaN(Number(value))) {
      onChangeProp(Number(value));
    }
  }, [value, prevValue, onChangeProp]);

  // Handle updates that originates from outside the component
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setValue((curr) => {
        if (
          Number(curr) !== Number(valueProp) ||
          (curr === "" && valueProp !== "")
        ) {
          return String(valueProp);
        } else {
          return curr;
        }
      });
    });

    return () => clearTimeout(timeoutId);
  }, [valueProp]);

  return (
    <>
      <FormControl
        autoComplete="off"
        placeholder={placeholder}
        className={className}
        autoFocus={autoFocus}
        step={step}
        dir="ltr"
        {...numberInputProps}
        {...input}
        // eslint-disable-next-line no-useless-escape
        pattern={`^-?[0-9]+(\.[0-9]{1,${maxDigitsAfterDot}})?$`}
        value={value}
        onChange={onChange}
        onFocus={selectOnFocus ? onSelectOnFocus : undefined}
        style={{ textAlign: "right" }}
        onClick={
          numberInputProps.displayNumpadOnFocus
            ? onOpenNumpad
            : numberInputProps.onClick
        }
      />
      <div>
        <FormErrorMessage>
          {meta.touched ? meta.error : meta.submitError}
        </FormErrorMessage>
      </div>
      <NumpadModal
        show={showNumpad}
        initialValue={input.value}
        onCancel={onHideNumpad}
        onDone={onNumpadDone}
      />
    </>
  );
};
