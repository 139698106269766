import React, { useMemo, useCallback } from "react";
import "./style.scss";
import { useNavigateCallback } from "base/hooks/useNavigateCallback";
import { BaseImage } from "base/components/BaseFoodImage";
import { YesNoTableFormatter } from "base/components/tables/utils/formatters/YesNoTableFormatter";
import {
  CardsTable,
  CardsTableProps,
} from "base/components/tables/components/CardsTable";
import { TableAmountCell } from "base/components/tables/components/TableAmountCell";
import { TableDateCell } from "base/components/tables/components/TableDateCell";
import { DishListItem } from "dish/types";
import { DishesRouteBuilder } from "dish/DishesRouteBuilder";
import { useCustomQuery } from "base/api/hooks/useCustomQuery";
import { DishesApi } from "dish/DishesApi";
import { TreeInfoRouteBuilder } from "tree-info/TreeInfoRouteBuilder";
import { TreeType } from "base/types/TreeType";
import { SelectColumnFilter } from "base/components/tables/components/SelectColumnFilter";

export const DishesTable: React.FC = () => {
  const { data } = useCustomQuery(DishesApi.getList);
  const navigate = useNavigateCallback();

  const onAddRowClick = useCallback(() => {
    navigate(DishesRouteBuilder.buildAddDishRoute());
  }, [navigate]);

  const onEditRowClick = useCallback(
    (data: DishListItem) => {
      navigate(TreeInfoRouteBuilder.buildTreeInfoRoute(TreeType.Dish, data.id));
    },
    [navigate]
  );

  const columns = useMemo<CardsTableProps<DishListItem>["columns"]>(
    () => [
      {
        id: "image",
        accessor: "imageName",
        Header: "",
        Cell: ({ value }) => <BaseImage alt="מרכיב" fileName={value} />,
        disableSortBy: true,
        disableGlobalFilter: true,
      },
      {
        id: "Name",
        accessor: "name",
        Header: "שם המנה",
        cellProps: {
          className: "name-col",
        },
      },
      {
        id: "Id",
        accessor: "id",
        Header: "קוד ישות",
      },
      {
        id: "MenuPrice",
        accessor: "menuPrice",
        Header: "מחיר תפריט",
        Cell: TableAmountCell,
      },
      {
        id: "AssemblingTime",
        accessor: "assemblingTime",
        Header: "זמן הרכבה",
      },
      {
        id: "DepartmentName",
        accessor: "departmentName",
        Header: "מחלקה",
        cellProps: {},
        Filter: SelectColumnFilter,
      },
      {
        id: "foodCostPercentage",
        accessor: "foodCostPercentage",
        Header: "FC%",
        cellProps: {},
        Cell: TableAmountCell,
      },
      {
        id: "IsActive",
        accessor: "isActive",
        Header: "פעיל?",
        cellProps: {},
        Cell: ({ value }) => YesNoTableFormatter.default.format(value),
        sortType: "boolean",
      },
      {
        id: "DateCreated",
        accessor: (row) => row.dateCreated,
        Header: "נוצר",
        cellProps: {},
        sortType: "datetime",
        Cell: TableDateCell,
      },
      {
        id: "table-closer",
        Header: "",
        cellProps: {
          className: "table-closer",
        },
        disableSortBy: true,
        disableGlobalFilter: true,
      },
    ],
    []
  );

  return (
    <div className="dishesTable">
      <CardsTable
        columns={columns}
        data={data}
        onEditRowClick={onEditRowClick}
        onAddRowClick={onAddRowClick}
        localStorageKey="dishesDT"
        globalFilterAllowedCols={["Name"]}
      />
    </div>
  );
};
