import axios from "axios";
import { RequestCacheHelper } from "base/api/RequestCacheHelper";
import { DepartmentAddonFoodWork } from "./types/DepartmentAddonFoodWork";
import { DepartmentsApi } from "department/DepartmentsApi";
import { DepartmentAddons } from "department/types";

export class DepartmentAddonFoodWorksApi {
  static async addFoodWork(
    addonId: number,
    departmentId: number,
    listName: keyof DepartmentAddons,
    foodWork: Omit<DepartmentAddonFoodWork, "id">
  ) {
    const { data: newFoodWork } = await axios.post<DepartmentAddonFoodWork>(
      `/api/departmentAddons/${addonId}/foodwork`,
      foodWork
    );

    await RequestCacheHelper.instance.update<DepartmentAddons>(
      (departmentAddons) => {
        if (!departmentAddons) {
          return;
        }

        return {
          ...departmentAddons,
          [listName]: departmentAddons[listName].map((addon) => {
            if (addon.id !== addonId) {
              return addon;
            } else {
              return {
                ...addon,
                foodWorks: [...addon.foodWorks, newFoodWork],
              };
            }
          }),
        };
      },
      DepartmentsApi.getAddons,
      [departmentId]
    );

    return newFoodWork;
  }

  static async updateFoodWork(
    addonId: number,
    departmentId: number,
    listName: keyof DepartmentAddons,
    modifiedFoodWork: DepartmentAddonFoodWork
  ) {
    await axios.post(
      `/api/departmentAddons/foodworks/${modifiedFoodWork.id}`,
      modifiedFoodWork
    );

    await RequestCacheHelper.instance.update<DepartmentAddons>(
      (departmentAddons) => {
        if (!departmentAddons) {
          return;
        }

        return {
          ...departmentAddons,
          [listName]: departmentAddons[listName].map((addon) => {
            if (addon.id !== addonId) {
              return addon;
            } else {
              return {
                ...addon,
                foodWorks: addon.foodWorks.map((existingFoodWork) => {
                  if (existingFoodWork.id !== modifiedFoodWork.id) {
                    return existingFoodWork;
                  } else {
                    return modifiedFoodWork;
                  }
                }),
              };
            }
          }),
        };
      },
      DepartmentsApi.getAddons,
      [departmentId]
    );
  }

  static async deleteFoodWork(
    addonId: number,
    departmentId: number,
    listName: keyof DepartmentAddons,
    FoodWorkToDeleteId: number
  ) {
    await axios.delete(`/api/departmentAddons/foodworks/${FoodWorkToDeleteId}`);

    await RequestCacheHelper.instance.update<DepartmentAddons>(
      (departmentAddons) => {
        if (!departmentAddons) {
          return;
        }

        return {
          ...departmentAddons,
          [listName]: departmentAddons[listName].map((addon) => {
            if (addon.id !== addonId) {
              return addon;
            } else {
              return {
                ...addon,
                foodWorks: addon.foodWorks.filter(
                  (existingFoodWork) =>
                    existingFoodWork.id !== FoodWorkToDeleteId
                ),
              };
            }
          }),
        };
      },
      DepartmentsApi.getAddons,
      [departmentId]
    );
  }
}
