export interface IWindow extends Window {
  webkitSpeechRecognition: any;
}

export class SpeechRecognitionService {
  recognition: any;
  constructor(onResult: (text: string) => void) {
    if ("webkitSpeechRecognition" in window) {
      const { webkitSpeechRecognition } = window as any;

      this.recognition = new webkitSpeechRecognition();
      this.recognition.lang = "he-IL";
      // settings
      //   this.recognition.continuous = false; // stop automatically
      //   this.recognition.interimResults = true;

      this.recognition.onresult = (event) => {
        console.log(event.results[0][0].transcript);
        onResult(event.results[0][0].transcript);
        //   $("#output").text(event.results[0][0].transcript);
      };

      this.recognition.onerror = (event) => {
        console.log(event.error);
      };
    }
  }

  startCapture() {
    this.recognition.start();
  }

  stopCapture() {
    this.recognition.stop();
  }
}
