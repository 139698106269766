import React from "react";
import { FormContextValues } from "react-hook-form";
import { Col, FormGroup } from "react-bootstrap";
import { FormInput } from "base/components/form-controls/FormInput";
import { FormCheckbox } from "base/components/form-controls/FormCheckbox";
import { FormDishDepartmentsSelect } from "dish/components/FormDishDepartmentsSelect";
import { DishFormData } from "../types";

export type DishFormFieldsProps = {
  form: FormContextValues<DishFormData>;
};

export const DishFormFields: React.FC<DishFormFieldsProps> = ({ form }) => {
  return (
    <>
      <FormGroup className="d-flex">
        <Col sm={5} md={4} xl={3}>
          שם
        </Col>
        <Col xs={9} sm={7} md={7} lg={6} xl={5}>
          <FormInput formInstance={form} name="name" />
        </Col>
      </FormGroup>
      <FormGroup className="d-flex">
        <Col sm={5} md={4} xl={3}>
          קוד ישות
        </Col>
        <Col xs={9} sm={7} md={7} lg={6} xl={5}>
          <FormInput formInstance={form} name="id" />
        </Col>
      </FormGroup>
      <FormGroup className="d-flex">
        <Col sm={5} md={4} xl={3}>
          מחיר תפריט
        </Col>
        <Col xs={9} sm={7} md={7} lg={6} xl={5}>
          <FormInput formInstance={form} name="menuPrice" />
        </Col>
      </FormGroup>
      <FormGroup className="d-flex">
        <Col sm={5} md={4} xl={3}>
          זמן הרכבה
        </Col>
        <Col xs={9} sm={7} md={7} lg={6} xl={5}>
          <FormInput formInstance={form} name="assemblingTime" />
        </Col>
      </FormGroup>
      <FormGroup className="d-flex">
        <Col sm={5} md={4} xl={3}>
          מחלקה
        </Col>
        <Col xs={9} sm={7} md={7} lg={6} xl={5}>
          <FormDishDepartmentsSelect formInstance={form} name="departmentId" />
        </Col>
      </FormGroup>
      <FormGroup className="d-flex">
        <Col sm={5} md={4} xl={3}>
          פעיל
        </Col>
        <Col xs={9} sm={7} md={7} lg={6} xl={5}>
          <FormCheckbox formInstance={form} name="isActive" />
        </Col>
      </FormGroup>
    </>
  );
};
