import React, { ButtonHTMLAttributes } from "react";
import classNames from "classnames";
import "./style.scss";

export type RoundButtonProps = {
  size?: number;
  danger?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const RoundButton: React.FC<RoundButtonProps> = ({
  size = 50,
  children,
  style: styleProp,
  className,
  danger,
  ...buttonPops
}) => {
  const style = {
    width: size,
    height: size,
    minWidth: size,
    minHeight: size,
    ...styleProp,
  };

  return (
    <button
      className={classNames(
        "round-button",
        {
          clickable: buttonPops.onClick && !buttonPops.disabled,
          danger,
        },
        className
      )}
      type="submit"
      style={style}
      {...buttonPops}
    >
      {children}
    </button>
  );
};
