import React, { useCallback } from "react";
import { FilterProps } from "react-table";
import { Checkbox } from "base/components/Checkbox";

export const CheckboxColumnFilter = <D extends object>({
  column: { filterValue, setFilter },
}: FilterProps<D>) => {
  const onChange = useCallback(
    (nextValue: boolean) => {
      setFilter(nextValue ? "true" : undefined);
    },
    [setFilter]
  );

  return (
    <Checkbox value={filterValue === "true"} onChange={onChange} width={32} />
  );
};
