import React, { useEffect, useRef } from "react";

export type DocumentKeyupListenerProps = {
  active?: boolean;
  onKeyup: (e: KeyboardEvent) => void;
};

export const DocumentKeyupListener: React.FC<DocumentKeyupListenerProps> = ({
  active = true,
  onKeyup,
  children,
}) => {
  const onKeyupRef = useRef<DocumentKeyupListenerProps["onKeyup"]>(onKeyup);
  onKeyupRef.current = onKeyup;
  useEffect(() => {
    if (active) {
      const fn = function (event: KeyboardEvent) {
        onKeyupRef.current!(event);
      };

      document.addEventListener("keyup", fn);

      return () => document.removeEventListener("keyup", fn);
    }
  }, [active]);

  return <>{children}</>;
};
