import React, { useCallback, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { RouteContent } from "base/components/RouteContent";
import { Typography } from "base/components/Typography";
import { OrdersApi } from "order/OrdersApi";
import { useCustomQueryWithParams } from "base/api/hooks/useCustomQuery";
import { PickIngredients } from "order/components/PickIngredients";
import "./style.scss";
import { Button } from "react-bootstrap";
import { useNavigateCallback } from "base/hooks/useNavigateCallback";
import { OrdersRouteBuilder } from "order/OrdersRouteBuilder";
import { debounce } from "lodash";

export const EditOrderScreen: React.FC = () => {
  const navigate = useNavigateCallback();

  const { orderId } = useParams();
  const { data } = useCustomQueryWithParams(OrdersApi.getOrder, () => [
    orderId,
  ]);

  const [notes, setNotes] = useState(data?.notes);

  const autoSaveNotes = useCallback(
    debounce((notes: string) => {
      OrdersApi.updateNotes(orderId, notes);
    }, 250),
    []
  );

  const onSubmitOrder = useCallback(async () => {
    const {
      supplier: { submitOrderMessage = "האם לשגר?" },
    } = data!;
    if (window.confirm(submitOrderMessage)) {
      await OrdersApi.submitOrder(orderId);
      navigate(OrdersRouteBuilder.buildOrdersStatusRoute());
    }
  }, [data, navigate, orderId]);

  useEffect(() => {
    if (notes !== undefined) {
      autoSaveNotes(notes);
    }
  }, [autoSaveNotes, notes]);

  const { notes: upToDatenotes } = data ?? {};
  useEffect(() => {
    setNotes(upToDatenotes);
  }, [upToDatenotes]);

  return (
    <RouteContent>
      <Typography variant="route-title" className="mb-2">
        הזמנה פתוחה - ספק:{" "}
        <span className="font-weight-bold">{data?.supplier.name}</span>
      </Typography>
      <Typography variant="title" className="mb-2">
        מספר הזמנה: {orderId}
      </Typography>
      <PickIngredients ingredients={data?.ingredients} />
      <Typography variant="description" className="my-2">
        הערות לספק
      </Typography>
      <textarea
        value={notes}
        onChange={(e) => {
          setNotes(e.target.value);
        }}
        className="form-control"
        style={{ maxWidth: 1200 }}
        rows={10}
      />
      <div className="mt-2">
        <Button onClick={onSubmitOrder} variant="success">
          שגר
        </Button>
      </div>
    </RouteContent>
  );
};
