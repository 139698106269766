import styled from "styled-components";

export const Spacer = styled.div<{
  type?: "vertical" | "horizontal";
  units?: number;
}>(({ type = "vertical", units = 2 }) => ({
  display: type === "horizontal" ? "inline-block" : undefined,
  height: type === "vertical" ? units * 4 : undefined,
  width: type === "horizontal" ? units * 4 : undefined,
}));
