import { useState, useEffect } from "react";
import { SpeechRecognitionService } from "base/utils/SpeechRecognitionService";

export const useSpeechService = (
  onResult: (text: string) => void,
  isActive?: boolean
) => {
  // initializes once.
  const [speechService] = useState(
    () => new SpeechRecognitionService(onResult)
  );

  useEffect(() => {
    if (isActive) {
      speechService.startCapture();
    } else {
      speechService.stopCapture();
    }

    return () => speechService.stopCapture();
  }, [isActive, speechService]);
};
