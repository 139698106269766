import React from "react";
import { useParams } from "react-router-dom";
import { RouteContent } from "base/components/RouteContent";
import { TreeInfoRouteParams } from "tree-info/types";
import { BasicTreeInfo } from "basic-tree-info/components/BasicTreeInfo";
import { TreeTypeFormatter } from "base/utils/formatters/TreeTypeFormatter";

export const BasicTreeInfoScreen: React.FC = () => {
  const { itemId: itemIdStr, type: typeStr } = useParams<TreeInfoRouteParams>();

  const itemId = Number(itemIdStr);
  const type = TreeTypeFormatter.formatRoutePathReverse(typeStr);

  return (
    <RouteContent>
      <BasicTreeInfo itemId={itemId} type={type} />
    </RouteContent>
  );
};
