import React, { useCallback } from "react";
import { NumberFormatter } from "base/utils/formatters";
import { AmountInputModal } from "./AmountInputModal";
import { useBooleanState } from "base/hooks/useBooleanState";
import classNames from "classnames";

export type AmountInputProps = {
  value?: number | undefined;
  onChange?: (nextValue: number) => void;
  min?: number;
  numpadTitle?: string;
  fixedDidigts?: number | undefined;
  disabled?: boolean;
};

export const AmountInput: React.FC<AmountInputProps> = ({
  value,
  onChange: onChangeProp,
  min = 0,
  numpadTitle,
  fixedDidigts = undefined,
  disabled = false,
}) => {
  const [showModal, onOpenModal, onHideModal] = useBooleanState(false);

  const onModalSave = useCallback(
    (value: number) => {
      onChangeProp?.(Math.max(value ?? 0, min));
      onHideModal();
    },
    [onHideModal, onChangeProp, min]
  );

  const onPlus = useCallback(() => {
    onChangeProp?.(Number(value ?? 0) + 1);
  }, [value, onChangeProp]);

  const onMinus = useCallback(() => {
    onChangeProp?.(Math.max(Number(value ?? 0) - 1, min));
  }, [value, onChangeProp, min]);

  const formatValue = (value?: number) => {
    if (value === undefined) {
      return undefined;
    }

    if (fixedDidigts === undefined) {
      return parseFloat(
        NumberFormatter.format(value, {
          fractionDigits: 3,
        })
      );
    } else {
      return NumberFormatter.format(value, {
        fractionDigits: fixedDidigts,
      });
    }
  };
  return (
    <>
      <div className={classNames("amount-input", { disabled: disabled })}>
        <div data-op="plus" className="plus" onClick={onPlus}>
          +
        </div>
        <div data-op="value">
          <input
            onClick={onOpenModal}
            type="number"
            step="0.01"
            dir="ltr"
            value={formatValue(value)}
            disabled={disabled}
          />
        </div>
        <div data-op="minus" className="minus" onClick={onMinus}>
          -
        </div>
      </div>
      <AmountInputModal
        show={showModal}
        title={numpadTitle}
        initialValue={value ? Number(value) : undefined}
        onCancel={onHideModal}
        onDone={onModalSave}
      />
    </>
  );
};
