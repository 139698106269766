import { format } from "date-fns";

export class DateFormatter {
  static default = new DateFormatter();

  format(date: Date | string | number | undefined, formatStr?: string) {
    return DateFormatter.format(date, formatStr);
  }

  static format(
    date: Date | string | number | undefined,
    formatStr = "dd/MM/yyyy"
  ) {
    if (!date) {
      return undefined;
    }

    return format(typeof date === "string" ? new Date(date) : date, formatStr);
  }
}
