import React, { useCallback } from "react";
import { BaseSelectListItem } from "base/types";
import { useBooleanState } from "base/hooks/useBooleanState";
import { SelectProps, Select } from "./Select";
import {
  SelectListItemEditorModal,
  SelectListItemEditorModalProps,
} from "select-list-item-editor/components/SelectListItemEditorModal";

export type SelectWithAddProps = Pick<
  SelectListItemEditorModalProps,
  "component" | "render" | "initialValues"
> & {
  customModalRender?: (arg: {
    show: boolean;
    onHide: () => void;
    initialValues?: BaseSelectListItem;
    onItemSaved: (item: { id: BaseSelectListItem["id"] }) => void;
  }) => JSX.Element;
} & SelectProps;

export const SelectWithAdd: React.FC<SelectWithAddProps> = ({
  component,
  render,
  initialValues,
  customModalRender,
  ...selectProps
}) => {
  const [displayAddModal, onShowAddModal, onHideAddModal] = useBooleanState(
    false
  );
  const { onChange } = selectProps;
  const onItemAdded = useCallback(
    (item: { id: BaseSelectListItem["id"] }) => {
      onChange(item.id);
    },
    [onChange]
  );

  if (
    process.env.NODE_ENV === "development" &&
    [render, component, customModalRender].filter((x) => x).length !== 1
  ) {
    throw new Error(
      "SelectWithAdd > Please supply props.component or props.render or props.customModalRender (only one of the above)"
    );
  }

  return (
    <>
      <div className="d-flex">
        <i
          role="button"
          className="fa fa-plus fa-1x align-self-center"
          onClick={selectProps.disabled ? undefined : onShowAddModal}
        />
        <Select {...selectProps} />
      </div>
      {customModalRender &&
        customModalRender({
          show: displayAddModal,
          onHide: onHideAddModal,
          initialValues: initialValues,
          onItemSaved: onItemAdded,
        })}
      {(render || component) && (
        <SelectListItemEditorModal
          show={displayAddModal}
          onHide={onHideAddModal}
          initialValues={initialValues}
          onItemSaved={onItemAdded}
          component={component}
          render={render}
        />
      )}
    </>
  );
};
