import React, { useRef, useState, useLayoutEffect } from "react";

export interface ImageBackgroundMixProps
  extends React.HTMLAttributes<HTMLDivElement> {}

export const ImageBackgroundMix: React.FC<ImageBackgroundMixProps> = (
  props
) => {
  const ref = useRef<HTMLDivElement>(null);
  const [background, setBackground] = useState<string>();

  function getAverageRGB(imgEl: HTMLImageElement) {
    var blockSize = 5, // only visit every 5 pixels
      defaultRGB = { r: 0, g: 0, b: 0 }, // for non-supporting envs
      canvas = document.createElement("canvas"),
      context = canvas.getContext && canvas.getContext("2d"),
      data,
      width,
      height,
      i = -4,
      length,
      rgb = { r: 0, g: 0, b: 0 },
      count = 0;

    if (!context) {
      return defaultRGB;
    }

    height = canvas.height =
      imgEl.naturalHeight || imgEl.offsetHeight || imgEl.height;
    width = canvas.width =
      imgEl.naturalWidth || imgEl.offsetWidth || imgEl.width;

    context.drawImage(imgEl, 0, 0);

    try {
      data = context.getImageData(0, 0, width, height);
    } catch (e) {
      /* security error, img on diff domain */
      return defaultRGB;
    }

    length = data.data.length;

    while ((i += blockSize * 4) < length) {
      ++count;
      rgb.r += data.data[i];
      rgb.g += data.data[i + 1];
      rgb.b += data.data[i + 2];
    }

    // ~~ used to floor values
    rgb.r = ~~(rgb.r / count);
    rgb.g = ~~(rgb.g / count);
    rgb.b = ~~(rgb.b / count);

    return rgb;
  }

  const buildRGB = (rgb: any) => {
    return `rgb(${rgb.r},${rgb.g},${rgb.b})`;
  };

  useLayoutEffect(() => {
    const img = ref.current?.querySelector("img");
    if (img) {
      const image = new Image();
      image.src = img.src;
      image.onload = function () {
        setBackground(buildRGB(getAverageRGB(img)));
      };
    }
  }, []);

  return <div {...props} style={{ background }} ref={ref} />;
};
