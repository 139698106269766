export class TextFormatter {
  static format(text: string, variables: (string | number)[]) {
    let output = text;
    variables.forEach((text, index) => {
      output = output.replace(new RegExp(`\\$${index + 1}`, "g"), String(text));
    });

    return output;
  }
}
