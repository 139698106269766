import { useState } from "react";
import { NestedFieldNameFormatter } from "form/utils/NestedFieldNameFormatter";

export const useNestedFieldNameFormatFN = <NestedValues>() => {
  const [formatter] = useState(
    () => new NestedFieldNameFormatter<NestedValues>()
  );

  return formatter.format;
};
