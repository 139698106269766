import React from "react";
import { FieldMetaState } from "react-final-form";
import { FormErrorMessage } from "./FormErrorMessage";

export type FormMetaErrorProps = {
  meta: FieldMetaState<any>;
};

export const FormMetaError: React.FC<FormMetaErrorProps> = ({ meta }) => {
  return (
    <div>
      <FormErrorMessage>
        {meta.touched ? meta.error : meta.submitError}
      </FormErrorMessage>
    </div>
  );
};
