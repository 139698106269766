import { useEffect } from "react";
import { useNavigateCallback } from "base/hooks/useNavigateCallback";
import { AuthRouteBuilder } from "auth/AuthRouteBuilder";
import { useTokenPayload } from "auth/hooks/useTokenPayload";
import { useStandType } from "auth/hooks/useStandType";
import { useStandTypeRedirectCallback } from "auth/hooks/useStandTypeRedirectCallback";

export const DefaultRouteRedirect: React.FC = () => {
  const tokenPayload = useTokenPayload();
  const standType = useStandType();
  const navigate = useNavigateCallback();
  const standTypeRedirect = useStandTypeRedirectCallback();

  useEffect(() => {
    if (!tokenPayload) {
      navigate(AuthRouteBuilder.buildLoginRoute(), { replace: true });
    } else {
      standTypeRedirect(standType, { replace: true });
    }
  }, [navigate, tokenPayload, standType, standTypeRedirect]);

  return null;
};
