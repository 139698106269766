import React from "react";
import { RouteContent } from "base/components/RouteContent";
import { OrdersRouteBuilder } from "order/OrdersRouteBuilder";
import { OrderWizardTopBar } from "order/components/OrderWizardTopBar";
import "order/styles/styles.scss";
import { useNavigateCallback } from "base/hooks/useNavigateCallback";

export const AddOrderScreen: React.FC = () => {
  const navigate = useNavigateCallback();

  return (
    <RouteContent className="AddOrderScreen">
      <OrderWizardTopBar />
      <div className="items">
        <div
          className="item"
          onClick={() => navigate(OrdersRouteBuilder.buildSupplierListRoute())}
        >
          <img
            src="/Content/BaseItemImages/placeholder.png"
            alt="Choose Supplier"
          />
          <div className="text">
            הזמנה
            <br /> לפי ספק
          </div>
        </div>
        <div
          className="item"
          onClick={() =>
            navigate(OrdersRouteBuilder.buildDepartmentListRoute())
          }
        >
          <img
            src="/Content/BaseItemImages/placeholder.png"
            alt="Choose Department"
          />
          <div className="text">
            הזמנה
            <br /> לפי מחלקה
          </div>
        </div>
        <div
          className="item"
          onClick={() =>
            navigate(OrdersRouteBuilder.buildOrderProductsSearchRoute())
          }
        >
          <img
            src="/Content/BaseItemImages/placeholder.png"
            alt="Find Product"
          />
          <div className="text">
            הזמנה
            <br /> לפי מוצר
          </div>
        </div>
      </div>
    </RouteContent>
  );
};
