import React from "react";
import { TreeType } from "base/types/TreeType";
import { TreeInfoMaterialHeader } from "./TreeInfoMaterialHeader";
import { TreeInfoDishHeader } from "./TreeInfoDishHeader";

export type TreeInfoHeaderProps = {
  itemId: number;
  type: TreeType;
};

export const TreeInfoHeader: React.FC<TreeInfoHeaderProps> = ({
  type,
  itemId,
}) => {
  switch (type) {
    case TreeType.Material:
      return <TreeInfoMaterialHeader itemId={itemId} />;
    case TreeType.Dish:
      return <TreeInfoDishHeader itemId={itemId} />;
  }
};
