import React from "react";
import { RouteContent } from "base/components/RouteContent";
import { Typography } from "base/components/Typography";
import { OrdersTable } from "order/components/OrdersTable";

export const OrdersScreen: React.FC = () => {
  return (
    <RouteContent>
      <Typography variant="route-title">הזמנות ממתינות לשיגור</Typography>
      <OrdersTable />
    </RouteContent>
  );
};
