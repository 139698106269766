import React from "react";
import { RoundButtonProps, RoundButton } from "./RoundButton";

export const RoundCheckButton: React.FC<RoundButtonProps> = (props) => {
  return (
    <RoundButton {...props}>
      <i className="fa fa-check fa-lg" />
    </RoundButton>
  );
};
