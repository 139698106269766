// import { TreePermanentFoodWork } from "food-works/tree-food-works/types";

import { NutritionalItem } from "nutritional/types";

export type { DishListItem } from "./DishListItem";
export type { Dish } from "./Dish";
export type { DishMaterial } from "./DishMaterial";
export type { DishIngredient } from "./DishIngredient";
export type { FoodTreeListSearchOptions } from "./FoodTreeListSearchOptions";
export type { EditDishRouteParams } from "./EditDishRouteParams";

export enum DishVEStepType {
  Welcome = "Welcome",
  DishType = "DishType",
  MenuPrice = "MenuPrice",
  VisualEditor = "VisualEditor",
}

export interface StorageUnitItem {
  id: number;
  name: string;
  imageName?: string;
  prepareUnitType: number;
  price: number;
}

export interface StorageUnit {
  id: number;
  name: string;
  imageName?: string;
  ingredients: StorageUnitItem[];
  materials: StorageUnitItem[];
}

export interface DishVEItem extends StorageUnitItem {
  amount: number;
}

export interface DishVE {
  id: number;
  menuPrice: number;
  name: string;
  image?: File;
  imageName: string; // GET
  materials: DishVEItem[];
  ingredients: DishVEItem[];
  nutritionals: NutritionalItem[];
  // foodWorks: TreePermanentFoodWork[];
}

export interface DishVEWizardFormValues extends DishVE {
  dishType?: "hot" | "cold";
}
