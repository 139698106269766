import React, { useMemo } from "react";
import { Field } from "react-final-form";
import { useCustomQueryWithParams } from "base/api/hooks/useCustomQuery";
import { Spacer } from "base/components/Spacer";
import { TreeType } from "base/types/TreeType";
import { FormTextInput } from "form/components/FormTextInput";
import { FormCheckbox } from "form/components/FormCheckbox";
import { useFieldNameFormatFN } from "form/hooks/useFieldNameFormatFN";
import { TreeInfo } from "tree-info/types";
import { BasicTreeInfoApi } from "basic-tree-info/BasicTreeInfoApi";
import { FormSelect } from "form/components/FormSelect";
import { FormNumberInput } from "form/components/FormNumberInput";

export type BasicTreeFieldsProps = {
  type: TreeType;
};

export const BasicTreeFields: React.FC<BasicTreeFieldsProps> = ({ type }) => {
  const formatFieldName = useFieldNameFormatFN<TreeInfo>();
  const {
    data: departments,
  } = useCustomQueryWithParams(BasicTreeInfoApi.getDepartments, () => [type]);

  const departmentOptions = useMemo(() => {
    return (
      departments?.map((item) => ({
        value: item.id,
        label: item.name,
      })) || []
    );
  }, [departments]);

  return (
    <div className="basic-tree-info-fields">
      <div data-id>
        <span>מס</span>
        <Spacer type="horizontal" />
        <Field
          name={formatFieldName("itemId")}
          render={({ input }) => <span data-id-text>{input.value}</span>}
        />
      </div>
      <div>
        <label data-is-active>
          פעיל
          <div className="big-checkbox d-inline-block">
            {/*@ts-ignore*/}
            <FormCheckbox name={formatFieldName("isActive")} />
          </div>
        </label>
      </div>

      <div data-name-label data-g-header>
        שם המנה
      </div>
      <div data-name-input>
        <FormTextInput
          name={formatFieldName("itemName")}
          className="basic-tree-info-input"
        />
      </div>

      <div data-price-label data-g-header>
        מחיר עלות
      </div>
      <div data-price-input>
        <input className="basic-tree-info-input" />
      </div>

      <div data-depart-label data-g-header>
        מחלקה
      </div>
      <div data-depart-input>
        <FormSelect
          //@ts-ignore
          name={formatFieldName("departmentId")}
          options={departmentOptions}
          applyCustomSelectClass={false}
          className="basic-tree-info-input"
          emptyOptionLabel="בחר מחלקה"
        />
      </div>

      <div data-menu-price-label data-g-header>
        מחיר תפריט
      </div>
      <div data-menu-price-input>
        <FormNumberInput
          //@ts-ignore
          name={formatFieldName("menuPrice")}
          className="basic-tree-info-input"
        />
      </div>

      <div data-per-cost-label data-g-header>
        אחוז עלות מהמנה
      </div>
      <div data-per-cost-value>
        <input className="basic-tree-info-input" />
      </div>

      <div data-image-label data-g-header>
        תמונה
      </div>
      <div data-image-input />
    </div>
  );
};
