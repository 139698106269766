export class IngredientsRouteBuilder {
  static buildIngredientsRoute() {
    return `/ingredients`;
  }

  static buildAddIngredientRoute() {
    return `/ingredients/new`;
  }

  static buildEditIngredientRoute(ingredientId: number) {
    return `/ingredients/${ingredientId}`;
  }
}
