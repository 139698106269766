import React from "react";
import { RouteContent } from "base/components/RouteContent";
import { SupplierList } from "order/components/SupplierList";
import { OrderWizardTopBar } from "order/components/OrderWizardTopBar";

export const SupplierListScreen: React.FC = () => {
  return (
    <RouteContent className="OrderSupplierListScreen">
      <OrderWizardTopBar />
      <SupplierList />
    </RouteContent>
  );
};
