import Axios from "axios";
import { RequestCacheHelper } from "base/api/RequestCacheHelper";
import { BodyFormDataBuilder } from "base/api/BodyFormDataBuilder";

export class SimpleGlobalListApi {
  static getList(routeApiPath: string) {
    return Axios.get<any[]>(`/api/${routeApiPath}`);
  }

  static async saveItem(
    routeApiPath: string,
    item: any,
    idAccessor = "id",
    isFormData = false
  ) {
    const { data: savedItem } = await Axios.post<any>(
      `/api/${routeApiPath}`,
      isFormData ? BodyFormDataBuilder.build2(item) : item,
      isFormData
        ? {
            headers: { "Content-Type": "multipart/form-data" },
          }
        : undefined
    );
    const isNew = item[idAccessor] === undefined;

    await RequestCacheHelper.instance.update<any[]>(
      (list) => {
        if (isNew) {
          return [...list!, savedItem];
        } else {
          return list!.map((cachedItem) => {
            if (cachedItem[idAccessor] === savedItem[idAccessor]) {
              return savedItem;
            } else {
              return cachedItem;
            }
          });
        }
      },
      SimpleGlobalListApi.getList,
      [routeApiPath]
    );

    return savedItem;
  }

  static async deleteItem(routeApiPath: string, id: any, idAccessor = "id") {
    await Axios.delete(`/api/${routeApiPath}/${id}`);

    await RequestCacheHelper.instance.update<any[]>(
      (list) => list!.filter((cachedItem) => cachedItem[idAccessor] !== id),
      SimpleGlobalListApi.getList,
      [routeApiPath]
    );
  }
}
