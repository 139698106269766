import React, { useEffect } from "react";
import "./style.scss";
import { useQueryParams } from "base/api/hooks/useQueryParams";
import { useStandType } from "auth/hooks/useStandType";
import { PinCodeLoginScreen } from "./PinCodeLoginScreen";
import { DefaultLoginScreen } from "./DefaultLoginScreen";
import { useAppContext } from "app/context/hooks/useAppContext";
import { AuthStandType } from "auth/types";
import { useNavigateCallback } from "base/hooks/useNavigateCallback";
import { AuthRouteBuilder } from "auth/AuthRouteBuilder";

export const LoginScreen: React.FC = () => {
  const standType = useStandType();

  // Temp Code - Start
  const navigate = useNavigateCallback();
  const { store } = useAppContext();
  const queryParams = useQueryParams();
  const qp_standType = queryParams.get("type");

  useEffect(() => {
    if (qp_standType) {
      switch (qp_standType as AuthStandType) {
        case "chef":
          store.setStandType("chef");
          break;
        case "receiveOrder":
          store.setStandType("receiveOrder");
          break;
        case "dish-editor":
          store.setStandType("dish-editor");
          break;
        case "default":
        default:
          store.setStandType("default");
          break;
      }
      navigate(AuthRouteBuilder.buildLoginRoute(), {
        replace: true,
      });
    }
  }, [navigate, qp_standType, store]);

  if (qp_standType) {
    return null;
  }
  // Temp Code - End

  switch (standType) {
    case "chef":
    case "receiveOrder":
    case "dish-editor":
      return <PinCodeLoginScreen />;
    case "default":
    default:
      return <DefaultLoginScreen />;
  }
};
