import React, { useCallback, useEffect } from "react";
import { useOrderWizardContext } from "order/context/hooks/useOrderWizardContext";
import { Typography } from "base/components/Typography";
import { FormControl } from "react-bootstrap";
import { useObserver } from "mobx-react";
import { useRouteMatch, useLocation } from "react-router-dom";
import { OrdersRouteBuilder } from "order/OrdersRouteBuilder";
import { useNavigateCallback } from "base/hooks/useNavigateCallback";
import { toJS } from "mobx";
import "order/styles/styles.scss";

export const OrderWizardTopBar = () => {
  const navigate = useNavigateCallback();
  const { store } = useOrderWizardContext();
  const location = useLocation();
  const isHomeRoute =
    useRouteMatch([OrdersRouteBuilder.templates.AddOrderRoute])?.isExact ??
    false;

  const observables = useObserver(() => ({
    routeSearchPhrases: toJS(store.routeSearchPhrases),
  }));

  const phrase = observables.routeSearchPhrases[location.pathname];

  const onPhraseChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const nextValue = e.target.value;
      store.setRouteSearchPhrase(location.pathname, nextValue);
    },
    [location.pathname, store]
  );

  useEffect(() => {
    if (phrase && isHomeRoute) {
      const timeoutId = setTimeout(() => {
        store.setRouteSearchPhrase(
          OrdersRouteBuilder.buildOrderProductsSearchRoute(),
          phrase
        );
        navigate(OrdersRouteBuilder.buildOrderProductsSearchRoute());
        store.setRouteSearchPhrase(OrdersRouteBuilder.buildAddOrderRoute(), "");
      }, 100);
      return () => clearTimeout(timeoutId);
    }
  }, [isHomeRoute, navigate, phrase, store]);

  return (
    <div className="OrderWizardTopBar">
      <Typography variant="title" className="text-white p-0 m-0">
        יצירת הזמנה חדשה
      </Typography>
      <div className="d-flex mt-2 justify-content-between">
        <div className="d-flex align-items-center input-col">
          <i className="fas fa-search input-icon right-side" />
          <FormControl
            value={phrase}
            onChange={onPhraseChange}
            placeholder="סינון לפי שם מוצר"
            className="filter-input"
          />
          <i className="fas fa-chevron-left input-icon left-side" />
        </div>
        <div className="d-flex align-items-center mr-3">
          <i className="fas fa-filter active action-icon" role="button" />
          <i className="fas fa-th action-icon" role="button" />
          <i className="fas fa-th-list active action-icon" role="button" />
        </div>
      </div>
    </div>
  );
};
