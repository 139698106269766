import React, { useCallback, useMemo, useState } from "react";
import { isEqual } from "lodash";
import { Field, FormSpy } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { debounce } from "lodash";
import { Button } from "react-bootstrap";
import { UnitTypeFormatter, NumberFormatter } from "base/utils/formatters";
import { Typography } from "base/components/Typography";
import { AmountInput } from "base/components/input-controls/AmountInput";
import { TreeType } from "base/types/TreeType";
import { useNestedFieldNameFormatFN } from "form/hooks/useNestedFieldNameFormatFN";
import { TreeItemFoodWorkType } from "food-works/tree-item-food-works/types/TreeItemFoodWorkType";
import { TreeItemFoodWorksModal } from "food-works/tree-item-food-works/components/TreeItemFoodWorksModal";
import { TreeInfo, TreeInfoFoodListItem } from "tree-info/types";
import { TreeInfoApi } from "tree-info/TreeInfoApi";
import { TreeInfoAddSection } from "./TreeInfoAddSection";
import { UnitType } from "base/types";
import { Link } from "react-router-dom";
import { IngredientsRouteBuilder } from "ingredient/IngredientsRouteBuilder";
import { TreeInfoRouteBuilder } from "tree-info/TreeInfoRouteBuilder";
import { TreeInfoHelper } from "tree-info/TreeInfoHelper";

export type TreeInfoFoodsTableProps = {
  type: TreeType;
  id: number;
  listName: "materials" | "ingredients";
};

type TreeItemFoodWorksModalState =
  | {
      show: false;
      type: undefined;
      id: undefined;
      itemId: undefined;
      departmentId: undefined;
    }
  | {
      show: true;
      type: TreeItemFoodWorkType;
      id: number;
      itemId: number;
      departmentId?: number;
    };

export const TreeInfoFoodsTable: React.FC<TreeInfoFoodsTableProps> = ({
  type,
  id,
  listName,
}) => {
  const [itemFoodWorksModalState, setTreeItemFoodWorksModalState] = useState<
    TreeItemFoodWorksModalState
  >({
    show: false,
    type: undefined,
    id: undefined,
    itemId: undefined,
    departmentId: undefined,
  });

  const formatFieldName = useNestedFieldNameFormatFN<TreeInfoFoodListItem>();

  const onListItemRemoved = useCallback(
    async (listItemId: number) => {
      if (listName === "materials") {
        await TreeInfoApi.removeMaterial(type, id, listItemId);
      } else {
        await TreeInfoApi.removeIngredient(type, id, listItemId);
      }
    },
    [id, listName, type]
  );

  const onRowChanged = useMemo(
    () =>
      debounce(async (row: TreeInfoFoodListItem) => {
        if (listName === "materials") {
          await TreeInfoApi.updateMaterial(type, id, row.id, row.amount);
        } else {
          await TreeInfoApi.updateIngredient(type, id, row.id, row.amount);
        }
      }, 500),
    [id, listName, type]
  );

  const onOpenTreeItemFoodWorks = useCallback(
    async (id: number, itemId: number, departmentId?: number) => {
      if (listName === "materials") {
        setTreeItemFoodWorksModalState({
          show: true,
          type: "material",
          id,
          itemId,
        });
      } else {
        setTreeItemFoodWorksModalState({
          show: true,
          type: "ingredient",
          id,
          itemId,
          departmentId,
        });
      }
    },
    [listName]
  );

  const onHideItemFoodWorks = useCallback(() => {
    setTreeItemFoodWorksModalState({
      show: false,
      type: undefined,
      id: undefined,
      itemId: undefined,
      departmentId: undefined,
    });
  }, []);

  return (
    <>
      <FormSpy<Partial<TreeInfo>>
        subscription={{ values: true }}
        render={({ values }) => {
          const itemsLength = values[listName]?.length ?? 0;

          const headerText =
            listName === "ingredients"
              ? `מרכיבים(${itemsLength})`
              : `חומרי גלם מעובדים(${itemsLength})`;

          return <Typography variant="title">{headerText}</Typography>;
        }}
      />
      <div className="table-container">
        <table className="table table-sm tree-info-foods-table">
          <thead>
            <tr>
              <th style={{ width: "20%" }}>שם</th>
              <th style={{ width: "15%" }}>כמות</th>
              <th>כמות לאחר ע.</th>
              <th>תמחיר</th>
              <th>עיבודים</th>
              <th>תמחיר לאחר ע.</th>
              <th>מחיר לכמות</th>
              <th />
            </tr>
          </thead>
          <tbody>
            <FieldArray<TreeInfoFoodListItem> name={listName} isEqual={isEqual}>
              {({ fields }) => (
                <>
                  {fields.map((nestingPrefix, index) => (
                    <tr key={fields.value[index].id}>
                      <Field
                        name={formatFieldName(nestingPrefix, "name")}
                        render={({ input }) => (
                          <td>
                            <Link
                              to={
                                listName === "ingredients"
                                  ? IngredientsRouteBuilder.buildEditIngredientRoute(
                                      fields.value[index].itemId
                                    )
                                  : TreeInfoRouteBuilder.buildTreeInfoRoute(
                                      TreeType.Material,
                                      fields.value[index].itemId
                                    )
                              }
                            >
                              {input.value}
                            </Link>
                          </td>
                        )}
                      />
                      <td>
                        <div className="d-flex align-items-center">
                          <Field
                            name={formatFieldName(nestingPrefix, "amount")}
                            render={({ input }) => (
                              <AmountInput
                                fixedDidigts={0}
                                numpadTitle="בחר כמות"
                                value={input.value}
                                onChange={input.onChange}
                                min={-Infinity}
                              />
                            )}
                          />
                          <Field
                            name={formatFieldName(nestingPrefix, "unitType")}
                            render={({ input }) => (
                              <span className="mx-2">{input.value}</span>
                            )}
                            format={(value) =>
                              UnitTypeFormatter.default.format(value, true)
                            }
                          />
                        </div>
                      </td>
                      <FormSpy<TreeInfo>
                        subscription={{ values: true }}
                        render={({ values }) => {
                          const listItem = values[listName][index];
                          const { amount = 0, foodWorks } = listItem;
                          const amountAfterFoodWorks = TreeInfoHelper.calcAmountAfterFoodWorks(
                            amount,
                            foodWorks
                          );

                          return (
                            <td>
                              <AmountInput
                                fixedDidigts={0}
                                numpadTitle="בחר כמות לאחר עיבוד"
                                value={amountAfterFoodWorks}
                                onChange={(nextAmountAfterFoodWorks) => {
                                  const percentageDiff =
                                    nextAmountAfterFoodWorks /
                                    amountAfterFoodWorks;

                                  const nextAmount = amount * percentageDiff;
                                  fields.update(index, {
                                    ...listItem,
                                    amount: nextAmount,
                                  });
                                }}
                                min={-Infinity}
                              />
                            </td>
                          );
                        }}
                      />
                      <Field
                        name={formatFieldName(nestingPrefix, "basicPrice")}
                        render={({ input }) => (
                          <td>
                            {NumberFormatter.default.formatPrice(input.value)}
                          </td>
                        )}
                      />

                      <td>
                        <Button
                          variant="info"
                          size="sm"
                          onClick={() => {
                            onOpenTreeItemFoodWorks(
                              fields.value[index].id,
                              fields.value[index].itemId,
                              fields.value[index].departmentId
                            );
                          }}
                        >
                          ניהול
                        </Button>
                      </td>
                      {/* תמחיר לאחר ע */}
                      <FormSpy
                        subscription={{ values: true }}
                        render={() => {
                          const { basicPrice = 0, foodWorks } = fields.value[
                            index
                          ];
                          const price = TreeInfoHelper.calcPriceAfterFoodWorks(
                            basicPrice,
                            foodWorks
                          );

                          return (
                            <td dir="ltr">
                              {NumberFormatter.default.formatPrice(price)}
                            </td>
                          );
                        }}
                      />
                      {/* מחיר לכמות */}
                      <FormSpy
                        subscription={{ values: true }}
                        render={() => {
                          const {
                            basicPrice = 0,
                            amount = 0,
                            foodWorks,
                            unitType,
                          } = fields.value[index];

                          const amountAfterFoodWorks = TreeInfoHelper.calcAmountAfterFoodWorks(
                            amount,
                            foodWorks
                          );
                          const priceAfterFoodWorks = TreeInfoHelper.calcPriceAfterFoodWorks(
                            basicPrice,
                            foodWorks
                          );

                          const value =
                            amountAfterFoodWorks * priceAfterFoodWorks;

                          const normalizedValue =
                            unitType !== UnitType.Yeh ? value / 1000 : value;

                          return (
                            <td dir="ltr">
                              {NumberFormatter.default.formatPrice(
                                normalizedValue
                              )}
                            </td>
                          );
                        }}
                      />
                      <td>
                        <button
                          className="btn btn-sm btn-danger"
                          onClick={() => {
                            onListItemRemoved(fields.value[index].id);
                            fields.remove(index);
                          }}
                        >
                          מחיקה
                        </button>
                      </td>
                      {/* this FormSpy handles row changes. */}
                      <FormSpy<Partial<TreeInfo>>
                        subscription={{
                          values: true,
                          initialValues: true,
                        }}
                        onChange={async ({ values, initialValues }) => {
                          const fieldId = fields.value[index].id;
                          const nextRowValue = values[listName]?.find(
                            (x) => x.id === fieldId
                          );

                          if (
                            nextRowValue &&
                            !isEqual(
                              nextRowValue,
                              initialValues[listName]?.[index]
                            )
                          ) {
                            await onRowChanged(nextRowValue!);
                          }
                        }}
                      />
                    </tr>
                  ))}
                </>
              )}
            </FieldArray>
          </tbody>
          <tfoot>
            <tr className="totalRow">
              <td />
              <td />
              <td />
              <td />
              <td />
              <td />
              <FormSpy<Partial<TreeInfo>>
                subscription={{ values: true }}
                render={({ values }) => {
                  const totalAmount = (values[listName] || []).reduce(
                    (total, item) => {
                      const {
                        basicPrice = 0,
                        amount = 0,
                        foodWorks,
                        unitType,
                      } = item;

                      const amountAfterFoodWorks = TreeInfoHelper.calcAmountAfterFoodWorks(
                        amount,
                        foodWorks
                      );
                      const priceAfterFoodWorks = TreeInfoHelper.calcPriceAfterFoodWorks(
                        basicPrice,
                        foodWorks
                      );

                      const value = amountAfterFoodWorks * priceAfterFoodWorks;

                      const normalizedValue =
                        unitType !== UnitType.Yeh ? value / 1000 : value;

                      return total + normalizedValue;
                    },
                    0
                  );

                  return (
                    <td>{NumberFormatter.default.formatPrice(totalAmount)}</td>
                  );
                }}
              />
              <td />
            </tr>
          </tfoot>
        </table>
      </div>
      <TreeInfoAddSection type={type} listName={listName} id={id} />
      <TreeItemFoodWorksModal
        departmentId={itemFoodWorksModalState.departmentId}
        show={itemFoodWorksModalState.show}
        treeItemType={itemFoodWorksModalState.type}
        treeType={type}
        treeItemId={itemFoodWorksModalState.id}
        itemId={itemFoodWorksModalState.itemId}
        onCancel={onHideItemFoodWorks}
      />
    </>
  );
};
