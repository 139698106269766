import React from "react";
import { AuthHelperMatchOptions, AuthHelper } from "auth/AuthHelper";
import { useTokenPayload } from "auth/hooks/useTokenPayload";

export interface AuthRendererProps extends AuthHelperMatchOptions {
  children: React.ReactNode;
}

export const AuthRenderer: React.FC<AuthRendererProps> = ({
  children,
  ...matchOptions
}) => {
  const tokenPayload = useTokenPayload();

  if (AuthHelper.match(tokenPayload, { ...matchOptions })) {
    return <>{children}</>;
  } else {
    return null;
  }
};
