import React, { useCallback, useEffect } from "react";
import classNames from "classnames";
import { BaseImage } from "base/components/BaseFoodImage";
import { SentOrderItemGet } from "sent-order/types";
import { NumpadModal } from "base/components/NumpadModal/NumpadModal";
import { SentOrderRemoteWeightModal } from "./SentOrderRemoteWeightModal";
import { UnitTypeFormatter, NumberFormatter } from "base/utils/formatters";
import { SupplyUnitType, BaseSelectListItem } from "base/types";
import { SupplyMethodDescFormatter } from "order/SupplyMethodDescFormatter";
import { SupplyMethodPicker } from "supply-methods/SupplyMethodPicker";
import { useBooleanState } from "base/hooks/useBooleanState";
import { BaseSupplyMethod } from "base/types/BaseSupplyMethod";
import { OrderHelper } from "order/OrderHelper";
import {
  useCustomQueryWithParams,
  CustomQueryResponse,
} from "base/api/hooks/useCustomQuery";
import { SimpleGlobalListApi } from "global-list/SimpleGlobalListApi";
import { SimpleGlobalListRoutePath } from "global-list/types";
import { useImmer } from "use-immer";
import { PackageWeightsPicker } from "package-weights/PackageWeightsPicker";
import { WritableDraft } from "immer/dist/internal";

export type SentOrderFoodItemModalProps = {
  show: boolean;
  item: SentOrderItemGet | undefined;
  onDone: (modifedItem: SentOrderItemGet) => void;
  onCancel: () => void;
  disabled?: boolean;
};

export const SentOrderFoodItemModal: React.FC<SentOrderFoodItemModalProps> = ({
  item,
  show,
  onCancel,
  onDone,
  disabled = false,
}) => {
  const {
    data: unitKinds,
  } = useCustomQueryWithParams(
    SimpleGlobalListApi.getList,
    () => [SimpleGlobalListRoutePath.UnitKinds],
    { skip: !show }
  ) as CustomQueryResponse<BaseSelectListItem[]>;
  const {
    data: packageKinds,
  } = useCustomQueryWithParams(
    SimpleGlobalListApi.getList,
    () => [SimpleGlobalListRoutePath.PackageKinds],
    { skip: !show }
  ) as CustomQueryResponse<BaseSelectListItem[]>;

  const [draftItem, updateDraftItem] = useImmer(item);
  const [showNumpad, onOpenNumpad, onHideNumpad] = useBooleanState(false);
  const [
    showRemoteWeight,
    onOpenRemoteWeight,
    onHideRemoteWeight,
  ] = useBooleanState(false);
  const [
    showSupplyMethodPicker,
    onDisplaySupplyMethodPicker,
    onHideSupplyMethodPicker,
  ] = useBooleanState(false);
  const [
    showPackageWeightsPicker,
    onDisplayPackageWeightsPicker,
    onHidePackageWeightsPicker,
  ] = useBooleanState(false);

  const makeWeightUpdates = useCallback(
    (
      draft: SentOrderItemGet | WritableDraft<SentOrderItemGet>,
      nextWeight: number
    ) => {
      draft.receivedWeight = nextWeight;

      if (draft.receivedSupplyMethod.supplyUnitType === SupplyUnitType.Amount) {
        draft.receivedAmount = nextWeight;
      }
    },
    []
  );

  const onNumpadSave = useCallback(
    (value: number) => {
      updateDraftItem((curr) => {
        curr!.receivedAmount = value;
        if (
          OrderHelper.isWeightable(curr!) &&
          curr!.receivedSupplyMethod.supplyUnitType === SupplyUnitType.Amount
        ) {
          curr!.receivedWeight = value;
        }
      });
      onHideNumpad();
    },
    [onHideNumpad, updateDraftItem]
  );

  const onWeightReceived = useCallback(
    (value: number) => {
      updateDraftItem((curr) => {
        makeWeightUpdates(curr!, curr!.receivedWeight + value);
        if (!curr!.weights) {
          curr!.weights = [];
        }

        curr!.weights.push(value);
      });
    },
    [makeWeightUpdates, updateDraftItem]
  );

  const onSupplyMethodPicked = useCallback(
    (supplyMethod: BaseSupplyMethod) => {
      updateDraftItem((curr) => {
        curr!.receivedSupplyMethod = supplyMethod;
      });
      onHideSupplyMethodPicker();
    },
    [onHideSupplyMethodPicker, updateDraftItem]
  );

  const onSave = useCallback(() => {
    onDone(draftItem!);
  }, [onDone, draftItem]);

  useEffect(() => {
    updateDraftItem(item);
  }, [item, updateDraftItem]);

  if (!draftItem || !show) {
    return null;
  }

  return (
    <>
      <div
        className={classNames("edit-sent-item-container", {
          weightable: OrderHelper.isWeightable(draftItem),
        })}
      >
        <div
          className="backdrop"
          style={{ position: "absolute", left: 0, right: 0, bottom: 0, top: 0 }}
          onClick={onCancel}
        />
        <div className="sentItem">
          <div className="close-button" onClick={onCancel}>
            <i className="fas fa-times" />
          </div>
          <div className="header" />
          <div className="icon" />
          <div className="title">
            <h1 data-name>{draftItem.name}</h1>
          </div>
          <div className="center-row"> </div>
          <div
            className={classNames("weight", { disabled })}
            onClick={onOpenRemoteWeight}
          >
            <h3>הנח הסחורה ולחץ לשקילה</h3>
            <img src="/content/images/scale.png" alt="פתיחת מסך שקילה" />
          </div>
          {/* <Button onClick={onDisplayPackageWeightsPicker}>
            הפחתת משקל אריזה
          </Button> */}
          <div className="left-weight-area" />
          <div className="middle-weight-headers">
            <h1 className="m-0">כמות נדרשת:</h1>

            <h1 className="m-0 mb-3">כמות התקבלה:</h1>
          </div>
          <div className="middle-weight-weights">
            <div className="measure">
              {OrderHelper.isWeightable(draftItem) && (
                <div>
                  <h1 className="mt-0">
                    {NumberFormatter.default.formatAmount(
                      OrderHelper.calcTotalAmount(
                        draftItem.requestedAmount,
                        draftItem.requestedSupplyMethod,
                        draftItem.prepareUnitType
                      )
                    )}
                  </h1>
                  <h3 className="m-0 mb-2 mr-3">
                    {UnitTypeFormatter.format(draftItem.prepareUnitType)}
                  </h3>
                </div>
              )}
              {!OrderHelper.isWeightable(draftItem) && (
                <div>
                  <h1 className="mt-0">
                    {NumberFormatter.default.formatAmount(
                      draftItem.requestedAmount
                    )}
                  </h1>
                  <h3 className="m-0 mb-2 mr-3">
                    {SupplyMethodDescFormatter.formatTopPackage(
                      draftItem.requestedSupplyMethod,
                      draftItem.prepareUnitType,
                      unitKinds,
                      packageKinds
                    )}
                  </h3>
                </div>
              )}
              <div>
                <span>
                  {SupplyMethodDescFormatter.format(
                    draftItem.requestedSupplyMethod,
                    draftItem.prepareUnitType,
                    unitKinds,
                    packageKinds
                  )}
                </span>
                {draftItem.requestedSupplyMethod.supplyUnitType !==
                  SupplyUnitType.Amount &&
                  OrderHelper.isWeightable(draftItem) && (
                    <h5>
                      סה״כ כמות:{" "}
                      <span className="font-weight-bold">
                        {NumberFormatter.default.formatAmount(
                          draftItem.requestedAmount
                        )}{" "}
                        {SupplyMethodDescFormatter.formatTopPackage(
                          draftItem.requestedSupplyMethod,
                          draftItem.prepareUnitType,
                          unitKinds,
                          packageKinds
                        )}
                      </span>
                    </h5>
                  )}
                {draftItem.requestedSupplyMethod.supplyUnitType !==
                  SupplyUnitType.Amount &&
                  !OrderHelper.isWeightable(draftItem) && (
                    <h5>
                      סה״כ כמות:{" "}
                      <span className="font-weight-bold">
                        {NumberFormatter.default.formatAmount(
                          OrderHelper.calcTotalAmount(
                            draftItem.requestedAmount,
                            draftItem.requestedSupplyMethod,
                            draftItem.prepareUnitType
                          )
                        )}{" "}
                        {UnitTypeFormatter.format(draftItem.prepareUnitType)}
                      </span>
                    </h5>
                  )}
              </div>
            </div>
            <div className="measure">
              <div>
                <h1 className="m-0">
                  {OrderHelper.isWeightable(draftItem) &&
                    NumberFormatter.formatAmount(draftItem.receivedWeight)}{" "}
                  {!OrderHelper.isWeightable(draftItem) &&
                    NumberFormatter.formatAmount(draftItem.receivedAmount)}{" "}
                </h1>
                <h3 className="m-3">
                  {OrderHelper.isWeightable(draftItem) &&
                    UnitTypeFormatter.format(draftItem.prepareUnitType)}
                  {!OrderHelper.isWeightable(draftItem) &&
                    SupplyMethodDescFormatter.formatTopPackage(
                      draftItem.receivedSupplyMethod,
                      draftItem.prepareUnitType,
                      unitKinds,
                      packageKinds
                    )}
                </h3>
                {!OrderHelper.isWeightable(draftItem) && (
                  <i
                    className={classNames("fas fa-pen edit-amount", {
                      disabled,
                    })}
                    onClick={onOpenNumpad}
                  />
                )}
              </div>
              <div>
                {" "}
                {draftItem.supplyMethods.length > 0 && (
                  <i
                    className={classNames("fas fa-cog", { disabled })}
                    role="button"
                    onClick={onDisplaySupplyMethodPicker}
                  />
                )}{" "}
                {SupplyMethodDescFormatter.format(
                  draftItem.receivedSupplyMethod,
                  draftItem.prepareUnitType,
                  unitKinds,
                  packageKinds
                )}
                {OrderHelper.isWeightable(draftItem) &&
                  draftItem.receivedSupplyMethod.supplyUnitType !==
                    SupplyUnitType.Amount && (
                    <h5>
                      סה״כ כמות:{" "}
                      <span className="font-weight-bold">
                        {NumberFormatter.default.formatAmount(
                          draftItem.receivedAmount
                        )}{" "}
                        {SupplyMethodDescFormatter.formatTopPackage(
                          draftItem.receivedSupplyMethod,
                          draftItem.prepareUnitType,
                          unitKinds,
                          packageKinds
                        )}
                      </span>
                      <i
                        className={classNames("fas fa-pen edit-amount", {
                          disabled,
                        })}
                        onClick={onOpenNumpad}
                      />
                    </h5>
                  )}
                {!OrderHelper.isWeightable(draftItem) &&
                  draftItem.receivedSupplyMethod.supplyUnitType !==
                    SupplyUnitType.Amount && (
                    <h5>
                      סה"כ כמות:{" "}
                      <span className="font-weight-bold">
                        {NumberFormatter.default.formatAmount(
                          OrderHelper.calcTotalAmount(
                            draftItem.receivedAmount,
                            draftItem.receivedSupplyMethod,
                            draftItem.prepareUnitType
                          )
                        )}{" "}
                        {UnitTypeFormatter.format(draftItem.prepareUnitType)}
                      </span>
                    </h5>
                  )}
              </div>
            </div>
          </div>
          <div className="save">
            <div className="container">
              <div className="row">
                <div className="col">
                  <button
                    data-op="save"
                    className="btn btn-xl btn-primary"
                    onClick={onSave}
                    disabled={disabled}
                  >
                    <i className="fas fa-save" /> שמירה
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="received-weights">
            <div className="title">משקלים שנקלטו</div>
            {draftItem.weights?.map((weight, index) => (
              <div className="received">
                <div>
                  <span data-weight>{weight} </span>
                  <span>
                    {UnitTypeFormatter.format(draftItem.prepareUnitType)}
                  </span>
                </div>
                <div>
                  <i
                    className={classNames("fa fa-minus-circle", { disabled })}
                    onClick={() => {
                      updateDraftItem((curr) => {
                        const nextWeight = curr!.receivedWeight - weight;
                        curr!.receivedWeight = nextWeight;
                        curr!.weights! = curr!.weights!.filter(
                          (_, i) => index !== i
                        );
                      });
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
          <div className="image">
            <BaseImage fileName={draftItem.imageName} />
          </div>
        </div>
        <SentOrderRemoteWeightModal
          show={showRemoteWeight}
          prepareUnitType={draftItem.prepareUnitType}
          onClose={onHideRemoteWeight}
          onWeightReceived={onWeightReceived}
        />
      </div>
      <NumpadModal
        show={showNumpad}
        title={`בחירת משקל: ${draftItem.name || ""}`}
        initialValue={draftItem.receivedAmount}
        onCancel={onHideNumpad}
        onDone={onNumpadSave}
      />
      <SupplyMethodPicker
        show={showSupplyMethodPicker}
        supplyMethods={draftItem.supplyMethods}
        prepareUnitType={draftItem.prepareUnitType}
        onPick={onSupplyMethodPicked}
        onHide={onHideSupplyMethodPicker}
      />
      <PackageWeightsPicker
        show={showPackageWeightsPicker}
        onPick={(weightItem, amount) => {
          const weightToReduceGR = amount * weightItem.weight;
          updateDraftItem((curr) => {
            makeWeightUpdates(
              curr!,
              curr!.receivedWeight - weightToReduceGR / 1000
            );
          });
        }}
        onHide={onHidePackageWeightsPicker}
      />
    </>
  );
};
