import { FieldValidator } from "final-form";

export class RequiredFieldValidator {
  static validate: FieldValidator<any> = (x, _, meta) => {
    if (x === undefined || x === "") {
      return Promise.resolve("שדה חובה");
    } else {
      return Promise.resolve();
    }
  };
}
