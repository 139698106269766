import Axios from "axios";
import {
  BasicPickListItem,
  OrderListItem,
  Order,
  OrderIngredientItem,
  DepartmentListItem,
  DetailedOrder,
} from "order/types";
import { RequestCacheHelper } from "base/api/RequestCacheHelper";
import produce from "immer";
import { BaseSupplyMethod } from "base/types/BaseSupplyMethod";

export class OrdersApi {
  static getSupplierList() {
    return Axios.get<BasicPickListItem[]>("/api/orders/suppliers");
  }

  static getDepartmentList() {
    return Axios.get<DepartmentListItem[]>("/api/orders/departments");
  }

  static getSupplier(id: number) {
    return Axios.get<Order>(`/api/orders/supplier/${id}`);
  }

  static getOrder(id: number) {
    return Axios.get<Order>(`/api/orders/${id}`);
  }

  static getDepartment(id: number) {
    return Axios.get<any>(`/api/orders/department/${id}`);
  }

  static getList() {
    return Axios.get<OrderListItem[]>(`/api/orders/items`);
  }

  static async getDetailedList() {
    return Axios.get<DetailedOrder[]>("/api/orders/detailedItems");
  }

  static searchIngredients(phrase: string) {
    return Axios.get<OrderIngredientItem[]>(`/api/orders/search/${phrase}`);
  }

  static async submitOrder(id: number) {
    await Axios.post(`/api/orders/submitOrder/${id}`);
    RequestCacheHelper.instance.update<DetailedOrder[]>(
      (detailedOrders) => {
        if (!detailedOrders) return;
        return detailedOrders.filter((x) => x.orderId !== id);
      },
      OrdersApi.getDetailedList,
      []
    );
  }

  static async updateNotes(id: number, notes: string) {
    await Axios.post(`/api/orders/${id}/notes`, { notes });
    RequestCacheHelper.instance.update<Order>(
      (order) => {
        if (!order) return;
        return { ...order, notes };
      },
      OrdersApi.getOrder,
      [id]
    );

    RequestCacheHelper.instance.update<DetailedOrder[]>(
      (detailedOrders) => {
        if (!detailedOrders) return;
        return produce(detailedOrders, (draft) => {
          draft.forEach((o) => {
            if (o.orderId === id) {
              o.notes = notes;
            }
          });
        });
      },
      OrdersApi.getDetailedList,
      []
    );
  }

  static async updateProduct(
    id: number,
    productDetails: {
      productId: number;
      amount: number;
      supplyMethod: BaseSupplyMethod;
      requestedPrice: number | undefined;
      supplierId: number;
    }
  ) {
    await Axios.post(`/api/orders/addToOrder`, productDetails);
    RequestCacheHelper.instance.refetch(OrdersApi.getOrder, [id]);

    RequestCacheHelper.instance.update<DetailedOrder[]>(
      (detailedOrders) => {
        if (!detailedOrders) return;
        return produce(detailedOrders, (draft) => {
          draft.forEach((o) => {
            if (o.orderId === id) {
              o.items.forEach((item) => {
                if (item.productId === productDetails.productId) {
                  item.requestedAmount = productDetails.amount;
                  Object.assign(item.supplyMethod, productDetails.supplyMethod);
                  item.requestedPrice =
                    productDetails.requestedPrice ?? item.requestedPrice;
                }
              });
            }
          });
        });
      },
      OrdersApi.getDetailedList,
      []
    );
  }

  static async deleteProducts(id: number, productIdsToDelete: number[]) {
    await Axios.delete(`/api/orders/${id}/items`, {
      data: productIdsToDelete,
    });

    RequestCacheHelper.instance.refetch(OrdersApi.getOrder, [id]);

    RequestCacheHelper.instance.update<DetailedOrder[]>(
      (detailedOrders) => {
        if (!detailedOrders) return;
        return produce(detailedOrders, (draft) => {
          draft.forEach((o) => {
            if (o.orderId === id) {
              o.items = o.items.filter((item) => {
                return !productIdsToDelete.includes(item.productId);
              });
            }
          });
        });
      },
      OrdersApi.getDetailedList,
      []
    );
  }

  /* api/sentOrder */
  static async getDetailedSentOrders() {
    return Axios.get<DetailedOrder[]>("/api/sentOrders/detailedItems");
  }
  /* api/sentOrder */
}
