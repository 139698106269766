import React from "react";
import { Modal } from "react-bootstrap";
import { DishVE } from "dish/types";
import { BaseImage } from "base/components/BaseFoodImage";
import classNames from "classnames";
import { Typography } from "base/components/Typography";

export interface DishVEDoneModalProps {
  show: boolean;
  onHide: () => void;
  data?: DishVE;
}

export const DishVEDoneModal: React.FC<DishVEDoneModalProps> = ({
  show,
  onHide,
  data,
}) => {
  const p_show = show && data;

  const items = [...(data?.ingredients ?? []), ...(data?.materials ?? [])];

  const rollAnimationTime = 1;
  const viberateAnimationTime = 0.3;
  const buildItemFlavourDelay = (x: number) => x / 8;
  const totalRollsAnimationTime = rollAnimationTime * items.length;
  const totalVibratesAnimationTime =
    buildItemFlavourDelay(items.length - 1) * viberateAnimationTime;
  const totalItemsAnimationTime =
    totalRollsAnimationTime + totalVibratesAnimationTime;

  return (
    <Modal
      show={p_show}
      onHide={onHide}
      centered
      size="xl"
      dialogClassName="ve-done-modal"
    >
      <div
        className="fade-out p-4"
        style={{
          animationDelay: `${totalItemsAnimationTime + 3}s`,
        }}
      >
        <Typography variant="title" className="blink text-center" fontSize={70}>
          מעבד
        </Typography>
      </div>
      <div className="wrap">
        <div
          className="position-relative fade-out"
          style={{
            animationDelay: `${totalItemsAnimationTime + 3}s`,
          }}
        >
          <img
            style={{ left: -200, top: -60, width: 400 }}
            src="https://wallpaperaccess.com/full/4685431.png"
            alt="Plate"
          />
          {items.map((x, index) => (
            <div
              className="vibrate"
              style={{
                animationDelay: `${
                  totalRollsAnimationTime + buildItemFlavourDelay(index)
                }s`,
              }}
            >
              <BaseImage
                fileName={x.imageName}
                style={{ animationDelay: `${index}s` }}
                className={classNames({
                  "roll-in-right": index % 2 === 0,
                  "roll-in-left": index % 2 === 1,
                })}
              />
            </div>
          ))}
        </div>
        <div
          className="done-dish-section fade-in position-absolute"
          style={{
            animationDelay: `${totalItemsAnimationTime + 3}s`,
          }}
        >
          <img
            src="https://test.bestlink.co.il/Content/BaseItemImages/65cc0d0b.png"
            style={{ width: 400 }}
            alt="Done Dish"
          />
        </div>
      </div>
    </Modal>
  );
};
