export class ProductsRouteBuilder {
  static buildListRoute() {
    return "/products";
  }

  static buildAddRoute() {
    return "/products/new";
  }

  static buildEditRoute(id: number) {
    return `/products/${id}`;
  }
}
