import { UnitType } from "base/types";

export class UnitTypeFormatter {
  static default = new UnitTypeFormatter();

  format(value: number | undefined, isConvertToSmallUnit: boolean = false) {
    return UnitTypeFormatter.format(value, isConvertToSmallUnit);
  }

  static format(
    value: number | undefined,
    isConvertToSmallUnit: boolean = false
  ) {
    switch (value) {
      case UnitType.Yeh:
        return isConvertToSmallUnit ? "יח'" : "יח'";
      case UnitType.Liter:
        return isConvertToSmallUnit ? 'מ"ל' : "ליטר";
      case UnitType.KG:
        return isConvertToSmallUnit ? "גרם" : 'ק"ג';
      default:
        return "";
    }
  }
}

export const defaultUnitTypeFormatter = new UnitTypeFormatter();
