export class POSRouteBuilder {
  static templates = {
    History: "/pos-history",
    HistoryItem: "/pos-history/:fileName",
  };

  static buildImportRoute() {
    return "/pos-import";
  }

  static buildHistoryRoute() {
    return POSRouteBuilder.templates.History;
  }

  static buildHistoryItemRoute(fileName: string) {
    return POSRouteBuilder.templates.HistoryItem.replace(":fileName", fileName);
  }
}
