import React, { useState, useCallback, useEffect } from "react";
import { Row, Col, Carousel, FormControl } from "react-bootstrap";
import { useCustomQueryWithParams } from "base/api/hooks/useCustomQuery";
import { Typography } from "base/components/Typography";
import { FileViewer } from "base/components/FileViewer";
import { RecipeApi } from "recipe/RecipeApi";
import { RecipeViewerIngredients } from "./RecipeViewerIngredients";
import { FileDir } from "base/types";
import { MaterialsApi } from "material/MaterialsApi";
import { NumberFormatter } from "base/utils/formatters";
import "../styles.scss";
import { CarouselFullScreenSupport } from "base/components/CarouselFullScreenSupport";
import { VideoTheaterSupport } from "base/components/VideoTheaterSupport";

export type RecipeViewerProps = {
  id: number;
  materialData?: {
    id: number;
    initialTotalAmount?: number;
  };
};

export const RecipeViewer: React.FC<RecipeViewerProps> = ({
  id,
  materialData,
}) => {
  const [fontSize, setFontSize] = useState(16);
  const { data } = useCustomQueryWithParams(RecipeApi.get, () => [id]);
  const { data: material } = useCustomQueryWithParams(
    MaterialsApi.getItem,
    () => [materialData!.id],
    {
      skip: !materialData,
    }
  );

  const { totalUnits, videoFilename } = data ?? {};

  const [selectedTotalUnits, setSelectedTotalUnits] = useState(totalUnits);

  const quantityMultiplier =
    totalUnits === undefined
      ? 1
      : (selectedTotalUnits ?? totalUnits) / totalUnits;

  const onDishesCountChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value === "") {
        setSelectedTotalUnits(undefined);
      } else if (Number(e.target.value) <= 0) {
        setSelectedTotalUnits(totalUnits);
      } else {
        setSelectedTotalUnits(Number(e.target.value));
      }
    },
    [totalUnits]
  );

  const onTotalAmountChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value === "") {
        setSelectedTotalUnits(undefined);
      } else if (Number(e.target.value) <= 0) {
        setSelectedTotalUnits(totalUnits);
      } else {
        setSelectedTotalUnits(Number(e.target.value) / material!.totalAmount!);
      }
    },
    [material, totalUnits]
  );

  const { initialTotalAmount } = materialData ?? {};
  useEffect(() => {
    if (
      initialTotalAmount !== undefined &&
      material?.totalAmount &&
      totalUnits !== undefined
    ) {
      setSelectedTotalUnits(
        (initialTotalAmount / material.totalAmount) * totalUnits!
      );
    }
  }, [initialTotalAmount, material, totalUnits]);

  // Make sure to sync the selectedTotalUnits state.
  useEffect(() => {
    if (totalUnits !== undefined) {
      setSelectedTotalUnits((currTotalUnits) => {
        if (currTotalUnits === undefined) {
          return totalUnits;
        } else {
          return currTotalUnits;
        }
      });
    }
  }, [totalUnits]);
  const hasPhotos = data?.photos && data?.photos.length > 0;

  return (
    <Row>
      <Col md={9}>
        <Row>
          <Col md={8}>
            <div className="d-flex align-items-center">
              <Typography variant="title" className="m-0">
                מצרכים
              </Typography>
              <div
                className="font-control"
                onClick={() => setFontSize((f) => Math.min(32, f + 1))}
              >
                A+
              </div>
              <div
                className="font-control"
                onClick={() => setFontSize((f) => Math.max(10, f - 1))}
              >
                A-
              </div>
            </div>
            <RecipeViewerIngredients
              ingredients={data?.treeInfo.ingredients ?? []}
              materials={data?.treeInfo.materials ?? []}
              quantityMultiplier={quantityMultiplier}
              fontSize={fontSize}
            />
          </Col>
          <Col md={4}>
            <div className="d-flex align-items-center">
              <span>מספר מנות:</span>
              <FormControl
                dir="ltr"
                type="number"
                style={{ width: 140 }}
                className="mx-1"
                value={NumberFormatter.format(selectedTotalUnits)}
                onChange={onDishesCountChange}
              />
            </div>
            {material && material.totalAmount && (
              <div className="d-flex align-items-center">
                <span>כמות תוצר כוללת:</span>
                <FormControl
                  dir="ltr"
                  type="number"
                  style={{ width: 140 }}
                  className="mx-1"
                  value={NumberFormatter.format(
                    material.totalAmount * quantityMultiplier
                  )}
                  onChange={onTotalAmountChange}
                  step={String(material.totalAmount)}
                />
              </div>
            )}
            <div className="d-flex align-items-center">
              <span>זמן הכנה:</span>
              <span className="mx-2">
                {data?.preparationTime ? `${data?.preparationTime} דקות` : "-"}
              </span>
            </div>
          </Col>
        </Row>
        <Typography variant="title">אופן ההכנה</Typography>
        <ul>
          {(data?.steps ?? []).map((step) => (
            <li key={step.id} className="mb-1" style={{ fontSize }}>
              {step.text}
            </li>
          ))}
        </ul>
      </Col>
      <Col md={3}>
        {videoFilename ? (
          <VideoTheaterSupport>
            <FileViewer
              fileName={videoFilename}
              fileDir={FileDir.Recipe}
              type="video"
              videoProps={{ className: "w-100 h-auto" }}
            />
          </VideoTheaterSupport>
        ) : null}
        {hasPhotos && (
          <div style={{ width: "100%", height: 300 }}>
            <CarouselFullScreenSupport>
              <Carousel style={{ height: "100%" }}>
                {data!.photos.map((photo) => (
                  <Carousel.Item key={photo.id}>
                    <FileViewer
                      fileName={photo.fileName}
                      fileDir={FileDir.Recipe}
                      type="image"
                      imageProps={{
                        style: { objectFit: "contain", maxHeight: 300 },
                        className: "w-100 h-auto",
                      }}
                    />
                  </Carousel.Item>
                ))}
              </Carousel>
            </CarouselFullScreenSupport>
          </div>
        )}
      </Col>
    </Row>
  );
};
