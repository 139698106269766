import Axios from "axios";
import { BaseSelectListItem } from "base/types";
import { RequestCacheHelper } from "base/api/RequestCacheHelper";
import { WorkScheduleType } from "./types";

export class WorkScheduleApi {
  static async getTemplateSchedules() {
    return Axios.get<WorkScheduleType[]>(`/api/workSchedules/templates`);
  }

  static async getRoleTypes() {
    return Axios.get<BaseSelectListItem[]>("/api/workSchedules/roleTypes");
  }

  static async getRoleNames() {
    return Axios.get<BaseSelectListItem[]>("/api/workSchedules/roleNames");
  }

  static async getShiftDepartments() {
    return Axios.get<BaseSelectListItem[]>(
      "/api/workSchedules/shiftDepartments"
    );
  }

  static async saveTemplateSchedules(schedules: WorkScheduleType[]) {
    const { data: savedSchedules } = await Axios.post<WorkScheduleType[]>(
      "/api/workSchedules/templates",
      schedules
    );

    RequestCacheHelper.instance.update(
      savedSchedules,
      WorkScheduleApi.getTemplateSchedules
    );
  }
}
