import React from "react";
import classnames from "classnames";
import "./styles.scss";

export type TypographyProps = {
  variant: "route-title" | "title" | "description";
  fontSize?: React.CSSProperties["fontSize"];
  bold?: boolean;
  rtl?: boolean;
  className?: string;
  style?: React.CSSProperties;
};

export const Typography: React.FC<TypographyProps> = ({
  variant,
  fontSize,
  bold = false,
  rtl = true,
  className,
  children,
  style,
}) => {
  const baseStyle: React.CSSProperties = {
    fontSize,
    fontWeight: bold ? "bold" : undefined,
    direction: rtl ? "rtl" : "ltr",
  };

  switch (variant) {
    case "route-title":
      return (
        <h2
          className={classnames("typography", className)}
          data-variant={variant}
          style={{ ...baseStyle, ...style }}
        >
          {children}
        </h2>
      );
    case "title":
      return (
        <h4
          className={classnames("typography", className)}
          data-variant={variant}
          style={{ ...baseStyle, ...style }}
        >
          {children}
        </h4>
      );
    case "description":
      return (
        <span
          className={classnames("typography", className)}
          data-variant={variant}
          style={{ ...baseStyle, ...style }}
        >
          {children}
        </span>
      );
  }
};
