import React, { useCallback, useMemo } from "react";
import { useCustomQuery } from "base/api/hooks/useCustomQuery";
import { OrdersApi } from "order/OrdersApi";
import "./style.scss";
import { useNavigateCallback } from "base/hooks/useNavigateCallback";
import { OrdersRouteBuilder } from "order/OrdersRouteBuilder";
import { FileDir } from "base/types";
import { FileViewer } from "base/components/FileViewer";
import { useOrderWizardContext } from "order/context/hooks/useOrderWizardContext";
import { useObserver } from "mobx-react";

export const DepartmentList: React.FC = () => {
  const { store } = useOrderWizardContext();

  const searchPhrase =
    useObserver(
      () =>
        store.routeSearchPhrases[OrdersRouteBuilder.buildDepartmentListRoute()]
    ) ?? "";
  let { data } = useCustomQuery(OrdersApi.getDepartmentList);

  const navigate = useNavigateCallback();

  const onItemClick = useCallback(
    (id: number) => {
      navigate(OrdersRouteBuilder.buildDepartmentRoute(id));
    },
    [navigate]
  );

  const filteredData = useMemo(() => {
    if (!data || !searchPhrase) return data;

    const formattedFilter = searchPhrase.toLowerCase().trim();
    return data.filter(
      (item) => item.name.toLowerCase().trim().indexOf(formattedFilter) !== -1
    );
  }, [data, searchPhrase]);

  return (
    <div className="basicList">
      {filteredData?.map((listItem) => (
        <div
          key={listItem.id}
          className="basicPickContainer"
          onClick={() => onItemClick(listItem.id)}
        >
          <FileViewer
            fileDir={FileDir.Department}
            type="image"
            fileName={listItem.imageName}
          />
          <div className="pick-title">{listItem.name}</div>
        </div>
      ))}
    </div>
  );
};
