import { Modal } from "react-bootstrap";
import React from "react";
import { ModalTitleBar } from "modal/ModalTitleBar";
import {
  useCustomQueryWithParams,
  CustomQueryResponse,
} from "base/api/hooks/useCustomQuery";
import { SimpleGlobalListApi } from "global-list/SimpleGlobalListApi";
import {
  SimpleGlobalListRoutePath,
  PackageWeightListItem,
} from "global-list/types";
import { BaseImage } from "base/components/BaseFoodImage";
import { FileDir } from "base/types";
import { Typography } from "base/components/Typography";
import { NumberFormatter } from "base/utils/formatters";

export interface PackageWeightsPickerProps {
  show: boolean;
  onPick: (listItem: PackageWeightListItem, amount: number) => void;
  onHide: () => void;
}

export const PackageWeightsPicker: React.FC<PackageWeightsPickerProps> = ({
  show,
  onPick,
  onHide,
}) => {
  const {
    data: packageWeightList,
  } = useCustomQueryWithParams(SimpleGlobalListApi.getList, () => [
    SimpleGlobalListRoutePath.PackageWeights,
  ]) as CustomQueryResponse<PackageWeightListItem[]>;

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <ModalTitleBar title="בחר משקל אריזה" onClose={onHide} />
      <div className="p-2">
        {packageWeightList?.map((weightItem) => (
          <div
            key={weightItem.id}
            role="button"
            onClick={() => onPick(weightItem, 5)}
          >
            <BaseImage
              fileDir={FileDir.PackageWeight}
              fileName={weightItem.imageName}
            />
            <Typography variant="description">{weightItem.name}</Typography>
            <Typography variant="description">
              {NumberFormatter.format(weightItem.weight)}
            </Typography>
          </div>
        ))}
      </div>
    </Modal>
  );
};
