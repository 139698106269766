import React, { useState, useEffect, useCallback } from "react";
import { Button } from "react-bootstrap";
import axios from "axios";
import SortableTree, {
  changeNodeAtPath,
  TreeItem,
  TreeNode,
} from "react-sortable-tree";
import "react-sortable-tree/style.css";
import "./style.scss";
import { ManageDepartmentModal } from "../ManageDepartmentModal";
import { useCustomQueryWithParams } from "base/api/hooks/useCustomQuery";
import { DepartmentsApi } from "department/DepartmentsApi";
import { Department } from "department/types";
import { FileViewer } from "base/components/FileViewer";
import { FileDir } from "base/types/Filedir";
import { Typography } from "base/components/Typography";

export type DepartmentTreeProps = { id: number };

type ManageModalState =
  | { show: false; departmentId: undefined }
  | { show: true; departmentId: number };

export const DepartmentTree: React.FC<DepartmentTreeProps> = ({ id }) => {
  const { data } = useCustomQueryWithParams(DepartmentsApi.getItem, () => [id]);

  const [departmentChildren, setDepartmentChildren] = useState(
    data?.children ?? []
  );

  const [newDepartmentName, setNewDepartmentName] = useState("");

  // manage modal
  const [manageModalState, setManageModalState] = useState<ManageModalState>({
    show: false,
    departmentId: undefined,
  });

  const onHideManageModal = useCallback(() => {
    setManageModalState({ show: false, departmentId: undefined });
  }, []);

  const onShowManageModal = useCallback((departmentId: number) => {
    setManageModalState({ show: true, departmentId });
  }, []);
  const getNodeKey = useCallback(({ node }: TreeNode) => node.id, []);

  const onTreeDataChange = useCallback(
    (nextTreeData: TreeItem[]) => {
      setDepartmentChildren(nextTreeData as Department[]);
      axios.post(`/api/departments/${id}`, nextTreeData);
    },
    [id]
  );

  const onDeleteNode = useCallback(
    (nodeId: number) => {
      setDepartmentChildren((currTreeData) =>
        currTreeData.filter((node) => node.id !== nodeId)
      );
      axios.delete(`/api/departments/${id}/${nodeId}`);
    },
    [id]
  );

  const onAddNewDepartment = useCallback(async () => {
    const nextTreeData = [
      ...departmentChildren,
      {
        name: newDepartmentName,
      } as Department,
    ];

    await DepartmentsApi.saveChildren(id, nextTreeData);
    setNewDepartmentName("");
  }, [id, newDepartmentName, departmentChildren]);

  useEffect(() => {
    if (data?.children) {
      setDepartmentChildren(data.children);
    }
  }, [data]);

  return (
    <div>
      <Typography variant="route-title" className="text-center" fontSize={32}>
        ניהול עץ מחלקות - <b> {data?.name}</b>
      </Typography>

      <div style={{ height: 800 }}>
        <input
          type="text"
          value={newDepartmentName}
          onChange={(e) => setNewDepartmentName(e.target.value)}
          className="form.text"
        />
        <button onClick={onAddNewDepartment} style={{ cursor: "pointer" }}>
          הוסף
        </button>
        <SortableTree
          treeData={departmentChildren}
          onChange={onTreeDataChange}
          rowDirection="rtl"
          getNodeKey={getNodeKey}
          generateNodeProps={({ node, path }) => ({
            title: (
              <div className="d-flex">
                <div>
                  <FileViewer
                    type="image"
                    fileDir={FileDir.Department}
                    fileName={(node as Department).photoFileName}
                    imageProps={{ style: { height: "32px", width: "32px" } }}
                  />
                </div>
                <div>
                  <input
                    style={{ fontSize: "1.1rem" }}
                    value={node.name as string}
                    onChange={(event) => {
                      const name = event.target.value;

                      onTreeDataChange(
                        changeNodeAtPath({
                          treeData: departmentChildren,
                          path,
                          getNodeKey,
                          newNode: { ...node, name },
                        })
                      );
                    }}
                  />
                </div>
              </div>
            ),
            buttons: [
              <Button
                variant="primary"
                onClick={() => onShowManageModal(node.id)}
                className="mx-1"
              >
                ניהול
              </Button>,
              (!node.children || node.children.length === 0) && (
                <Button
                  variant="danger"
                  data-id={node.id}
                  onClick={() => onDeleteNode(node.id)}
                  className="mx-1"
                >
                  מחק
                </Button>
              ),
            ],
          })}
        />
        <ManageDepartmentModal
          isShowAddons={data?.name === "מנות"}
          onCancel={onHideManageModal}
          show={manageModalState.show}
          departmentId={manageModalState.departmentId}
        />
      </div>
    </div>
  );
};

export default DepartmentTree;
