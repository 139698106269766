import React, { useCallback } from "react";
import { FormControl, FormControlProps } from "react-bootstrap";
import { FieldRenderProps } from "react-final-form";
import { FormErrorMessage } from "../../FormErrorMessage";

export type TextInputFieldInnerProps = FieldRenderProps<any> & {
  placeholder?: string;
  className?: string;
  autoFocus?: boolean;
  selectOnFocus?: boolean;
} & Partial<FormControlProps>;

export const TextInputFieldInner: React.FC<TextInputFieldInnerProps> = ({
  input,
  meta,
  placeholder,
  className,
  autoFocus,
  selectOnFocus,
  ...textInputProps
}) => {
  const { onFocus } = input;

  const onSelectOnFocus = useCallback<
    React.FocusEventHandler<HTMLInputElement>
  >(
    (event) => {
      const inputElement = event.target;
      onFocus(event);
      setTimeout(() => inputElement.select());
    },
    [onFocus]
  );

  return (
    <>
      <FormControl
        autoComplete="off"
        type="text"
        placeholder={placeholder}
        className={className}
        autoFocus={autoFocus}
        {...textInputProps}
        {...input}
        onFocus={selectOnFocus ? onSelectOnFocus : undefined}
      />
      <div>
        <FormErrorMessage>
          {meta.touched ? meta.error : meta.submitError}
        </FormErrorMessage>
      </div>
    </>
  );
};
