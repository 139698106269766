import axios from "axios";
import { RequestCacheHelper } from "base/api/RequestCacheHelper";
import { DepartmentFoodWork } from "./types/DepartmentFoodWork";

export class DepartmentFoodWorksApi {
  static async getFoodWorks(departmentId: number) {
    return axios.get<DepartmentFoodWork[]>(
      `/api/departments/${departmentId}/foodworks`
    );
  }

  static async addFoodWork(
    departmentId: number,
    departmentFoodWork: Omit<DepartmentFoodWork, "id">
  ) {
    const { data: newFoodWork } = await axios.post<DepartmentFoodWork>(
      `/api/departments/${departmentId}/foodworks`,
      departmentFoodWork
    );

    await RequestCacheHelper.instance.update<DepartmentFoodWork[]>(
      (currValue) => [...(currValue ?? []), newFoodWork],
      DepartmentFoodWorksApi.getFoodWorks,
      [departmentId]
    );
  }

  static async updateFoodWork(
    departmentId: number,
    departmentFoodWork: DepartmentFoodWork
  ) {
    const { data: modifiedFoodWork } = await axios.post<DepartmentFoodWork>(
      `/api/departments/${departmentId}/foodworks`,
      departmentFoodWork
    );

    await RequestCacheHelper.instance.update<DepartmentFoodWork[]>(
      (currValue) =>
        currValue?.map((fw) =>
          fw.id === modifiedFoodWork.id ? modifiedFoodWork : fw
        ) ?? [],
      DepartmentFoodWorksApi.getFoodWorks,
      [departmentId]
    );
  }

  static async deleteFoodWork(departmentId: number, foodWorkId: number) {
    await axios.delete<DepartmentFoodWork>(
      `/api/departments/${departmentId}/foodworks/${foodWorkId}`
    );

    await RequestCacheHelper.instance.update<DepartmentFoodWork[]>(
      (currValue) => currValue?.filter((fw) => fw.id !== foodWorkId) ?? [],
      DepartmentFoodWorksApi.getFoodWorks,
      [departmentId]
    );
  }
}
