export const TreeInfoIngredientsChartBGColors = [
  "#cbd54e",
  "#b55cd7",
  "#69d360",
  "#d75c93",
  "#80a76a",
  "#db5742",
  "#79c2be",
  "#c7934a",
  "#898ecd",
  "#bc9595",
];

export const TreeInfoIngredientsChartHoverBGColors = [
  "#c2d48e",
  "#eb9bd8",
  "#96e04d",
  "#bebae3",
  "#dec945",
  "#7ecbe4",
  "#eda56f",
  "#6cdf8d",
  "#d7c2b6",
  "#79dcc6",
];
