import React, { useCallback, useState } from "react";
import { Form } from "form/components/Form";
import { FormTextInput } from "form/components/FormTextInput";
import { Typography } from "base/components/Typography";
import { Spacer } from "base/components/Spacer";
import { AuthCredentials } from "auth/types";
import { AuthApi } from "auth/AuthApi";
import { useAppContext } from "app/context/hooks/useAppContext";
import { useNavigateCallback } from "base/hooks/useNavigateCallback";
import "./style.scss";
import { Alert } from "react-bootstrap";

export const DefaultLoginScreen: React.FC = () => {
  const { onSignIn } = useAppContext();
  const navigate = useNavigateCallback();
  const [authError, setAuthError] = useState<string>();

  const onSubmit = useCallback(
    async (credentials: AuthCredentials) => {
      try {
        const auth = await AuthApi.login(credentials);
        onSignIn(auth);
        navigate("/");
      } catch (e) {
        setAuthError("שם המשתמש או הסיסמה אינם נכונים");
      }
    },
    [onSignIn, navigate]
  );

  return (
    <div className="defaultLoginScreen d-flex w-100 h-100 justify-content-center align-items-center">
      <div
        className="loginInner px-5 pb-5 pt-2 text-center rounded"
        style={{ background: "#fff" }}
      >
        <Typography variant="description" fontSize={24}>
          התחברות
        </Typography>
        <Spacer units={3} />
        <Form<AuthCredentials>
          onSubmit={onSubmit}
          subscription={{ values: true }}
        >
          {({ handleSubmit, values }) => (
            <>
              <FormTextInput
                placeholder="שם משתמש"
                name="username"
                className="mb-3"
              />
              <FormTextInput
                name="password"
                type="password"
                placeholder="סיסמה"
                className="mb-3"
              />
              {authError && (
                <Alert style={{ fontSize: 22 }} variant="danger">
                  {authError}
                </Alert>
              )}
              <button
                className="btn btn-primary"
                onClick={handleSubmit}
                disabled={!values.username || !values.password}
              >
                התחבר
              </button>
            </>
          )}
        </Form>
      </div>
    </div>
  );
};
