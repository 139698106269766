import { FormControl, FormControlProps } from "react-bootstrap";
import React, { useCallback } from "react";

export type CheckboxProps = {
  value: boolean;
  onChange: (next: boolean) => void;
  width?: number;
  height?: number | "auto";
} & Omit<FormControlProps, "value" | "checked" | "onChange">;

export const Checkbox: React.FC<CheckboxProps> = ({
  value,
  onChange: onChangeProp,
  width,
  height,
  ...formConrolProps
}) => {
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChangeProp(e.target.checked);
    },
    [onChangeProp]
  );

  return (
    <FormControl
      type="checkbox"
      {...formConrolProps}
      style={{ width, height }}
      checked={value}
      onChange={onChange}
    />
  );
};
