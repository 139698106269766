import React from "react";
import { useNavigateCallback } from "base/hooks/useNavigateCallback";
import { IngredientsApi } from "ingredient/IngredientsApi";
import { useCustomQueryWithParams } from "base/api/hooks/useCustomQuery";
import { useCallback, useMemo } from "react";
import { IngredientRelatedProduct } from "ingredient/types";
import {
  CardsTable,
  CardsTableColumn,
} from "base/components/tables/components/CardsTable";
import { ProductsRouteBuilder } from "product/ProductsRouteBuilder";
import { FileViewer } from "base/components/FileViewer";
import { FileDir } from "base/types";

export const IngredientProductsTab: React.FC<{ id: number }> = ({ id }) => {
  const navigate = useNavigateCallback();

  const { data } = useCustomQueryWithParams(IngredientsApi.getProducts, () => [
    id,
  ]);

  const onEditRowClick = useCallback(
    (data: IngredientRelatedProduct) => {
      navigate(ProductsRouteBuilder.buildEditRoute(data.id));
    },
    [navigate]
  );

  const columns = useMemo<CardsTableColumn<IngredientRelatedProduct>[]>(
    () => [
      {
        id: "treeName",
        accessor: "imageName",
        Header: "",
        Cell: ({ value }) => (
          <FileViewer type="image" fileDir={FileDir.Product} fileName={value} />
        ),
        disableSortBy: true,
        disableGlobalFilter: true,
      },
      {
        id: "treeType",
        accessor: "manufacturerName",
        Header: "שם היצרן",
      },
      {
        id: "table-closer",
        Header: "",
        cellProps: {
          className: "table-closer",
        },
        disableSortBy: true,
        disableGlobalFilter: true,
      },
    ],
    []
  );

  return (
    <CardsTable
      columns={columns}
      data={data}
      globalFilterAllowedCols={["treeName"]}
      onEditRowClick={onEditRowClick}
      searchable={false}
    />
  );
};
