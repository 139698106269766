import React, { useMemo, useCallback } from "react";
import { Modal, Row, Col, Button } from "react-bootstrap";
import { useCustomQuery } from "base/api/hooks/useCustomQuery";
import { Form } from "form/components/Form";
import { Typography } from "base/components/Typography";
import { FormSelect } from "form/components/FormSelect";
import { FormCustomReset } from "form/utils/FormCustomReset";
import { FieldsValidator } from "form/validators/FieldsValidator";
import "modal/styles.scss";
import { NutritionalsApi } from "./NutritionalsApi";
import { useSelectOptionsAdapter } from "base/components/Select/hooks/useSelectOptionsAdapter";
import { isEqual } from "lodash";
import { FieldArray } from "react-final-form-arrays";
import { Field } from "react-final-form";
import { NutritionalItem } from "./types";
import { FormNumberInput } from "form/components/FormNumberInput";
import { NutritionalUnitTypeFormatter } from "./NutritionalUnitTypeFormatter";
import produce from "immer";

export type DishVENutritionalsModalProps = {
  show: boolean;
  onHide: () => void;
  onSave: (values: NutritionalItem[]) => void;
  existingNutritionals?: NutritionalItem[];
  dishName?: string;
};

export const DishVENutritionalsModal: React.FC<DishVENutritionalsModalProps> = ({
  show,
  onHide,
  onSave,
  existingNutritionals,
  dishName,
}) => {
  const { data: nutritionalTypes } = useCustomQuery(NutritionalsApi.getTypes, {
    skip: !show,
  });
  const nutritionalOptions = useSelectOptionsAdapter(nutritionalTypes);

  const formInitialValues = useMemo(
    () => ({
      nutritionals: existingNutritionals ?? [],
    }),
    [existingNutritionals]
  );

  const onSubmit = useCallback(
    (values: { nutritionals: NutritionalItem[] }) => {
      onSave(values.nutritionals);
      onHide();
    },
    [onHide, onSave]
  );

  return (
    <Modal show={show} onHide={onHide} centered size="lg">
      <div>
        <div className="modal-title-bar">
          <Typography variant="description" fontSize={24} bold>
            ניהול ערכים תזונתיים - {dishName}
          </Typography>
          <div className="close-btn" onClick={onHide} />
        </div>
        <div className="p-2">
          <Form initialValues={formInitialValues} onSubmit={onSubmit}>
            {({ form, handleSubmit }) => (
              <>
                <Typography variant="description" fontSize={20} bold>
                  הוספת ערך תזונתי חדש
                </Typography>
                <Form
                  onSubmit={async (values: NutritionalItem, addForm) => {
                    const {
                      nutritionals: currNutritionals,
                    } = form.getState().values;

                    form.change(
                      "nutritionals",
                      produce(currNutritionals, (d) => {
                        d.push(values);
                      })
                    );
                    FormCustomReset.hardReset(addForm);
                  }}
                  subscription={{ submitting: true }}
                >
                  {({ handleSubmit: handleSubmitAdd, submitting }) => (
                    <Row noGutters className="pt-2">
                      <Col xs={4} className="px-1">
                        <FormSelect
                          name="nutritional"
                          emptyOptionLabel="בחירת ערך תזונתי"
                          options={nutritionalOptions}
                          validate={FieldsValidator.required}
                        />
                      </Col>
                      <Col xs={2} className="px-1">
                        <FormNumberInput name="value" placeholder="כמות במנה" />
                      </Col>
                      <Col xs="auto" className="px-1 d-flex align-items-center">
                        <Field
                          name="nutritional"
                          render={({
                            input: { value: selectedNutritionalTypeId },
                          }) => {
                            const nutUnitType = nutritionalTypes?.find(
                              (t) => t.id === selectedNutritionalTypeId
                            );
                            return NutritionalUnitTypeFormatter.format(
                              nutUnitType?.type
                            );
                          }}
                        />
                      </Col>
                      <Col xs="auto">
                        <Button
                          variant="primary"
                          onClick={() => {
                            handleSubmitAdd();
                          }}
                          disabled={submitting}
                          size="sm"
                          style={{ height: 38 }}
                        >
                          הוספה
                        </Button>
                      </Col>
                    </Row>
                  )}
                </Form>
                <div className="pt-2">
                  <Typography variant="description" fontSize={20} bold>
                    ערכים תזונתיים קיימים
                  </Typography>
                </div>
                <FieldArray
                  name="nutritionals"
                  isEqual={isEqual}
                  subscription={{ length: true }}
                >
                  {({ fields }) => (
                    <div>
                      {fields.map((fieldPrefix, index) => {
                        return (
                          <Row noGutters key={`${fieldPrefix}.id`}>
                            <Col xs={4} className="p-1">
                              <FormSelect
                                name={`${fieldPrefix}.nutritional`}
                                emptyOptionLabel="בחירת ערך תזונתי"
                                options={nutritionalOptions}
                                validate={FieldsValidator.required}
                              />
                            </Col>
                            <Col xs={2} className="p-1">
                              <FormNumberInput
                                name={`${fieldPrefix}.value`}
                                placeholder="כמות במנה"
                              />
                            </Col>
                            <Col
                              xs="auto"
                              className="px-1 d-flex align-items-center"
                            >
                              <Field
                                name={`${fieldPrefix}.nutritional`}
                                render={({ input: { value: nutritional } }) => {
                                  const nutUnitType = nutritionalTypes?.find(
                                    (t) => t.id === nutritional
                                  );
                                  return NutritionalUnitTypeFormatter.format(
                                    nutUnitType?.type
                                  );
                                }}
                              />
                            </Col>
                            <Col
                              xs="auto"
                              className="p-1 d-flex align-items-center"
                            >
                              <i
                                className="fas fa-trash text-danger ml-auto"
                                aria-hidden="true"
                                role="button"
                                onClick={async () => {
                                  if (window.confirm("האם אתה בטוח?")) {
                                    fields.remove(index);
                                  }
                                }}
                              />
                            </Col>
                          </Row>
                        );
                      })}
                    </div>
                  )}
                </FieldArray>
                <div className="mt-3 d-flex justify-content-end">
                  <div className="mx-1">
                    <Button onClick={handleSubmit}>שמור</Button>
                  </div>
                </div>
              </>
            )}
          </Form>
        </div>
      </div>
    </Modal>
  );
};
