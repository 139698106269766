import { Modal, Button, Alert } from "react-bootstrap";
import React, { useMemo, useState, useEffect } from "react";
import { Typography } from "base/components/Typography";
import { UnitType, BaseSelectListItem } from "base/types";
import { SupplyMethodDescFormatter } from "order/SupplyMethodDescFormatter";
import { BaseSupplyMethod } from "base/types/BaseSupplyMethod";
import {
  useCustomQuery,
  useCustomQueryWithParams,
  CustomQueryResponse,
} from "base/api/hooks/useCustomQuery";
import { SuppliersApi } from "supplier/SuppliersApi";
import { ProductSupplyMethod } from "product/supply-method/types";
import { SimpleGlobalListApi } from "global-list/SimpleGlobalListApi";
import { SimpleGlobalListRoutePath } from "global-list/types";

export type SupplyMethodPickerProps = {
  show: boolean;
  supplyMethods?: ProductSupplyMethod[];
  prepareUnitType?: UnitType;
  supplierIdFilter?: number;
  onHide: () => void;
  onPick: (
    supplyMethod: BaseSupplyMethod,
    supplierId?: number,
    requestedPrice?: number
  ) => void;
};

export const SupplyMethodPicker: React.FC<SupplyMethodPickerProps> = ({
  show,
  supplyMethods,
  prepareUnitType,
  supplierIdFilter,
  onHide,
  onPick,
}) => {
  const resolvedShow = show && supplyMethods;
  const [selectedSupplyMet, setSelectedSupplyMet] = useState<
    ProductSupplyMethod
  >();
  const { data: suppliers } = useCustomQuery(SuppliersApi.getList);
  const {
    data: unitKinds,
  } = useCustomQueryWithParams(
    SimpleGlobalListApi.getList,
    () => [SimpleGlobalListRoutePath.UnitKinds],
    { skip: !resolvedShow }
  ) as CustomQueryResponse<BaseSelectListItem[]>;
  const {
    data: packageKinds,
  } = useCustomQueryWithParams(
    SimpleGlobalListApi.getList,
    () => [SimpleGlobalListRoutePath.PackageKinds],
    { skip: !resolvedShow }
  ) as CustomQueryResponse<BaseSelectListItem[]>;

  const p_supplyMethods = useMemo(() => {
    if (!supplyMethods) return [];
    if (supplierIdFilter === undefined) return supplyMethods;
    return supplyMethods.filter((sm) => sm.supplierId === supplierIdFilter);
  }, [supplierIdFilter, supplyMethods]);

  useEffect(() => {
    if (!resolvedShow) {
      setSelectedSupplyMet(undefined);
    }
  }, [resolvedShow]);

  useEffect(() => {
    if (selectedSupplyMet && selectedSupplyMet.suppliers.length === 0) {
      onPick(selectedSupplyMet);
    }

    if (selectedSupplyMet && selectedSupplyMet.suppliers.length === 1) {
      onPick(
        selectedSupplyMet,
        selectedSupplyMet.suppliers[0].supplierId,
        selectedSupplyMet.suppliers[0].price
      );
    }
  }, [onPick, selectedSupplyMet]);

  return (
    <Modal show={resolvedShow} onHide={onHide} centered>
      <div className="m-3">
        <Typography variant="title" className="text-center" bold>
          בחרו צורת אספקה
        </Typography>
      </div>
      {supplierIdFilter !== undefined &&
        (supplyMethods ?? []).length > 0 &&
        p_supplyMethods.length === 0 && (
          <Alert variant="danger" className="text-center mt-1">
            <h5 className="m-0">
              יש לאפס את הכמות בהזמנה בכדי לראות צורות אספקה נוספות
            </h5>
          </Alert>
        )}
      {p_supplyMethods.map((item) => {
        return (
          <React.Fragment key={item.id}>
            <Button
              className="m-2 btn-lg"
              onClick={() => setSelectedSupplyMet(item)}
            >
              {`${SupplyMethodDescFormatter.format(
                item,
                prepareUnitType!,
                unitKinds,
                packageKinds
              )}${
                item.supplierId !== undefined && suppliers
                  ? ` - ${
                      suppliers.find((s) => s.id === item.supplierId)!.name
                    }`
                  : ""
              }`}
            </Button>
            {selectedSupplyMet === item &&
              item.suppliers.map((itemSupplier) => (
                <Button
                  variant="success"
                  className="m-2"
                  onClick={() =>
                    onPick(item, itemSupplier.supplierId, itemSupplier.price)
                  }
                >
                  {
                    suppliers?.find((s) => s.id === itemSupplier.supplierId)
                      ?.name
                  }
                </Button>
              ))}
          </React.Fragment>
        );
      })}
    </Modal>
  );
};
