import React, { useMemo, useCallback } from "react";
import { useNavigateCallback } from "base/hooks/useNavigateCallback";
import {
  CardsTable,
  CardsTableProps,
} from "base/components/tables/components/CardsTable";
import { DepartmentListItem } from "department/types";
import { DepartmentsRouteBuilder } from "department/DepartmentsRouteBuilder";
import { useCustomQuery } from "base/api/hooks/useCustomQuery";
import { DepartmentsApi } from "department/DepartmentsApi";

export const DepartmentsTable: React.FC = () => {
  const { data } = useCustomQuery(DepartmentsApi.getList);
  const navigate = useNavigateCallback();

  const onEditRowClick = useCallback(
    (data: DepartmentListItem) => {
      navigate(DepartmentsRouteBuilder.buildEditDepartmentRoute(data.id));
    },
    [navigate]
  );

  const columns = useMemo<CardsTableProps<DepartmentListItem>["columns"]>(
    () => [
      {
        id: "Name",
        accessor: "name",
        Header: "שם",
        cellProps: {
          className: "name-col",
        },
      },
      {
        id: "Id",
        accessor: "id",
        Header: "קוד ישות",
      },
      {
        id: "table-closer",
        Header: "",
        cellProps: {
          className: "table-closer",
        },
        disableSortBy: true,
        disableGlobalFilter: true,
      },
    ],
    []
  );

  return (
    <div className="departmentsTable">
      <CardsTable
        columns={columns}
        data={data}
        onEditRowClick={onEditRowClick}
        localStorageKey="departmentsDT"
        globalFilterAllowedCols={["Name"]}
      />
    </div>
  );
};
