export enum FileDir {
  BaseFood = "/Content/BaseItemImages/",
  User = "/content/UserImages/",
  Recipe = "/content/Recipes/",
  Department = "/content/Departments/",
  Supplier = "/content/Suppliers/",
  Product = "/content/BaseItemImages/",
  StorageUnit = "/Content/StorageUnits/",
  PackageWeight = "/Content/PackageWeights/",
  Empty = "",
}
