import React, { useMemo } from "react";
import { CellProps } from "react-table";
import { isEqual } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { useFieldArray } from "react-final-form-arrays";
import { TreeType } from "base/types/TreeType";
import { BaseImage } from "base/components/BaseFoodImage";
import {
  CardsTable,
  CardsTableProps,
} from "base/components/tables/components/CardsTable";
import { TreeInfoFoodListItem, TreeInfo } from "tree-info/types";
import { useFieldNameFormatFN } from "form/hooks/useFieldNameFormatFN";
import { TreeInfoApi } from "tree-info/TreeInfoApi";
import { UnitTypeFormatter } from "base/utils/formatters";

type TableCell = TreeInfoFoodListItem & { type: "material" | "ingredient" };

export type BasicTreeFoodsTableProps = {
  type: TreeType;
  id: number;
};

export const BasicTreeFoodsTable: React.FC<BasicTreeFoodsTableProps> = ({
  type,
  id,
}) => {
  const formatFieldName = useFieldNameFormatFN<TreeInfo>();

  const { fields: materialsFields } = useFieldArray<TreeInfoFoodListItem>(
    formatFieldName("materials"),
    {
      isEqual,
      // re-render uppon array values change
      subscription: { value: true },
    }
  );

  const { fields: ingredientsFields } = useFieldArray<TreeInfoFoodListItem>(
    formatFieldName("ingredients"),
    {
      isEqual,
      // re-render uppon array values change
      subscription: { value: true },
    }
  );

  const data = useMemo(() => {
    const materialCells = (materialsFields.value || []).map<TableCell>(
      (material) => ({
        type: "material",
        ...material,
      })
    );

    const ingredientCells = (ingredientsFields.value || []).map<TableCell>(
      (ingredient) => ({
        type: "ingredient",
        ...ingredient,
      })
    );

    return [...materialCells, ...ingredientCells];
  }, [materialsFields, ingredientsFields]);

  const columns = useMemo<CardsTableProps<TreeInfoFoodListItem>["columns"]>(
    () => [
      {
        id: "image",
        accessor: "imageName",
        Header: "",
        Cell: ({ value }) => <BaseImage alt="חומר גלם" fileName={value} />,
        disableSortBy: true,
        disableGlobalFilter: true,
      },
      {
        id: "Name",
        accessor: "name",
        Header: "שם",
        cellProps: {
          className: "name-col",
        },
      },
      {
        id: "Quantity",
        accessor: "amount",
        Header: "כמות",
        cellProps: {},
      },
      {
        id: "UnitType",
        accessor: "unitType",
        Header: "יחידת מידה",
        Cell: ({ value }) => (
          <span>{UnitTypeFormatter.default.format(value, true)}</span>
        ),
        cellProps: {},
      },
      {
        Header: "פעולות",
        disableSortBy: true,
        disableGlobalFilter: true,
        Cell: ({ row }: CellProps<TableCell>) => (
          <FontAwesomeIcon
            icon={faTrash}
            size="lg"
            onClick={async () => {
              const { type: itemType, id: itemId } = row.original;

              switch (itemType) {
                case "material":
                  ingredientsFields.remove(
                    ingredientsFields.value.findIndex(
                      (mat) => mat.id === itemId
                    )
                  );
                  await TreeInfoApi.removeMaterial(type, id, itemId);
                  break;
                case "ingredient":
                  ingredientsFields.remove(
                    ingredientsFields.value.findIndex(
                      (ing) => ing.id === itemId
                    )
                  );
                  await TreeInfoApi.removeIngredient(type, id, itemId);
                  break;
              }
            }}
            color="red"
          />
        ),
      },
      {
        id: "table-closer",
        Header: "",
        cellProps: {
          className: "table-closer",
        },
        disableSortBy: true,
        disableGlobalFilter: true,
      },
    ],
    [id, ingredientsFields, type]
  );

  return (
    <CardsTable
      columns={columns}
      data={data}
      globalFilterAllowedCols={["Name"]}
    />
  );
};
