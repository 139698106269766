import React, { useState, useCallback } from "react";
import { Modal } from "react-bootstrap";
import classNames from "classnames";
import { Typography } from "base/components/Typography";
import "../styles.scss";
import { RecipeEditor } from "./RecipeEditor";
import { RecipeViewer, RecipeViewerProps } from "./RecipeViewer";
import { TreeType } from "base/types";

export type RecipeModalProps = {
  show: boolean;
  onHide: () => void;
  treeType: TreeType;
  id?: number;
  materialData?: RecipeViewerProps["materialData"];
  isCanEdit?: boolean;
};

export const RecipeModal: React.FC<RecipeModalProps> = ({
  show,
  onHide,
  id,
  materialData,
  isCanEdit = true,
}) => {
  const [mode, setMode] = useState<"editor" | "viewer">(
    isCanEdit ? "editor" : "viewer"
  );

  const onDisplayEditor = useCallback(() => {
    setMode("editor");
  }, []);

  const onDisplayViewer = useCallback(() => {
    setMode("viewer");
  }, []);

  return (
    <Modal show={show && id} onHide={onHide} centered dialogClassName="recipe">
      <div className="border-bottom text-center p-3 d-flex align-items-start">
        <Typography variant="title" fontSize="2rem" className="p-0 m-1">
          מתכון
        </Typography>
        {isCanEdit && (
          <div
            className="btn btn-primary mr-auto"
            onClick={mode === "editor" ? onDisplayViewer : onDisplayEditor}
          >
            {mode === "editor" ? "מעבר למצב צפיה" : "מעבר למצב עריכה"}
          </div>
        )}
      </div>
      <div className={classNames("p-4", { "d-none": mode !== "editor" })}>
        {id && <RecipeEditor id={id} />}
      </div>
      <div className={classNames("p-4", { "d-none": mode !== "viewer" })}>
        {id && <RecipeViewer id={id} materialData={materialData} />}
      </div>
    </Modal>
  );
};
