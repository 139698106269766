import React, { useMemo, useCallback } from "react";
import { Modal } from "react-bootstrap";
import { Grid } from "@material-ui/core";
import { pick } from "lodash";
import { useCustomQueryWithParams } from "base/api/hooks/useCustomQuery";
import { Form } from "form/components/Form";
import { TreeType } from "base/types";
import { Typography } from "base/components/Typography";
import { Spacer } from "base/components/Spacer";
import { DepartmentFoodWork } from "food-works/department-food-works/types/DepartmentFoodWork";
import { DepartmentFoodWorksApi } from "food-works/department-food-works/DepartmentFoodWorksApi";
import { TreeFoodWorksFormValues } from "../types";
import { TreeFoodWorksApi } from "../TreeFoodWorksApi";
import { TreeFoodWorksForm } from "./TreeFoodWorksForm";
import "food-works/styles.scss";

export type TreeFoodWorksModalProps = {
  show: boolean;
  treeType?: TreeType;
  treeId?: number;
  onCancel: () => void;
};

export const TreeFoodWorksModal: React.FC<TreeFoodWorksModalProps> = ({
  show,
  treeType,
  treeId,
  onCancel,
}) => {
  const { data } = useCustomQueryWithParams(
    TreeFoodWorksApi.getFoodWorks,
    () => [treeType!, treeId!],
    {
      skip: !show || !treeType || !treeId,
    }
  );

  const { data: treeItem } = useCustomQueryWithParams(
    TreeFoodWorksApi.getFoodItem,
    () => [treeType!, treeId!],
    {
      skip: !show || !treeType || !treeId,
    }
  );

  const { data: departmentFoodWorks } = useCustomQueryWithParams(
    DepartmentFoodWorksApi.getFoodWorks,
    () => [treeItem!.departmentId!],
    {
      skip: !show || !treeItem?.departmentId,
    }
  );

  const foodWorksForm = useMemo<TreeFoodWorksFormValues | undefined>(() => {
    if (!treeType || !treeId) {
      return undefined;
    } else {
      return {
        treeType,
        treeId,
        foodWorks: data ?? [],
      };
    }
  }, [data, treeId, treeType]);

  const onAddFoodWorkByDepartment = useCallback(
    async (depFoodWork: DepartmentFoodWork) => {
      const foodWork = pick<DepartmentFoodWork, keyof DepartmentFoodWork>(
        depFoodWork,
        ["name", "cost", "depreciation", "duration"]
      );

      switch (treeType) {
        case TreeType.Dish:
          await TreeFoodWorksApi.addFoodWork(treeType!, treeId!, foodWork);
          return;
        case TreeType.Material:
          await TreeFoodWorksApi.addFoodWork(treeType!, treeId!, {
            ...foodWork,
            isRequired: false,
          });
          return;
      }
    },
    [treeId, treeType]
  );

  return (
    <Modal
      show={show}
      onHide={onCancel}
      dialogClassName="foodworks-modal"
      centered
      size="lg"
    >
      <div className="foodworks-modal-content">
        <div className="fwm-title-bar">
          <Typography variant="description" fontSize={24} bold>
            ניהול עיבוד מתקדם - {treeItem?.name}
          </Typography>
          <div className="close-btn" onClick={onCancel} />
        </div>
        <div className="fwm-body">
          <Form
            initialValues={foodWorksForm}
            onSubmit={() => {}}
            component={TreeFoodWorksForm}
          />
          <Spacer units={4} />
          {departmentFoodWorks && departmentFoodWorks?.length > 0 && (
            <>
              <Typography variant="description" fontSize={20} bold>
                הוספת עיבוד ממחלקה
              </Typography>
              <Spacer />
              <Grid container className="available-foodworks" spacing={2}>
                {departmentFoodWorks?.map((dfw) => (
                  <Grid item onClick={() => onAddFoodWorkByDepartment(dfw)}>
                    <div className="grid-item-content">{dfw.name}</div>
                  </Grid>
                ))}
              </Grid>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};
