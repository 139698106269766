import React, { useCallback } from "react";
import { Row, Col } from "react-bootstrap";
import { FormApi, getIn } from "final-form";
import { FormSpy, Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { isEqual } from "lodash";
import { FileDir } from "base/types";
import { useCustomQueryWithParams } from "base/api/hooks/useCustomQuery";
import { Typography } from "base/components/Typography";
import { RoundCheckButton } from "base/components/RoundCheckButton";
import { Form } from "form/components/Form";
import { RecipeStep, RecipeEditorFormValues, RecipePhoto } from "recipe/types";
import { FormTextArea } from "form/components/FormTextArea";
import { FormNumberInput } from "form/components/FormNumberInput";
import { FormFileUpload } from "form/components/FormFileUpload";
import { FormFileViewer } from "form/components/FormFileViewer";
import { RecipeApi } from "recipe/RecipeApi";
import "../styles.scss";

export type RecipeEditorProps = {
  id: number;
};

export const RecipeEditor: React.FC<RecipeEditorProps> = ({ id }) => {
  const { data } = useCustomQueryWithParams(RecipeApi.get, () => [id]);
  const onSubmit = useCallback(
    async (
      values: RecipeEditorFormValues,
      form: FormApi<RecipeEditorFormValues>
    ) => {
      await RecipeApi.save(values);
      alert("הפעולה הסתיימה בהצלחה");

      form.batch(() => {
        form.change("videoFile", undefined);
        form.change("photoFiles", []);
      });
    },
    []
  );

  return (
    <Form<RecipeEditorFormValues>
      onSubmit={onSubmit}
      initialValues={data}
      subscription={{ submitting: true }}
    >
      {({ handleSubmit, submitting, form }) => (
        <>
          <Row>
            <Col md={8}>
              <Typography variant="title">אופן ההכנה</Typography>
              <FieldArray<RecipeStep>
                name="steps"
                isEqual={isEqual}
                subscription={{ length: true }}
              >
                {({ fields }) => (
                  <>
                    {fields.map((name, index) => (
                      <div
                        key={index}
                        className="d-flex align-items-center steps-container"
                      >
                        <FormTextArea name={`${name}.text`} className="mb-2" />
                        <i
                          role="button"
                          className="fa fa-trash fa-lg m-3"
                          onClick={() => fields.remove(index)}
                        />
                      </div>
                    ))}
                    <Form<RecipeStep>
                      onSubmit={(step, form) => {
                        fields.push(step);
                        setTimeout(form.reset);
                      }}
                      keepDirtyOnReinitialize={false}
                      subscription={{ pristine: true, submitting: true }}
                    >
                      {({
                        handleSubmit: handleAddRecipeStepSubmit,
                        pristine,
                        submitting,
                      }) => (
                        <>
                          <FormTextArea
                            name="text"
                            className="mt-4"
                            placeholder="כאן כותבים עוד שורה למתכון"
                          />
                          <button
                            className="btn btn-primary mt-2 mb-4"
                            onClick={handleAddRecipeStepSubmit}
                            disabled={pristine || submitting}
                          >
                            הוספה
                          </button>
                        </>
                      )}
                    </Form>
                  </>
                )}
              </FieldArray>
              <Typography variant="title">תמונות</Typography>

              <FieldArray<File>
                name="photoFiles"
                isEqual={isEqual}
                subscription={{ length: true }}
              >
                {({ fields: photoFilesFields }) => (
                  <FieldArray<string>
                    name="photos"
                    isEqual={isEqual}
                    subscription={{ length: true }}
                  >
                    {({ fields: photoPathsFields }) => (
                      <>
                        <Form<{ image: File }>
                          onSubmit={({ image }, form) => {
                            photoFilesFields.push(image);
                            setTimeout(form.reset);
                          }}
                          keepDirtyOnReinitialize={false}
                        >
                          {({ handleSubmit: handleAddPhotoSubmit }) => (
                            <>
                              <FormFileUpload name="image" />
                              <FormSpy<{ image?: File }>
                                subscription={{ values: true }}
                                onChange={({ values }) => {
                                  if (values.image !== undefined) {
                                    handleAddPhotoSubmit();
                                  }
                                }}
                              />
                            </>
                          )}
                        </Form>
                        <br />
                        <div className="d-flex mb-3">
                          {photoPathsFields.map((name) => {
                            const photo: RecipePhoto = getIn(
                              form.getState().values,
                              name
                            );

                            return (
                              <Field<number[] | undefined>
                                name="deletedPhotos"
                                render={({
                                  input: { value: deletedPhotos = [] },
                                }) => (
                                  <FormFileViewer
                                    key={name}
                                    pathName={`${name}.fileName`}
                                    fileDir={FileDir.Recipe}
                                    className="photo-wrap"
                                    type="image"
                                    isDeleted={
                                      deletedPhotos.indexOf(photo.id) !== -1
                                    }
                                    onDelete={() => {
                                      const {
                                        deletedPhotos = [],
                                      } = form.getState().values;
                                      form.change("deletedPhotos", [
                                        ...deletedPhotos,
                                        photo.id,
                                      ]);
                                    }}
                                  />
                                )}
                              />
                            );
                          })}
                          {photoFilesFields.map((name, index) => (
                            <div key={name} className="photo-wrap">
                              <FormFileViewer
                                uploadName={name}
                                fileDir={FileDir.Recipe}
                                type="image"
                                onDelete={() => photoFilesFields.remove(index)}
                              />
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                  </FieldArray>
                )}
              </FieldArray>
            </Col>
            <Col md={4}>
              <Typography variant="title">פרטי בסיס</Typography>

              <div className="d-flex align-items-center mb-1">
                <span style={{ minWidth: 100 }}>מספר מנות:</span>
                <FormNumberInput
                  name="totalUnits"
                  style={{ width: 80 }}
                  required
                />
              </div>
              <div className="d-flex  align-items-center mb-5">
                <span style={{ width: 100 }}>זמן הכנה:</span>
                <FormNumberInput name="preparationTime" style={{ width: 80 }} />
              </div>
              <Typography variant="title">סרטון</Typography>
              <FormFileUpload name="videoFile" type="video" />
              <div>
                <FormSpy
                  subscription={{ values: true }}
                  render={({ values }) => (
                    <FormFileViewer
                      pathName="videoFilename"
                      uploadName="videoFile"
                      type="video"
                      fileDir={FileDir.Recipe}
                      onDelete={() => {
                        if (form.getState().values.videoFile) {
                          form.change("videoFile", undefined);
                        } else {
                          form.change("isDeleteVideoFile", true);
                        }
                      }}
                      isDeleted={values.isDeleteVideoFile && !values.videoFile}
                    />
                  )}
                />
              </div>
            </Col>
          </Row>
          <RoundCheckButton onClick={handleSubmit} disabled={submitting} />
        </>
      )}
    </Form>
  );
};
