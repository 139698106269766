import React from "react";
import { DateFormatter } from "base/utils/formatters";

export type TableDateCellProps = {
  value: string | undefined;
};

export const TableDateCell: React.FC<TableDateCellProps> = ({ value }) => {
  if (!value) {
    return null;
  }

  return <>{DateFormatter.default.format(value)}</>;
};
