import React, { useMemo, useCallback } from "react";
import { FormGroup } from "react-bootstrap";
import InputMask from "react-input-mask";
import { FieldArrayRenderProps } from "react-final-form-arrays";
import { Field, FormSpy, useForm } from "react-final-form";
import { Form } from "form/components/Form";
import { ContractGroupTypes, ContractItem, Contract } from "contract/types";
import { useCreateForm } from "form/hooks/useCreateForm";
import { addMinutes } from "date-fns/esm";
import { parse, format } from "date-fns";

export type ContractGroupProps = {
  type: ContractGroupTypes;
  fieldArrayProps: FieldArrayRenderProps<ContractItem, HTMLElement>;
};

export const ContractGroup: React.FC<ContractGroupProps> = ({
  type,
  fieldArrayProps,
}) => {
  const { getState } = useForm<Contract>();
  const { fields } = fieldArrayProps;
  const { push: addContractItem, remove: removeContractItem } = fields;

  const addItemForm = useCreateForm({
    onSubmit: addContractItem,
    initialValues: {
      itemType: type,
      fromTime: "",
      toTime: "",
      percentage: 100,
    },
  });

  const allFieldsNames = useMemo(() => fields.map((name) => name), [fields]);

  const buildGroupedIndexes = useCallback(
    (items: ContractItem[]) => {
      return items
        .map((_, index) => index)
        .filter((_, index) => items[index].itemType === type);
    },
    [type]
  );

  const groupName = (() => {
    switch (type) {
      case ContractGroupTypes.Yom_Hol:
        return "ימי חול";
      case ContractGroupTypes.Erev_Shabat_And_Hag:
        return "ערבי שבת וערבי חג";
      case ContractGroupTypes.Shabat_And_Hag:
        return "שבת/חג";
      default:
        return "ללא הגדרה";
    }
  })();

  const onRecalculateAddItemFromHour = useCallback(
    (values: Contract) => {
      const groupedIndexes = buildGroupedIndexes(values.contractItems);

      if (groupedIndexes.length > 0) {
        const lastItemIndex = groupedIndexes[groupedIndexes.length - 1];
        try {
          const toTime = format(
            addMinutes(
              parse(
                values.contractItems[lastItemIndex].toTime,
                "HH:mm",
                new Date()
              ),
              1
            ),
            "HH:mm"
          );

          addItemForm.change("fromTime", toTime);
        } catch {
          addItemForm.change("fromTime", "");
        }
      } else {
        addItemForm.change("fromTime", "");
      }
    },
    [addItemForm, buildGroupedIndexes]
  );

  const { values } = getState(); // Unobserved values.
  const currentGroupedIndexes = useMemo(
    () => buildGroupedIndexes(values.contractItems ?? []),
    [buildGroupedIndexes, values]
  );

  return (
    <div style={{ marginTop: 40 }}>
      <div>
        <h4>{groupName}</h4>
        <div className="d-flex contract-titles-flex">
          <div>משעה</div>
          <div>עד שעה</div>
          <div>אחוז משרה</div>
        </div>
        {currentGroupedIndexes.map((itemIndex) => (
          <div key={values.contractItems[itemIndex].id}>
            <FormGroup className="d-flex">
              <Field
                name={`${allFieldsNames[itemIndex]}.fromTime`}
                render={({ input }) => (
                  <InputMask mask="99:99" alwaysShowMask dir="ltr" {...input} />
                )}
              />
              <Field
                name={`${allFieldsNames[itemIndex]}.toTime`}
                render={({ input }) => (
                  <InputMask mask="99:99" alwaysShowMask dir="ltr" {...input} />
                )}
              />
              <Field
                name={`${allFieldsNames[itemIndex]}.percentage`}
                component="input"
              />
              <button
                className="btn btn-danger"
                onClick={() => removeContractItem(itemIndex)}
              >
                מחיקה
              </button>
            </FormGroup>
          </div>
        ))}
        <Form onSubmit={addContractItem} form={addItemForm}>
          {({ handleSubmit }) => (
            <FormGroup className="d-flex ">
              <Field
                name="fromTime"
                render={({ input }) => (
                  <InputMask mask="99:99" alwaysShowMask dir="ltr" {...input} />
                )}
              />
              <Field
                name="toTime"
                render={({ input }) => (
                  <InputMask mask="99:99" alwaysShowMask dir="ltr" {...input} />
                )}
              />
              <Field name="percentage" component="input" />
              <button className="btn btn-success" onClick={handleSubmit}>
                הוספה
              </button>
            </FormGroup>
          )}
        </Form>
        <FormSpy<Partial<Contract>>
          subscription={{ values: true }}
          onChange={({ values }) => {
            if (Object.keys(values).length > 0) {
              onRecalculateAddItemFromHour(values as Contract);
            }
          }}
        />
      </div>
    </div>
  );
};
