/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { RouteContent } from "base/components/RouteContent";
import { Link } from "react-router-dom";
import "./style.scss";
import { IngredientsRouteBuilder } from "ingredient/IngredientsRouteBuilder";
import { DishesRouteBuilder } from "dish/DishesRouteBuilder";
import { OrdersRouteBuilder } from "order/OrdersRouteBuilder";
import { SentOrdersRouteBuilder } from "sent-order/SentOrdersRouteBuilder";
import { SuppliersRouteBuilder } from "supplier/SuppliersRouteBuilder";
import { StockRouteBuilder } from "stock/StockRouteBuilder";
import { StockTransactionsRouteBuilder } from "stockTransactions/StockTransactionsRouteBuilder";
import { MaterialsRouteBuilder } from "material/MaterialsRouteBuilder";
import { EmployeesRouteBuilder } from "employee/EmployeesRouteBuilder";
import { WorkSchedulesRouteBuilder } from "work-schedule/WorkSchedulesRouteBuilder";
import { ScheduleRequestsRouteBuilder } from "schedule-requests/ScheduleRequestsRouteBuilder";
import { DepartmentsRouteBuilder } from "department/DepartmentsRouteBuilder";
import { startOfWeek } from "date-fns";
import { SimpleGlobalListRouteBuilder } from "global-list/SimpleGlobalListRouteBuilder";
import { ContractsRouteBuilder } from "contract/ContractsRouteBuilder";
import { POSRouteBuilder } from "pos/POSRouteBuilder";
import { AdminRouteBuilder } from "admin/AdminRouteBuilder";
import { WeeklyWorkSchedulesRouteBuilder } from "weekly-work-schedule/WeeklyWorkSchedulesRouteBuilder";

export const HomeScreen: React.FC = () => {
  const [isOrderExpaneded, setIsOrderExpanded] = useState(true);

  return (
    <RouteContent>
      <div className="main-menu-container">
        <div className="main-menu">
          <h1 className="main-menu-header">מסך מנהל</h1>
          <div className="group-container">
            <div>
              <div className="menu-group-header">רכש</div>
              <div className="menu-group-content">
                <div
                  onClick={() => setIsOrderExpanded(!isOrderExpaneded)}
                  className="d-flex justify-content-between align-items-center section"
                >
                  <a className={`flex-grow-1 nav-link orders`}>הזמנות</a>
                  <div className="icon-container">
                    <i
                      className={`fas fa-caret-right ${
                        isOrderExpaneded ? "opened" : "collapsed"
                      }`}
                    />
                  </div>
                </div>

                <div
                  className={`collapsed-content ${
                    isOrderExpaneded ? "opened" : "collapsed"
                  }`}
                >
                  <Link
                    className="nav-link new-order"
                    to={OrdersRouteBuilder.buildAddOrderRoute()}
                  >
                    הזמנה חדשה
                  </Link>
                  <Link
                    className="nav-link orders"
                    to={OrdersRouteBuilder.buildOrdersStatusRoute()}
                  >
                    רשימת הזמנות
                  </Link>
                  <Link
                    className="nav-link sent-orders"
                    to={SentOrdersRouteBuilder.buildSentOrdersRoute()}
                  >
                    קליטת הזמנה
                  </Link>
                </div>

                <Link
                  className={`nav-link ingredients`}
                  to={IngredientsRouteBuilder.buildIngredientsRoute()}
                >
                  מרכיבים
                </Link>
                <Link
                  className="nav-link suppliers"
                  to={SuppliersRouteBuilder.buildSuppliersRoute()}
                >
                  ספקים
                </Link>
                <Link
                  className="nav-link ingredients"
                  to={StockRouteBuilder.buildAddToStockRoute()}
                >
                  קליטה למלאי ללא הזמנה
                </Link>
              </div>
            </div>
            <div>
              <div className="menu-group-header">מכר</div>
              <div className="menu-group-content">
                <Link
                  className="nav-link dishes"
                  to={DishesRouteBuilder.buildDishesRoute()}
                >
                  מנות
                </Link>
                <Link
                  className="nav-link materials"
                  to={MaterialsRouteBuilder.buildMaterialsRoute()}
                >
                  חומרי גלם מעובדים
                </Link>
                <Link
                  to={POSRouteBuilder.buildImportRoute()}
                  className="nav-link pos-import"
                >
                  קליטת קובץ מכר
                </Link>
              </div>
            </div>
            <div>
              <div className="menu-group-header">כח אדם</div>
              <div className="menu-group-content">
                <Link
                  className="nav-link employees"
                  to={EmployeesRouteBuilder.buildEmployeesRoute()}
                >
                  עובדים
                </Link>
                <Link
                  className="nav-link manage-shifts"
                  to={WorkSchedulesRouteBuilder.editTemplates()}
                >
                  הגדרות סידור שבועי
                </Link>
                <Link
                  className="nav-link manage-shifts"
                  to={() =>
                    WeeklyWorkSchedulesRouteBuilder.buildWeeklyWorkScheduleRoute(
                      startOfWeek(Date.now())
                    )
                  }
                >
                  סידור שבועי
                </Link>
                <Link
                  to={() =>
                    ScheduleRequestsRouteBuilder.buildMyScheduleRequestsRoute(
                      startOfWeek(new Date())
                    )
                  }
                  className="nav-link myScheduleRequests"
                >
                  המשמרות שלי
                </Link>
                <Link
                  className="nav-link manageShifts"
                  to={EmployeesRouteBuilder.buildshiftsPresenceRoute()}
                >
                  דיווח נוכחות
                </Link>
                <Link
                  to={EmployeesRouteBuilder.buildshiftsPresenceRoute()}
                  className="nav-link employees-shifts-presence"
                >
                  כניסת עובדים
                </Link>
              </div>
            </div>
            <div>
              <div className="menu-group-header">ניהול כללי</div>
              <div className="menu-group-content">
                <Link
                  className="nav-link departments"
                  to={DepartmentsRouteBuilder.buildDepartmentsRoute()}
                >
                  ניהול מחלקות
                </Link>
                <Link
                  className="nav-link stock-transactions"
                  to={StockTransactionsRouteBuilder.buildStockTransactionsRoute()}
                >
                  תנועות מלאי
                </Link>
                <Link
                  to={SimpleGlobalListRouteBuilder.buildListsIndexRoute()}
                  className="nav-link fast-lists"
                >
                  רשימות מהירות
                </Link>
                <Link
                  to={ContractsRouteBuilder.buildContractsRoute()}
                  className="nav-link contracts"
                >
                  הסכמי שכר
                </Link>
                <Link
                  to={AdminRouteBuilder.buildStandSettingsScreen()}
                  className="nav-link stand-settings"
                >
                  הגדרות עמדה
                </Link>
                <Link
                  to={POSRouteBuilder.buildHistoryRoute()}
                  className="nav-link stand-settings"
                >
                  היסטוריית קליטת דוח מכר
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </RouteContent>
  );
};
