import React from "react";
import { useCustomQueryWithParams } from "base/api/hooks/useCustomQuery";
import { useFullScreenLoader } from "full-screen-loader/hooks/useFullScreenLoader";
import { ContractsApi } from "contract/ContractsApi";
import { ContractForm } from "./ContractForm";
import { Form } from "form/components/Form";
import { Contract } from "contract/types";
import { useSaveContractCallback } from "contract/hooks/useSaveContractCallback";

export type ContractExistingItemEditorProps = {
  id: number;
};

export const ContractExistingItemEditor: React.FC<ContractExistingItemEditorProps> = ({
  id,
}) => {
  const { data, isValidating } = useCustomQueryWithParams(
    ContractsApi.getItem,
    () => [id]
  );

  const isLoading = !data || isValidating;

  useFullScreenLoader(isLoading);

  const onSave = useSaveContractCallback();

  return (
    <Form<Contract>
      onSubmit={onSave}
      initialValues={data}
      component={ContractForm}
    />
  );
};
