import React from "react";
import classNames from "classnames";
import { RoundButtonProps, RoundButton } from "./RoundButton";

export const RoundTrashButton: React.FC<RoundButtonProps> = (props) => {
  return (
    <RoundButton danger {...props}>
      <i
        className={classNames("fa fa-trash ", {
          "fa-lg": !props.size || props.size > 26,
        })}
      />
    </RoundButton>
  );
};
