import React, { useEffect } from "react";
import { FieldRenderProps } from "react-final-form";
import { FormErrorMessage } from "../../FormErrorMessage";
import { Checkbox } from "base/components/Checkbox";

export type CheckboxFieldInnerProps = FieldRenderProps<any>;

export const CheckboxFieldInner: React.FC<CheckboxFieldInnerProps> = ({
  input,
  meta,
  ...props
}) => {
  const { value, onChange } = input;

  useEffect(() => {
    if (value === undefined || value === "") {
      // For some reason, without timeout, it is not picked up by FormSpys.
      const timeoutId = setTimeout(() => onChange(false), 60);
      return () => clearTimeout(timeoutId);
    }
  }, [onChange, value]);

  return (
    <>
      <Checkbox
        {...input}
        {...props}
        value={value}
        onChange={onChange}
        width={props.width}
      />
      <div>
        <FormErrorMessage>
          {meta.touched ? meta.error : meta.submitError}
        </FormErrorMessage>
      </div>
    </>
  );
};
