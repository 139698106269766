import axios from "axios";
import {
  SentOrderListItem,
  SentOrder,
  SentOrderViewerInfo,
  SentOrderPost,
  SentOrderSTSimulationListItem,
  SentOrderQuickAddProductFormValues,
  SentOrderItemGet,
} from "./types";
import { RequestCacheHelper } from "base/api/RequestCacheHelper";
import produce from "immer";

export class SentOrdersApi {
  static getList() {
    return axios.get<SentOrderListItem[]>("/api/sentOrders/items");
  }

  static getReceivedList() {
    return axios.get<SentOrderListItem[]>("/api/sentOrders/received");
  }

  static getItem(id: number) {
    return axios.get<SentOrder>(`/api/sentOrders/${id}`);
  }

  static getViewerInfo(orderKey: string) {
    return axios.get<SentOrderViewerInfo>(`/api/sentOrders/${orderKey}/viewer`);
  }

  static getSTSimulation(id: number) {
    return axios.get<SentOrderSTSimulationListItem[]>(
      `/api/sentOrders/${id}/simulateST`
    );
  }

  static async saveSentOrder(sentOrder: SentOrderPost) {
    await axios.post(`/api/sentOrders/save`, sentOrder);

    RequestCacheHelper.instance.update(
      produce(sentOrder, (s) => {
        s.items = s.items.filter((item) => !item.isDelete);
      }),
      SentOrdersApi.getItem,
      [sentOrder.id]
    );
  }

  static async closeSentOrder(id: number) {
    await axios.post(`/api/sentOrders/${id}/close`);
  }

  static async saveOccasional(sentOrder: SentOrderPost) {
    await axios.post(`/api/sentOrders/occasional`, sentOrder);

    RequestCacheHelper.instance.update(sentOrder, this.getItem, [sentOrder.id]);
    RequestCacheHelper.instance.refetch(this.getList);
    RequestCacheHelper.instance.refetch(this.getReceivedList);
  }

  static async quickAddProduct(values: SentOrderQuickAddProductFormValues) {
    const { data: item } = await axios.post<SentOrderItemGet>(
      `/api/sentOrders/AddToSubmittedOrder`,
      values
    );
    RequestCacheHelper.instance.update<SentOrder>(
      (sentOrder) => {
        if (!sentOrder) return;
        return produce(sentOrder, (s) => {
          s.items.push(item);
        });
      },
      SentOrdersApi.getItem,
      [values.orderId]
    );
  }

  static async markOrderReceived(id: number) {
    await axios.post(`/api/sentOrders/${id}/setReceived`);
    RequestCacheHelper.instance.refetch(this.getList);
    RequestCacheHelper.instance.refetch(this.getReceivedList);
  }

  static async markOrderSeenBySupplier(orderKey: string) {
    await axios.post(`/api/sentOrders/${orderKey}/setSupplierSeen`);
  }

  static async cancel(id: number) {
    await axios.post(`/api/sentOrders/${id}/cancel`);
  }

  static async requestMissingDocument(id: number) {
    await axios.get(`/api/sentOrders/${id}/requestMissingDocument`);
  }

  static async getWeight() {
    return axios.get<number>(`/api/sentOrders/getWeight`);
  }
}
