import axios from "axios";
import { BaseFood, TreeType } from "base/types";
import { TreeApiPathFormatter } from "base/utils/formatters/TreeApiPathFormatter";
import { RequestCacheHelper } from "base/api/RequestCacheHelper";
import {
  TreeFoodWork,
  TreePermanentFoodWork,
  TreeRequirableFoodWork,
} from "./types";

export class TreeFoodWorksApi {
  static async getFoodWorks(type: TreeType, id: number) {
    return axios.get<TreeFoodWork[]>(
      `/api/${TreeApiPathFormatter.format(type)}/${id}/foodworks`
    );
  }

  static async getFoodItem(type: TreeType, id: number) {
    return axios.get<BaseFood>(
      `/api/${TreeApiPathFormatter.format(type)}/${id}`
    );
  }

  static async addFoodWork(
    type: TreeType,
    id: number,
    foodWork:
      | Omit<TreePermanentFoodWork, "id">
      | Omit<TreeRequirableFoodWork, "id">
  ) {
    const { data: newFoodWork } = await axios.post<TreeFoodWork>(
      `/api/${TreeApiPathFormatter.format(type)}/${id}/foodworks`,
      foodWork
    );

    await RequestCacheHelper.instance.update<TreeFoodWork[]>(
      (currValue) => [...(currValue ?? []), newFoodWork],
      TreeFoodWorksApi.getFoodWorks,
      [type, id]
    );
  }

  static async updateFoodWork(
    type: TreeType,
    id: number,
    foodWork: TreeFoodWork
  ) {
    const { data: modifiedFoodWork } = await axios.post<TreeFoodWork>(
      `/api/${TreeApiPathFormatter.format(type)}/${id}/foodworks`,
      foodWork
    );

    await RequestCacheHelper.instance.update<TreeFoodWork[]>(
      (currValue) =>
        currValue?.map((fw) =>
          fw.id === modifiedFoodWork.id ? modifiedFoodWork : fw
        ) ?? [],
      TreeFoodWorksApi.getFoodWorks,
      [type, id]
    );
  }

  static async deleteFoodWork(type: TreeType, id: number, foodWorkId: number) {
    await axios.delete<TreeFoodWork>(
      `/api/${TreeApiPathFormatter.format(type)}/${id}/foodworks/${foodWorkId}`
    );

    await RequestCacheHelper.instance.update<TreeFoodWork[]>(
      (currValue) => currValue?.filter((fw) => fw.id !== foodWorkId) ?? [],
      TreeFoodWorksApi.getFoodWorks,
      [type, id]
    );
  }
}
