export type AuthCredentials = {
  username: string;
  password: string;
};

export type PinAuthCredentials = {
  username: string;
  pinCode: string;
};

export type AuthStandType = "default" | "chef" | "receiveOrder" | "dish-editor";

export type Auth = {
  token: string;
};

export type AuthTokenPayload = {
  role: number;
  permissions: number[];
  name: string;
};

export enum Role {
  Manager = 1,
  // Two = 2,
}

export enum Claim {
  Order_Create = 100,
}

export interface AccountUserListItem {
  userName: string;
  firstName?: string;
  lastName?: string;
  imageName?: string;
}
