import React from "react";
import { useForm, Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { isEqual } from "lodash";
import { ContractGroupTypes } from "contract/types";
import { ContractGroup } from "./ContractGroup";
import { Spacer } from "base/components/Spacer";
import { RoundButton } from "base/components/RoundButton";
import { Form } from "react-bootstrap";
import "../../style.scss";

export const ContractForm: React.FC = () => {
  const { submit } = useForm();

  return (
    <div id="contractsForm">
      <Field
        name="name"
        render={({ input }) => (
          <Form inline>
            <Form.Control type="text" {...input} placeholder="שם החוזה" />
          </Form>
        )}
      />
      <FieldArray
        name="contractItems"
        isEqual={isEqual}
        subscription={{ length: true }}
      >
        {(fieldArrayProps) => (
          <>
            <ContractGroup
              type={ContractGroupTypes.Yom_Hol}
              fieldArrayProps={fieldArrayProps}
            />
            <ContractGroup
              type={ContractGroupTypes.Erev_Shabat_And_Hag}
              fieldArrayProps={fieldArrayProps}
            />
            <ContractGroup
              type={ContractGroupTypes.Shabat_And_Hag}
              fieldArrayProps={fieldArrayProps}
            />
          </>
        )}
      </FieldArray>
      <Spacer />
      <RoundButton onClick={submit}>
        <i className="fa fa-check fa-lg" />
      </RoundButton>
    </div>
  );
};
