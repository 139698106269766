import React, { useMemo, useCallback } from "react";
import { FileDir } from "base/types";
import { BaseImage } from "base/components/BaseFoodImage";
import { useCustomQuery } from "base/api/hooks/useCustomQuery";
import { useNavigateCallback } from "base/hooks/useNavigateCallback";
import { YesNoTableFormatter } from "base/components/tables/utils/formatters/YesNoTableFormatter";
import {
  CardsTable,
  CardsTableProps,
} from "base/components/tables/components/CardsTable";
import { EmployeeListItem } from "employee/types";
import { EmployeesRouteBuilder } from "employee/EmployeesRouteBuilder";
import { EmployeesApi } from "employee/EmployeesApi";

export const EmployeesTable = () => {
  const { data } = useCustomQuery(EmployeesApi.getList);
  const navigate = useNavigateCallback();

  const onAddRowClick = useCallback(() => {
    navigate(EmployeesRouteBuilder.buildAddEmployeeRoute());
  }, [navigate]);

  const onEditRowClick = useCallback(
    (data: EmployeeListItem) => {
      navigate(EmployeesRouteBuilder.buildEditEmployeeRoute(data.id));
    },
    [navigate]
  );

  const columns = useMemo<CardsTableProps<EmployeeListItem>["columns"]>(
    () => [
      {
        id: "image",
        accessor: "imageName",
        Header: "",
        Cell: ({ value }) => (
          <BaseImage fileName={value} fileDir={FileDir.User} alt="user" />
        ),
        disableSortBy: true,
        disableGlobalFilter: true,
      },
      {
        id: "Name",
        Header: "שם",
        accessor: (row) => {
          if (!row.firstName) return row.lastName ?? "";
          if (!row.lastName) return row.firstName ?? "";
          return `${row.firstName} ${row.lastName}`;
        },
        cellProps: {
          className: "name-col",
        },
      },
      {
        id: "EmployeeRoleName",
        accessor: "employeeRoleName",
        Header: "תפקיד",
        cellProps: {},
      },
      {
        id: "HourlyRate",
        accessor: "hourlyRate",
        Header: "שכר לשעה",
        cellProps: {},
      },
      {
        id: "salaryType",
        accessor: "salaryType",
        Header: "סוג שכר",
        cellProps: {},
      },
      {
        id: "IsGlobalSalary",
        accessor: (row) =>
          YesNoTableFormatter.default.format(row.isGlobalSalary),
        Header: "שכר גלובלי?",
        cellProps: {},
      },
      {
        id: "IsPartOfTips",
        accessor: (row) => YesNoTableFormatter.default.format(row.isPartOfTips),
        Header: "שותף לטיפים?",
        cellProps: {},
      },
      {
        id: "IsActive",
        accessor: "isActive",
        Header: "פעיל?",
        cellProps: {},
        Cell: ({ value }) => YesNoTableFormatter.default.format(value),
        sortType: "boolean",
      },
      {
        id: "table-closer",
        Header: "",
        cellProps: {
          className: "table-closer",
        },
        disableSortBy: true,
        disableGlobalFilter: true,
      },
    ],
    []
  );

  return (
    <CardsTable
      columns={columns}
      data={data}
      onEditRowClick={onEditRowClick}
      onAddRowClick={onAddRowClick}
      localStorageKey="employeesDT"
      globalFilterAllowedCols={["Name"]}
    />
  );
};
