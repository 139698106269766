import { format, parse } from "date-fns";

export class WeeklyWSStartOfWeekAdapter {
  static format(date: Date) {
    return format(date, "MM-dd-yyyy");
  }

  static parse(formattedStrDate: string) {
    return parse(formattedStrDate, "MM-dd-yyyy", new Date());
  }
}
