import Axios from "axios";
import { BaseSelectListItem } from "base/types";
import { RequestCacheHelper } from "base/api/RequestCacheHelper";
import { AllergenRelationType, AllergenItem } from "./types";

export class AllergensApi {
  static getTypes() {
    return Axios.get<BaseSelectListItem[]>(`/api/itemAllergens/types`);
  }

  static getAllergens(relationType: AllergenRelationType, relationId: number) {
    switch (relationType) {
      case AllergenRelationType.Food:
        return Axios.get<AllergenItem[]>(`/api/foods/${relationId}/allergens`);
      case AllergenRelationType.Product:
        return Axios.get<AllergenItem[]>(
          `/api/products/${relationId}/allergens`
        );
      case AllergenRelationType.Material:
        return Axios.get<AllergenItem[]>(
          `/api/materials/${relationId}/allergens`
        );
    }
  }

  static async save(
    relationType: AllergenRelationType,
    relationId: number,
    allergen: AllergenItem
  ) {
    const { data: savedAllergen } = await Axios.post(`/api/itemAllergens/`, {
      foodId:
        relationType === AllergenRelationType.Food ? relationId : undefined,
      productId:
        relationType === AllergenRelationType.Product ? relationId : undefined,
      materialId:
        relationType === AllergenRelationType.Material ? relationId : undefined,
      ...allergen,
    });

    RequestCacheHelper.instance.update<AllergenItem[]>(
      (allergens) => {
        if (!allergens) return undefined;
        else if (!allergen.id) return [...allergens, savedAllergen];
        else
          return allergens.map((item) =>
            item.id === savedAllergen.id ? savedAllergen : item
          );
      },
      AllergensApi.getAllergens,
      [relationType, relationId]
    );
  }

  static async delete(
    idToDelete: number,
    relationType: AllergenRelationType,
    relationId: number
  ) {
    await Axios.delete(`/api/itemAllergens/${idToDelete}`);

    RequestCacheHelper.instance.update<AllergenItem[]>(
      (allergens) => {
        if (!allergens) return undefined;

        return allergens.filter((a) => a.id !== idToDelete);
      },
      AllergensApi.getAllergens,
      [relationType, relationId]
    );
  }
}
