import React from "react";
import { RouteContent } from "base/components/RouteContent";
import { Link } from "react-router-dom";
import { SimpleGlobalListRouteBuilder } from "global-list/SimpleGlobalListRouteBuilder";
import { Typography } from "base/components/Typography";
import "./../style.scss";
import { SimpleGlobalListRoutePath } from "global-list/types";

export const SimpleGlobalListIndexScreen: React.FC = () => {
  return (
    <RouteContent>
      <Typography variant="route-title" className="pb-2">
        ניהול רשימות
      </Typography>
      <div className="simple-lists-index-container">
        <Link
          to={SimpleGlobalListRouteBuilder.buildSystemSettingsRoute()}
          className="btn btn-info"
        >
          הגדרות מערכת
        </Link>
        <Link
          to={SimpleGlobalListRouteBuilder.buildRoleTypesRoute()}
          className="btn btn-info"
        >
          סוגי תפקיד
        </Link>
        <Link
          to={SimpleGlobalListRouteBuilder.buildRoleNamesRoute()}
          className="btn btn-info"
        >
          שמות תפקיד
        </Link>
        <Link
          to={SimpleGlobalListRouteBuilder.buildSpecialDatesRoute()}
          className="btn btn-info"
        >
          תאריכים מיוחדים
        </Link>
        <Link
          to={SimpleGlobalListRouteBuilder.buildShiftDepartmentsRoute()}
          className="btn btn-info"
        >
          מחלקות עובדים
        </Link>
        <Link
          to={SimpleGlobalListRouteBuilder.buildPOSMappingRoute()}
          className="btn btn-info"
        >
          טבלאות המרה מכר
        </Link>
        <Link
          to={SimpleGlobalListRouteBuilder.buildPackageKindsRoute()}
          className="btn btn-info"
        >
          סוגי מארזים
        </Link>
        <Link
          to={SimpleGlobalListRouteBuilder.buildUnitKindsRoute()}
          className="btn btn-info"
        >
          סוגי אריזות פריטים
        </Link>
        <Link
          to={SimpleGlobalListRouteBuilder.buildManufacturersRoute()}
          className="btn btn-info"
        >
          יצרנים
        </Link>
        <Link
          to={SimpleGlobalListRouteBuilder.buildContactTypesRoute()}
          className="btn btn-info"
        >
          סוגי אנשי קשר
        </Link>
        <Link
          to={SimpleGlobalListRouteBuilder.buildContactsRoute()}
          className="btn btn-info"
        >
          אנשי קשר
        </Link>
        <Link
          to={SimpleGlobalListRouteBuilder.buildStorageUnitsRoute()}
          className="btn btn-info"
        >
          יחידות אחסון
        </Link>
        <Link
          to={SimpleGlobalListRouteBuilder.buildSimpleGlobalListRoute(
            SimpleGlobalListRoutePath.PackageWeights
          )}
          className="btn btn-info"
        >
          משקלי אריזה
        </Link>
      </div>
    </RouteContent>
  );
};
