import React, { useMemo, useCallback } from "react";
import { Form } from "form/components/Form";
import {
  WeeklyWorkScheduleType,
  WeeklyWorkScheduleFormValues,
} from "weekly-work-schedule/types";
import { WeeklyWorkScheduleApi } from "weekly-work-schedule/WeeklyWorkScheduleApi";
import { WeeklyWorkScheduleForm } from "./WeeklyWorkScheduleForm";
import { WeeklyWSStartOfWeekAdapter } from "weekly-work-schedule/utils/WeeklyWSStartOfWeekAdapter";
import { usePrevious } from "base/hooks/usePrevious";

export type WeeklyWorkScheduleProps = {
  startOfWeekDay: Date;
  schedules: WeeklyWorkScheduleType[];
};

export const WeeklyWorkSchedule: React.FC<WeeklyWorkScheduleProps> = ({
  schedules,
  startOfWeekDay,
}) => {
  const prevStartOfWeekDay = usePrevious(startOfWeekDay);
  const formValues = useMemo<WeeklyWorkScheduleFormValues>(
    () => ({
      startOfWeekDay,
      schedules,
      isDataReady: schedules.length > 0,
    }),
    [schedules, startOfWeekDay]
  );

  const onSubmit = useCallback(
    async ({ schedules: nextSchedules }: WeeklyWorkScheduleFormValues) => {
      await WeeklyWorkScheduleApi.save(
        WeeklyWSStartOfWeekAdapter.format(startOfWeekDay),
        nextSchedules
      );
      alert("הפעולה הסתיימה בהצלחה");
    },
    [startOfWeekDay]
  );

  return (
    <Form
      initialValues={formValues}
      onSubmit={onSubmit}
      render={() => <WeeklyWorkScheduleForm startOfWeekDay={startOfWeekDay} />}
      keepDirtyOnReinitialize={
        !prevStartOfWeekDay || prevStartOfWeekDay === startOfWeekDay
      }
    />
  );
};
