export class ContractsRouteBuilder {
  static buildContractsRoute() {
    return `/contracts`;
  }

  static buildAddContractRoute() {
    return `/contracts/new`;
  }

  static buildEditContractRoute(contractId: number) {
    return `/contracts/${contractId}`;
  }
}
