import Axios from "axios";
import { PreparationTaskListItem, AddPreparationTaskFormValues } from "./types";
import { RequestCacheHelper } from "base/api/RequestCacheHelper";

export class PreparationTasksApi {
  static async getList() {
    return Axios.get<PreparationTaskListItem[]>(
      "/api/preparationTasks/current"
    );
  }

  static async addItem(values: AddPreparationTaskFormValues) {
    await Axios.post<PreparationTaskListItem[]>(
      "/api/preparationTasks",
      values
    );
    RequestCacheHelper.instance.refetch(PreparationTasksApi.getList);
  }

  static async startWork(id: number) {
    const { data: savedListItem } = await Axios.post<PreparationTaskListItem>(
      `/api/preparationTasks/${id}/setStart`
    );

    RequestCacheHelper.instance.update<PreparationTaskListItem[]>((list) => {
      if (!list) return undefined;
      else return list.map((item) => (item.id === id ? savedListItem : item));
    }, PreparationTasksApi.getList);
  }

  static async delete(id: number) {
    await Axios.delete(`/api/preparationTasks/${id}`);

    RequestCacheHelper.instance.update<PreparationTaskListItem[]>((list) => {
      if (!list) return undefined;
      else return list.filter((item) => item.id !== id);
    }, PreparationTasksApi.getList);
  }

  static async printLabels(id: number, amount: number) {
    await Axios.post(`/api/preparationTasks/${id}/printLabels/${amount}`);
  }

  static async setDone(id: number) {
    await Axios.post(`/api/preparationTasks/${id}/setDone`);
    RequestCacheHelper.instance.refetch(PreparationTasksApi.getList);
  }
}
