import React, { useMemo, useCallback } from "react";
import { Modal, Tabs, Tab, Row, Col } from "react-bootstrap";
import { FormSpy } from "react-final-form";
import { Form } from "form/components/Form";
import { Typography } from "base/components/Typography";
import { useCustomQueryWithParams } from "base/api/hooks/useCustomQuery";
import { DepartmentFoodWorksApi } from "food-works/department-food-works/DepartmentFoodWorksApi";
import { DepartmentsApi } from "department/DepartmentsApi";
import { DepartmentFoodWorksFormValues } from "food-works/department-food-works/types/DepartmentFoodWorksFormValues";
import { DepartmentFoodWorksForm } from "food-works/department-food-works/components/DepartmentFoodWorksForm";
import "./style.scss";
import { FormTextInput } from "form/components/FormTextInput";
import { FormFileUpload } from "form/components/FormFileUpload";
import { RoundCheckButton } from "base/components/RoundCheckButton";
import { FormFileViewer } from "form/components/FormFileViewer";
import { FileDir } from "base/types";
import { DepartmentInfoFormValues } from "department/types";
import { DepartmentAddonsTab } from "./DepartmentAddonsTab";
import { usePreviosDefined } from "base/hooks/usePreviosDefined";

export type ManageDepartmentModalProps = {
  show: boolean;
  isShowAddons: boolean;
  departmentId?: number;
  onCancel: () => void;
};

export const ManageDepartmentModal: React.FC<ManageDepartmentModalProps> = ({
  show,
  isShowAddons,
  departmentId,
  onCancel,
}) => {
  const prevDepartmentId = usePreviosDefined(departmentId);
  const { data } = useCustomQueryWithParams(
    DepartmentFoodWorksApi.getFoodWorks,
    () => [departmentId!],
    {
      skip: !show || !departmentId,
    }
  );

  const { data: departmentData } = useCustomQueryWithParams(
    DepartmentsApi.getItem,
    () => [departmentId!],
    {
      skip: !show || !departmentId,
    }
  );

  const foodWorksForm = useMemo<
    DepartmentFoodWorksFormValues | undefined
  >(() => {
    if (!departmentId) {
      return undefined;
    } else {
      return {
        departmentId,
        foodWorks: data ?? [],
      };
    }
  }, [departmentId, data]);

  const onSaveDepartmentInfo = useCallback(
    async (departmentInfo: DepartmentInfoFormValues) => {
      await DepartmentsApi.saveItemInfo(departmentInfo);
    },
    []
  );

  return (
    <Modal show={show && departmentId} onHide={onCancel} centered size="xl">
      <div className="manage-department-modal-content">
        <div className="fwm-title-bar">
          <Typography variant="description" fontSize={24} bold>
            ניהול מחלקה - {departmentData?.name}
          </Typography>
          <div className="close-btn" onClick={onCancel} />
        </div>
        <div className="fwm-body">
          <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
            <Tab eventKey="home" title="כללי">
              <Form
                onSubmit={onSaveDepartmentInfo}
                initialValues={departmentData}
              >
                {({ handleSubmit, form }) => (
                  <Row>
                    <Col md={6} className="d-flex flex-column">
                      <Typography variant="description" fontSize={20} bold>
                        ניהול מידע בסיסי
                      </Typography>
                      <FormTextInput name="name" />
                      <div className="flex-grow-1 d-flex flex-column justify-content-end">
                        <RoundCheckButton onClick={handleSubmit} />
                      </div>
                    </Col>
                    <Col md={6}>
                      <FormFileUpload name="photoFile" />
                      <div style={{ width: 120, marginTop: 20 }}>
                        <FormSpy<DepartmentInfoFormValues>
                          subscription={{ values: true }}
                          render={({ values }) => (
                            <FormFileViewer
                              uploadName="photoFile"
                              pathName="photoFileName"
                              fileDir={FileDir.Department}
                              onDelete={() => {
                                if (form.getState().values.photoFile) {
                                  form.change("photoFile", undefined);
                                } else {
                                  form.change("isDeletePhotoFile", true);
                                }
                              }}
                              isDeleted={
                                values.isDeletePhotoFile && !values.photoFile
                              }
                            />
                          )}
                        />
                      </div>
                    </Col>
                  </Row>
                )}
              </Form>
            </Tab>
            <Tab eventKey="profile" title="עיבודים">
              <Form
                initialValues={foodWorksForm}
                onSubmit={() => {}}
                component={DepartmentFoodWorksForm}
              />
            </Tab>
            {isShowAddons && (
              <Tab eventKey="dishAddons" title="תוספות">
                <DepartmentAddonsTab
                  departmentId={departmentId ?? prevDepartmentId!}
                />
              </Tab>
            )}
          </Tabs>
        </div>
      </div>
    </Modal>
  );
};
