import { useMemo } from "react";
import { BaseSelectListItem } from "base/types";
import { SelectProps } from "../Select";

export const useSelectOptionsAdapter = (
  arr: BaseSelectListItem[] | undefined
) => {
  return useMemo<SelectProps["options"]>(() => {
    return (arr ?? []).map((x) => ({
      value: x.id,
      label: x.name,
    }));
  }, [arr]);
};
