import { JWTPayloadParser } from "base/utils/parsers";
import { AuthTokenPayload } from "auth/types";
import { useToken } from "./useToken";

export const useTokenPayload = () => {
  const token = useToken();

  if (token) {
    return JWTPayloadParser.parse<AuthTokenPayload>(token);
  } else {
    return undefined;
  }
};
