import React, { useCallback, useMemo } from "react";
import { Pagination } from "react-bootstrap";
import "./styles.scss";

export type TablePaginationProps = {
  pageCount: number;
  pageIndex: number;
  pageSizes: readonly (number | "All")[];
  pageSizeIndex: number;
  onPageSizeChange: (pageSize: number | "All") => void;
  onGoToPage: (index: number) => void;
};

export const TablePagination: React.FC<TablePaginationProps> = ({
  pageIndex,
  pageCount,
  pageSizeIndex,
  pageSizes,
  onGoToPage,
  onPageSizeChange,
}) => {
  const canPreviousPage = pageIndex > 0;
  const canNextPage = pageIndex < pageCount - 1;

  const onPrevPage = useCallback(() => {
    onGoToPage(pageIndex - 1);
  }, [onGoToPage, pageIndex]);

  const onNextPage = useCallback(() => {
    onGoToPage(pageIndex + 1);
  }, [onGoToPage, pageIndex]);

  const onPageSelectionChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const { value } = e.target;
      if (value === "All") {
        onPageSizeChange("All");
      } else {
        onPageSizeChange(Number(e.target.value));
      }
    },
    [onPageSizeChange]
  );

  const paginationItemElements = useMemo(() => {
    return Array.from({
      length: pageCount,
    })
      .fill(undefined)
      .map((_, index) => (
        <Pagination.Item
          key={index}
          active={pageIndex === index}
          onClick={() => onGoToPage(index)}
        >
          <span>{index + 1}</span>
        </Pagination.Item>
      ));
  }, [pageCount, pageIndex, onGoToPage]);

  const pageSizeElements = useMemo(() => {
    return pageSizes.map((pageSize) => (
      <option key={pageSize} value={pageSize}>
        {pageSize}
      </option>
    ));
  }, [pageSizes]);

  return (
    <div className="table-pagination">
      <span>
        מספר שורות בעמוד{" "}
        <select
          value={pageSizes[pageSizeIndex]}
          onChange={onPageSelectionChange}
        >
          {pageSizeElements}
        </select>
      </span>
      <Pagination size="sm">
        <span onClick={onPrevPage}>
          <Pagination.Prev disabled={!canPreviousPage} />
        </span>
        {paginationItemElements}
        <span onClick={onNextPage}>
          <Pagination.Next disabled={!canNextPage} />
        </span>
      </Pagination>
    </div>
  );
};
