import React, { useCallback } from "react";
import { Field } from "react-final-form";
import { Modal } from "react-bootstrap";
import InputMask from "react-input-mask";
import { Typography } from "base/components/Typography";
import { Form } from "form/components/Form";
import { FormMetaError } from "form/components/FormMetaError";
import { FieldsValidator } from "form/validators/FieldsValidator";
import { FormTextArea } from "form/components/FormTextArea";

export type ScheduleRequestShiftDayTimePickerProps = {
  show: boolean;
  onHide: () => void;
  onSave: (fromTime: string, toTime: string, notes: string | undefined) => void;
  data?: {
    title: string;
    initialFromTime: string;
    initialToTime: string;
    notes?: string;
  };
};

type FormValues = {
  fromTime: string;
  toTime: string;
  notes: string | undefined;
};

export const ScheduleRequestShiftDayTimePicker: React.FC<ScheduleRequestShiftDayTimePickerProps> = ({
  show,
  onHide,
  onSave,
  data,
}) => {
  const onSubmit = useCallback(
    (values: FormValues) => {
      onSave(values.fromTime, values.toTime, values.notes);
    },
    [onSave]
  );

  return (
    <Modal show={Boolean(show && data)} onHide={onHide} centered>
      <div className="p-2 text-center">
        <Typography variant="title">{data?.title}</Typography>
        <Form
          onSubmit={onSubmit}
          initialValues={{
            fromTime: data?.initialFromTime,
            toTime: data?.initialToTime,
            notes: data?.notes,
          }}
        >
          {({ handleSubmit }) => (
            <>
              <Typography variant="description">יכול משעה</Typography>
              <div className="d-flex justify-content-center">
                <Field
                  validate={FieldsValidator.compose(
                    FieldsValidator.timeMaskRequired,
                    FieldsValidator.timeMask
                  )}
                  render={({ input, meta }) => (
                    <div>
                      <InputMask
                        className="form-control"
                        mask={"99:99"}
                        alwaysShowMask
                        dir="ltr"
                        style={{ width: 100, fontSize: 30 }}
                        {...input}
                      />
                      <FormMetaError meta={meta} />
                    </div>
                  )}
                  name="fromTime"
                />
              </div>
              <Typography variant="description">יכול עד שעה</Typography>
              <div className="d-flex justify-content-center">
                <Field
                  validate={FieldsValidator.compose(
                    FieldsValidator.timeMaskRequired,
                    FieldsValidator.timeMask
                  )}
                  render={({ input, meta }) => (
                    <div>
                      <InputMask
                        className="form-control"
                        mask={"99:99"}
                        alwaysShowMask
                        dir="ltr"
                        style={{ width: 100, fontSize: 30 }}
                        {...input}
                      />
                      <FormMetaError meta={meta} />
                    </div>
                  )}
                  name="toTime"
                />
              </div>
              <Typography variant="description">הערות</Typography>
              <div className="d-flex justify-content-center">
                <FormTextArea name="notes" />
              </div>
              <div className="d-flex justify-content-center mt-1">
                <div className="btn btn-primary" onClick={handleSubmit}>
                  שמירה
                </div>
              </div>
            </>
          )}
        </Form>
      </div>
    </Modal>
  );
};
