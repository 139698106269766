import axios from "axios";
import { TreeInfo, TreeInfoFoodListItem } from "./types";
import { TreeType } from "base/types/TreeType";
import { TreeApiPathFormatter } from "base/utils/formatters/TreeApiPathFormatter";
import { RequestCacheHelper } from "base/api/RequestCacheHelper";

export class TreeInfoApi {
  static getTreeInfo(type: TreeType, id: number) {
    return axios.get<TreeInfo>(
      `/api/${TreeApiPathFormatter.format(type)}/${id}/tree`
    );
  }

  static async addMaterial(
    type: TreeType,
    id: number,
    listItemId: number,
    listItemAmount: number
  ) {
    const { data } = await axios.post<TreeInfoFoodListItem>(
      `/api/${TreeApiPathFormatter.format(type)}/${id}/material`,
      {
        addedItemId: listItemId,
        amount: listItemAmount,
      }
    );

    RequestCacheHelper.instance.update(
      (current: TreeInfo | undefined) => {
        return { ...current!, materials: [...current!.materials, data] };
      },
      TreeInfoApi.getTreeInfo,
      [type, id]
    );
  }

  static async addIngredient(
    type: TreeType,
    id: number,
    listItemId: number,
    listItemAmount: number
  ) {
    const { data } = await axios.post<TreeInfoFoodListItem>(
      `/api/${TreeApiPathFormatter.format(type)}/${id}/ingredient`,
      {
        addedItemId: listItemId,
        amount: listItemAmount,
      }
    );

    RequestCacheHelper.instance.update(
      (current: TreeInfo | undefined) => {
        return { ...current!, ingredients: [...current!.ingredients, data] };
      },
      TreeInfoApi.getTreeInfo,
      [type, id]
    );
  }

  static async updateMaterial(
    type: TreeType,
    id: number,
    rowId: number,
    listItemAmount: number
  ) {
    const { data } = await axios.post<TreeInfoFoodListItem>(
      `/api/${TreeApiPathFormatter.format(type)}/${id}/material`,
      {
        id: rowId,
        amount: listItemAmount,
      }
    );

    RequestCacheHelper.instance.update(
      (current: TreeInfo | undefined) => {
        return {
          ...current!,
          materials: current!.materials.map((x) => (x.id === rowId ? data : x)),
        };
      },
      TreeInfoApi.getTreeInfo,
      [type, id]
    );
  }

  static async updateIngredient(
    type: TreeType,
    id: number,
    rowId: number,
    listItemAmount: number
  ) {
    const { data } = await axios.post<TreeInfoFoodListItem>(
      `/api/${TreeApiPathFormatter.format(type)}/${id}/ingredient`,
      {
        id: rowId,
        amount: listItemAmount,
      }
    );

    RequestCacheHelper.instance.update(
      (current: TreeInfo | undefined) => {
        return {
          ...current!,
          ingredients: current!.ingredients.map((x) =>
            x.id === rowId ? data : x
          ),
        };
      },
      TreeInfoApi.getTreeInfo,
      [type, id]
    );
  }

  static async removeMaterial(type: TreeType, id: number, materialId: number) {
    await axios.delete<TreeInfoFoodListItem>(
      `/api/${TreeApiPathFormatter.format(type)}/${id}/material/${materialId}`
    );

    RequestCacheHelper.instance.update(
      (current: TreeInfo | undefined) => {
        return {
          ...current!,
          materials: current!.materials.filter(
            (item) => item.id !== materialId
          ),
        };
      },
      TreeInfoApi.getTreeInfo,
      [type, id]
    );
  }

  static async removeIngredient(
    type: TreeType,
    id: number,
    ingredientId: number
  ) {
    await axios.delete(
      `/api/${TreeApiPathFormatter.format(
        type
      )}/${id}/ingredient/${ingredientId}`
    );

    RequestCacheHelper.instance.update(
      (current: TreeInfo | undefined) => {
        return {
          ...current!,
          ingredients: current!.ingredients.filter(
            (item) => item.id !== ingredientId
          ),
        };
      },
      TreeInfoApi.getTreeInfo,
      [type, id]
    );
  }

  static async setMaterialTotalAmount(id: number, totalAmount: number) {
    await axios.post(
      `/api/${TreeApiPathFormatter.format(
        TreeType.Material
      )}/${id}/totalamount/${totalAmount}`
    );

    await RequestCacheHelper.instance.update(
      (current: TreeInfo | undefined) => {
        return {
          ...current!,
          totalAmount,
        };
      },
      TreeInfoApi.getTreeInfo,
      [TreeType.Material, id]
    );
  }
}
