import React, { useCallback, useMemo } from "react";
import { Modal, Col, Row, Button } from "react-bootstrap";
import { Form } from "form/components/Form";
import { FormNumberInput } from "form/components/FormNumberInput";
import { FormSelect } from "form/components/FormSelect";
import { ProductSupplyMethodListItemFormValues } from "./types";
import { Typography } from "base/components/Typography";
import "modal/styles.scss";
import {
  useCustomQuery,
  useCustomQueryWithParams,
  CustomQueryResponse,
} from "base/api/hooks/useCustomQuery";
import { SuppliersApi } from "supplier/SuppliersApi";
import { useSelectOptionsAdapter } from "base/components/Select/hooks/useSelectOptionsAdapter";
import { FieldsValidator } from "form/validators/FieldsValidator";
import { FormCheckbox } from "form/components/FormCheckbox";
import { SupplyUnitTypesSelectOptions } from "base/components/Select/constants/SupplyUnitTypesSelectOptions";
import { Field } from "react-final-form";
import { SupplyUnitType, BaseSelectListItem, UnitType } from "base/types";
import { SimpleGlobalListApi } from "global-list/SimpleGlobalListApi";
import { SimpleGlobalListRoutePath } from "global-list/types";
import { SimpleGlobalListItemModal } from "global-list/components/SimpleGlobalListItemModal";
import { ProductSupplyMethodsApi } from "./ProductSupplyMethodsApi";
import { ProductsApi } from "product/ProductsApi";
import { SelectProps } from "base/components/Select";
import { AmountUnitTypeOptionsBuilder } from "./AmountUnitTypeOptionsBuilder";

export type ProductSupplyMethodListItemModalProps = {
  show: boolean;
  productName: string;
  onHide: () => void;
  initialValues: WithRequiredProperties<
    Partial<ProductSupplyMethodListItemFormValues>,
    "productId"
  >;
  onItemSaved?: (values: ProductSupplyMethodListItemFormValues) => void;
};

export const ProductSupplyMethodListItemModal: React.FC<ProductSupplyMethodListItemModalProps> = ({
  show,
  productName,
  onHide,
  initialValues,
  onItemSaved,
}) => {
  const { productId } = initialValues;

  const { data: product } = useCustomQueryWithParams(
    ProductsApi.getItem,
    () => [productId],
    {
      skip: !show,
    }
  );

  const { data: suppliers } = useCustomQuery(SuppliersApi.getList, {
    skip: !show,
  });
  const suppliersOptions = useSelectOptionsAdapter(suppliers);

  const { data: supplyMethods } = useCustomQueryWithParams(
    ProductSupplyMethodsApi.get,
    () => [productId],
    {
      skip: !show,
    }
  );

  const { data: unitKinds } = useCustomQueryWithParams(
    SimpleGlobalListApi.getList,
    () => [SimpleGlobalListRoutePath.UnitKinds],
    {
      skip: !show,
    }
  ) as CustomQueryResponse<BaseSelectListItem[]>;
  const unitKindsOptions = useSelectOptionsAdapter(unitKinds);

  const { data: packageKinds } = useCustomQueryWithParams(
    SimpleGlobalListApi.getList,
    () => [SimpleGlobalListRoutePath.PackageKinds],
    {
      skip: !show,
    }
  ) as CustomQueryResponse<BaseSelectListItem[]>;
  const packageKindsOptions = useSelectOptionsAdapter(packageKinds);

  const amountUnitTypeOptions = useMemo<SelectProps["options"]>(() => {
    return AmountUnitTypeOptionsBuilder.build(product?.prepareUnitType);
  }, [product]);

  const fieldName = (property: keyof ProductSupplyMethodListItemFormValues) => {
    return property;
  };

  const onSave = useCallback(
    async (values: ProductSupplyMethodListItemFormValues) => {
      const savedProductSupplyMethod = await ProductSupplyMethodsApi.add({
        ...values,
        suppliers: [
          {
            id: undefined as any,
            productSupplyMethodId: undefined as any,
            supplierId: values.supplierId,
            price: 0,
            isDefault: true,
            discountPercentage: 0,
            taraWeight: 0,
          },
        ],
      });
      onItemSaved?.(savedProductSupplyMethod);
      onHide();
    },
    [onHide, onItemSaved]
  );

  return (
    <Modal show={show} onHide={onHide} centered size="xl">
      <div>
        <div className="modal-title-bar">
          <Typography variant="description" fontSize={24} bold>
            הוספת אספקה למוצר: {productName}
          </Typography>
          <div className="close-btn" onClick={onHide} />
        </div>
        <div className="p-2">
          <Form
            onSubmit={onSave}
            subscription={{ submitting: true }}
            initialValues={{
              ...initialValues,
              isDefault:
                initialValues.isDefault ||
                (supplyMethods && supplyMethods.length === 0),
            }}
          >
            {({ handleSubmit, submitting }) => (
              <div>
                <Row noGutters>
                  <Col xs={4} className="p-1">
                    <FormSelect
                      name={fieldName("supplierId")}
                      variant="react-select"
                      emptyOptionLabel="בחירת ספק"
                      options={suppliersOptions}
                      validate={FieldsValidator.required}
                    />
                  </Col>
                  <Col md={2} className="p-1">
                    <FormNumberInput name="barCode" placeholder="ברקוד" />
                  </Col>
                  <Col md="auto" className="p-1">
                    <FormSelect
                      name="supplyUnitType"
                      emptyOptionLabel="צורת אספקה"
                      options={SupplyUnitTypesSelectOptions}
                      validate={FieldsValidator.required}
                    />
                  </Col>
                </Row>
                <Row noGutters>
                  <Field
                    name="supplyUnitType"
                    render={({ input: supplyUnitTypeInput }) =>
                      supplyUnitTypeInput.value !== SupplyUnitType.Amount && (
                        <>
                          {supplyUnitTypeInput.value ===
                            SupplyUnitType.Package && (
                            <Col md={2} className="p-1">
                              <FormSelect
                                name="packageKindId"
                                emptyOptionLabel="סוג מארז"
                                options={packageKindsOptions}
                                withAdd={{
                                  customModalRender: (props) => (
                                    <SimpleGlobalListItemModal
                                      {...props}
                                      routeApiPath={
                                        SimpleGlobalListRoutePath.PackageKinds
                                      }
                                    />
                                  ),
                                }}
                              />
                            </Col>
                          )}
                          {(supplyUnitTypeInput.value ===
                            SupplyUnitType.SingleItem ||
                            supplyUnitTypeInput.value ===
                              SupplyUnitType.Package) && (
                            <Col md={2} className="p-1">
                              <FormSelect
                                name="unitKindId"
                                emptyOptionLabel="סוג פריט"
                                options={unitKindsOptions}
                                withAdd={{
                                  customModalRender: (props) => (
                                    <SimpleGlobalListItemModal
                                      {...props}
                                      routeApiPath={
                                        SimpleGlobalListRoutePath.UnitKinds
                                      }
                                    />
                                  ),
                                }}
                              />
                            </Col>
                          )}
                          {supplyUnitTypeInput.value &&
                            supplyUnitTypeInput.value !==
                              SupplyUnitType.SingleItem && (
                              <Col md={2} className="p-1">
                                <FormNumberInput
                                  name="unitsInPackage"
                                  placeholder="פריטים במארז"
                                />
                              </Col>
                            )}
                          {supplyUnitTypeInput.value &&
                            supplyUnitTypeInput.value !==
                              SupplyUnitType.Amount &&
                            product?.prepareUnitType !== UnitType.Yeh && (
                              <Col md={1} className="p-1">
                                <FormNumberInput
                                  name="unitWeight"
                                  placeholder="כמות"
                                />
                              </Col>
                            )}
                          {supplyUnitTypeInput.value &&
                            supplyUnitTypeInput.value !==
                              SupplyUnitType.Amount &&
                            product?.prepareUnitType !== UnitType.Yeh && (
                              <Col md={1} className="p-1">
                                <FormSelect
                                  name="isLargeUnit"
                                  options={amountUnitTypeOptions}
                                  displayEmptyOption={false}
                                />
                              </Col>
                            )}
                        </>
                      )
                    }
                  />
                </Row>
                <Row noGutters>
                  <Col md="auto" className="p-1">
                    <label>
                      <div className="d-inline-block align-middle mx-2">
                        <FormCheckbox
                          name="isDefault"
                          width={23}
                          disabled={supplyMethods && supplyMethods.length === 0}
                        />
                      </div>
                      ברירת מחדל
                    </label>
                  </Col>
                  <Col xs="auto">
                    <Button
                      variant="primary"
                      onClick={() => {
                        handleSubmit();
                      }}
                      disabled={submitting || !supplyMethods}
                      size="sm"
                      style={{ height: 38 }}
                    >
                      הוספה
                    </Button>
                  </Col>
                </Row>
              </div>
            )}
          </Form>
        </div>
      </div>
    </Modal>
  );
};
