import React from "react";
import { RouteContent } from "base/components/RouteContent";
import { WorkSchedule } from "work-schedule/components/WorkSchedule";
import { useCustomQuery } from "base/api/hooks/useCustomQuery";
import { WorkScheduleApi } from "work-schedule/WorkScheduleApi";

export const WorkScheduleScreen = () => {
  const { data } = useCustomQuery(WorkScheduleApi.getTemplateSchedules);

  return (
    <RouteContent>
      <WorkSchedule schedules={data ?? []} />
    </RouteContent>
  );
};
