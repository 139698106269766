import { OrderDocumentType, SentOrderItemGapReason } from "./types";

export const OrderDocumentTypeOptions = [
  {
    label: "תעודת משלוח",
    value: OrderDocumentType.TM,
  },
  {
    label: "חשבונית מס",
    value: OrderDocumentType.HM,
  },
  {
    label: "חשבונית מס-קבלה",
    value: OrderDocumentType.HMK,
  },
  {
    label: "חשבונית עסקה",
    value: OrderDocumentType.HI,
  },
  {
    label: "תעודת משלוח זיכוי",
    value: OrderDocumentType.TMZ,
  },
  {
    label: "חשבונית זיכוי",
    value: OrderDocumentType.HZ,
  },
  {
    label: "תעודת החזרה",
    value: OrderDocumentType.TH,
  },
];

export const SentOrderGapReasonOptions = [
  {
    label: "חוסר",
    value: SentOrderItemGapReason.Shortage,
  },
  {
    label: "פגום",
    value: SentOrderItemGapReason.Defect,
  },
  {
    label: "הפרש מחיר",
    value: SentOrderItemGapReason.PriceGap,
  },
];
