export class OrdersRouteBuilder {
  static templates = {
    OrderWizardRouter: "/orders/new",
    AddOrderRoute: `/orders/new/home`, // Part of OrderWizardRouter
    SupplierListRoute: `/orders/new/suppliers`, // Part of OrderWizardRouter
    SupplierRoute: "/orders/new/suppliers/:supplierId", // Part of OrderWizardRouter
    DepartmentListRoute: `/orders/new/departments`, // Part of OrderWizardRouter
    DepartmentRoute: "/orders/new/departments/:departmentId", // Part of OrderWizardRouter
    OrderSearchProductsRoute: "/orders/new/search-products", // Part of OrderWizardRouter
    OrdersStatusRoute: "/orders/status",
  };

  static buildOrdersStatusRoute() {
    return OrdersRouteBuilder.templates.OrdersStatusRoute;
  }

  static buildAddOrderRoute() {
    return OrdersRouteBuilder.templates.AddOrderRoute;
  }

  static buildSupplierListRoute() {
    return OrdersRouteBuilder.templates.SupplierListRoute;
  }
  static buildDepartmentListRoute() {
    return OrdersRouteBuilder.templates.DepartmentListRoute;
  }

  static buildSupplierRoute(id: number) {
    return OrdersRouteBuilder.templates.SupplierRoute.replace(
      ":supplierId",
      String(id)
    );
  }

  static buildDepartmentRoute(id: number) {
    return OrdersRouteBuilder.templates.DepartmentRoute.replace(
      ":departmentId",
      String(id)
    );
  }

  static buildEditOrderRoute(orderId: number) {
    return `/orders/${orderId}`;
  }

  static buildOrderProductsSearchRoute() {
    return OrdersRouteBuilder.templates.OrderSearchProductsRoute;
  }
}
