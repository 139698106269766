import React from "react";
import { Modal } from "react-bootstrap";
import { useBooleanState } from "base/hooks/useBooleanState";
import "../styles/VideoTheaterSupport.scss";

export interface VideoTheaterSupportProps {
  children: React.ReactNode;
}
export const VideoTheaterSupport: React.FC<VideoTheaterSupportProps> = ({
  children,
}) => {
  const [show, onShow, onHide] = useBooleanState(false);

  return (
    <>
      <div className="position-relative h-100">
        {children}
        <div
          onClick={(e) => {
            e.stopPropagation();
            onShow();
          }}
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          }}
        />
      </div>
      <Modal show={show} onHide={onHide} dialogClassName="VideoTheaterSupport">
        {children}
      </Modal>
    </>
  );
};
