import React, { useMemo, useRef } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { IngredientPriceAgreement } from "supplier/types";
import { FieldArray } from "react-final-form-arrays";
import { Form } from "form/components/Form";
import { FormSpy, Field } from "react-final-form";
import {
  useCustomQueryWithParams,
  useCustomQuery,
} from "base/api/hooks/useCustomQuery";
import { SuppliersApi } from "supplier/SuppliersApi";
import { useSelectOptionsAdapter } from "base/components/Select/hooks/useSelectOptionsAdapter";
import { FormSelect } from "form/components/FormSelect";
import { FormCustomReset } from "form/utils/FormCustomReset";
import { Cancelable, isEqual, debounce } from "lodash";
import { getIn } from "final-form";
import { IngredientsApi } from "ingredient/IngredientsApi";
import { FormNumberInput } from "form/components/FormNumberInput";
import { FieldsValidator } from "form/validators/FieldsValidator";
import { Fields } from "form/components/Fields";

export type SupplierIngredientPriceAgreementsTabProps = {
  supplierId: number;
};

export const SupplierIngredientPriceAgreementsTab: React.FC<SupplierIngredientPriceAgreementsTabProps> = ({
  supplierId,
}) => {
  const fieldName = (
    property: keyof IngredientPriceAgreement,
    prefix?: string
  ) => `${prefix ? `${prefix}.` : ""}${property}`;

  const {
    data: priceAgreements,
  } = useCustomQueryWithParams(
    SuppliersApi.getIngredientPriceAgreements,
    () => [supplierId]
  );

  const { data: ingreientsTaklitSelectList } = useCustomQuery(
    IngredientsApi.getTaklitSelectList
  );

  const ingredientsTaklitSelectOptions = useSelectOptionsAdapter(
    ingreientsTaklitSelectList
  );

  const initialValues = useMemo(() => ({ priceAgreements }), [priceAgreements]);

  const saveFn = async (data: IngredientPriceAgreement) => {
    await SuppliersApi.saveIngredientPriceAgreement(data);
  };
  const rowsAutoSaveMapRef = useRef<{
    [id: number]: typeof saveFn & Cancelable;
  }>({});

  return (
    <>
      <h4 className="mt-3">הסכמי מחיר</h4>
      <Form initialValues={initialValues} onSubmit={() => {}}>
        {({ form }) => (
          <>
            <FieldArray name="priceAgreements" subscription={{ length: true }}>
              {({ fields }) =>
                fields.map((prefix, index) => {
                  const priceAgreementId: number = getIn(
                    form.getState().values,
                    fieldName("id", prefix)
                  );
                  return (
                    <Row noGutters key={prefix}>
                      <Col className="p-1" md={3}>
                        <FormSelect
                          name={fieldName("ingredientId", prefix)}
                          options={ingredientsTaklitSelectOptions}
                          displayEmptyOption={false}
                        />
                      </Col>
                      <Fields
                        names={[
                          fieldName("fixedPrice", prefix),
                          fieldName("ingredientId", prefix),
                        ]}
                        render={([fixedPrice, ingreidientId]) => {
                          const matchingIng = ingreientsTaklitSelectList?.find(
                            (x) => x.id === ingreidientId
                          );
                          if (!matchingIng?.isTaklit) {
                            return null;
                          }

                          return (
                            <Col className="p-1" md={2}>
                              <FormNumberInput
                                name={fieldName("taklitPercentage", prefix)}
                                placeholder="אחוז הנחה מתקליט"
                                step="0.1"
                                disabled={typeof fixedPrice === "number"}
                              />
                            </Col>
                          );
                        }}
                      />
                      <Col className="p-1" md={1}>
                        <Field
                          name={fieldName("taklitPercentage", prefix)}
                          render={({ input: { value: taklitPercentage } }) => (
                            <FormNumberInput
                              name={fieldName("fixedPrice", prefix)}
                              placeholder="מחיר קבוע"
                              step="0.01"
                              disabled={typeof taklitPercentage === "number"}
                            />
                          )}
                        />
                      </Col>
                      <Col className="p-1">
                        <button
                          className="btn btn-danger"
                          onClick={() => {
                            fields.remove(index);
                            SuppliersApi.deleteIngredientPriceAgreement(
                              supplierId,
                              priceAgreementId
                            );
                          }}
                        >
                          מחיקה
                        </button>
                      </Col>
                      <FormSpy
                        subscription={{
                          validating: true,
                        }}
                        onChange={async ({ validating }) => {
                          if (!rowsAutoSaveMapRef.current[priceAgreementId]) {
                            rowsAutoSaveMapRef.current[
                              priceAgreementId
                            ] = debounce(saveFn, 500);
                          }

                          const formState = form.getState();
                          if (validating || !formState.valid) {
                            rowsAutoSaveMapRef.current[
                              priceAgreementId
                            ].cancel();
                            return;
                          }

                          const { values, initialValues } = formState;
                          const nextRowValue = values?.priceAgreements.find(
                            (c) => c.id === priceAgreementId
                          );
                          const prevRowValue = initialValues?.priceAgreements?.find(
                            (c) => c.id === priceAgreementId
                          );

                          if (
                            nextRowValue &&
                            prevRowValue &&
                            !isEqual(nextRowValue, prevRowValue)
                          ) {
                            rowsAutoSaveMapRef.current[priceAgreementId](
                              nextRowValue
                            );
                          }
                        }}
                      />
                    </Row>
                  );
                })
              }
            </FieldArray>
            <h4 className="mt-3">הוספת הסכם מחיר</h4>
            <Form<IngredientPriceAgreement>
              onSubmit={async (newPriceAgreement, form) => {
                await saveFn(newPriceAgreement);
                FormCustomReset.hardReset(form);
              }}
              initialValues={{ supplierId, ingredientId: 5 }}
              subscription={{ dirty: true, submitting: true }}
            >
              {({ handleSubmit: handleAdd, dirty, submitting }) => (
                <Row noGutters>
                  <Col className="p-1" md={3}>
                    <FormSelect
                      name={fieldName("ingredientId")}
                      emptyOptionLabel="בחר מרכיב"
                      options={ingredientsTaklitSelectOptions}
                      validate={FieldsValidator.required}
                    />
                  </Col>
                  <Fields
                    names={[fieldName("fixedPrice"), fieldName("ingredientId")]}
                    render={([fixedPrice, ingreidientId]) => {
                      const matchingIng = ingreientsTaklitSelectList?.find(
                        (x) => x.id === ingreidientId
                      );
                      if (!matchingIng?.isTaklit) {
                        return null;
                      }

                      return (
                        <Col className="p-1" md={2}>
                          <FormNumberInput
                            name={fieldName("taklitPercentage")}
                            placeholder="אחוז הנחה מתקליט"
                            step="0.1"
                            disabled={typeof fixedPrice === "number"}
                          />
                        </Col>
                      );
                    }}
                  />
                  <Col className="p-1" md={1}>
                    <Field
                      name={fieldName("taklitPercentage")}
                      render={({ input: { value: taklitPercentage } }) => (
                        <FormNumberInput
                          name={fieldName("fixedPrice")}
                          placeholder="מחיר קבוע"
                          step="0.01"
                          disabled={typeof taklitPercentage === "number"}
                        />
                      )}
                    />
                  </Col>
                  <Col className="p-1">
                    <Button
                      onClick={handleAdd}
                      disabled={!dirty && !submitting}
                    >
                      הוספה
                    </Button>
                  </Col>
                </Row>
              )}
            </Form>
          </>
        )}
      </Form>
    </>
  );
};
