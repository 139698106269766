import { mutate, trigger } from "swr";
import { isEqual } from "lodash";

let token = 0;

// singleton.
export class RequestCacheHelper {
  private cache: Array<{ fn: Function; cacheKey: string; paramsArr: any[][] }>;

  private constructor() {
    this.cache = [];
  }

  static instance = new RequestCacheHelper();

  async update<T>(
    value: T | ((current: T | undefined) => T | undefined),
    fn: Function,
    params: any[] = []
  ) {
    const key = this.getKey(fn, params);
    await mutate(key, value, false);
  }

  refetch(fn: Function, params: any[] = []) {
    const key = this.getKey(fn, params);
    trigger(key);
  }

  refetchByPartialParams(fn: Function, partialParams: any[] = []) {
    const keys = this.getMatchingFnKeysByPartialParams(fn, partialParams);
    keys.forEach((key) => trigger(key));
  }

  getKey(fn: Function, params: any[] = []) {
    if (process.env.NODE_ENV === "development" && fn.length !== params.length) {
      throw new Error(`
        DEV Validation Error - 
          trying to get cache key for "${fn.name}", that method expects ${
        fn.length
      } params.
          You passed the following params: [${params.join(",")}],
          Containing ${params.length} items.
          ${fn.length} != ${params.length}. 
          Make sure to pass the right params 
          with the exact length,
          otherwise App won't work correctly from this point forward.
      `);
    }

    let existingItem = this.cache.find((item) => item.fn === fn);
    if (!existingItem) {
      existingItem = {
        fn,
        cacheKey: String(`__${fn.name}__${token++}`),
        paramsArr: [params],
      };
      this.cache.push(existingItem);
    } else {
      const matchingParams = existingItem.paramsArr.find((p) => {
        return isEqual(
          this.serializeParameters(params),
          this.serializeParameters(p)
        );
      });

      if (!matchingParams) {
        existingItem.paramsArr.push(params);
      }
    }

    return [existingItem.cacheKey, ...this.serializeParameters(params)];
  }

  getMatchingFnKeysByPartialParams(fn: Function, partialParams: any[]) {
    const existingItem = this.cache.find((item) => item.fn === fn);
    if (!existingItem) {
      return [];
    }

    const matchingParamsArr = existingItem.paramsArr.filter((p) => {
      return isEqual(partialParams, p.slice(0, partialParams.length));
    });

    return matchingParamsArr.map((p) => this.getKey(fn, p));
  }

  private serializeParameters(params: any[] = []) {
    return params?.map((p) => {
      if (typeof p === "object") {
        return JSON.stringify(p);
      } else {
        return String(p);
      }
    });
  }
}
