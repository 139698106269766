import axios from "axios";
import { RequestCacheHelper } from "base/api/RequestCacheHelper";
import { BaseFood } from "base/types";
import { TreeType } from "base/types/TreeType";
import { TreeApiPathFormatter } from "base/utils/formatters/TreeApiPathFormatter";
import { TreeItemFoodWork } from "./types/TreeItemFoodWork";
import { TreeItemFoodWorkType } from "./types/TreeItemFoodWorkType";
import { TreeInfoApi } from "tree-info/TreeInfoApi";

export class TreeItemFoodWorksApi {
  static async getFoodWorks(
    treeType: TreeType,
    treeItemType: TreeItemFoodWorkType,
    treeItemId: number
  ) {
    return axios.get<TreeItemFoodWork[]>(
      `/api/${TreeApiPathFormatter.format(
        treeType
      )}/${TreeItemFoodWorksApi.getTreeItemApiPath(
        treeItemType
      )}/${treeItemId}/foodworks`
    );
  }

  static async getItem(treeItemType: TreeItemFoodWorkType, itemId: number) {
    return axios.get<BaseFood>(
      `/api/${TreeItemFoodWorksApi.getTreeItemApiPath(treeItemType)}/${itemId}`
    );
  }

  static async addFoodWork(
    treeType: TreeType,
    treeItemType: TreeItemFoodWorkType,
    treeItemId: number,
    foodWork: Omit<TreeItemFoodWork, "id" | "isRequired">
  ) {
    const { data: newFoodWork } = await axios.post<TreeItemFoodWork>(
      `/api/${TreeApiPathFormatter.format(
        treeType
      )}/${TreeItemFoodWorksApi.getTreeItemApiPath(
        treeItemType
      )}/${treeItemId}/foodworks`,
      foodWork
    );

    await Promise.all([
      RequestCacheHelper.instance.update<TreeItemFoodWork[]>(
        (currValue) => [...(currValue ?? []), newFoodWork],
        TreeItemFoodWorksApi.getFoodWorks,
        [treeType, treeItemType, treeItemId]
      ),
      RequestCacheHelper.instance.refetchByPartialParams(
        TreeInfoApi.getTreeInfo,
        [treeType]
      ),
    ]);
  }

  static async updateFoodWork(
    treeType: TreeType,
    treeItemType: TreeItemFoodWorkType,
    treeItemId: number,
    departmentFoodWork: Omit<TreeItemFoodWork, "isRequired">
  ) {
    const { data: modifiedFoodWork } = await axios.post<TreeItemFoodWork>(
      `/api/${TreeApiPathFormatter.format(
        treeType
      )}/${TreeItemFoodWorksApi.getTreeItemApiPath(
        treeItemType
      )}/${treeItemId}/foodworks`,
      departmentFoodWork
    );

    await Promise.all([
      RequestCacheHelper.instance.update<TreeItemFoodWork[]>(
        (currValue) =>
          currValue?.map((fw) =>
            fw.id === modifiedFoodWork.id ? modifiedFoodWork : fw
          ) ?? [],
        TreeItemFoodWorksApi.getFoodWorks,
        [treeType, treeItemType, treeItemId]
      ),
      RequestCacheHelper.instance.refetchByPartialParams(
        TreeInfoApi.getTreeInfo,
        [treeType]
      ),
    ]);
  }

  static async deleteFoodWork(
    treeType: TreeType,
    treeItemType: TreeItemFoodWorkType,
    treeItemId: number,
    foodWorkId: number
  ) {
    await axios.delete<TreeItemFoodWork>(
      `/api/foodworks/treeitems/${foodWorkId}`
    );

    await Promise.all([
      RequestCacheHelper.instance.update<TreeItemFoodWork[]>(
        (currValue) => currValue?.filter((fw) => fw.id !== foodWorkId) ?? [],
        TreeItemFoodWorksApi.getFoodWorks,
        [treeType, treeItemType, treeItemId]
      ),
      RequestCacheHelper.instance.refetchByPartialParams(
        TreeInfoApi.getTreeInfo,
        [treeType]
      ),
    ]);
  }

  static getTreeItemApiPath = (type: TreeItemFoodWorkType): string => {
    switch (type) {
      case "ingredient":
        return "ingredients";
      case "material":
        return "materials";
    }
  };

  static getTreeApiPath = (type: TreeType): string => {
    switch (type) {
      case TreeType.Dish:
        return "foods";
      case TreeType.Material:
        return "materials";
    }
  };
}
