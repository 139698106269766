import React, { useState } from "react";
import { RouteContent } from "base/components/RouteContent";
import { DishVEStepType } from "dish/types";
import { DishVEWelcomeStep } from "dish/components/visual-editor/DishVEWelcomeStep";
import { DishVEDishTypeStep } from "dish/components/visual-editor/DishVEDishTypeStep";
import { Form } from "form/components/Form";
import { noop } from "base/utils/noop";
import { DishVEMenuPriceStep } from "dish/components/visual-editor/DishVEMenuPriceStep";
import { DishVEEditorStep } from "dish/components/visual-editor/DishVEEditorStep";

export const DishVisualEditorScreen: React.FC = () => {
  const [step, setStep] = useState<DishVEStepType>(DishVEStepType.Welcome);

  return (
    <RouteContent>
      <Form onSubmit={noop}>
        {() => {
          switch (step) {
            case DishVEStepType.VisualEditor:
              return <DishVEEditorStep onStepChange={setStep} />;
            case DishVEStepType.DishType:
              return <DishVEDishTypeStep onStepChange={setStep} />;
            case DishVEStepType.MenuPrice:
              return <DishVEMenuPriceStep onStepChange={setStep} />;
            case DishVEStepType.Welcome:
            default:
              return <DishVEWelcomeStep onStepChange={setStep} />;
          }
        }}
      </Form>
    </RouteContent>
  );
};
