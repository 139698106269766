import React from "react";
import { Field, FormSpy } from "react-final-form";
import { FileUploadFieldInner } from "./FileUploadFieldInner";
import { FieldValidator } from "final-form";

export type FormFileUploadProps = {
  name: string;
  onUploaded?: () => void;
  type?: "image" | "video" | "excel";
  validate?: FieldValidator<any>;
};

const acceptMapping = {
  image: ".png,.jpg,.jpeg,.gif",
  file: ".mp4,.avi",
  excel: ".xlsx",
};

export const FormFileUpload: React.FC<FormFileUploadProps> = ({
  name,
  onUploaded,
  type = "image",
  validate,
}) => {
  return (
    <>
      <Field
        name={name}
        component={FileUploadFieldInner}
        accept={acceptMapping[type]}
        validate={validate}
      />
      {onUploaded && (
        <FormSpy
          subscription={{ values: true }}
          onChange={({ values }) => {
            if (values[name] !== undefined) {
              onUploaded?.();
            }
          }}
        />
      )}
    </>
  );
};
