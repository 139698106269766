import React, { useCallback } from "react";
import { Modal, Button, Col, Row } from "react-bootstrap";
import { NumpadFormValues } from "./types";
import "./styles.scss";
import { Form } from "form/components/Form";
import { Field } from "react-final-form";

export type NumpadModalProps = {
  show: boolean;
  title?: string;
  initialValue?: number;
  onDone: (value: number) => void;
  onCancel: () => void;
};

export const NumpadModal: React.FC<NumpadModalProps> = ({
  show,
  title = "הזנת משקל",
  initialValue = 0,
  onDone,
  onCancel,
}) => {
  const onSave = useCallback(
    ({ value }: NumpadFormValues) => {
      if (value.indexOf(".") === value.length - 1) {
        // If value ends with Dot, remove the dot.
        onDone(parseInt(value.substr(0, value.length - 1)));
      } else {
        onDone(parseFloat(value));
      }
    },
    [onDone]
  );

  return (
    <Modal
      show={show}
      onHide={onCancel}
      dialogClassName="numpad-modal"
      centered
    >
      <div className="numpad-modal-content">
        <div className="title">{title}</div>
        <Form
          initialValues={{ value: String(initialValue) }}
          onSubmit={onSave}
          render={({ handleSubmit, form }) => (
            <>
              <Field
                name="value"
                render={({ input: { value } }) => (
                  <div className="number" dir="ltr">
                    {value}
                  </div>
                )}
              />
              <Row noGutters dir="ltr" className="justify-content-center">
                {([1, 2, 3, 4, 5, 6, 7, 8, 9, ".", 0, "del"] as const).map(
                  (slot) => {
                    if (slot === "del") {
                      return (
                        <Col
                          key={slot}
                          xs={4}
                          className="p-2 d-flex justify-content-center"
                        >
                          <div
                            className="num-slot"
                            role="button"
                            onClick={() => {
                              const {
                                value: currValue,
                              } = form.getState().values;

                              if (currValue === undefined) {
                                // no value. do nothing.
                                return;
                              } else {
                                if (currValue.length === 1) {
                                  form.change("value", "0");
                                } else {
                                  form.change(
                                    "value",
                                    currValue.substr(0, currValue.length - 1)
                                  );
                                }
                              }
                            }}
                          >
                            Del
                          </div>
                        </Col>
                      );
                    } else {
                      return (
                        <Col
                          key={slot}
                          xs={4}
                          className="p-2 d-flex justify-content-center"
                        >
                          <div
                            className="num-slot"
                            role="button"
                            onClick={() => {
                              const {
                                value: currValue,
                              } = form.getState().values;

                              if (
                                slot === "." &&
                                (currValue === undefined ||
                                  currValue.indexOf(".") !== -1)
                              ) {
                                return;
                              }

                              if (
                                (currValue === undefined ||
                                  currValue === "0") &&
                                slot !== "."
                              ) {
                                form.change("value", String(slot));
                              } else {
                                form.change("value", `${currValue}${slot}`);
                              }
                            }}
                          >
                            {slot}
                          </div>
                        </Col>
                      );
                    }
                  }
                )}
              </Row>
              <div className="text-center p-3">
                <Button className="btn-xl" onClick={handleSubmit}>
                  אישור
                </Button>
              </div>
            </>
          )}
        />
      </div>
    </Modal>
  );
};
