export interface POSUploadFormValues {
  file: File;
  date: string;
  zReference: string;
}

export interface POSFixErrorsFormValues {
  errors: {
    foodId?: number;
    isMapped?: boolean;
  }[];
}

export interface POSUploadResult {
  zReference: string;
  date: string;
  errors: POSUploadError[];
}

export interface POSUploadError {
  errorType: POSErrorType;
  itemName: string;
  foodId?: number;
  foodName?: string;
}

export enum POSErrorType {
  FoodNotFound = 0,
  FoodAddonNotFound,
  FoodAddonCloseToFood,
  FoodCorrespondedAddonRowNotFound,
}

export interface POSHistoryListItem {
  zReference: string;
  date: string;
  fileName: string;
}

export interface POSHistoryTreeItemMaterial {
  Name: string;
  Amount: number;
  PrepareUnitType: string;
}

export interface POSHistoryTreeItemIngredient {
  Name: string;
  Amount: number;
  PrepareUnitType: string;
}

export interface POSHistoryTreeItemSet {
  Ingredients: POSHistoryTreeItemIngredient[];
  Materials: POSHistoryTreeItemMaterial[];
}

export interface POSHistoryAddonItem {
  Name: string;
  TreeItemsSet: POSHistoryTreeItemSet;
  Sold: number;
  SoldAddon: number;
  Quantity: number;
}

export interface POSHistoryFoodItem {
  Name: string;
  TreeItemsSet: POSHistoryTreeItemSet;
  TotalSoldAddon: number;
  TotalSold: number;
  Quantity: number;
  Addons: POSHistoryAddonItem[];
}

export interface POSHistoryItem {
  Foods: POSHistoryFoodItem[];
  ZReference: string;
  Date: string;
}
