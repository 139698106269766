import React, { useState, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBalanceScaleRight } from "@fortawesome/free-solid-svg-icons";
import Swiper, { ReactIdSwiperProps } from "react-id-swiper";
import {
  useCustomQueryWithParams,
  CustomQueryResponse,
} from "base/api/hooks/useCustomQuery";
import { BaseImage } from "base/components/BaseFoodImage";
import { Typography } from "base/components/Typography";
import { RoundButton } from "base/components/RoundButton";
import { SentOrdersApi } from "sent-order/SentOrdersApi";
import { SentOrderItemGet } from "sent-order/types";
import { SentOrderFoodItemModal } from "./SentOrderFoodItemModal";
import { SupplyMethodDescFormatter } from "order/SupplyMethodDescFormatter";
import { IngredSupplyMethodBarcodeModal } from "ingredient-supply-method/IngredSupplyMethodBarcodeModal";
import { useBooleanState } from "base/hooks/useBooleanState";
import { Button } from "react-bootstrap";
import { SimpleGlobalListApi } from "global-list/SimpleGlobalListApi";
import { SimpleGlobalListRoutePath } from "global-list/types";
import { BaseSelectListItem, UnitType } from "base/types";
import { NumberFormatter, UnitTypeFormatter } from "base/utils/formatters";
import { OrderHelper } from "order/OrderHelper";
import { useNavigateCallback } from "base/hooks/useNavigateCallback";
import { SentOrdersRouteBuilder } from "sent-order/SentOrdersRouteBuilder";

export type SentOrderDefaultEditorEditorProps = {
  id: number;
};

type FoodItemModalState =
  | {
      show: false;
      item: undefined;
    }
  | { show: true; item: SentOrderItemGet };

export const SentOrderDefaultEditorEditor: React.FC<SentOrderDefaultEditorEditorProps> = ({
  id,
}) => {
  const navigate = useNavigateCallback();
  const { data } = useCustomQueryWithParams(SentOrdersApi.getItem, () => [id]);
  const isOrderClosed = data?.status === 5;

  const {
    data: unitKinds,
  } = useCustomQueryWithParams(SimpleGlobalListApi.getList, () => [
    SimpleGlobalListRoutePath.UnitKinds,
  ]) as CustomQueryResponse<BaseSelectListItem[]>;

  const {
    data: packageKinds,
  } = useCustomQueryWithParams(SimpleGlobalListApi.getList, () => [
    SimpleGlobalListRoutePath.PackageKinds,
  ]) as CustomQueryResponse<BaseSelectListItem[]>;

  const {
    data: systemSettings,
  } = useCustomQueryWithParams(SimpleGlobalListApi.getList, () => [
    SimpleGlobalListRoutePath.SystemSettings,
  ]) as CustomQueryResponse<Array<{ key: string; value: number }>>;

  const allowedWeightDeviation = systemSettings?.find(
    (x) => x.key === "AllowedWeightDeviation"
  )?.value;

  const [foodItemModalState, setFoodItemModalState] = useState<
    FoodItemModalState
  >({
    show: false,
    item: undefined,
  });
  const [
    showBarcodePicker,
    onDisplayBarcodePicker,
    onHideBarcodePicker,
  ] = useBooleanState(false);

  const onMarkOrderReceived = useCallback(async () => {
    await SentOrdersApi.markOrderReceived(id);
    navigate(SentOrdersRouteBuilder.buildSentOrdersRoute());
  }, [id, navigate]);

  const onOpenFoodItemModal = useCallback((item: SentOrderItemGet) => {
    setFoodItemModalState({ show: true, item });
  }, []);

  const onHideFoodItemModal = useCallback(() => {
    setFoodItemModalState({ show: false, item: undefined });
  }, []);

  const onFoodItemModalSave = useCallback(
    async (modifiedItem: SentOrderItemGet) => {
      await SentOrdersApi.saveSentOrder({
        ...data!,
        items: data!.items.map((item) => {
          if (item.id !== modifiedItem.id) {
            return item;
          } else {
            return modifiedItem;
          }
        }),
      });
      onHideFoodItemModal();
    },
    [data, onHideFoodItemModal]
  );

  const params: ReactIdSwiperProps = {
    rtl: ".swiper-container-rtl",
    slidesPerView: "auto",
    spaceBetween: 30,
    slidesPerColumn: 1,
    grabCursor: true,
    centeredSlides: true,
    effect: "coverflow",
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    coverflowEffect: {
      rotate: 30,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: false,
    },
    containerClass: "swiper-container swiper-sent-order-ingredients",
  };

  if (!data) {
    return null;
  }

  const { items: ingredients } = data;

  return (
    <>
      <div>
        <Button onClick={onDisplayBarcodePicker} disabled={isOrderClosed}>
          סריקת ברקוד
        </Button>
      </div>
      <Swiper {...params}>
        {ingredients.map((item) => {
          const isItemFilled = OrderHelper.isFilled(
            item,
            allowedWeightDeviation
          );

          return (
            <div
              className={`swiper-item ${isItemFilled && "done"}`}
              key={item.id}
              onClick={() => onOpenFoodItemModal(item)}
            >
              <BaseImage
                fileName={item.imageName}
                className="swiper-item-image"
              />
              <Typography
                variant="description"
                fontSize={20}
                bold
                className="text-truncate w-100"
              >
                {item.name}
              </Typography>
              <Typography variant="description" fontSize={20} rtl={false}>
                {OrderHelper.isWeightable(item) &&
                  NumberFormatter.formatAmount(item.receivedWeight)}
                {!OrderHelper.isWeightable(item) &&
                  NumberFormatter.formatAmount(item.receivedAmount)}{" "}
                /{" "}
                {OrderHelper.isWeightable(item) &&
                  NumberFormatter.default.formatAmount(
                    OrderHelper.calcTotalAmount(
                      item.requestedAmount,
                      item.requestedSupplyMethod,
                      item.prepareUnitType
                    )
                  )}
                {!OrderHelper.isWeightable(item) &&
                  NumberFormatter.formatAmount(item.requestedAmount)}
              </Typography>
              <Typography variant="description" fontSize={18}>
                {!OrderHelper.isWeightable(item) &&
                  SupplyMethodDescFormatter.format(
                    item.requestedSupplyMethod,
                    item.prepareUnitType,
                    unitKinds,
                    packageKinds
                  )}
                {OrderHelper.isWeightable(item) &&
                  UnitTypeFormatter.format(UnitType.KG)}
              </Typography>
              {isItemFilled && (
                <div className="done-mark">
                  <i className="fas fa-check px-1" aria-hidden="true" />
                </div>
              )}
              <div className="floating-actions">
                {OrderHelper.isWeightable(item) && (
                  <RoundButton
                    size={32}
                    onClick={() => {}}
                    className="checked-floating-action"
                  >
                    <FontAwesomeIcon icon={faBalanceScaleRight} size="sm" />
                  </RoundButton>
                )}
              </div>
            </div>
          );
        })}
      </Swiper>
      <div className="text-center my-5">
        <Button size="lg" onClick={onMarkOrderReceived}>
          סיימתי
        </Button>
      </div>
      <SentOrderFoodItemModal
        show={foodItemModalState.show}
        item={foodItemModalState.item}
        onCancel={onHideFoodItemModal}
        onDone={onFoodItemModalSave}
        disabled={isOrderClosed}
      />
      <IngredSupplyMethodBarcodeModal
        show={showBarcodePicker}
        onMatch={async (ingSupplyMethod) => {
          const matchIng = data.items.find(
            (ing) => ing.productId === ingSupplyMethod.ingredientId
          );

          if (matchIng) {
            await onFoodItemModalSave({
              ...matchIng,
              receivedSupplyMethod: ingSupplyMethod,
              receivedAmount: matchIng.receivedAmount + 1,
            });
          }
        }}
        onHide={onHideBarcodePicker}
      />
    </>
  );
};
