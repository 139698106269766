import { SimpleGlobalListRoutePath } from "./types";

export class SimpleGlobalListRouteBuilder {
  static templates = {
    SimpleGlobalList: "/lists/:routePath",
  };

  static buildRoleTypesRoute() {
    return `/lists/role-types`;
  }

  static buildRoleNamesRoute() {
    return `/lists/role-names`;
  }

  static buildShiftDepartmentsRoute() {
    return `/lists/shift-departments`;
  }

  static buildSpecialDatesRoute() {
    return `/lists/special-dates`;
  }

  static buildSystemSettingsRoute() {
    return `/lists/system-settings`;
  }

  static buildPOSMappingRoute() {
    return `/lists/pos-mapping`;
  }

  static buildPackageKindsRoute() {
    return `/lists/package-kinds`;
  }

  static buildUnitKindsRoute() {
    return `/lists/unit-kinds`;
  }

  static buildManufacturersRoute() {
    return `/lists/manufacturers`;
  }

  static buildContactTypesRoute() {
    return `/lists/contactTypes`;
  }

  static buildContactsRoute() {
    return `/lists/contacts`;
  }

  static buildStorageUnitsRoute() {
    return `/lists/storageUnits`;
  }

  static buildSimpleGlobalListRoute(routePath: SimpleGlobalListRoutePath) {
    return SimpleGlobalListRouteBuilder.templates.SimpleGlobalList.replace(
      ":routePath",
      routePath
    );
  }

  static buildListsIndexRoute() {
    return `/lists`;
  }
}
