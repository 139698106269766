import React from "react";
import { RouteContent } from "base/components/RouteContent";
import { Typography } from "base/components/Typography";
import { ProductEditor } from "./components/ProductEditor";

export const AddProductScreen: React.FC = () => {
  return (
    <RouteContent>
      <Typography variant="route-title">הוספה</Typography>
      <ProductEditor mode="add" />
    </RouteContent>
  );
};
