import axios from "axios";
import { RequestCacheHelper } from "base/api/RequestCacheHelper";
import { BaseFood } from "base/types";
import { SingleItemFoodWork } from "./types/SingleItemFoodWork";
import { SingleItemFoodWorkType } from "./types/SingleItemFoodWorkType";

export class SingleItemFoodWorksApi {
  static async getFoodWorks(type: SingleItemFoodWorkType, id: number) {
    return axios.get<SingleItemFoodWork[]>(
      `/api/${SingleItemFoodWorksApi.getSingleItemApiPath(
        type
      )}/${id}/foodworks`
    );
  }

  static async getFoodItem(type: SingleItemFoodWorkType, id: number) {
    return axios.get<BaseFood>(
      `/api/${SingleItemFoodWorksApi.getSingleItemApiPath(type)}/${id}`
    );
  }

  static async addFoodWork(
    type: SingleItemFoodWorkType,
    id: number,
    departmentFoodWork: Omit<SingleItemFoodWork, "id">
  ) {
    const { data: newFoodWork } = await axios.post<SingleItemFoodWork>(
      `/api/${SingleItemFoodWorksApi.getSingleItemApiPath(
        type
      )}/${id}/foodworks`,
      departmentFoodWork
    );

    await RequestCacheHelper.instance.update<SingleItemFoodWork[]>(
      (currValue) => [...(currValue ?? []), newFoodWork],
      SingleItemFoodWorksApi.getFoodWorks,
      [type, id]
    );
  }

  static async updateFoodWork(
    type: SingleItemFoodWorkType,
    id: number,
    departmentFoodWork: SingleItemFoodWork
  ) {
    const { data: modifiedFoodWork } = await axios.post<SingleItemFoodWork>(
      `/api/${SingleItemFoodWorksApi.getSingleItemApiPath(
        type
      )}/${id}/foodworks`,
      departmentFoodWork
    );

    await RequestCacheHelper.instance.update<SingleItemFoodWork[]>(
      (currValue) =>
        currValue?.map((fw) =>
          fw.id === modifiedFoodWork.id ? modifiedFoodWork : fw
        ) ?? [],
      SingleItemFoodWorksApi.getFoodWorks,
      [type, id]
    );
  }

  static async deleteFoodWork(
    type: SingleItemFoodWorkType,
    id: number,
    foodWorkId: number
  ) {
    await axios.delete<SingleItemFoodWork>(
      `/api/${SingleItemFoodWorksApi.getSingleItemApiPath(
        type
      )}/${id}/foodworks/${foodWorkId}`
    );

    await RequestCacheHelper.instance.update<SingleItemFoodWork[]>(
      (currValue) => currValue?.filter((fw) => fw.id !== foodWorkId) ?? [],
      SingleItemFoodWorksApi.getFoodWorks,
      [type, id]
    );
  }

  static getSingleItemApiPath = (type: SingleItemFoodWorkType): string => {
    switch (type) {
      case "ingredient":
        return "ingredients";
      case "material":
        return "materials";
    }
  };
}
