import axios from "axios";
import { FoodDepartmentListItem } from "base/types";
import { BodyFormDataBuilder } from "base/api/BodyFormDataBuilder";
import { RequestCacheHelper } from "base/api/RequestCacheHelper";
import { EmployeeListItem, Employee, EmployeeOngoingShiftItem } from "./types";

export class EmployeesApi {
  static getList() {
    return axios.get<EmployeeListItem[]>("/api/employees/items");
  }

  static getItem(id: string) {
    return axios.get<Employee>(`/api/employees/${id}`);
  }

  static getDepartments() {
    return axios.get<FoodDepartmentListItem[]>("/api/employees/departments");
  }

  static getShiftsPresence() {
    return axios.get<EmployeeOngoingShiftItem[]>("/api/ShiftsPrensence/items");
  }

  static async saveItem(
    data: WithPartialProperties<Employee, "id">,
    attachment?: File
  ) {
    const { data: savedItem } = await axios.post<Employee>(
      "/api/employees",
      BodyFormDataBuilder.build({ ...data, image: attachment }),
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );

    // updating the cache, so the ui will get notified of the changes.
    await RequestCacheHelper.instance.update(savedItem, this.getItem, [
      savedItem.id,
    ]);
    RequestCacheHelper.instance.refetch(this.getList);
  }

  static async reportEnterShift(employeeId: string) {
    await axios.post("/api/ShiftsPrensence/enterShift", { employeeId });
    RequestCacheHelper.instance.refetch(this.getShiftsPresence);
  }

  static async reportLeaveShift(employeeId: string) {
    await axios.post("/api/ShiftsPrensence/exitShift", { employeeId });

    await RequestCacheHelper.instance.update<EmployeeOngoingShiftItem[]>(
      (shiftsPresence) => {
        if (!shiftsPresence) return undefined;

        return shiftsPresence.map((shift) => {
          if (shift.employeeId !== employeeId) return shift;
          return { ...shift, entranceTime: undefined };
        });
      },
      this.getShiftsPresence
    );
  }
}
