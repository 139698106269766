import Axios from "axios";
import { BaseSelectListItem } from "base/types";
import { RequestCacheHelper } from "base/api/RequestCacheHelper";
import { WorkScheduleApi } from "work-schedule/WorkScheduleApi";

export class SelectListItemEditorApi {
  static async saveWorkScheduleRoleType(
    data: WithPartialProperties<BaseSelectListItem, "id">
  ) {
    const { data: savedItem } = await Axios.post<BaseSelectListItem>(
      "/api/workSchedules/roleTypes",
      data
    );
    RequestCacheHelper.instance.refetch(WorkScheduleApi.getRoleTypes);
    return savedItem;
  }

  static async saveWorkScheduleRoleName(
    data: WithPartialProperties<BaseSelectListItem, "id">
  ) {
    const { data: savedItem } = await Axios.post<BaseSelectListItem>(
      "/api/workSchedules/roleNames",
      data
    );
    RequestCacheHelper.instance.refetch(WorkScheduleApi.getRoleNames);
    return savedItem;
  }
}
