import React, { useState, useCallback } from "react";
import { Nav, Row, Col } from "react-bootstrap";
import { Form } from "form/components/Form";
import { FormSelect } from "form/components/FormSelect";
import {
  useCustomQuery,
  useCustomQueryWithParams,
} from "base/api/hooks/useCustomQuery";
import { IngredientsApi } from "ingredient/IngredientsApi";
import { useSelectOptionsAdapter } from "base/components/Select/hooks/useSelectOptionsAdapter";
import { MaterialsApi } from "material/MaterialsApi";
import { FormNumberInput } from "form/components/FormNumberInput";
import { RequiredFieldValidator } from "form/validators/RequiredFieldValidator";
import {
  AddDepartmentAddonFormValues,
  DepartmentAddon,
  DepartmentAddons,
} from "department/types";
import { DepartmentsApi } from "department/DepartmentsApi";
import { FieldArray } from "react-final-form-arrays";
import { Typography } from "base/components/Typography";
import { Field, FormSpy } from "react-final-form";
import { FormApi, getIn } from "final-form";
import { isEqual, debounce } from "lodash";
import { Spacer } from "base/components/Spacer";
import { DepartmentAddonFoodWorksModal } from "food-works/department-addon-food-works/components/DepartmentAddonFoodWorksModal";
import { DepartmentAddonFoodWork } from "food-works/department-addon-food-works/types/DepartmentAddonFoodWork";
import { useBooleanState } from "base/hooks/useBooleanState";
import { NumberFormatter } from "base/utils/formatters";
import { AmountInput } from "base/components/input-controls/AmountInput";
import { FieldsValidator } from "form/validators/FieldsValidator";
import { FormMetaError } from "form/components/FormMetaError";
import { FormTextInput } from "form/components/FormTextInput";
import { UnitType } from "base/types";

enum NavItem {
  Ingredients = "ingredients",
  Materials = "materials",
}

type DepartmentAddonsModalState =
  | {
      addonId: number;
      addonName: string;
      foodWorks: DepartmentAddonFoodWork[];
      departmentId: number;
      listName: keyof DepartmentAddons;
      itemId?: number;
      itemDepartmentId?: number;
    }
  | undefined;

export type DepartmentAddonsTabProps = {
  departmentId: number;
};

export const DepartmentAddonsTab: React.FC<DepartmentAddonsTabProps> = ({
  departmentId,
}) => {
  const [selectedNavItem, setSelectedNavItem] = useState(NavItem.Ingredients);
  const { data: ingredientsList } = useCustomQuery(
    IngredientsApi.getSelectList,
    {
      skip: selectedNavItem !== NavItem.Ingredients,
    }
  );
  const { data: materialsList } = useCustomQuery(MaterialsApi.getSelectList, {
    skip: selectedNavItem !== NavItem.Materials,
  });
  const {
    data: departmentAddons,
  } = useCustomQueryWithParams(DepartmentsApi.getAddons, () => [departmentId]);

  const addItemOptions = useSelectOptionsAdapter(
    selectedNavItem === NavItem.Ingredients ? ingredientsList : materialsList
  );

  const [
    displayDepartmentAddonFoodWorksModal,
    onShowDepartmentAddonFoodWorksModal,
    onHideDepartmentAddonFoodWorksModal,
  ] = useBooleanState(false);

  const [
    departmentAddonFoodWorksModalState,
    setDepartmentAddonFoodWorksModalState,
  ] = useState<DepartmentAddonsModalState>();

  const onAddItem = useCallback(
    async (
      itemToAdd: AddDepartmentAddonFormValues,
      formApi: FormApi<any, any>
    ) => {
      await DepartmentsApi.addAddon(departmentId, itemToAdd, selectedNavItem);
      Object.keys(formApi.getState().touched || {}).forEach(
        formApi.resetFieldState as any
      );
      setTimeout(formApi.reset);
    },
    [departmentId, selectedNavItem]
  );

  const onAddonDeleted = useCallback(
    async (addonId: number) => {
      await DepartmentsApi.deleteAddon(departmentId, addonId, selectedNavItem);
    },
    [departmentId, selectedNavItem]
  );

  const onRowChanged = useCallback(
    debounce(async (addon: DepartmentAddon) => {
      await DepartmentsApi.updateAddon(departmentId, addon, selectedNavItem);
    }, 500),
    [departmentId, selectedNavItem]
  );

  const listName =
    selectedNavItem === NavItem.Materials ? "materials" : "ingredients";

  return (
    <>
      <Nav variant="pills" className="d-flex justify-content-center">
        <Nav.Link
          active={selectedNavItem === NavItem.Ingredients}
          onClick={() => setSelectedNavItem(NavItem.Ingredients)}
        >
          מרכיבים
        </Nav.Link>
        <Nav.Link
          active={selectedNavItem === NavItem.Materials}
          onClick={() => setSelectedNavItem(NavItem.Materials)}
        >
          חומרי גלם
        </Nav.Link>
      </Nav>
      <Typography variant="description" fontSize={20}>
        {selectedNavItem === NavItem.Materials
          ? "הוספת חומר גלם"
          : "הוספת מרכיב"}
      </Typography>
      <Spacer />
      <Form
        key={selectedNavItem}
        onSubmit={onAddItem}
        subscription={{ dirty: true, submitting: true }}
        keepDirtyOnReinitialize={false}
      >
        {({ handleSubmit, dirty, submitting }) => (
          <Row className="m-0">
            <Col md={2} className="p-0">
              <FormSelect
                name="itemId"
                options={addItemOptions}
                variant="react-select"
                emptyOptionLabel={
                  selectedNavItem === NavItem.Materials
                    ? "בחר חומר גלם"
                    : "בחר מרכיב"
                }
                validate={RequiredFieldValidator.validate}
              />
            </Col>
            <Col md={2} className="px-1">
              <FormTextInput name="name" placeholder="שם" required />
            </Col>
            <Col md={2} className="px-1">
              <FormNumberInput name="amount" placeholder="כמות" required />
            </Col>
            <Col md={4} className="px-1">
              <FormNumberInput
                name="menuPrice"
                placeholder="מחיר תפריט(רשות)"
              />
            </Col>
            <Col md={2} className="px-1">
              <button
                className="btn btn-primary"
                onClick={handleSubmit}
                disabled={!dirty && !submitting}
              >
                הוספה
              </button>
            </Col>
          </Row>
        )}
      </Form>
      <Spacer />
      <Form onSubmit={() => {}} initialValues={departmentAddons}>
        {({ form }) => (
          <>
            <FieldArray
              key={selectedNavItem}
              name={
                selectedNavItem === NavItem.Materials
                  ? "materials"
                  : "ingredients"
              }
              subscription={{ length: true }}
            >
              {({ fields }) => (
                <>
                  {selectedNavItem === NavItem.Materials && (
                    <Typography variant="description" fontSize={20}>
                      חומרי גלם קיימים({fields.length})
                    </Typography>
                  )}
                  {selectedNavItem === NavItem.Ingredients && (
                    <Typography variant="description" fontSize={20}>
                      מרכיבים קיימים({fields.length})
                    </Typography>
                  )}
                  <Row>
                    <Col md={2} />
                    <Col md={2}>שם</Col>
                    <Col md={2}>כמות</Col>
                    <Col md={2}>כמות לאחר ע.</Col>
                    <Col md={1}>עלות</Col>
                    <Col md={1}>מ׳ תפריט</Col>
                  </Row>
                  {fields.map((fieldPrefix, index) => {
                    const addonId: number = getIn(
                      form.getState().values,
                      `${fieldPrefix}.id`
                    );
                    return (
                      <Row className="mx-0 mb-2" key={addonId}>
                        <Col md={2} className="p-0 d-flex align-items-center">
                          <Field
                            name={`${fieldPrefix}.itemName`}
                            render={({ input }) => input.value}
                          />
                        </Col>
                        <Col md={2} className="px-1">
                          <FormTextInput name={`${fieldPrefix}.name`} />
                        </Col>
                        <Col md={2} className="px-1">
                          <Field
                            name={`${fieldPrefix}.amount`}
                            validate={FieldsValidator.required}
                            render={({ input, meta }) => (
                              <>
                                <AmountInput
                                  fixedDidigts={2}
                                  min={0}
                                  value={input.value}
                                  onChange={input.onChange}
                                />
                                <FormMetaError meta={meta} />
                              </>
                            )}
                          />
                        </Col>
                        <Col md={2} className="px-1">
                          <FormSpy<DepartmentAddons>
                            subscription={{ values: true }}
                            render={({ values }) => {
                              const listItem = values[listName][index];
                              const { amount = 0, foodWorks } = listItem;
                              let amountAfterFoodWorks = amount;
                              foodWorks.forEach((item) => {
                                amountAfterFoodWorks =
                                  amountAfterFoodWorks -
                                  (amountAfterFoodWorks * item.depreciation) /
                                    100;
                              });

                              return (
                                <AmountInput
                                  fixedDidigts={2}
                                  min={0}
                                  value={amountAfterFoodWorks}
                                  onChange={(nextAmountAfterFoodWorks = 0) => {
                                    if (amountAfterFoodWorks === 0) return;

                                    const percentageDiff =
                                      nextAmountAfterFoodWorks /
                                      amountAfterFoodWorks;

                                    const nextAmount = amount * percentageDiff;

                                    fields.update(index, {
                                      ...listItem,
                                      amount: nextAmount,
                                    });
                                  }}
                                />
                              );
                            }}
                          />
                        </Col>
                        <Col md={1} className="px-1 d-flex align-items-center">
                          <FormSpy<DepartmentAddons>
                            subscription={{ values: true }}
                            render={({ values }) => {
                              const {
                                amount,
                                defaultPrice,
                                prepareUnitType,
                              } = values[listName][index];
                              return (
                                <span>
                                  {NumberFormatter.format(
                                    (amount * defaultPrice) /
                                      (prepareUnitType !== UnitType.Yeh
                                        ? 1000
                                        : 1)
                                  )}
                                </span>
                              );
                            }}
                          />
                        </Col>
                        <Col md={1} className="px-1">
                          <FormNumberInput
                            name={`${fieldPrefix}.menuPrice`}
                            placeholder="מחיר תפריט"
                          />
                        </Col>
                        <Col md="auto" className="px-1">
                          <button
                            className="btn btn-primary ml-1"
                            onClick={() => {
                              const addons = form.getState().values[listName];
                              const addon = addons.find(
                                (a) => a.id === addonId
                              )!;
                              setDepartmentAddonFoodWorksModalState({
                                addonId,
                                addonName: addon.name,
                                departmentId,
                                foodWorks: addon.foodWorks,
                                itemId: addon.ingredientId || addon.materialId,
                                itemDepartmentId: addon.departmentId,
                                listName,
                              });
                              onShowDepartmentAddonFoodWorksModal();
                            }}
                          >
                            עיבודים
                          </button>
                          <button
                            className="btn btn-danger"
                            onClick={() => {
                              fields.remove(index);
                              onAddonDeleted(addonId);
                            }}
                          >
                            מחיקה
                          </button>
                        </Col>
                        <FormSpy
                          subscription={{
                            validating: true,
                            initialValues: true,
                          }}
                          onChange={({ validating }) => {
                            const formState = form.getState();
                            if (validating || !formState.valid) {
                              onRowChanged.cancel();
                              return;
                            }

                            const nextValuesList =
                              selectedNavItem === NavItem.Ingredients
                                ? formState.values.ingredients
                                : formState.values.materials;
                            const initialValuesList =
                              selectedNavItem === NavItem.Ingredients
                                ? formState.initialValues.ingredients
                                : formState.initialValues.materials;

                            const nextRowValue = nextValuesList?.find(
                              (x) => x.id === addonId
                            );
                            const prevRowValue = initialValuesList?.find(
                              (x) => x.id === addonId
                            );

                            if (
                              nextRowValue &&
                              prevRowValue &&
                              !isEqual(nextRowValue, prevRowValue)
                            ) {
                              onRowChanged(nextRowValue!);
                            }
                          }}
                        />
                      </Row>
                    );
                  })}
                </>
              )}
            </FieldArray>
          </>
        )}
      </Form>
      <DepartmentAddonFoodWorksModal
        show={displayDepartmentAddonFoodWorksModal}
        onCancel={onHideDepartmentAddonFoodWorksModal}
        addonId={departmentAddonFoodWorksModalState?.addonId}
        addonName={departmentAddonFoodWorksModalState?.addonName}
        departmentId={departmentAddonFoodWorksModalState?.departmentId}
        foodWorks={departmentAddonFoodWorksModalState?.foodWorks}
        itemId={departmentAddonFoodWorksModalState?.itemId}
        listName={departmentAddonFoodWorksModalState?.listName}
        itemDepartmentId={departmentAddonFoodWorksModalState?.itemDepartmentId}
      />
    </>
  );
};
