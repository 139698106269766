import React, { useState } from "react";
import { Bar } from "react-chartjs-2";

import { BalancePeriodType } from "./types";
import { format as dateFormat } from "date-fns";
import { RouteContent } from "base/components/RouteContent";
import { Row, Col, Container } from "react-bootstrap";
import { Select } from "base/components/Select";
import { DatePicker } from "base/components/DatePicker";
import { BalancePeriodTypeOptions } from "./constants";
import { Typography } from "base/components/Typography";
import { useCustomQueryWithParams } from "base/api/hooks/useCustomQuery";
import { ManagersApi } from "./ManagersApi";
import "./styles.scss";
import { NumberFormatter } from "base/utils/formatters";

export const DashboardScreen: React.FC = () => {
  const [balancePeriodType, setBalancePeriodType] = useState(
    BalancePeriodType.Daily
  );
  const [date, setDate] = useState(dateFormat(Date.now(), "yyyy-MM-dd"));

  const {
    data: dashboardData,
  } = useCustomQueryWithParams(ManagersApi.getDashboardData, () => [
    balancePeriodType,
    date,
  ]);

  const selectedRangeItem = BalancePeriodTypeOptions.find(
    (x) => x.value === balancePeriodType
  )!;
  const totalOutcome =
    (dashboardData?.balance.outcome.total ?? 0) +
    (dashboardData?.balance.outcome.total ?? 0) * 0.4 +
    (dashboardData?.balance.outcome.total ?? 0) * 1.3;
  return (
    <RouteContent>
      <div className="manager-dashboard">
        <Container>
          <Row className="justify-content-center mt-2">
            <Col xs={6} md={4} className="px-1">
              <Select
                value={balancePeriodType}
                onChange={setBalancePeriodType}
                options={BalancePeriodTypeOptions}
                sortAsc={false}
                displayEmptyOption={false}
              />
            </Col>
            <Col xs={6} md={4} className="px-1">
              <DatePicker value={date} onChange={setDate} />
            </Col>
          </Row>
          <Typography variant="title" className="text-center mt-5">
            מאזן {selectedRangeItem.label}
          </Typography>
          <Bar
            options={{
              legend: {
                display: false,
              },
              scales: {
                xAxes: [
                  {
                    stacked: true,
                    ticks: {
                      display: false,
                    },
                  },
                ],
                yAxes: [
                  {
                    stacked: true,
                    beginAtZero: true,
                    ticks: {
                      display: Boolean(
                        dashboardData &&
                          (dashboardData.balance.income.total > 0 ||
                            dashboardData.balance.outcome.total > 0)
                      ),
                    },
                  },
                ],
              },
            }}
            data={{
              labels: ["הוצאות", "הכנסות"],
              datasets: dashboardData
                ? [
                    {
                      data: [
                        NumberFormatter.format(
                          dashboardData.balance.outcome.total
                        ),
                      ],
                      backgroundColor: "yellow",
                      label: "פודקוסט",
                    },
                    {
                      data: [
                        NumberFormatter.format(
                          dashboardData.balance.outcome.total * 1.3
                        ),
                      ],
                      backgroundColor: "lightblue",
                      label: "עובדים",
                    },
                    {
                      data: [
                        NumberFormatter.format(
                          dashboardData.balance.outcome.total * 0.4
                        ),
                      ],
                      backgroundColor: "orange",
                      label: "תפעול",
                    },
                    {
                      data: [
                        0,
                        NumberFormatter.format(
                          dashboardData.balance.income.total
                        ),
                      ],
                      backgroundColor: "lightgreen",
                      label: "מכירות",
                    },
                  ]
                : [],
            }}
          />
          <div className="d-flex justify-content-around my-2">
            <div className="data-box">
              <Typography variant="description" fontSize={18} className="mb-2">
                הכנסות
              </Typography>
              {NumberFormatter.format(dashboardData?.balance.income.total)} ₪
            </div>
            <div className="data-box">
              <Typography variant="description" fontSize={18} className="mb-2">
                רווח
              </Typography>
              {NumberFormatter.format(
                (dashboardData?.balance.income.total ?? 1) - totalOutcome
              )}{" "}
              ₪
              <br />
              {NumberFormatter.format(
                100 -
                  (totalOutcome / (dashboardData?.balance.income.total ?? 1)) *
                    100
              )}{" "}
              %
            </div>

            <div className="data-box">
              <Typography variant="description" fontSize={18} className="mb-2">
                הוצאות
              </Typography>
              {NumberFormatter.format(totalOutcome)} ₪
            </div>
          </div>
        </Container>
      </div>
    </RouteContent>
  );
};
