import React, { useCallback } from "react";
import { Modal } from "react-bootstrap";
import { SelectListItemEditorBaseProps } from "select-list-item-editor/types";

export type SelectListItemEditorModalProps = {
  show: boolean;
  onHide: () => void;
  onItemSaved: SelectListItemEditorBaseProps["onItemSaved"];
  initialValues?: SelectListItemEditorBaseProps["initialValues"];
  component?: React.ComponentType<SelectListItemEditorBaseProps>;
  render?: (props: SelectListItemEditorBaseProps) => JSX.Element;
};

export const SelectListItemEditorModal: React.FC<SelectListItemEditorModalProps> = ({
  show,
  onHide,
  initialValues,
  onItemSaved,
  component: Component,
  render,
}) => {
  const onItemAddedWithHide = useCallback(
    (savedItem: any) => {
      onItemSaved?.(savedItem);
      onHide();
    },
    [onHide, onItemSaved]
  );

  return (
    <Modal show={show} onHide={onHide} centered>
      {Component && (
        <Component
          onItemSaved={onItemAddedWithHide}
          initialValues={initialValues}
          show={show}
        />
      )}
      {!Component &&
        render &&
        render({ onItemSaved: onItemAddedWithHide, initialValues, show })}
    </Modal>
  );
};
