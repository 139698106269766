import React, { useState, useEffect, useMemo } from "react";
import classNames from "classnames";
import { UnitTypeFormatter } from "base/utils/formatters";
import { useCustomQuery } from "base/api/hooks/useCustomQuery";
import { SentOrdersApi } from "sent-order/SentOrdersApi";

export type SentOrderRemoteWeightModalProps = {
  show: boolean;
  onClose: () => void;
  prepareUnitType: number;
  onWeightReceived: (weight: number) => void;
};

export const SentOrderRemoteWeightModal: React.FC<SentOrderRemoteWeightModalProps> = ({
  show,
  prepareUnitType,
  onClose,
  onWeightReceived,
}) => {
  const pollInterval = 500;
  const [receivedWeight, setReceivedWeight] = useState(0);
  const [isWeighting, setIsWeighting] = useState(false);

  // Fixing cached weight issue.
  const getWeightFn = useMemo(
    () => () => SentOrdersApi.getWeight(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isWeighting]
  );

  const { data, isValidating } = useCustomQuery(getWeightFn, {
    pollingInterval: isWeighting && !receivedWeight ? pollInterval : undefined,
    skip: !isWeighting,
  });

  // cleaning the state once modal closes/opens.
  useEffect(() => {
    setReceivedWeight(0);
  }, [isWeighting]);

  // notify that weight has been received.
  useEffect(() => {
    if (isWeighting && receivedWeight > 0 && !isValidating) {
      onWeightReceived(receivedWeight);
    }
  }, [isValidating, onWeightReceived, receivedWeight, isWeighting]);

  // handle receiving data.
  useEffect(() => {
    if (data && !isValidating && data > 0) {
      setReceivedWeight(data / 1000);
    }
  }, [data, isValidating]);

  // closing with fade effect.
  useEffect(() => {
    if (receivedWeight > 0 && isWeighting) {
      const delayId = setTimeout(onClose, 1500);
      return () => clearTimeout(delayId);
    }
  }, [onClose, isWeighting, receivedWeight]);

  useEffect(() => {
    if (show) {
      const timeoutId = setTimeout(() => {
        setIsWeighting(true);
      }, 2000);
      return () => clearTimeout(timeoutId);
    } else {
      setIsWeighting(false);
    }
  }, [show]);

  return (
    <div className={classNames("sent-order-remote-weight-modal", { show })}>
      <div
        className="backdrop"
        onClick={receivedWeight === 0 ? onClose : undefined}
      />
      <div className="content">
        <div className="weight-title">המתן לשקילה</div>
        {receivedWeight > 0 && (
          <div className="received-data">
            סה"כ נקלט:
            <span> {receivedWeight} </span>
            <span>{UnitTypeFormatter.format(prepareUnitType)}</span>
          </div>
        )}
        {receivedWeight <= 0 && (
          <div>
            <button
              className="close-weight btn btn-warning btn-lg"
              onClick={onClose}
            >
              ביטול
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
