import { ShiftItem } from "work-schedule/types";

export class ShiftItemDuplicator {
  static duplicate(shiftItem: ShiftItem): ShiftItem {
    return {
      ...shiftItem,
      id: (undefined as any) as number,
    };
  }
}
