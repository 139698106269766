import React, { useMemo, useCallback } from "react";
import { FormControl } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  .react-datepicker-popper {
    z-index: 999;
  }
`;

export type DatePickerProps = {
  value: string | undefined;
  onChange: (value: string) => void;
  placeholder?: string;
  disabled?: boolean;
  onBlur?: () => void;
  onFocus?: () => void;
};

export const DatePicker: React.FC<DatePickerProps> = ({
  value,
  placeholder,
  onChange,
  onBlur,
  onFocus,
  disabled,
}) => {
  const resolvedValue = useMemo(() => {
    if (!value) {
      return undefined;
    } else {
      return new Date(value);
    }
  }, [value]);

  const onChangeAdapter = useCallback(
    (date: Date) => {
      onChange(format(date, "yyyy-MM-dd"));
    },
    [onChange]
  );

  return (
    <>
      <ReactDatePicker
        placeholderText={placeholder}
        wrapperClassName="w-100"
        customInput={<FormControl />}
        dateFormat={"dd/MM/yyyy"}
        selected={resolvedValue}
        onChange={onChangeAdapter}
        onBlur={onBlur}
        onFocus={onFocus}
        disabled={disabled}
      />
      <GlobalStyle />
    </>
  );
};
