import React, { useCallback, useMemo } from "react";
import { useCustomQuery } from "base/api/hooks/useCustomQuery";
import { OrdersApi } from "order/OrdersApi";
import "./style.scss";
import { useNavigateCallback } from "base/hooks/useNavigateCallback";
import { OrdersRouteBuilder } from "order/OrdersRouteBuilder";
import { FileViewer } from "base/components/FileViewer";
import { FileDir } from "base/types/Filedir";
import { useObserver } from "mobx-react";
import { useOrderWizardContext } from "order/context/hooks/useOrderWizardContext";
import { ImageBackgroundMix } from "base/components/ImageBackgroundMix";

export const SupplierList: React.FC = () => {
  const { store } = useOrderWizardContext();

  const { data } = useCustomQuery(OrdersApi.getSupplierList);
  const searchPhrase =
    useObserver(
      () =>
        store.routeSearchPhrases[OrdersRouteBuilder.buildSupplierListRoute()]
    ) ?? "";

  const navigate = useNavigateCallback();

  const onItemClick = useCallback(
    (id: number) => {
      navigate(OrdersRouteBuilder.buildSupplierRoute(id));
    },
    [navigate]
  );

  const filteredData = useMemo(() => {
    if (!data || !searchPhrase) return data;

    const formattedFilter = searchPhrase.toLowerCase().trim();
    return data.filter(
      (item) => item.name.toLowerCase().trim().indexOf(formattedFilter) !== -1
    );
  }, [data, searchPhrase]);

  return (
    <div className="OrderSupplierList">
      {filteredData?.map((listItem) => (
        <div
          key={listItem.id}
          className="item"
          onClick={() => onItemClick(listItem.id)}
        >
          <ImageBackgroundMix className="item-background">
            <FileViewer
              fileDir={FileDir.Supplier}
              type="image"
              fileName={listItem.logoFileName}
            />
          </ImageBackgroundMix>
          <div className="pick-title">{listItem.name}</div>
        </div>
      ))}
    </div>
  );
};
