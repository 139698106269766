import React, { useEffect } from "react";
import { useNavigateCallback } from "base/hooks/useNavigateCallback";
import { useTokenPayload } from "auth/hooks/useTokenPayload";
import { AuthRouteBuilder } from "auth/AuthRouteBuilder";

export const GuardedRouteScreen: React.FC = ({ children }) => {
  const tokenPayload = useTokenPayload();
  const navigate = useNavigateCallback();

  useEffect(() => {
    if (!tokenPayload) {
      navigate(AuthRouteBuilder.buildLoginRoute());
    }
  }, [navigate, tokenPayload]);

  if (tokenPayload) {
    return <>{children}</>;
  } else {
    return null;
  }
};
