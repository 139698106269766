import { Shift } from "work-schedule/types";
import { ShiftItemDuplicator } from "./ShiftItemDuplicator";

export class ShiftDuplicator {
  static duplicate(shift: Shift): Shift {
    return {
      ...shift,
      items: shift.items.map(ShiftItemDuplicator.duplicate),
      id: (undefined as any) as number,
    };
  }
}
