import React, { useMemo } from "react";
import { useCustomQuery } from "base/api/hooks/useCustomQuery";
import {
  CardsTable,
  CardsTableProps,
} from "base/components/tables/components/CardsTable";
import { StockTransactionsApi } from "stockTransactions/StockTransactionsApi";
import { StockTransactionListItem } from "stockTransactions/StockTransactionListItem";
import { TableDateCell } from "base/components/tables/components/TableDateCell";
import { UnitTypeFormatter, NumberFormatter } from "base/utils/formatters";
import { TableAmountCell } from "base/components/tables/components/TableAmountCell";

export const StockTransactionsTable = () => {
  const { data } = useCustomQuery(StockTransactionsApi.getList);

  const columns = useMemo<CardsTableProps<StockTransactionListItem>["columns"]>(
    () => [
      {
        id: "Id",
        accessor: "id",
        Header: "מזהה",
      },
      {
        id: "TransactionType",
        accessor: (row) =>
          `${row.transactionTypeCode} - ${row.transactionType}`,
        Header: "סוג תנועה",
        cellProps: {},
      },
      {
        id: "ReferenceNo",
        accessor: "referenceNo",
        Header: "אסמכתא",
        cellProps: {},
      },
      {
        id: "itemName",
        accessor: "itemName",
        Header: "פריט",
        cellProps: {},
      },
      {
        id: "SupplierName",
        accessor: "supplierName",
        Header: "ספק",
        cellProps: {},
      },
      {
        id: "amount",
        accessor: "amount",
        Header: "כמות",
        cellProps: {},
        Cell: ({ value, row }) => (
          <span>
            {NumberFormatter.default.formatAmount(value)}{" "}
            {UnitTypeFormatter.default.format(row.original.unitType, true)}
          </span>
        ),
      },
      {
        id: "price",
        accessor: "price",
        Header: "מחיר",
        cellProps: {},
        Cell: TableAmountCell,
      },
      {
        id: "ourOrderId",
        accessor: "ourOrderId",
        Header: "הזמנה",
        cellProps: {},
      },
      {
        id: "transactionDate",
        accessor: "transactionDate",
        Header: "תאריך משלוח",
        cellProps: {},
        sortType: "datetime",
        Cell: TableDateCell,
      },
      {
        id: "dateCreated",
        accessor: "dateCreated",
        Header: "יצירה",
        cellProps: {},
        sortType: "datetime",
        Cell: TableDateCell,
      },
      {
        id: "actions",
        Cell: ({ row }) => (
          <i
            className="fas fa-trash text-danger ml-auto"
            role="button"
            onClick={(e) => {
              e.stopPropagation();
              const { id } = row.original;
              StockTransactionsApi.delete(id);
            }}
          />
        ),
      },
      {
        id: "table-closer",
        Header: "",
        cellProps: {
          className: "table-closer",
        },
        disableSortBy: true,
        disableGlobalFilter: true,
      },
    ],
    []
  );

  return (
    <CardsTable
      columns={columns}
      getRowProps={(row) =>
        row.original.isCancelled ? { "data-line-through": true } : undefined
      }
      data={data}
      localStorageKey="stockTransactionsDT"
      globalFilterAllowedCols={["itemName", "SupplierName"]}
    />
  );
};
