import React from "react";
import { OrderWizardStore } from "./OrderWizardStore";
import { OrderWizardDisplayVariant } from "order/types";

export type OrderWizardContextType = {
  store: OrderWizardStore;
  setDisplayVariat: (value: OrderWizardDisplayVariant) => void;
  setRouteSearchPhrase: (routePathName: string, phrase: string) => void;
};

export const OrderWizardContext = React.createContext<
  OrderWizardContextType | undefined
>(undefined);
