import React, { TextareaHTMLAttributes } from "react";
import { FormControl, FormControlProps } from "react-bootstrap";
import { FieldRenderProps } from "react-final-form";
import { FormErrorMessage } from "../../FormErrorMessage";

export type FormTextAreaInnerProps = FieldRenderProps<any> & {
  placeholder?: string;
  className?: string;
} & Partial<FormControlProps> &
  TextareaHTMLAttributes<any>;

export const FormTextAreaInner: React.FC<FormTextAreaInnerProps> = ({
  input,
  meta,
  placeholder,
  className,
  ...textInputProps
}) => {
  return (
    <>
      <FormControl
        as="textarea"
        type="text"
        placeholder={placeholder}
        className={className}
        {...textInputProps}
        {...input}
      />
      <div>
        <FormErrorMessage>
          {meta.touched ? meta.error : meta.submitError}
        </FormErrorMessage>
      </div>
    </>
  );
};
