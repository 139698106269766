import React, { useCallback, useEffect, useRef } from "react";
import styled from "styled-components";
import {
  FormGroup,
  Col,
  FormLabel,
  ButtonGroup,
  Button,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { DevTool as ReactHookFormDevTool } from "react-hook-form-devtools";
import { useNavigateCallback } from "base/hooks/useNavigateCallback";
import { FormFileUpload } from "base/components/form-controls/FormFileUpload";
import { FormImageViewer } from "base/components/form-controls/FormImageViewer";
import { Material } from "material/types";
import { MaterialsApi } from "material/MaterialsApi";
import { MaterialsRouteBuilder } from "material/MaterialsRouteBuilder";
import { MaterialFormData } from "../types";
import { MaterialFormSchema } from "../schema";
import { MaterialFormFields } from "./MaterialFormFields";
import { TreeInfoRouteBuilder } from "tree-info/TreeInfoRouteBuilder";
import { TreeType } from "base/types";

// When rendering, <ImageViewerWrap />, will have max-width: 240.
const ImageViewerWrap = styled.div({});

export type MaterialFormProps = {
  data: Partial<Material> | undefined;
};

export const MaterialForm: React.FC<MaterialFormProps> = ({ data }) => {
  const navigate = useNavigateCallback();
  const formInstance = useForm<MaterialFormData>({
    validationSchema: MaterialFormSchema,
    defaultValues: data,
    mode: "onBlur",
  });
  const { reset } = formInstance;
  const actionRef = useRef<"save" | "saveAndGoToTreeInfo">("save");

  const onSave = useCallback(
    async (materialForm: MaterialFormData) => {
      const { image, ...nextMaterial } = materialForm;
      const savedMaterial = await MaterialsApi.saveItem(
        nextMaterial,
        image?.[0]
      );
      if (actionRef.current === "save") {
        navigate(MaterialsRouteBuilder.buildMaterialsRoute());
      } else {
        navigate(
          TreeInfoRouteBuilder.buildTreeInfoRoute(
            TreeType.Material,
            savedMaterial.id
          )
        );
      }
    },
    [navigate]
  );

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [reset, data]);

  return (
    <>
      <FormGroup className="d-flex flex-wrap">
        <Col xs={12} md={9}>
          <MaterialFormFields formInstance={formInstance} />
        </Col>
        <Col xs={12} md={3} className="align-self-start">
          <FormLabel>תמונה</FormLabel>
          <FormFileUpload formInstance={formInstance} name="image" />
          <ImageViewerWrap>
            <FormImageViewer
              formInstance={formInstance}
              pathName="imageName"
              uploadName="image"
            />
          </ImageViewerWrap>
        </Col>
      </FormGroup>
      <ButtonGroup>
        <div className="mx-2">
          <Button
            onClick={() => {
              actionRef.current = "saveAndGoToTreeInfo";
              formInstance.handleSubmit(onSave)();
            }}
          >
            שמירה ומעבר לעץ מוצר
          </Button>
        </div>
        <div className="mx-2">
          <Button
            onClick={() => {
              actionRef.current = "save";
              formInstance.handleSubmit(onSave)();
            }}
          >
            שמירה
          </Button>
        </div>
      </ButtonGroup>
      {process.env.NODE_ENV === "development" && (
        <ReactHookFormDevTool control={formInstance.control} />
      )}
    </>
  );
};
