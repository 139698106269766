import React, { useMemo, useCallback } from "react";
import { useCustomQuery } from "base/api/hooks/useCustomQuery";
import { useNavigateCallback } from "base/hooks/useNavigateCallback";
import {
  CardsTable,
  CardsTableProps,
} from "base/components/tables/components/CardsTable";
import { OrderListItem } from "order/types";
import { OrdersRouteBuilder } from "order/OrdersRouteBuilder";
import { OrdersApi } from "order/OrdersApi";
import { TableDateCell } from "base/components/tables/components/TableDateCell";
import { OrderStatusFormatter, NumberFormatter } from "base/utils/formatters";
import { FileViewer } from "base/components/FileViewer";
import { FileDir } from "base/types/Filedir";

export const OrdersTable = () => {
  const { data } = useCustomQuery(OrdersApi.getList);
  const navigate = useNavigateCallback();

  const onAddRowClick = useCallback(() => {
    navigate(OrdersRouteBuilder.buildAddOrderRoute());
  }, [navigate]);

  const onEditRowClick = useCallback(
    (data: OrderListItem) => {
      navigate(OrdersRouteBuilder.buildEditOrderRoute(data.id));
    },
    [navigate]
  );

  const columns = useMemo<CardsTableProps<OrderListItem>["columns"]>(
    () => [
      {
        id: "Id",
        accessor: "id",
        Header: "מס' הזמנה",
      },
      {
        id: "image",
        accessor: "imageName",
        Header: "",
        Cell: ({ value }) => (
          <FileViewer
            type="image"
            fileDir={FileDir.Supplier}
            fileName={value}
          />
        ),
        disableSortBy: true,
        disableGlobalFilter: true,
      },
      {
        id: "SupplierName",
        accessor: "supplierName",
        Header: "ספק",
        cellProps: {},
      },
      {
        id: "Status",
        accessor: "status",
        Header: "סטטוס",
        cellProps: {
          className: "name-col",
        },
        Cell: ({ value }) => <>{OrderStatusFormatter.format(value)}</>,
      },
      {
        id: "Amount",
        accessor: "amount",
        Header: "מחיר",
        Cell: ({ value }) => <>{NumberFormatter.default.formatPrice(value)}</>,
      },
      {
        id: "ItemsCount",
        accessor: "itemsCount",
        Header: "פריטים",
        cellProps: {},
      },
      {
        id: "CreatorName",
        accessor: "creatorName",
        Header: "שם היוצר",
        cellProps: {},
      },
      {
        id: "DateCreated",
        accessor: "dateCreated",
        Header: "תאריך יצירה",
        cellProps: {},
        Cell: TableDateCell,
      },
      {
        id: "table-closer",
        Header: "",
        cellProps: {
          className: "table-closer",
        },
        disableSortBy: true,
        disableGlobalFilter: true,
      },
    ],
    []
  );

  return (
    <CardsTable
      searchPlaceholder="חיפוש לפי שם ספק"
      columns={columns}
      data={data}
      onEditRowClick={onEditRowClick}
      onAddRowClick={onAddRowClick}
      localStorageKey="ordersDT"
      globalFilterAllowedCols={["SupplierName"]}
    />
  );
};
