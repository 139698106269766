import React, { useCallback, useState, useEffect, useRef } from "react";
import { FormControl } from "react-bootstrap";
import { FieldRenderProps } from "react-final-form";
import { usePrevious } from "base/hooks/usePrevious";
import { FormErrorMessage } from "../../FormErrorMessage";

export type FileUploadFieldInnerProps = FieldRenderProps<any>;

export const FileUploadFieldInner: React.FC<FileUploadFieldInnerProps> = ({
  input,
  meta,
  ...inputProps
}) => {
  const fileUploadRef = useRef<HTMLInputElement>(null);
  const [path, setPath] = useState("");
  const prevPath = usePrevious(path);

  const { onChange: onChangeProp, value } = input;
  const prevValue = usePrevious(value);

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setPath(e.target.value);
  }, []);

  // If path changed, fire onChange.
  useEffect(() => {
    if (prevPath !== undefined && prevPath !== path) {
      onChangeProp(fileUploadRef.current!.files?.[0]);
    }
  }, [onChangeProp, prevPath, path]);

  // If file value changed from outside this comp, reset the path.
  useEffect(() => {
    if (value !== prevValue && value !== fileUploadRef.current?.files?.[0]) {
      setPath("");
    }
  }, [value, prevValue]);

  return (
    <>
      <FormControl
        type="file"
        value={path}
        onChange={onChange}
        ref={fileUploadRef}
        {...inputProps}
      />
      <div>
        <FormErrorMessage>
          {meta.touched ? meta.error : meta.submitError}
        </FormErrorMessage>
      </div>
    </>
  );
};
